import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, Filler } from 'chart.js';



export default class BarGraph extends Component {

    constructor(props) {
        super(props)
        
        ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
            LinearScale,
            BarElement,
            Title, CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Filler,
        );
    }
    componentDidMount(){
      
    }

    state={
       
        data:{
            labels:this.props.labels?this.props.labels:[],
            datasets:this.props.datasets?this.props.datasets:[]
        },
        options : this.props.options?this.props.options:{
            responsive: true,
          }
    }


   


    render(){
        return(
            <React.Fragment>
                <Bar options={this.state.options} data={this.state.data}></Bar>
            </React.Fragment>
        )
    }
}






