import { DateFormatMAS90 } from "../../../functions/dateFormat"
import { getPriceMargin } from "../../../functions/generalFunctions"

export class TrackingSalesOrder{
    SalesOrderNo
    Type
    CustomerName
    CustomerNo
    TotalUnitCost
    TotalUnitPrice
    TrackingNo
    Date
    Detail

    constructor(SalesOrderNo,Type,Date,CustomerName,CustomerNo){
        this.SalesOrderNo=SalesOrderNo
        this.Type=Type==='S'?'Standard':'Quote'
        this.CustomerName=CustomerName
        this.CustomerNo=CustomerNo
        this.Date=DateFormatMAS90(Date)
        this.TotalUnitCost=0
        this.TotalUnitPrice=0
        this.TrackingNo=''
        this.Detail=[]
    }

    addProd(UnitCost,UnitPrice){
        this.TotalUnitCost+=UnitCost
        this.TotalUnitPrice+=UnitPrice
        this.TrackingNo=getPriceMargin(this.TotalUnitCost,this.TotalUnitPrice,'1')
    }
}

export class TrackingDetail{
    ItemCode
    ProdLine
    Description
    QuantityOrdered
    QuantityShipped
    TrackingNo

    constructor(ItemCode,ProdLine,Description,QuantityOrdered,QuantityShipped,TrackingNo){
        this.ItemCode=ItemCode
        this.ProdLine=ProdLine
        this.Description=Description
        this.QuantityOrdered=QuantityOrdered
        this.QuantityShipped=QuantityShipped
        this.TrackingNo=TrackingNo
    }

}