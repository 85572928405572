import React, { Component } from "react";
import '../../css/table-responsive.css'
import "../../css/general-style.css"
import { AuthContext } from "../../services/auth/authContext";
import { getValueCookie } from "../../services/cookieService";
import TableSalesTypeView from "../../components/TableofSalesType/TableSalesTypeView";
import { getCurrentBalance, getCustomerExcess, getCustomerHistory, getSalesByCustomer, getSalesOrdersPerUser, getSalesPerMonth, getSalesPerRoute, getShorts } from "./Functions";
import TableofPendingSalesView from "../../components/TableofPendingSales/TableofPendingSalesView";
import TableofExcessCustomerView from "../../components/TableofExcessCustomer/TableofExcessCustomerView";
import TableofCurrentBalanceView from "../../components/TableofCurrentBalance/TableofCurrentBalanceView";
import { MonthInLetters, NumberFormat } from "../../functions/generalFunctions";

import CalendarOfSales from "../../components/CalendarOfSales/CalendarOfSales";
import TableHistoryCustomerView from "../../components/TableHistoryCustomer/TableHistoryCustomerView";
import TableDetailSalesPerRouteView from "../../components/TableDetailSalesPerRoute/TableDetailSalesPerRouteView";
import LoadingWindow from '../../components/LoadingWindow';
import TableShortView from "../../components/TableShort/TableShortView";






export default class PersonalLobby extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }

    state = {
        ListOfCorporative: ['0000', 'WW'],
        SalesPerWeek: {},
        Titles: {
            PendingOrders: ''
        },
        Information: {
            SalesPerRouteDate: new Date(),
            PendingSalesOrders: [],
            SalesPerRoute: { Information: [], Months: [] },
            CustomerExcess: [],
            CurrentBalance: [],
            CustomerHistory: [],
            SalesByCustomer: [],
            SalesPerMonth: [],
            Shorts: {}
        },
        Totals: {

            SalesOrder: {
                NotStarted: 0,
                InProcess: 0,
                Finished: 0
            },
            Customer: {
                Normal: 0,
                Frequent: 0,
                Waring: 0,
                Risk: 0,
                Total: 0
            },
            TotalPendingInvoices: {
                TotalDueInv: 0,
                Range1: 0,
                Range2: 0,
                Range3: 0,
                TotalR1: 0,
                TotalR2: 0,
                TotalR3: 0
            },
            Sales:{
                NoInvoices:0,
                Total:0
            }
        }

    }


    async getInformation() {
        if (getValueCookie('TypeUser') === '7' || getValueCookie('TypeUser') === '4' || getValueCookie('TypeUser') === '1') {
            if (getValueCookie('CompanyId') === '3') {
                
                const SalesPerRoute = await getSalesPerRoute(this.props.SalesPerson)
                const PendingSalesOrder = await getSalesOrdersPerUser(this.props.SalesPerson)
                const temporal = this.state.Information
                const temporalTotals = this.state.Totals
                temporal.SalesPerRoute = SalesPerRoute
                temporal.PendingSalesOrders = PendingSalesOrder
                temporal.CustomerExcess = await getCustomerExcess(this.props.SalesPerson)
                temporal.CurrentBalance = await getCurrentBalance(this.props.SalesPerson)
                temporal.CustomerHistory = await getCustomerHistory(this.props.SalesPerson)
                temporal.SalesByCustomer = await getSalesByCustomer(this.props.SalesPerson)
                temporal.SalesPerMonth = await getSalesPerMonth(this.props.SalesPerson)
                temporal.Shorts = await getShorts(this.props.SalesPerson)
                let NotStarted = 0
                let InProcess = 0
                let Finished = 0
                let TotalCustomer = {
                    Normal: 0,
                    Frequent: 0,
                    Waring: 0,
                    Risk: 0,
                    Total: 0
                }
                let TotSales={
                    NoInvoices:0,
                    Total:0
                }
                let TotalPendingInvoices = {
                    TotalDueInv: 0,
                    Range1: 0,
                    Range2: 0,
                    Range3: 0,
                    TotalR1: 0,
                    TotalR2: 0,
                    TotalR3: 0
                }
                for (let Invoice of temporal.CurrentBalance) {
                    if (Invoice.Balance > 0) {
                        TotalPendingInvoices.TotalDueInv += Invoice.Balance
                    }
                    if (Number(Invoice.DaysDlq) <= 14) {
                        TotalPendingInvoices.Range1 += 1
                        TotalPendingInvoices.TotalR1 += Invoice.Balance > 0 ? Invoice.Balance : 0
                    } else if (Number(Invoice.DaysDlq) >= 15 && Number(Invoice.DaysDlq) <= 29) {
                        TotalPendingInvoices.Range2 += 1
                        TotalPendingInvoices.TotalR2 += Invoice.Balance > 0 ? Invoice.Balance : 0
                    } else {
                        TotalPendingInvoices.Range3 += 1
                        TotalPendingInvoices.TotalR3 += Invoice.Balance > 0 ? Invoice.Balance : 0
                    }
                }
                for (const SalesOrder of temporal.PendingSalesOrders) {
                    SalesOrder.Status = SalesOrder.Status ? SalesOrder.Status : 0
                    switch (SalesOrder.Status) {
                        case 0:
                            NotStarted += SalesOrder.NonTaxableAmt
                            break;
                        case 1:
                            InProcess += SalesOrder.NonTaxableAmt
                            break;
                        case 2:
                            Finished += SalesOrder.NonTaxableAmt
                            break;
                        default:
                            break;
                    }
                }
                for (const Customer of temporal.CustomerHistory) {
                    if (Number(Customer.NumberDays) <= 21) {
                        TotalCustomer.Frequent += 1
                    } else if (Number(Customer.NumberDays) > 21 && Number(Customer.NumberDays) <= 45) {
                        TotalCustomer.Normal += 1
                    } else if (Number(Customer.NumberDays) > 45 && Number(Customer.NumberDays) <= 90) {
                        TotalCustomer.Waring += 1
                    } else {
                        TotalCustomer.Risk += 1
                    }
                    TotalCustomer.Total += 1
                }
                for (const Sale of temporal.SalesPerMonth){
                    if(Sale.Week){
                    TotSales.NoInvoices+=Number(Sale.totalsales)
                    TotSales.Total+=Number(Sale.total)
                }
                }


                temporalTotals.Sales=TotSales
                temporalTotals.SalesOrder.NotStarted = NotStarted
                temporalTotals.SalesOrder.InProcess = InProcess
                temporalTotals.SalesOrder.Finished = Finished
                temporalTotals.Customer = TotalCustomer
                temporalTotals.TotalPendingInvoices = TotalPendingInvoices
                await this.setState({ Information: temporal, Totals: temporalTotals })
                await this.asignTotalsCalendar()
            } else {
                const PendingSalesOrder = await getSalesOrdersPerUser(this.props.SalesPerson)
                const temporal = this.state.Information
                temporal.PendingSalesOrders = PendingSalesOrder
                this.setState({ Information: temporal })
            }
            
        }
    }

    asignTotalsCalendar() {


        for (const week of this.state.Information.SalesPerMonth) {

            if (week.Week) {

                let m = document.getElementById('quant_' + week.Week + "_" + getValueCookie('SalesPerson'))
                let n = document.getElementById('sales_' + week.Week + "_" + getValueCookie('SalesPerson'))
                if (m) {
                    m.innerHTML = NumberFormat(Number(Number(week.totalsales).toFixed(0)))
                }
                if (n) {
                    n.innerHTML = "$" + NumberFormat(Number(Number(week.total).toFixed(0)))

                }
            }


        }
    }

    render() {
        return (
            <div >
                <LoadingWindow ref={this.ModalLoading} />
                <button hidden id={"LobbyInfoCharge"} onClick={() => this.getInformation()}></button>
                {getValueCookie('TypeUser') === '7' || getValueCookie('TypeUser') === '4' || getValueCookie('TypeUser') === '1' ?
                    <div className="row pb-4 justify-content-between backgroundGrayLow">
                        <div className="col-sm-6">
                            <div  className="col-11 pt-2 pb-3">
                                <TableSalesTypeView Father={this} Date={this.state.Information.SalesPerRouteDate} Month={MonthInLetters(this.state.Information.SalesPerRouteDate.getMonth() + 1) + " " + this.state.Information.SalesPerRouteDate.getFullYear()} data={this.state.Information.SalesPerRoute} />
                            </div>
                            <div  className="col-11 pt-2 pb-3">
                                <TableDetailSalesPerRouteView Title={"Sales by Customer"} data={this.state.Information.SalesByCustomer} />
                            </div>
                            <div  className="col-11 pt-2 pb-3">
                                <CalendarOfSales Totals={this.state.Totals.Sales} SalesPerson={getValueCookie('SalesPerson')} Father={this} data={this.state.Information.SalesPerMonth} Title={"Sales Per Day"} />
                            </div>
                            <div  className="col-11 pt-2 pb-3">
                                <TableShortView SalesPerson={getValueCookie('SalesPerson')} data={this.state.Information.Shorts} Title={"Shorts of Last 15 Days"} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div  className="col-sm-12">

                                    <div className="row">
                                        <div className="col-12 pt-2 pb-3">
                                            <TableofCurrentBalanceView Title={"Past Due Invoices"} Totals={this.state.Totals.TotalPendingInvoices} data={this.state.Information.CurrentBalance} />
                                        </div>
                                    </div>
                                </div>
                                <div  className="col-sm-12">

                                    <div className="row">
                                        <div className="col-12 pt-2 pb-3">
                                            <TableofExcessCustomerView Title={"Customers with Exceeded Credit Limit"} Totals={this.state.Totals.Customer} data={this.state.Information.CustomerExcess} />
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-12">

                                    <div className="row">
                                        <div className="col-12 pt-2 pb-3">
                                            <TableofPendingSalesView Totals={this.state.Totals.SalesOrder} Title={"Actual Sales Order"} data={this.state.Information.PendingSalesOrders} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">

                                    <div className="row">
                                        <div className="col-12 pt-2 pb-3">
                                            <TableHistoryCustomerView Title={"# Days Of Last Sale"} Totals={this.state.Totals.Customer} data={this.state.Information.CustomerHistory} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    : <></>}

            </div>
        )
    }
}