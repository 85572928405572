import { StyleSheet } from '@react-pdf/renderer'

const pdfConfig = StyleSheet.create({
HeaderStyle: {
        backgroundColor: "rgb(255, 255, 255)",
        color: "rgb(0, 0, 0)",
    },
    HeaderMargin: {
        top: "30px",
        height: "110px"
    },
    BusinessLogo: {
        width: "150px",
        left: "35px",
        top: "10px",

    },
    ContactInfo: {
        textAlign: "justify",
        fontSize: "12px",
        top: "-30px",
        left: "220px"
    },
    marginInfo: {
        width: "80%",
        marginLeft: "12%",
        display: "flex",
        flexDirection: "row",
        marginBottom: "2px",
        color: "rgb(255, 255, 255)"

    },
    OrderTitleStyle: {
        color: "rgb(0, 0, 0)",
        textAlign: "center",
        fontSize: "20px",
        marginTop: "0px",
        marginBottom: "15px"
    },
    OrderInformation: {
        width: "100%",
        backgroundColor: "rgb(255, 255, 255)",
    },
    TitleStyle: {
        textAlign: "left",
        fontSize: "10px",
        width: "50%",
        color: "rgb(0, 0, 0)",
    },

    TextSeparator: {
        marginBottom: "4px"
    },
    InfoEncabezadoStyle: {
        width: "30%",
        fontSize: "12px",
    },
    TextSeparator2: {
        marginTop: "1px",
        marginBottom: "3.5px"
    },

    Table: {
        width: "92%",
        marginLeft: "4%",
        marginTop: "9px"

    },

    CabeceraTabla: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        borderBottom: '1px',
        borderColor: 'black',
        borderStyle: 'dotted',
    },
    CuerpoTabla: {
        width: "100%",
        textAlign: "center",
        fontSize: "8px",
        borderBottom: '1px',
        borderColor: 'black',
        borderStyle: 'dotted',

    },
    Fila: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    FilaBreak: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        paddingTop: "2px"
    },
    ColumnaPie: {

        textAlign: "center",
        fontSize: "10px",
    },
    ColumnaTextoNormal: {
        fontSize: "8px",
        width: "12.5%",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargo: {
        width: "25%",

        fontSize: "8px",
        textAlign: "left",
        display: "block",

    },
    ColumnaTextoLargoCenter: {
        width: "20%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaCantidades: {
        width: "8%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },

    ColumnaTextoNormalTitulo: {
        fontSize: "8px",
        width: "12.5%",
        textAlign: "center",
        display: "block"
    },
    ColumnaTextoLargoTitulo: {
        width: "25%",

        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargo4Titulo: {
        width: "70%",

        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargoCenterTitulo: {
        width: "20%",

        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },

    ColumnaCantidadesTitulo: {
        width: "8%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargo2: {
        width: "35%",
        fontSize: "8px",
        textAlign: "left",
        display: "block",

    },
    ColumnaTextoLargo2Titulo: {
        width: "35%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargo3: {
        width: "50%",
        fontSize: "8px",
        textAlign: "left",
        display: "block",

    },
    ColumnaTextoLargo4: {
        width: "70%",
        fontSize: "8px",
        textAlign: "left",
        display: "block",

    },
    ColumnaTextoLargo3Titulo: {
        width: "50%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoNormal2: {
        fontSize: "8px",
        width: "25%",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoNormal2Titulo: {
        fontSize: "8px",
        width: "25%",
        textAlign: "center",
        display: "block",

    },

    CommentStyle: {
        marginTop: '15px',
        fontSize: "8px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        textAlign: "justify",
        borderBottom: '1px',
        borderRight: '1px',
        borderLeft: '1px',
        borderTop: '1px',
        borderColor: 'black',
        borderStyle: 'solid',
    },
    ColumnaHeader: {
        width: "35%",
        fontSize: "11px",
        textAlign: "left",
        display: "block",
        color: "#000000"
    },
    ColumnaHeader2: {
        width: "15%",
        fontSize: "11px",
        textAlign: "left",
        display: "block",
        color: "#000000",

    },
    ColumnaTextoNormalLeft: {
        fontSize: "8px",
        width: "12.5%",
        textAlign: "left",
        display: "block",

    },
    ColumnaPrecios: {
        width: "8%",
        fontSize: "8px",
        textAlign: "right",
        display: "block",

    },
    ColumnaPreciosTitulo: {
        width: "8%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoNormalLeftTitulo: {
        fontSize: "8px",
        width: "12.5%",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoNormalBottom: {
        fontSize: "8px",
        width: "12.5%",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargoBottom: {
        width: "25%",

        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargoCenterBottom: {
        width: "20%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },

    ColumnaCantidadesBottom: {
        width: "8%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargo2Bottom: {
        width: "35%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargo3Bottom: {
        width: "50%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoLargo4Bottom: {
        width: "70%",
        fontSize: "8px",
        textAlign: "center",
        display: "block",

    },
    ColumnaTextoNormal2Bottom: {
        fontSize: "8px",
        width: "25%",
        textAlign: "center",
        display: "block",

    },
    ColumnaPreciosBottom: {
        width: "8%",
        fontSize: "8px",
        textAlign: "right",
        display: "block",

    },
    ColumnaTextoNormalLeftBottom: {
        fontSize: "8px",
        width: "12.5%",
        textAlign: "center",
        display: "block",

    },

    ColumnaTextoNormal2Left: {
        fontSize: "8px",
        width: "25%",
        textAlign: "left",
        display: "block",

    },
    ColumnaTextoNormal2LeftTitulo: {
        fontSize: "8px",
        width: "25%",
        textAlign: "center",
        display: "block",

    },

    ColumnaTextoNormal2LeftBottom: {
        fontSize: "8px",
        width: "25%",
        textAlign: "center",
        display: "block",

    },
    DolarTitulo: {
        width: "2.5%",
        fontSize: "8px",
        textAlign: "right",
        display: "block",

    },
    Dolar: {
        width: "2.5%",
        fontSize: "8px",
        textAlign: "right",
        display: "block",

    },
    DolarBottom: {
        width: "2.5%",
        fontSize: "8px",
        textAlign: "right",
        display: "block",

    }
});

export default pdfConfig;