import React, { Component } from 'react'
//, BsFileArrowUpFill, BsFileArrowDownFill
import { BsArrowRepeat, BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";

import { getInformationWithData } from '../../../services/CABE';
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import LoadingWindow from '../../../components/LoadingWindow';
import { getValueCookie } from '../../../services/cookieService';
//import { getIndexElement } from '../../../functions/searchInObject';
//DateFormatMAS90, FormatQueryReturnDate, getDateYearMonthDayDash
import { DateFormatMAS90, DeleteQueryFormat, formatInputDateQuerytoInput, FormatQueryReturnDate, getDateFromReports, getIntervalAbleDays, ReplaceDashForLateralBar } from '../../../functions/dateFormat';
//NumberFormat
import { ItemDesc, NumberFormat, OrderArray } from '../../../functions/generalFunctions';
//import { pricesFormat } from '../../../functions/pricesFormat';
import ModalOrders from '../../../components/ModalComponent';
import { DayPerAdjustment, Inventory, InventoryProductLine } from './Class';
import { getIndexElement, getIndexElement2 } from '../../../functions/searchInObject';
import { pricesFormat } from '../../../functions/pricesFormat';
import ExcelDocument from '../../../components/ExcelDocument';
import { getDataSet } from '../../../functions/generateDataSetExcel';



export default class HistoryInventoryReport extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        showModal1: false,
        order: false,
        History: [],
        FilterHistory: [],
        Detail: [],
        FilterDetail: [],
        ProdLine: [],
        ProdLineFilter: [],
        InfoProdLine: {},
        CurrentInvent: {
            NoInventory: '',
            QuantityDays: '',
            CloseDate: '',
            QuantityItemsCount: 0,
            ValuedAccuracy: 0,
            BinaryAccuracy: 0,
            Adjustments: 0,
            KPI: 0,
            TotalQuantityItemsError: 0,
            TotalValuedInventory: 0,
            TotalQuantityItemsOk: 0,
            Detail: [],
            DaysPerAdjustmenst: [],
            AllErrorItems: [],
            AllWrongDifferences: []
        },
        FilterCurrentInvent: {
            NoInventory: '',
            QuantityDays: '',
            CloseDate: '',
            QuantityItemsCount: 0,
            ValuedAccuracy: 0,
            BinaryAccuracy: 0,
            Adjustments: 0,
            KPI: 0,
            TotalQuantityItemsError: 0,
            TotalValuedInventory: 0,
            TotalQuantityItemsOk: 0,
            Detail: [],
            DaysPerAdjustmenst: [],
            AllErrorItems: [],
            AllWrongDifferences: []
        }
    }
    OrderInformation(array, key, asc, type) {
        const order = OrderArray(array, key, asc)

        this.setState({ [type]: order, order: !asc })

    }
    Order(array, key, asc) {
        const order = OrderArray(array, key, asc)
        return order
    }
    OrderSpecificInformation(key, asc, type) {
        const temporal = this.state.FilterCurrentInvent
        temporal[type] = OrderArray(temporal[type], key, asc)
        this.setState({ FilterCurrentInvent: temporal, order: !asc })


    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchDetailCycleInventory = e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.Detail
        } else {
            Filter = this.state.Detail.filter((detail) => {
                if (this.Contains(detail.Name, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterDetail: Filter })
    }

    searchDetailErrorCycleInventory = e => {
        const searchValue = e.target.value
        let Filter = {}
        if (searchValue === '') {
            Filter = JSON.parse(JSON.stringify(this.state.CurrentInvent))
        } else {
            Filter = JSON.parse(JSON.stringify(this.state.FilterCurrentInvent))
            Filter.AllErrorItems = this.state.CurrentInvent.AllErrorItems.filter((detail) => {
                if (this.Contains(detail.ItemCode, searchValue) ||
                    this.Contains(detail.productLine, searchValue) ||
                    this.Contains(detail.Description, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterCurrentInvent: Filter })
    }
    searchAllWrongDifferences = e => {
        const searchValue = e.target.value
        let Filter = {}
        if (searchValue === '') {
            Filter = JSON.parse(JSON.stringify(this.state.CurrentInvent))
        } else {
            Filter = JSON.parse(JSON.stringify(this.state.FilterCurrentInvent))
            Filter.AllWrongDifferences = this.state.CurrentInvent.AllWrongDifferences.filter((detail) => {
                if (this.Contains(detail.ItemCode, searchValue) ||
                    this.Contains(detail.productLine, searchValue) ||
                    this.Contains(detail.Description, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterCurrentInvent: Filter })
    }

    searchProdLineFilter = e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.ProdLine
        } else {
            Filter = this.state.ProdLine.filter((detail) => {
                if (this.Contains(detail.ItemCode, searchValue) ||
                    this.Contains(detail.productLine, searchValue) ||
                    this.Contains(detail.Description, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ ProdLineFilter: Filter })
    }

    async getDetailActualInventory() {
        this.ModalLoading.current.showState(true);
        const data = {
            idcompany: getValueCookie('CompanyId'),
            company: getValueCookie('Company'),
            ItemCode: '',

        }
        //Obtiene las cabeceras de los inventarios ciclicos cerrados
        const datos = await getInformationWithData('/invertory/oldCycleInventorys/post', data)
        //Se obtienen los ajustes desde el inventario 1 hasta la fecha

        let ListofInventory = []
        data.Start = ReplaceDashForLateralBar(formatInputDateQuerytoInput(datos.data[0].startDate))
        data.End = ReplaceDashForLateralBar(formatInputDateQuerytoInput(datos.data[datos.data.length - 1].realFinishDate))
        const costs = await getInformationWithData('/adjustment/history/getByItemCode', data)

        if (datos.status.code === 1) {
            for (const inventory of datos.data) {
              
                let inv = new Inventory(inventory.CycleInfo, getIntervalAbleDays(inventory.realFinishDate,inventory.startDate), inventory.realFinishDate2)
                data.id = inventory.id
                const detailInventory = await getInformationWithData('/inventory/cycledetail/post', data)
                detailInventory.data= await ItemDesc(detailInventory.data,'Description','ItemCode')
                for (const detail of detailInventory.data) {

                    const index = getIndexElement(inv.Detail, 'Name', detail.productLine)
                    const index2 = getIndexElement2(costs.data, 'ItemCode', 'Date', detail.ItemCode, DeleteQueryFormat(detail.date))
                    detail.TotalCost = index2 !== -1 ? costs.data[index2].ExtendedCost : 0.00
                    detail.MAS90Adjustment = index2 !== -1 ? costs.data[index2].NewQuantity : 0
                    detail.MAS90AdjCost = index2 !== -1 ? costs.data[index2].ExtendedCost : 0.00
                    inv.addAdjustment(index2 !== -1 ? costs.data[index2].ExtendedCost : 0.00)
                    if (index2 !== -1) {
                        const index3 = getIndexElement(inv.DaysPerAdjustmenst, 'Day', DateFormatMAS90(costs.data[index2].Date))
                        if (index3 !== -1) {
                            inv.DaysPerAdjustmenst[index3].addValue(costs.data[index2].ExtendedCost)
                        } else {
                            const newDayAdj = new DayPerAdjustment(DateFormatMAS90(costs.data[index2].Date), inv.DaysPerAdjustmenst.length)
                            newDayAdj.addValue(costs.data[index2].ExtendedCost)
                            inv.DaysPerAdjustmenst.push(newDayAdj)
                        }
                    }

                    if (index !== -1) {
                        detail.TotalVal = detail.realQuantity * detail.LastTotalUnitCost
                        detail.TotalAdjustment = detail.difference * detail.LastTotalUnitCost
                        inv.Detail[index].LastDate = FormatQueryReturnDate(detail.date)
                        inv.Detail[index].addItem()
                        inv.Detail[index].addCountItem()
                        inv.addItemCount()
                        detail.difference === 0 ? inv.Detail[index].addOkItem() : inv.Detail[index].addWrongItem()
                        detail.difference === 0 ? inv.addItemOk() : inv.addItemWrong()
                        detail.TotalVal = detail.realQuantity * detail.LastTotalUnitCost
                        detail.TotalAdjustment = detail.difference * detail.LastTotalUnitCost
                        inv.Detail[index].calculateBinaryAccuracy()
                        inv.Detail[index].calculateAdvance(detailInventory.data.length)
                        inv.Detail[index].calculateTotalCost(detail.TotalCost)
                        inv.Detail[index].addAcumulated(detail.TotalVal)
                        inv.Detail[index].ListProducts.push(detail)

                    } else {
                        //Nuevo product line
                        detail.TotalVal = detail.realQuantity * detail.LastTotalUnitCost
                        detail.TotalAdjustment = detail.difference * detail.LastTotalUnitCost
                        let newProdLine = new InventoryProductLine(detail.productLine)
                        newProdLine.LastDate = FormatQueryReturnDate(detail.date)
                        newProdLine.addAcumulated(detail.TotalVal)
                        newProdLine.addItem()
                        newProdLine.addCountItem()
                        inv.addItemCount()
                        detail.difference === 0 ? newProdLine.addOkItem() : newProdLine.addWrongItem()
                        detail.difference === 0 ? inv.addItemOk() : inv.addItemWrong()
                        newProdLine.calculateBinaryAccuracy()
                        newProdLine.calculateAdvance(detailInventory.data.length)
                        newProdLine.calculateTotalCost(detail.TotalCost)
                        newProdLine.ListProducts.push(detail)
                        inv.Detail.push(newProdLine)
                    }

                    if (detail.difference !== 0) {
                        inv.AllErrorItems.push(detail)
                    }
                    if (detail.difference !== detail.MAS90Adjustment) {
                        inv.AllWrongDifferences.push(detail)
                    }
                }



                inv.calculateBinaryAccuracy()
                inv.calculateTotalValuedInventory()
                inv.calculateValuedAccuracy()
                inv.calculateKPI()
                ListofInventory.push(inv)
            }
        }

        this.setState({ History: ListofInventory, FilterHistory: ListofInventory })

        this.ModalLoading.current.showState(false);
    }



    SelectCycleInvDet() {
        var button = document.getElementById('detailCycleInv')
        if (button) {
            button.click()
        }
    }
    SelectHistory(det) {
        this.setState({ CurrentInvent: det, FilterCurrentInvent: det, Detail: det.Detail, FilterDetail: det.Detail })
        this.SelectCycleInvDet()
    }
    SelectSpecificProdLine(det) {
        this.setState({ ProdLine: det.ListProducts, ProdLineFilter: det.ListProducts, InfoProdLine: det })

        this.handleModalOpen("showModal1")
    }


    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Report History KPI Inventory</p>
                <div className='row'>
                    <div className='col-1 pb-3'></div>
                    <div className='col-10 pb-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getDetailActualInventory()} type="button" className="btn blueButton btn-lg">Get Information <BsArrowRepeat /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1 pb-3'></div>
                    <div className='col-12 pb-2' hidden={this.state.History.length === 0}>
                        <ul className="nav nav-tabs" id="historyControls" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#generalCycle" type="button" role="tab" aria-controls="generalCycle" aria-selected="true">All Cycle Inventory</button>
                            </li>
                            <li hidden={this.state.Detail.length === 0} className="nav-item" role="presentation">
                                <button id='detailCycleInv' className="nav-link" data-bs-toggle="tab" data-bs-target="#detailCycle" type="button" role="tab" aria-controls="detailCycle" aria-selected="false">Detail Cycle Inventory </button>
                            </li>
                            <li hidden={this.state.Detail.length === 0} className="nav-item" role="presentation">
                                <button id='errorItemsInv' className="nav-link" data-bs-toggle="tab" data-bs-target="#errorItems" type="button" role="tab" aria-controls="errorItems" aria-selected="false">All Wrong Items</button>
                            </li>
                            <li hidden={this.state.Detail.length === 0} className="nav-item" role="presentation">
                                <button id='adjustmentsErrorInv' className="nav-link" data-bs-toggle="tab" data-bs-target="#adjustmentsError" type="button" role="tab" aria-controls="adjustmentsError" aria-selected="false">Not Equal Adjustments</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="allCycleHeader">
                            <div className="tab-pane fade show active" id="generalCycle" role="tabpanel" aria-labelledby="generalCycle">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-3'>
                                        <ExcelDocument
                                            hidden={false}
                                            archname={'History KPI Cycle Inventory ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'History KPI Cycle Inventory ' + getValueCookie('Company')}
                                            data={getDataSet(this.state.FilterHistory, ['Cycle', 'Days Count Complete Cycle', 'End Date', 'Count Items', 'Binary Accuracy', 'Valued Accuracy', 'Total Valued ($)', 'Adjustments ($)', 'KPI'], ['NoInventory', 'QuantityDays', 'CloseDate', 'QuantityItemsCount', 'BinaryAccuracy', 'ValuedAccuracy', 'TotalValuedInventory', 'Adjustments', 'KPI'])}

                                        />
                                    </div>
                                    <div hidden={this.state.History.length === 0} className='col-12 pb-2 tableFixHead'>

                                        <table className='table align-middle'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'NoInventory', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>Cycle</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'QuantityDays', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>Days Count Complete Cycle</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'CloseDate', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>End Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'QuantityItemsCount', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>Count Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'BinaryAccuracy', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>Binary Accuracy</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'ValuedAccuracy', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>Valued Accuracy</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ><div className='row'><div className='col-12'></div><div className='col-12'></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'TotalValuedInventory', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>Total Valued ($)</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ><div className='row'><div className='col-12'></div><div className='col-12'></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'Adjustments', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>Adjustments</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterHistory, 'KPI', this.state.order, 'FilterHistory')}><div className='row'><div className='col-12'>KPI</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>

                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.FilterHistory.map((det, d) => (
                                                        <tr className={det.NoInventory === this.state.CurrentInvent.NoInventory ? 'bg-warning' : ''} onClick={() => this.SelectHistory(det)} key={d}>
                                                            <td className='text-center'>{det.NoInventory}</td>
                                                            <td className='text-center'>{det.QuantityDays}</td>
                                                            <td className='text-center'>{det.CloseDate}</td>
                                                            <td className='text-center'>{det.QuantityItemsCount}</td>
                                                            <td className='text-center'>{pricesFormat(det.BinaryAccuracy) + " %"}</td>
                                                            <td className='text-center'>{pricesFormat(det.ValuedAccuracy) + " %"}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(det.TotalValuedInventory))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(det.Adjustments))}</td>
                                                            <td className='text-center'>{NumberFormat(pricesFormat(det.KPI)) + " %"}</td>
                                                        </tr>

                                                    ))
                                                }
                                            </tbody>
                                            <tfoot className='tfoot'>
                                                <tr className='bg-secondary text-white'>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>

                                            </tfoot>

                                        </table>

                                    </div>

                                </div>
                            </div>


                            <div className="tab-pane fade" id="detailCycle" role="tabpanel" aria-labelledby="detailCycle">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-3'>
                                        <input onChange={this.searchDetailCycleInventory} className="form-control form-control-lg" placeholder='Search by Product Line' id='searchDetailCycleInventory' type="text" />
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <ExcelDocument
                                            hidden={false}
                                            archname={'Cycle Inventory KPI Product Lines' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'Cycle Inventory KPI Product Lines' + getValueCookie('Company')}
                                            data={getDataSet(this.state.FilterDetail, ['Product Line', 'Date', 'Total Items', 'Ok Items', 'Error Items', 'Binary Accuracy', 'Valued Accuracy', 'Percentage of Advance', 'Total Accumulated ($)', 'Total Valued ($)'], ['Name', 'LastDate', 'TotalItem', 'TotalOKItem', 'TotalWrongItem', 'BinaryAccuracy', 'ValuedAccuracy', 'Advance', 'TotalCost', 'Accumulated'])}

                                        />
                                    </div>
                                    <div hidden={this.state.Detail.length === 0} className='col-12 pb-2 tableFixHead'>

                                        <table className='table align-middle'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'Name', this.state.order, 'FilterDetail')} ><div className='row'><div className='col-12'>Product Line </div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'LastDate', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'TotalItem', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Total Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'TotalOKItem', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Ok Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'TotalWrongItem', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Error Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'BinaryAccuracy', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Binary Accuracy</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'ValuedAccuracy', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Valued Accuracy</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'Advance', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Percentage of Advance</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'TotalCost', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Total Accumulated ($)</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterDetail, 'Accumulated', this.state.order, 'FilterDetail')}><div className='row'><div className='col-12'>Total Valued ($)</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>

                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.FilterDetail.map((proLine, i) => (
                                                        <tr onClick={() => this.SelectSpecificProdLine(proLine)} key={i}>
                                                            <td className='text-start'>{proLine.Name}</td>
                                                            <td className='text-center'>{proLine.LastDate}</td>
                                                            <td className='text-center'>{proLine.TotalItem}</td>
                                                            <td className='text-center'>{proLine.TotalOKItem}</td>
                                                            <td className='text-center'>{proLine.TotalWrongItem}</td>
                                                            <td className='text-center'>{pricesFormat(proLine.BinaryAccuracy) + " %"}</td>
                                                            <td className='text-center'>{pricesFormat(proLine.ValuedAccuracy) + " %"}</td>
                                                            <td className='text-center'>{pricesFormat(proLine.Advance) + " %"}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalCost))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.Accumulated))}</td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                            <tfoot className='tfoot'>
                                                <tr className='bg-secondary text-white'>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'>TOTALS</td>
                                                    <td className='text-center'>{NumberFormat(Number(this.state.CurrentInvent.QuantityItemsCount))}</td>
                                                    <td className='text-center'>{this.state.CurrentInvent.TotalQuantityItemsOk}</td>
                                                    <td className='text-center'>{this.state.CurrentInvent.TotalQuantityItemsError}</td>
                                                    <td className='text-center'>{pricesFormat(this.state.CurrentInvent.BinaryAccuracy) + " %"}</td>
                                                    <td className='text-center'>{pricesFormat(this.state.CurrentInvent.ValuedAccuracy) + " %"}</td>
                                                    <td className='text-center'></td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(this.state.CurrentInvent.Adjustments))}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(this.state.CurrentInvent.TotalValuedInventory))}</td>
                                                </tr>

                                            </tfoot>

                                        </table>

                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="errorItems" role="tabpanel" aria-labelledby="errorItems">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-3'>
                                        <input onChange={this.searchDetailErrorCycleInventory} className="form-control form-control-lg" placeholder='Search by ItemCode Product Line Description' id='searchDetailErrorCycleInventory' type="text" />
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <ExcelDocument
                                            hidden={false}
                                            archname={'Cycle Inventory All Error Items: '+ getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'Cycle Inventory All Error Items: '+this.state.InfoProdLine.Name+" " + getValueCookie('Company')}
                                            data={getDataSet(this.state.FilterCurrentInvent.AllErrorItems, ['Item Code','Product Line','Description', 'BIN', 'System Quantity', 'Real Quantity', 'System Difference', 'MAS90 Diffrence', 'System Total Cost ($)', 'MAS90 Total Cost ($)', 'Total Valued ($)', 'Date', 'Counted By', 'Comment'], ['ItemCode','productLine', 'Description', 'BIN', 'systemQuantity', 'realQuantity', 'difference', 'MAS90Adjustment', 'TotalAdjustment', 'MAS90AdjCost', 'TotalVal', 'date', 'countBy', 'comentary'])}

                                        />
                                    </div>

                                    <div hidden={this.state.Detail.length === 0} className='col-12 pb-2 tableFixHead'>
                                        <table className='table align-middle'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('ItemCode', this.state.order, 'AllErrorItems')} ><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('productLine', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('Description', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('BIN', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>BIN</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('systemQuantity', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>System Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('realQuantity', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Real Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('difference', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>System Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('MAS90Adjustment', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>MAS90 Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('TotalAdjustment', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Total Cost System</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('MAS90AdjCost', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Total Cost MAS90</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('TotalVal', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Total Valued</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('date', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('countBy', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Counted By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('comentary', this.state.order, 'AllErrorItems')}><div className='row'><div className='col-12'>Comment</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.FilterCurrentInvent.AllErrorItems.map((proLine, i) => (
                                                        <tr className={proLine.difference !== proLine.MAS90Adjustment ? 'bg-danger' : ''} key={i}>
                                                            <td className='text-start'>{proLine.ItemCode}</td>
                                                            <td className='text-start'>{proLine.productLine}</td>
                                                            <td className='text-start'>{proLine.Description}</td>
                                                            <td className='text-center'>{proLine.BIN || proLine.BIN === 'null' ? 'Not Available' : proLine.BIN}</td>
                                                            <td className='text-center'>{proLine.systemQuantity}</td>
                                                            <td className='text-center'>{proLine.realQuantity}</td>
                                                            <td className='text-center'>{proLine.difference}</td>
                                                            <td className='text-center'>{proLine.MAS90Adjustment}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{pricesFormat(proLine.TotalAdjustment)}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{pricesFormat(proLine.MAS90AdjCost)}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{pricesFormat(proLine.TotalVal)}</td>
                                                            <td className='text-center'>{proLine.date !== "" ? FormatQueryReturnDate(proLine.date) : 'Not Counted Yet'}</td>
                                                            <td className='text-center'>{proLine.countBy !== "" ? proLine.countBy : 'Not Counted Yet'}</td>
                                                            <td className='text-start'>{proLine.countBy !== "" ? proLine.comentary : 'Not Counted Yet'}</td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                        </table>


                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="adjustmentsError" role="tabpanel" aria-labelledby="adjustmentsError">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-3'>
                                        <input onChange={this.searchAllWrongDifferences} className="form-control form-control-lg" placeholder='Search by ItemCode Product Line Description' id='searchAllWrongDifferences' type="text" />
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <ExcelDocument
                                            hidden={false}
                                            archname={'Cycle Inventory All Error Items: '+ getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'Cycle Inventory All Error Items: '+this.state.InfoProdLine.Name+" " + getValueCookie('Company')}
                                            data={getDataSet(this.state.FilterCurrentInvent.AllWrongDifferences, ['Item Code','Product Line','Description', 'BIN', 'System Quantity', 'Real Quantity', 'System Difference', 'MAS90 Diffrence', 'System Total Cost ($)', 'MAS90 Total Cost ($)', 'Total Valued ($)', 'Date', 'Counted By', 'Comment'], ['ItemCode','productLine', 'Description', 'BIN', 'systemQuantity', 'realQuantity', 'difference', 'MAS90Adjustment', 'TotalAdjustment', 'MAS90AdjCost', 'TotalVal', 'date', 'countBy', 'comentary'])}

                                        />
                                    </div>
                                    <div hidden={this.state.Detail.length === 0} className='col-12 pb-2 tableFixHead'>
                                        <table className='table align-middle'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('ItemCode', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('productLine', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('Description', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('BIN', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>BIN</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('systemQuantity', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>System Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('realQuantity', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Real Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('difference', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>System Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('MAS90Adjustment', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>MAS90 Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('TotalAdjustment', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Total Cost System</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('MAS90AdjCost', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Total Cost MAS90</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('TotalVal', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Total Valued</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('date', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('countBy', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Counted By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderSpecificInformation('comentary', this.state.order, 'AllWrongDifferences')}><div className='row'><div className='col-12'>Comment</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.FilterCurrentInvent.AllWrongDifferences.map((proLine, i) => (
                                                        <tr key={i}>
                                                            <td className='text-start'>{proLine.ItemCode}</td>
                                                            <td className='text-start'>{proLine.productLine}</td>
                                                            <td className='text-start'>{proLine.Description}</td>
                                                            <td className='text-center'>{proLine.BIN || proLine.BIN === 'null' ? 'Not Available' : proLine.BIN}</td>
                                                            <td className='text-center'>{proLine.systemQuantity}</td>
                                                            <td className='text-center'>{proLine.realQuantity}</td>
                                                            <td className='text-center'>{proLine.difference}</td>
                                                            <td className='text-center'>{proLine.MAS90Adjustment}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{pricesFormat(proLine.TotalAdjustment)}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{pricesFormat(proLine.MAS90AdjCost)}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{pricesFormat(proLine.TotalVal)}</td>
                                                            <td className='text-center'>{proLine.date !== "" ? FormatQueryReturnDate(proLine.date) : 'Not Counted Yet'}</td>
                                                            <td className='text-center'>{proLine.countBy !== "" ? proLine.countBy : 'Not Counted Yet'}</td>
                                                            <td className='text-start'>{proLine.countBy !== "" ? proLine.comentary : 'Not Counted Yet'}</td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                        </table>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>




                </div>

                <ModalOrders title={'Inventory Detail Product Line: '+this.state.InfoProdLine.Name} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Product Line:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.InfoProdLine.Name}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Total Items: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.InfoProdLine.TotalItem}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Counted Items: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.InfoProdLine.TotalCountItem}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Ok Items: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.InfoProdLine.TotalOKItem}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-6 titleTotal'>
                                            <p>Error Items:</p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.InfoProdLine.TotalWrongItem}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Binary Accuracy: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{pricesFormat(this.state.InfoProdLine.BinaryAccuracy) + '%'}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Percentage of Advance: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{pricesFormat(this.state.InfoProdLine.Advance) + '%'}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-1'></div>
                        <div className='col-10 pb-3'>
                            <input onChange={this.searchProdLineFilter} className="form-control form-control-lg" placeholder='Search by ItemCode Product Line Description' id='searchProdLineFilter' type="text" />

                        </div>
                        <div className='col-1'></div>
                        <div className='col-12 pb-3'>
                            <ExcelDocument
                                hidden={false}
                                archname={'Cycle Inventory Items Product Line: ' + this.state.InfoProdLine.Name + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                sheetname={'Cycle Inventory Items Product Line: ' + this.state.InfoProdLine.Name + " " + getValueCookie('Company')}
                                data={getDataSet(this.state.ProdLineFilter, ['Item Code', 'Description', 'BIN', 'System Quantity', 'Real Quantity', 'System Difference', 'MAS90 Diffrence', 'System Total Cost ($)', 'MAS90 Total Cost ($)', 'Total Valued ($)', 'Date', 'Counted By', 'Comment'], ['ItemCode', 'Description', 'BIN', 'systemQuantity', 'realQuantity', 'difference', 'MAS90Adjustment', 'TotalAdjustment', 'MAS90AdjCost', 'TotalVal', 'date', 'countBy', 'comentary'])}

                            />
                        </div>
                        <div className='col-12 pb-5 tableFixHead'>

                            <table className='table align-middle'>
                                <thead className='thead'>
                                    <tr className='text-light text-center'>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'ItemCode', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'Description', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'BIN', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>BIN</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'systemQuantity', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>System Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'realQuantity', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Real Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'difference', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>System Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'MAS90Adjustment', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>MAS90 Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'TotalAdjustment', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Total Cost System</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'MAS90AdjCost', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Total Cost MAS90</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'TotalVal', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Total Valued</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'date', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'countBy', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Counted By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ProdLineFilter, 'comentary', this.state.order, 'ProdLineFilter')}><div className='row'><div className='col-12'>Comment</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {
                                        this.state.ProdLineFilter.map((proLine, i) => (
                                            <tr className={proLine.difference !== proLine.MAS90Adjustment ? 'bg-danger' : ''} key={i}>
                                                <td className='text-start'>{proLine.ItemCode}</td>
                                                <td className='text-start'>{proLine.Description}</td>
                                                <td className='text-center'>{proLine.BIN || proLine.BIN === 'null' ? 'Not Available' : proLine.BIN}</td>
                                                <td className='text-center'>{proLine.systemQuantity}</td>
                                                <td className='text-center'>{proLine.realQuantity}</td>
                                                <td className='text-center'>{proLine.difference}</td>
                                                <td className='text-center'>{proLine.MAS90Adjustment}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalAdjustment))}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(proLine.MAS90AdjCost))}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalVal))}</td>
                                                <td className='text-center'>{proLine.date !== "" ? FormatQueryReturnDate(proLine.date) : 'Not Counted Yet'}</td>
                                                <td className='text-center'>{proLine.countBy !== "" ? proLine.countBy : 'Not Counted Yet'}</td>
                                                <td className='text-start'>{proLine.countBy !== "" ? proLine.comentary : 'Not Counted Yet'}</td>
                                            </tr>
                                        ))

                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </ModalOrders>
            </div>
        )
    }
}
