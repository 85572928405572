export function getIndexElement(array, key, element){
    let index=-1
    if(element){
     index = array.findIndex(code => {  
        return String(code[key]).toUpperCase() === element.toUpperCase()
    })
}

    return index
}

export function getIndexElement2(array, key1,key2, element1,element2){
    let index=-1
    if(element1&&element2){
     index = array.findIndex(code => {  
        return ((String(code[key1]).toUpperCase() === String(element1).toUpperCase())&&(String(code[key2]).toUpperCase() === String(element2).toUpperCase()))
    })
}

    return index
}


export default {getIndexElement}