
import { removeCookie } from "../services/cookieService"
import { OpenLogin } from "./pagesFunction"
import { v4 as uuidv4 } from 'uuid';
export const closeSession=async ()=>{
    await removeCookie('userName')
    await  removeCookie('name')
    await removeCookie('surname')
    await removeCookie('SubCompanyId')
    await removeCookie('sessionAuthToken')
    await removeCookie('sessionAuthToken')
    await removeCookie('Company')
    await removeCookie('phone')
    await removeCookie('warehouse')
    await removeCookie('website')
    await removeCookie('address')
    await removeCookie('fullName')
    await removeCookie('TypeUser')
    await removeCookie('SalesPerson')
    await removeCookie('DocumentsLogo')
    await removeCookie('CompanyLogo')
    await removeCookie('CompanyId')
    await removeCookie('TotalOrders')
    window.localStorage.setItem('session',uuidv4())
    await OpenLogin()
}

export default {closeSession}