export function formatInputDate(datevalue) {
    if (datevalue !== null && datevalue !== undefined && datevalue !== "") {
        var arrayDate = datevalue.split('-')
        if (arrayDate.length === 3) {
            return arrayDate[1] + "/" + arrayDate[2] + "/" + arrayDate[0]
        } else {
            return ""
        }
    }




    return ""
}



export function formatDateByLine(datevalue) {
    if (datevalue !== null && datevalue !== undefined && datevalue !== "") {
        var arrayDate = datevalue.split('/')
        if (arrayDate.length === 3) {
            return arrayDate[2] + "-" + arrayDate[0] + "-" + arrayDate[1]
        } else {
            return ""
        }
    }

    return ""
}



function padLeft(value, length) {
    return String(value).padStart(length, '0');
}
export const getActualDateQuery = () => {
    var actualDate = new Date();

    var anio = actualDate.getFullYear();
    var mes = padLeft(actualDate.getMonth() + 1, 2);
    var dia = padLeft(actualDate.getDate(), 2);
    var horas = padLeft(actualDate.getHours(), 2);
    var minutos = padLeft(actualDate.getMinutes(), 2);
    var segundos = padLeft(actualDate.getSeconds(), 2);

    var fechaFormateada = anio + '-' + mes + '-' + dia + ' ' + horas + ':' + minutos + ':' + segundos;

    return fechaFormateada;
}

export const getActualDateFromPrint = () => {
    var actualDate = new Date();

    var anio = actualDate.getFullYear();
    var mes = padLeft(actualDate.getMonth() + 1, 2);
    var dia = padLeft(actualDate.getDate(), 2);
    var horas = padLeft(actualDate.getHours(), 2);
    var minutos = padLeft(actualDate.getMinutes(), 2);
    var segundos = padLeft(actualDate.getSeconds(), 2);

    var fechaFormateada = mes + '/' + dia + '/' + anio + ' ' + horas + ':' + minutos + ':' + segundos;

    return fechaFormateada;
}

export function formatInputDateQuery(datevalue) {
    if (datevalue !== null && datevalue !== undefined && datevalue !== "") {
        var arrayDate = datevalue.split('T')
        if (arrayDate.length === 2) {
            const date2 = arrayDate[0].split('-')
            if (date2.length === 3) {
                return date2[1] + "/" + date2[2] + "/" + date2[0]
            } else {
                return ""
            }

        } else {
            return ""
        }
    }

    return ""
}

export function formatInputDateQuerytoInput(datevalue) {
    if (datevalue !== null && datevalue !== undefined && datevalue !== "") {
        var arrayDate = datevalue.split('T')
        if (arrayDate.length === 2) {
            //console.log(arrayDate[0])
            const date2 = arrayDate[0].split('-')
            if (date2.length === 3) {
                return date2[0] + "-" + date2[1] + "-" + date2[2]
            } else {
                return ""
            }

        } else {
            return ""
        }
    }

    return ""
}

export function ReplaceDashForLateralBar(date) {
    if (date) {
        var tem = date.split('-')
        if (tem.length === 3) {
            return tem[1] + "/" + tem[2] + "/" + tem[0]
        }
        return ""
    }

    return ""
}

export function OrderArrayByDate(array) {
    for (let a = 0; a < array.length; a++) {
        for (let b = 0; b < (array.length - a - 1); b++) {
            const date1 = array[b].Date.split('/')
            const date2 = array[b + 1].Date.split('/')
            if (date1.length === 3 && date2.length === 3) {
                const date3 = new Date(date1[2] + "-" + date1[0] + "-" + date1[1])
                const date4 = new Date(date2[2] + "-" + date2[0] + "-" + date2[1])

                if (date3 > date4) {
                    var temp = Object.assign({}, array[b])
                    array[b] = Object.assign({}, array[b + 1])
                    array[b + 1] = Object.assign({}, temp)
                }
            }
        }
    }

    return array
}

export function OrderArrayByDateGenericLines(array, key) {
    for (let a = 0; a < array.length; a++) {
        for (let b = 0; b < (array.length - a - 1); b++) {
            const date1 = array[b][key]
            const date2 = array[b + 1][key]

            const date3 = new Date(date1)
            const date4 = new Date(date2)

            if (date3 > date4) {
                var temp = Object.assign({}, array[b])
                array[b] = Object.assign({}, array[b + 1])
                array[b + 1] = Object.assign({}, temp)
            }

        }
    }

    return array
}

export function OrderArrayByDateGenericLinesDesc(array, key) {
    for (let a = 0; a < array.length; a++) {
        for (let b = 0; b < (array.length - a - 1); b++) {
            const date1 = array[b][key]
            const date2 = array[b + 1][key]

            const date3 = new Date(date1)
            const date4 = new Date(date2)

            if (date3 < date4) {
                var temp = Object.assign({}, array[b])
                array[b] = Object.assign({}, array[b + 1])
                array[b + 1] = Object.assign({}, temp)
            }

        }
    }

    return array
}


export function CompareDates(datesta, dateend) {

    const date1 = datesta.split('/')
    const date2 = dateend.split('/')
    if (date1.length === 3 && date2.length === 3) {
        const date3 = new Date(date1[2] + "-" + date1[0] + "-" + date1[1])
        const date4 = new Date(date2[2] + "-" + date2[0] + "-" + date2[1])

        if (date3 <= date4) {
            return true
        }

        return false
    }


}

export function DateFormatMAS90(date) {
    if (date !== null && date !== undefined) {
        var arr = date.split('-')
        if (arr.length === 3) {
            return arr[1] + "/" + arr[2] + "/" + arr[0]
        } else {
            return ""
        }
    } else {
        return date
    }
}

export function ConvertDateFormatMAS90ToQueryFormat(date) {
    if (date !== null && date !== undefined) {
        return date + "T00:00:00.00Z"
    } else {
        return date
    }
}

export function FormatQueryReturnDate(date) {
    var div = String(date).split('T')
    if (div.length === 2) {
        var div2 = div[0].split('-')
        if (div2.length === 3) {
            return div2[1] + "/" + div2[2] + "/" + div2[0]
        } else {
            return ""
        }


    } else {
        return ""
    }
}

export function FormatQueryReturnDateWithDash(date) {
    var div = String(date).split('T')
    if (div.length === 2) {
        return div[0].split('-')
    } else {
        return ""
    }
}

export function getDateFromReports() {
    const datenow = new Date()
    return (datenow.getMonth() + 1) + "-" + datenow.getDate() + "-" + datenow.getFullYear()
}

export function getDateYearMonthDayDash() {
    const datenow = new Date()
    return datenow.getFullYear() + '-' + (datenow.getMonth() + 1) + "-" + datenow.getDate()
}

export function getMonthInNumbers() {
    const datenow = new Date()
    return Number(datenow.getMonth() + 1)
}

export function ActualDate() {
    const datenow = new Date()
    return (datenow.getMonth() + 1) + "/" + datenow.getDate() + "/" + datenow.getFullYear()
}
export function getActualDateUTC() {
    const datenow = new Date()
    let temp = datenow.toLocaleString('sv')
    let temp2 = temp.split(' ')

    return temp2.join('T')
}

export function FormatQueryReturnDateForReport(date) {

    var div = String(date).split('T')

    if (div.length === 2) {
        var div2 = div[0].split('-')
        if (div2.length === 3) {
            var hour = div[1].split('.')
            return div2[1] + "/" + div2[2] + "/" + div2[0] + " " + hour[0]
        } else {
            return ""
        }


    } else {
        return ""
    }
}
export function DeleteQueryFormat(date) {
    if (date) {
        var div = String(date).split('T')
        if (div.length === 2) {
            return div[0]
        } else {
            return ""
        }
    }
    return ""
}

export function FormatQueryReturnDateWithDash2(date) {

    var div = String(date).split('T')

    if (div.length === 2) {
        var div2 = div[0].split('-')
        if (div2.length === 3) {
            var hour = div[1].split('.')
            return div2[1] + "-" + div2[2] + "-" + div2[0] + " " + hour[0]
        } else {
            return ""
        }


    } else {
        return ""
    }
}


export function getIntervalAbleDays(startDate, FinishDate) {
    var date3 = new Date(startDate)
    var date4 = new Date(FinishDate)
    var date5 = new Date(startDate)
    var date6 = new Date(FinishDate)
    var difference = Math.abs(date4 - date3);

    var preliminarDays = difference / (1000 * 3600 * 24)
    var inhDays = 0
    while (date4 <= date3) {
        if (date4.getDay() === 0 || date4.getDay() === 6) {
            inhDays++
        }
        date4 = new Date(date4.getTime() + 86400000)
    }

    if (date6 > date5) {
        return ((preliminarDays - inhDays) * -1).toFixed(0)
    } else {

        return (preliminarDays - inhDays).toFixed(0)
    }

}

export function isSameDay(date1, date2) {
    return (
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate()
    );
}
export function getWeekNumber(currentDate) {
    
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    return Math.ceil(days / 7);
}




export default {
    formatInputDate, OrderArrayByDate, FormatQueryReturnDate, ActualDate, getActualDateUTC, formatInputDateQuery, FormatQueryReturnDateWithDash,
    getDateYearMonthDayDash, CompareDates, formatInputDateQuerytoInput, DateFormatMAS90, FormatQueryReturnDateForReport, OrderArrayByDateGenericLines, FormatQueryReturnDateWithDash2,
    DeleteQueryFormat, getIntervalAbleDays,
    ConvertDateFormatMAS90ToQueryFormat
}