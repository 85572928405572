import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import "../../css/pickingSystem-style.css"
import LoadingWindow from '../../components/LoadingWindow';
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai"
//BsClipboardPlus
import { BsClipboardPlus, BsFileArrowUpFill, BsFileArrowDownFill, BsArrowRepeat } from "react-icons/bs"
import { NumberFormat, OrderArray } from '../../functions/generalFunctions';
import ModalOrders from '../../components/ModalComponent';
import { getValueCookie } from '../../services/cookieService';
import { getInformationWithData } from '../../services/CABE';
import { pricesFormat } from '../../functions/pricesFormat';
import { DateFormatMAS90, FormatQueryReturnDate, getActualDateUTC } from '../../functions/dateFormat';
import { getIndexElement } from '../../functions/searchInObject';
import { OrderPDF } from '../../components/OrderPDF';
import { getPrintHeaderCompany } from '../../functions/companyInformation';
import { WeightPerInvoice } from './Class';
export default class LogisticRoutesView extends Component {

    state = {
        order: false,
        showModal1: false,
        DetailModal1: [],
        DetailModal1Filter: [],
        currentWeight: 0,
        selectAllInvoices: false,
        CurrentInvoiceSelect: [],
        CurrentInvoiceSelectFilter: [],
        companyPrintHeader: getPrintHeaderCompany()

    }


    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }

    handleModalOpen = async (modal) => {
        await this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {

        this.setState({ [modal]: false })
    }

    OrderInformation(array, key, state) {
        const order = OrderArray(array, key, this.state.order)
        this.setState({ [state]: order, order: !this.state.order })
    }

    async getOpenInvoices() {
        const data = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            CustomerNo: '*',
            HeaderSeqNo: '000000',
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            InvoiceNo: "-1",
        }

        let result = await getInformationWithData('/invoice/header', data)
        let detail = await getInformationWithData('/invoice/detail', data)



        let InvoicesDet = []
        for (const item of detail.data) {
            const index = getIndexElement(InvoicesDet, 'InvoiceNo', item.InvoiceNo)
            if (index !== -1) {
                InvoicesDet[index].Detail.push(item)
                InvoicesDet[index].calculateTotalWeight()
            } else {
                let newInvoice = new WeightPerInvoice(item.InvoiceNo)
                newInvoice.Detail.push(item)
                newInvoice.calculateTotalWeight()
                InvoicesDet.push(newInvoice)
            }
        }
        for (const header of result.data) {
            const index = getIndexElement(InvoicesDet, 'InvoiceNo', header.InvoiceNo)
            if (index !== -1) {
                header.Weight = InvoicesDet[index].TotalWeight
                header.TotalAmt = InvoicesDet[index].TotalAmt
                header.TotalQuantity = InvoicesDet[index].TotalQuantity
            }
        }
        return result

    }

    async OpenInvoiceSelect(open) {
        if (open) {
            await this.handleModalOpen("showModal1")
        }
        this.ModalLoading.current.showState(true);
        let result = await this.getOpenInvoices()
        if (result.status.code === 1) {
            await result.data.forEach(element => {
                const index = getIndexElement(this.state.CurrentInvoiceSelect, 'InvoiceNo', element.InvoiceNo)
                element.Check = index === -1 ? false : true

            });
            
            await this.setState({ DetailModal1: result.data, DetailModal1Filter: result.data })
            await this.updateChecks()
        } else {
            //ERROR
        }
        this.ModalLoading.current.showState(false);

    }

    calculateTotalWeight() {
        let totalWeight = 0
        for (const Invoice of this.state.CurrentInvoiceSelect) {
            totalWeight += Number(pricesFormat(Invoice.Weight))
        }
        this.setState({ currentWeight: totalWeight })
    }

    selectAllInvoices = async (e, actualStat) => {
        const temporal = this.state.DetailModal1
        const temporalF = this.state.DetailModal1Filter
        if (!actualStat) {
            temporal.forEach(element => {
                element.Check = true
            })
            temporalF.forEach(element => {
                element.Check = true
            })
            this.setState({ CurrentInvoiceSelect: temporal, CurrentInvoiceSelectFilter: temporal })


        } else {
            temporal.forEach(element => {
                element.Check = false
            })
            temporalF.forEach(element => {
                element.Check = false
            })
            this.setState({ CurrentInvoiceSelect: [], CurrentInvoiceSelectFilter: [] })
        }
        await this.setState({ DetailModal1: temporal, DetailModal1Filter: temporalF, selectAllInvoices: !actualStat })
        await this.calculateTotalWeight()
    }

    checkSpecificInvoice = async (e, actualStat, InvoiceNo) => {
        this.specificSelectInvoice(!actualStat, InvoiceNo)
        this.updateChecks()
    }
    async specificSelectInvoice(stat, InvoiceNo) {
        const index1 = getIndexElement(this.state.DetailModal1, 'InvoiceNo', InvoiceNo)
        const index2 = getIndexElement(this.state.DetailModal1Filter, 'InvoiceNo', InvoiceNo)

        if (index1 !== -1 && index2 !== -1) {
            const temporal = this.state.DetailModal1
            const temporalF = this.state.DetailModal1Filter
            temporal[index1].Check = stat
            temporalF[index2].Check = stat


            let CurrentInv = this.state.CurrentInvoiceSelect

            if (stat) {
                CurrentInv.push(temporal[index1])
            } else {

                const index = getIndexElement(CurrentInv, 'InvoiceNo', InvoiceNo)
                if (index !== -1) {
                    await CurrentInv.splice(index, 1)

                }

            }

           
            await this.setState({ DetailModal1: temporal, DetailModal1Filter: temporalF, CurrentInvoiceSelect: CurrentInv, CurrentInvoiceSelectFilter: CurrentInv })
            await this.calculateTotalWeight()
        }
    }

    updateChecks() {
        let all = true
        for (const inv of this.state.DetailModal1) {
            if (!inv.Check) {
                all = false
                break
            }
        }

        this.setState({ selectAllInvoices: all })
    }

    async deleteInvoice(InvoiceNo) {
        let temporal = this.state.CurrentInvoiceSelect
        let temporalF = JSON.parse(JSON.stringify(this.state.CurrentInvoiceSelectFilter))
        const index = getIndexElement(temporal, 'InvoiceNo', InvoiceNo)
        const index2 = getIndexElement(temporalF, 'InvoiceNo', InvoiceNo)
        if (index !== -1) {
            await temporal.splice(index, 1)
        }
        if (index2 !== -1) {
            await temporalF.splice(index2, 1)
        }

        await this.setState({ CurrentInvoiceSelect: temporal, CurrentInvoiceSelectFilter: temporalF })
        await this.calculateTotalWeight()
    }

    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    onSearch = async (e) => {
        const id = e.target.id
        const searchValue = e.target.value
        let Filter = []
        switch (id) {
            case "searchSelectInvoiceWeightReport":
                if (searchValue === '') {
                    Filter = this.state.CurrentInvoiceSelect
                } else {
                    Filter = this.state.CurrentInvoiceSelect.filter((detail) => {
                        if ((this.Contains(detail.InvoiceNo, searchValue)) || (this.Contains(detail.BillToName, searchValue))) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ CurrentInvoiceSelectFilter: Filter })

                break;
            case "ModalSelectInvoicesWeightReport":
                if (searchValue === '') {
                    Filter = this.state.DetailModal1
                } else {
                    Filter = this.state.DetailModal1Filter.filter((detail) => {
                        if ((this.Contains(detail.InvoiceNo, searchValue)) || (this.Contains(detail.BillToName, searchValue))) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ DetailModal1Filter: Filter })

                break;
            default:
                break;
        }
    }

    formatPDF(Detail) {
        let Format = []
        for (const Invoice of Detail) {
            const data = {
                InvoiceNo: Invoice.InvoiceNo,
                BillToName: Invoice.BillToName,
                Date: DateFormatMAS90(Invoice.InvoiceDate),
                Weight: NumberFormat(pricesFormat(Invoice.Weight)),
                Space: ""
            }
            Format.push(data)
        }

        return Format
    }


    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2'> Routes</p>
                <div className='row'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-12 pb-3'>
                                <div className='row'>
                                    <div className='col-12 InputTextBolder'><p>Route Name: </p></div>
                                    <div className='col-12 input-group input-group-lg flex-nowrap'>
                                        <input autoComplete='off' type="text" id="RouteName" placeholder="Route Name" className="form-control inputResize" />

                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg flex-nowrap">
                                            <span className="input-group-text"><AiOutlineSearch /></span>
                                            <input autoComplete='off' type="text" id="searchInvoiceNoWeightReport" placeholder="Search by Invoice No..." className="form-control inputResize" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button type="button" onClick={() => this.OpenInvoiceSelect(true)} className="btn blackButton btn-lg w-100" >Select Invoices <BsClipboardPlus /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 pt-5 pb-3'>
                                <div className="input-group input-group-lg">
                                    <input onChange={this.onSearch} autoComplete='off' type="text" id="searchSelectInvoiceWeightReport" placeholder="Search by Invoice No, Customer Name..." className="form-control inputResize" />
                                </div>
                            </div>
                            <div className='col-12 tableFixHead'>
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th onClick={() => this.OrderInformation(this.state.CurrentInvoiceSelectFilter, 'InvoiceNo', 'CurrentInvoiceSelectFilter')} className='bg-primary'>Invoice No <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.CurrentInvoiceSelectFilter, 'BillToName', 'CurrentInvoiceSelectFilter')} className='bg-primary'>Customer Name<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.CurrentInvoiceSelectFilter, 'InvoiceDate', 'CurrentInvoiceSelectFilter')} className='bg-primary'>Date<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.CurrentInvoiceSelectFilter, 'Weight', 'CurrentInvoiceSelectFilter')} className='bg-primary'>Total Weight (lbs)<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.CurrentInvoiceSelectFilter, 'TotalQuantity', 'CurrentInvoiceSelectFilter')} className='bg-primary'>Total Quantity<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th className='bg-primary'></th>
                                            <th onClick={() => this.OrderInformation(this.state.CurrentInvoiceSelectFilter, 'TotalAmt', 'CurrentInvoiceSelectFilter')} className='bg-primary'>Total ($)<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th className='bg-primary'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.CurrentInvoiceSelectFilter.map((invoice, i) => (
                                                <tr key={i}>
                                                    <td className='text-start textTableSize'>{invoice.InvoiceNo}</td>
                                                    <td className='text-start textTableSize'>{invoice.BillToName}</td>
                                                    <td className='text-center textTableSize'>{DateFormatMAS90(invoice.InvoiceDate)}</td>
                                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(invoice.Weight))}</td>
                                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(invoice.TotalQuantity))}</td>
                                                    <td className='text-end textTableSize'>$</td>
                                                    <td className='text-end textTableSize'>{NumberFormat(pricesFormat(invoice.TotalAmt))}</td>
                                                    <td className='text-center textTableSize'>
                                                        <button onClick={() => this.deleteInvoice(invoice.InvoiceNo)} type="button" className="btn redButton btn-lg w-100" ><AiOutlineCloseCircle /></button>
                                                    </td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>
                                    <tfoot className='tfoot'>

                                        <tr className='bg-dark text-light text-center'>
                                            <td className='textTableSize'></td>
                                            <td className='textTableSize'>Totals</td>
                                            <td className='textTableSize'></td>
                                            <td className='textTableSize'>{NumberFormat(pricesFormat(this.state.currentWeight)) + ' lbs'}</td>
                                            <td className='textTableSize'></td>
                                            <td className='textTableSize'></td>
                                            <td className='textTableSize'></td>
                                            <td className='textTableSize'></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div className='col-12 pt-3'>
                                <OrderPDF
                                    id={"ReportPDFWeight"} disabled={this.state.CurrentInvoiceSelect.length === 0} colorButton="orangeButton" title="Print Report"
                                    companyLogo={getValueCookie('DocumentsLogo')}
                                    OrderTitle="Route Name: "
                                    contactInfo={this.state.companyPrintHeader}
                                    OrderInfo1={["Printed by: ", getValueCookie('userName'), "Printed Date: ", FormatQueryReturnDate(getActualDateUTC())]}
                                    headerTable={["\nInvoice No", "\nCustomer Name", "Date", "Weigth(lbs)","Cash ($)", "PayCheck ($)"]}
                                    bodyTable={this.formatPDF(this.state.CurrentInvoiceSelect)}
                                    headerBodyTable={["InvoiceNo", "BillToName","Date","Weight","Space",  "Space"]}
                                    bottomInfo={["", "TOTALS:", "", NumberFormat(pricesFormat(this.state.currentWeight)) + ' lbs',"",""]}
                                    styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaTextoLargo", "ColumnaTextoLargo"]}
                                    hidden={false}
                                    comment={''}
                                    breakpoint={57}
                                    keysDecimalFormat={[""]}
                                    titleButton='Print Report'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                </div>
                <ModalOrders title={'Invoice List'} show={this.state.showModal1} close={() => this.handleModalClose("showModal1")}>
                    <div className="form-group pt-2">
                        <div className='row'>
                            <div className='col-12 pt-2'>
                                <div className="input-group input-group-lg flex-nowrap">
                                    <input onChange={this.onSearch} className='form-control w-75 display-inline' placeholder='Search by Invoice No, Customer Name...' id="ModalSelectInvoicesWeightReport" />
                                    <span className="input-group-text">
                                        <button className='btn greenButton btn-lg' onClick={() => this.OpenInvoiceSelect(false)}><BsArrowRepeat /></button>
                                    </span>
                                </div>
                            </div>
                            <div className='col-6 pt-4 pb-3'>
                                <div className='row'>
                                    <div className='col-6 titleTotal'>
                                        <p>Current Total Weight: </p>
                                    </div>
                                    <div className='col-6 TitleText'>
                                        <p>{NumberFormat(pricesFormat(this.state.currentWeight)) + ' lbs'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 pt-4 pb-3'>
                                <div className='row'>
                                    <div className="col-xs-2">
                                        <div className="checkbox-inline checkBoxTitle">
                                            <label className="">
                                                {'Select All Invoices '}
                                                <input onChange={(e) => this.selectAllInvoices(e, this.state.selectAllInvoices)} checked={this.state.selectAllInvoices} className="form-check-input" type="checkbox" value="" id="checkAllInvoiceWeightReport" />
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 tableFixHead'>
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ItemCode', 'ModalBodyFilter')} className='bg-dark'>Invoice No <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ProductLineDesc', 'ModalBodyFilter')} className='bg-dark'>Customer Name<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ProductLineDesc', 'ModalBodyFilter')} className='bg-dark'>Date<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastReceiptDate', 'ModalBodyFilter')} className='bg-dark'>Total Weight<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th className='bg-dark'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.DetailModal1Filter.map((invoice, i) => (
                                                <tr key={i}>
                                                    <td className='text-start textTableSize'>{invoice.InvoiceNo}</td>
                                                    <td className='text-start textTableSize'>{invoice.BillToName}</td>
                                                    <td className='text-center textTableSize'>{DateFormatMAS90(invoice.InvoiceDate)}</td>
                                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(invoice.Weight))}</td>
                                                    <td className='text-center textTableSize'>
                                                        <div className="row">
                                                            <div className="col-xs-2">
                                                                <div className="checkbox-inline">
                                                                    <input onChange={(e) => this.checkSpecificInvoice(e, invoice.Check, invoice.InvoiceNo)} className="form-check-input" type="checkbox" checked={invoice.Check} value="" id={"WeightReport" + invoice.InvoiceNo} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </div>
                </ModalOrders>
            </div>
        )
    }
}
