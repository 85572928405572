import ReactExport from 'react-data-export';
import React from 'react';
import { AiFillFileExcel } from "react-icons/ai"
import "../css/general-style.css"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const ExcelDocument = (props) => {
    return (
        <>{
            ((props.sheetname2 !== null && props.sheetname2 !== undefined) && (props.data2 !== null && props.data2 !== undefined)) ?
                <ExcelFile element={<button id={props.id} type="button" hidden={props.hidden} className="btn greenButton btn-lg w-100 h-100">{props.buttonTitle ? props.buttonTitle : "Download Report In XLSX"}<AiFillFileExcel /></button>} filename={props.archname}>
                    <ExcelSheet dataSet={props.data} name={props.sheetname} />
                    {props.data2.map((data,p)=>(
                        <ExcelSheet key={p} dataSet={data} name={props.sheetname2[p]} />
                    ))}
                    
                </ExcelFile>
                :
                <ExcelFile element={<button id={props.id} type="button" hidden={props.hidden} className="btn greenButton btn-lg w-100 h-100">{props.buttonTitle ? props.buttonTitle : "Download Report In XLSX"}<AiFillFileExcel /></button>} filename={props.archname}>
                    <ExcelSheet dataSet={props.data} name={props.sheetname} />
                </ExcelFile>
        }
        </>
    )
}

export default ExcelDocument