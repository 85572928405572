export class SalesOrder {
    constructor(SalesOrderNo,CustomerNo,BillToName,SalespersonNo,OrderSatus,OrderType,TermsCode,OrderDate,Weight,TotalAmount,PackingType,MAS90Status,Warehouse,
        ProductList) {
        this.SalesOrderNo=SalesOrderNo
        this.CustomerNo=CustomerNo
        this.BillToName=BillToName
        this.SalespersonNo=SalespersonNo
        this.OrderSatus=OrderSatus
        this.OrderType=OrderType
        this.TermsCode=TermsCode
        this.OrderDate=OrderDate
        this.Weight=Weight
        this.TotalAmount=TotalAmount
        this.PackingType=PackingType
        this.MAS90Status=MAS90Status
        this.Warehouse=Warehouse
        this.ProductList = ProductList
    }
}

export class SalesOrderDetail {
    constructor(LineKey, ItemCode, ItemCodeDesc, QuantityOrdered, UnitPrice, LineWeight, CommentText, TotalWeight, TotalAmt) {
        this.LineKey = LineKey
        this.ItemCode = ItemCode
        this.ItemCodeDesc = ItemCodeDesc
        this.QuantityOrdered = QuantityOrdered
        this.UnitPrice = UnitPrice
        this.LineWeight = LineWeight
        this.CommentText = CommentText
        this.TotalWeight = TotalWeight
        this.TotalAmt = TotalAmt
    }

}