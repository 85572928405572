export class WeightPerInvoice{
    InvoiceNo
    TotalWeight
    Detail

    constructor(InvoiceNo){
        this.InvoiceNo=InvoiceNo
        this.TotalWeight=0
        this.Detail=[]
    }

    calculateTotalWeight(){
        let weight=0
        for (const item of this.Detail) {
            weight+=(Number(item.LineWeight)*Number(item.QuantityShipped))
        }
        this.TotalWeight=weight
    }
    
}