export class Users {
    constructor(userName, name, surname, phone, emailAddress, company) {
        this.UserName = userName
        this.name = name
        this.surname = surname
        this.phone = phone
        this.emailAddress = emailAddress
        this.value = userName
        this.label = name + " " + surname
        this.IdCompany = Number(company)
        this.Modules = []

    }
}

export class ListOfUsers {
    constructor() {
        this.List = []
    }

    addUser(user, Company) {
        let newUser = new Users(user.userName, user.name, user.surname, user.phone, user.emailAddress, Company)
        this.List.push(newUser)
    }
}

export class UpdateByUsers {
    constructor(userName, name, surname, phone, emailAddress, createBy, PassWord, address, country, city) {
        this.userName = userName 
        this.PassWord = PassWord ? PassWord : ""
        this.name = name
        this.surname = surname
        this.phone = phone
        this.address = address ? address : ""
        this.country = country ? country : ""
        this.city = city ? city : ""
        this.emailAddress = emailAddress
        this.createBy = createBy

    }
}