import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { DateFormatMAS90 } from '../../functions/dateFormat';
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';



export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {

    }

    selectColor(number) {

        if (number >= 0 && number <= 14) {
            return "softGreen"
        } else if (number >= 15 && number <= 29) {
            return "softYellow"
        } else if (number >= 3) {
            return "softRed"
        }
                
        return ""

}


render() {
    return (
        <>

            <div className='row '>
                <div className='col-sm-6'>
                    <h5>{this.props.Title}</h5>
                </div>
                <div className='col-sm-6 text-end text-danger'>
                    <h3>{"Total: $ " + NumberFormat(pricesFormat(this.props.Totals.TotalDueInv))}</h3>
                </div>
                <div className='col-sm-12 backgroundWhite'>
                    <div className='col-12'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='text-center text-success' scope="col"> <h5>{"0 - 14 Days: "}</h5></th>
                                    <th className='text-center text-warning' scope="col"><h5>{"15 - 29 Days: "}</h5></th>
                                    <th className='text-center text-danger' scope="col"><h5>{"+ 30 Days: "}</h5></th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center text-success'><h5>{NumberFormat(this.props.Totals.Range1)+" / $ "}{NumberFormat(pricesFormat(this.props.Totals.TotalR1))}</h5></td>
                                    <td className='text-center text-warning'><h5>{NumberFormat(this.props.Totals.Range2)+" / $ "}{NumberFormat(pricesFormat(this.props.Totals.TotalR2))}</h5></td>
                                    <td className='text-center text-danger'><h5>{NumberFormat(this.props.Totals.Range3)+" / $ "}{NumberFormat(pricesFormat(this.props.Totals.TotalR3))}</h5></td>
                                </tr>
                                <tr>
                                    <td className='text-center text-success'><h5>{pricesFormat((this.props.Totals.TotalR1/this.props.Totals.TotalDueInv)*100)} %</h5></td>
                                    <td className='text-center text-warning'><h5>{pricesFormat((this.props.Totals.TotalR2/this.props.Totals.TotalDueInv)*100)} %</h5></td>
                                    <td className='text-center text-danger'><h5>{pricesFormat((this.props.Totals.TotalR3/this.props.Totals.TotalDueInv)*100)} %</h5></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='pb-3' />
                    <div className="tableFixHeadSmall ">
                        <table className='table align-middle'>
                            <thead className='bg-primary text-white'>
                                <tr>
                                    <th className="text-center bg-primary ">Invoice No</th>
                                    <th className='text-center bg-primary '>Customer No</th>
                                    <th className='text-center bg-primary '>Customer Name</th>
                                    <th className='text-center bg-primary '>Invoice Due Date</th>
                                    <th className='text-center bg-primary '>Days Dlq</th>
                                    <th className='text-center bg-primary '></th>
                                    <th className='text-center bg-primary '>Current Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.map((order, o) => (
                                    <tr className={this.selectColor(Number(order.DaysDlq))} key={o}>
                                        <td className='text-center'>{order.InvoiceNo}</td>
                                        <td className='text-center'>{order.CustomerNo}</td>
                                        <td className='text-start'>{order.CustomerName}</td>
                                        <td className='text-center'>{DateFormatMAS90(order.InvoiceDueDate)}</td>
                                        <td className='text-center'>{order.DaysDlq}</td>
                                        <td className='text-end'>$</td>
                                        <td className='text-end'>{NumberFormat(pricesFormat(order.Balance))}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>


            </div>




        </>
    )
}
}
