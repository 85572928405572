import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { getInformationWithData } from '../../../services/CABE';
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import LoadingWindow from '../../../components/LoadingWindow';
import { getIMGByItemCode, NumberFormat, OrderArray } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { FormatQueryReturnDate, getDateFromReports, OrderArrayByDateGenericLinesDesc } from '../../../functions/dateFormat';
import { ItemXVendor, VendorXItem } from './Class'
import { getIndexElement } from '../../../functions/searchInObject';
import ModalOrders from '../../../components/ModalComponent';
import ExcelDocument from '../../../components/ExcelDocument';
import { getValueCookie } from '../../../services/cookieService';
import { getDataSet } from '../../../functions/generateDataSetExcel';

export default class PurchaseProcessReport extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        BodyFilter: [],
        flag: true,
        ItemVendorList: [],
        VendorItemList: [],
        AllInformation: [],
        mode: 'Item',
        ModalBody: [],
        ModalBodyFilter: [],
        showModal1: false,
        order: true,
        currentItem: {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: '',
            onHand:''
        }
    }

    async getVendors() {
        this.ModalLoading.current.showState(true);
        const result = await getInformationWithData('/purchase/get/getItemsInPurchasesTravel',{IdCompany:Number(getValueCookie('CompanyId'))})
        let ItemVendorList = []
        let VendorItemList = []
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            priceLevel: undefined,
            Component:null
        }
        const items=await getInformationWithData('/Items/get',data)

        if (result.status.code === 1) {
            for (const item of result.data) {
                const index = getIndexElement(ItemVendorList, 'ItemCode', item.ItemCode)
                const index2 = getIndexElement(VendorItemList, 'VendorNo', String(item.VendorNo))
                const index3 = getIndexElement(items.data,'itemCode', item.ItemCode)
                item.onHand=index3!==-1?items.data[index3].quantity:0
                let desc=''
                if(index3!==-1){
                    desc = (!(items.data[index3].completeDesc) || items.data[index3].completeDesc === "null") ? items.data[index3].abbreviatedDesc : items.data[index3].completeDesc
                        
                }
                if(desc){
                    item.abbreviatedDesc=desc
                }


                if (index === -1) {
                    let it = new ItemXVendor(item.ItemCode, item.abbreviatedDesc, null, null, null, null, null, null, null, null, null, null,item.onHand)
                    it.addVendor(new VendorXItem(String(item.VendorNo), item.VendorName, item.Carrier, item.Comment, item.OrderNo, item.OrderDate?FormatQueryReturnDate(item.OrderDate):null, item.completionDate?FormatQueryReturnDate(item.completionDate):null, item.QuantityOrdered, item.UnitCost, item.abbreviatedDesc, item.createBy))
                    ItemVendorList.push(it)
                } else {
                    ItemVendorList[index].addVendor(new VendorXItem(String(item.VendorNo), item.VendorName, item.Carrier, item.Comment, item.OrderNo, item.OrderDate?FormatQueryReturnDate(item.OrderDate):null, item.completionDate?FormatQueryReturnDate(item.completionDate):null, item.QuantityOrdered, item.UnitCost, item.abbreviatedDesc, item.createBy))
                    ItemVendorList[index].VendorList = OrderArrayByDateGenericLinesDesc(ItemVendorList[index].VendorList, 'OrderDate')
                }

                if (index2 === -1) {
                    let vend = new VendorXItem(String(item.VendorNo), item.VendorName, null, null, null, null, null, null, null, null, null)
                    vend.addItem(new ItemXVendor(item.ItemCode, item.abbreviatedDesc, null, item.Carrier, item.Comment, item.OrderNo, item.OrderDate?FormatQueryReturnDate(item.OrderDate):null,item.completionDate?FormatQueryReturnDate(item.completionDate):null, item.QuantityOrdered, item.UnitCost, item.abbreviatedDesc, item.createBy,item.onHand))
                    VendorItemList.push(vend)
                } else {
                    VendorItemList[index2].addItem(new ItemXVendor(item.ItemCode, item.abbreviatedDesc, null, item.Carrier, item.Comment, item.OrderNo, item.OrderDate?FormatQueryReturnDate(item.OrderDate):null, item.completionDate?FormatQueryReturnDate(item.completionDate):null, item.QuantityOrdered, item.UnitCost, item.abbreviatedDesc, item.createBy,item.onHand))
                    VendorItemList[index2].ItemList = OrderArrayByDateGenericLinesDesc(VendorItemList[index2].ItemList, 'OrderDate')
                }
            
                item.OrderDate=item.OrderDate?FormatQueryReturnDate(item.OrderDate):'Not Available' 
                item.completionDate=item.completionDate?FormatQueryReturnDate(item.completionDate):'Not Available'
                
            }
        }
        this.setState({ ItemVendorList: ItemVendorList, VendorItemList: VendorItemList, BodyFilter: ItemVendorList, mode: 'Item', AllInformation: result.data })
        this.ModalLoading.current.showState(false);

    }

    valueRadioButton = async e => {
        let stat = e.target.value
        if (stat === 'Item') {
            this.setState({ BodyFilter: this.state.ItemVendorList, mode: stat })

        } else {

            this.setState({ BodyFilter: this.state.VendorItemList, mode: stat })
        }
    }

    async getVendorsList(index, it) {
        const temporal = this.state.currentItem
        temporal.ItemCode = it.ItemCode
        temporal.ItemCodeDesc = it.ItemCodeDesc
        temporal.ProductLine = it.ProductLineDesc
        temporal.itemURL = await getIMGByItemCode(it.ItemCode)
        temporal.onHand=it.onHand
        const index2=getIndexElement(this.state.ItemVendorList,'ItemCode',it.ItemCode)
        const item = this.state.ItemVendorList[index2]
        await this.setState({ ModalBody: item.VendorList, ModalBodyFilter: item.VendorList, currentItem: temporal })
        this.handleModalOpen("showModal1")

    }


    async getItemList(index, vend) {
        const index2=getIndexElement(this.state.VendorItemList,'VendorNo',vend.VendorNo)
        const vendor = this.state.VendorItemList[index2]
        
        const temporal = this.state.currentItem
        temporal.ItemCode = vend.VendorNo
        temporal.ItemCodeDesc = vend.VendorName

        await this.setState({ ModalBody: vendor.ItemList, ModalBodyFilter: vendor.ItemList, currentItem: temporal })
        this.handleModalOpen("showModal1")


    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    OrderInformation(array, key, state) {
        const order = OrderArray(array, key, this.state.order)
        this.setState({ [state]: order, order: !this.state.order })
    }
    contains(parametro, busqueda) {
       if(parametro){
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }
    }


    searchVendor = e => {

        let search = e.target.value;

        let Vendor=[]
        if (this.state.mode === 'Item') {
            Vendor = this.state.ItemVendorList.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ItemCodeDesc, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });

        } else {
            Vendor = this.state.VendorItemList.filter((vendor) => {
                if (((
                    this.contains(String(vendor.VendorNo), search) ||
                    this.contains(vendor.VendorName, search)
                ))
                ) {
                    return vendor
                } else {
                    return null
                }
            });

        }

        this.setState({ BodyFilter: Vendor });
    }



    searchVendorModal = e => {

        let search = e.target.value;

        let Vendor = []
        if (this.state.mode === 'Item') {
            Vendor = this.state.ModalBody.filter((vendor) => {
                if (((
                    this.contains(vendor.VendorNo, search) ||
                    this.contains(vendor.VendorName, search)||
                    this.contains(vendor.OrderBy, search)||
                    this.contains(vendor.OrderNo, search)
                ))
                ) {
                    return vendor
                } else {
                    return null
                }
            });

        } else {
            Vendor = this.state.ModalBody.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLineDesc, search) ||
                    this.contains(item.ItemCodeDesc, search)||
                    this.contains(item.OrderBy, search)||
                    this.contains(item.OrderNo, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });
        }

        this.setState({ ModalBodyFilter: Vendor });
    }


    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Report In Transit Purchase Order</p>

                <div className='row pt-2'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row TitleText'>
                                    <div className="d-grid gap-2 col-12">
                                        <ExcelDocument
                                            hidden={this.state.AllInformation.length === 0}
                                            archname={'Purchase Process ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'Items Vendor Report ' + getValueCookie('Company')}
                                            data={getDataSet(this.state.ItemVendorList, ['Item Code', 'Description'], ['ItemCode', 'ItemCodeDesc'])}
                                            sheetname2={['Vendors List ' + getValueCookie('Company'), 'All Information Purchase Process Report ' + getValueCookie('Company')]}
                                            data2={[
                                                getDataSet(this.state.VendorItemList, ['Vendor No', 'Vendor Name'], ['VendorNo', 'VendorName']),
                                                getDataSet(this.state.AllInformation, ['Vendor No', 'Vendor Name', 'Item Code', 'Description', 'Order No','Order Date','Completion Date','Comment', 'Carrier','On Hand', 'Quantity Ordered','Unit Cost ($)','Ordered By'], ['VendorNo', 'VendorName', 'ItemCode', 'abbreviatedDesc', 'OrderNo','OrderDate','completionDate','Comment', 'Carrier','onHand','QuantityOrdered','UnitCost','createBy'])
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div hidden={this.state.AllInformation.length===0} className='row TitleText'>
                                    <div className='col-12 pt-3'>
                                        <div className="form-check">
                                        <label className="form-check-label textRadioButtonSize" >
                                            <input className="form-check-input" value={'Item'} onChange={this.valueRadioButton} checked={this.state.mode === 'Item'} type="radio" name="typePurchaseReport" />
                                                Item List
                                            </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label textRadioButtonSize" >
                                            <input className="form-check-input" value={'Vendor'} onChange={this.valueRadioButton} checked={this.state.mode === 'Vendor'} type="radio" name="typePurchaseReport" />
                                                Vendor List
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row TitleText'>
                                    <div className='col-12 pb-4 InputTextBolder text-center'>
                                        <div className="d-grid gap-2">
                                            <button onClick={() => this.getVendors()} type="button" className="btn blueButton btn-lg">Reload <BsArrowRepeat /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={this.state.AllInformation.length === 0} className='row pt-3'>
                            <div className='col-12'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input type="text" autoComplete='off' className="form-control" id='searchPrincipalVendorPruchaseReport' placeholder={this.state.mode === 'Item' ? 'Search Item by ItemCode, Line Product, Description...' : 'Search Vendor by VendorNo, Vendor Name...'} onChange={this.searchVendor} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                    <div className='col-1'></div>
                    {
                        this.state.mode === 'Item' ?

                            <div hidden={this.state.AllInformation.length===0} className='col-10 tableFixHead'>
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCode', 'BodyFilter')} className='bg-dark'>Item Code <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th hidden onClick={() => this.OrderInformation(this.state.BodyFilter, 'ProductLineDesc', 'BodyFilter')} className='bg-dark'>Product Line <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCodeDesc', 'BodyFilter')} className='bg-dark'>Description <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.BodyFilter.map((item, i) => (
                                                <tr onClick={() => this.getVendorsList(i, item)} key={i}>
                                                    <td className='text-start'>{item.ItemCode}</td>
                                                    <td hidden className='text-start'>{item.ProductLineDesc}</td>
                                                    <td className='text-start'>{item.ItemCodeDesc}</td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>

                                </table>
                            </div>
                            :
                            <div hidden={this.state.AllInformation.length===0} className='col-10 tableFixHead'>
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'VendorNo', 'BodyFilter')} className='bg-dark'>Vendor No <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'VendorName', 'BodyFilter')} className='bg-dark'>Vendor Name <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.BodyFilter.map((vendor, v) => (
                                                <tr onClick={() => this.getItemList(v, vendor)} key={v}>
                                                    <td className='text-center'>{(vendor.VendorNo)}</td>
                                                    <td className='text-start'>{vendor.VendorName}</td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>

                                </table>
                            </div>
                    }
                    <div className='col-1'></div>
                </div>

                <ModalOrders title={this.state.mode === 'Item' ? 'Vendors Detail' : 'Items Detail'} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        {
                            this.state.mode === 'Item' ?
                                <div className='col-10'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12 titleTotal'>
                                                    <p>Item Code:</p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCode}</p>
                                                </div>
                                                <div className='col-12 titleTotal'>
                                                    <p>Description: </p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCodeDesc}</p>
                                                </div>
                                                <div className='col-12 titleTotal'>
                                                    <p>Product Line: </p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ProductLine}</p>
                                                </div>
                                                <div className='col-12 titleTotal'>
                                                    <p>On Hand: </p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{NumberFormat(this.state.currentItem.onHand)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12 pt-2 text-center'>
                                                    <div className='ItemImg'>
                                                        <img key={this.state.currentItem.itemURL} className='pb-5 pt-5' id='ShortsProductImg' src={this.state.currentItem.itemURL !== '' ? this.state.currentItem.itemURL : '/assets/notavailable.png'} alt='ProductImg' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 input-group input-group-lg pt-2 pb-3'>
                                            <input type="text" autoComplete='off' className="form-control" id='searchVendorPurchaseReportModal' placeholder={'Search Vendor by VendorNo, Vendor Name...'} onChange={this.searchVendorModal} />
                                        </div>
                                        <div className="d-grid gap-2 col-12 pt-4 pb-4">
                                            <ExcelDocument
                                                hidden={this.state.ModalBody.length === 0}
                                                archname={'Purchase Process Vendor List per Item ' + this.state.currentItem + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Vendor List ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.ModalBody, ['Vendor No', 'Vendor Name', 'Order No','Order Date','Estimated Completion Date','Quantity Ordered','Unit Cost ($)','Ordered By'], ['VendorNo', 'VendorName', 'OrderNo','OrderDate','EstCompletionDate','QuantityOrdered','UnitCost','OrderBy'])}

                                            />
                                        </div>
                                        <div className='col-12 tableFixHead'>
                                            <table className='table align-middle'>
                                                <thead className='thead'>
                                                    <tr className='text-light text-center'>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorNo', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Vendor No</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorName', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Vendor Name</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'OrderNo', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Order No</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'OrderDate', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Order Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'EstCompletionDate', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Estimated Completion Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'QuantityOrdered', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Quantity Ordered</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'UnitCost', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Unit Cost</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'OrderBy', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Ordered By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbody'>
                                                    {
                                                        this.state.ModalBodyFilter.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className='text-start'>{item.VendorNo}</td>
                                                                <td className='text-start'>{item.VendorName}</td>
                                                                <td className='text-center'>{item.OrderNo}</td>
                                                                <td className='text-center'>{item.OrderDate ? item.OrderDate : 'Not available'}</td>
                                                                <td className='text-center'>{item.EstCompletionDate?item.EstCompletionDate:'Not available'}</td>
                                                                <td className='text-center'>{item.QuantityOrdered?NumberFormat(item.QuantityOrdered):'0.00'}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.UnitCost ? pricesFormat( NumberFormat(item.UnitCost)) : '0.00'}</td>
                                                                <td className='text-center'>{item.OrderBy}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='col-10'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12 titleTotal'>
                                                    <p>Vendor No:</p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCode}</p>
                                                </div>
                                                <div className='col-12 titleTotal'>
                                                    <p>Vendor Name: </p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCodeDesc}</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-6'>

                                        </div>
                                        <div className='col-12 col-12 input-group input-group-lg pt-2 pb-3'>
                                            <input type="text" autoComplete='off' className="form-control" id='searchItemPurchaseReportModal' placeholder={'Search Item by ItemCode, Product Line, Description, Order No, Crated By...'} onChange={this.searchVendorModal} />
                                        </div>
                                        <div className="d-grid gap-2 col-12 pt-4 pb-4">
                                            <ExcelDocument
                                                hidden={this.state.ModalBody.length === 0}
                                                archname={'Purchase Process Item List per Vendor ' + this.state.currentItem + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Item List ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.ModalBody, ['Item Code', 'Description', 'Order No','Order Date','Estimated Completion Date','Quantity Ordered','On Hand','Unit Cost ($)','Ordered By'], ['ItemCode', 'Description', 'OrderNo','OrderDate','EstCompletionDate','QuantityOrdered','onHand','UnitCost','OrderBy'])}

                                            />
                                        </div>
                                        <div className='col-12 tableFixHead'>
                                            <table className='table align-middle'>
                                                <thead className='thead'>
                                                    <tr className='text-light text-center'>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ItemCode', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th hidden onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ProductLineDesc', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ItemCodeDesc', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'OrderNo', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Order No</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'OrderDate', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Order Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'EstCompletionDate', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Estimated Completion Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'QuantityOrdered', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Quantity Ordered</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'onHand', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>On Hand</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'UnitCost', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Unit Cost</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'OrderBy', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Ordered By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbody'>
                                                    {

                                                        this.state.ModalBodyFilter.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className='text-start'>{item.ItemCode}</td>
                                                                <td hidden className='text-start'>{item.ProductLineDesc}</td>
                                                                <td className='text-start'>{item.Description}</td>
                                                                <td className='text-center'>{item.OrderNo}</td>
                                                                <td className='text-center'>{item.OrderDate ? item.OrderDate : 'Not available'}</td>
                                                                <td className='text-center'>{item.EstCompletionDate ?item.EstCompletionDate : 'Not available'}</td>
                                                                <td className='text-center'>{item.QuantityOrdered ? NumberFormat(item.QuantityOrdered) : '0.00'}</td>
                                                                <td className='text-center'>{item.onHand ? NumberFormat(item.onHand) : '0.00'}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.UnitCost ? pricesFormat(NumberFormat(item.UnitCost)) : '0.00'}</td>
                                                                <td className='text-center'>{item.OrderBy}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </div></div>
                        }
                        <div className='col-1'></div>
                    </div>
                </ModalOrders>

            </div>
        )
    }
}
