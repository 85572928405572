import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { DateFormatMAS90 } from '../functions/dateFormat.js';
import "../css/general-style.css"
import '../css/table-responsive.css'
import { pricesFormat } from '../functions/pricesFormat.js';
import { DeleteCharacter, ItemDesc, NumberFormat } from '../functions/generalFunctions.js';

import ModalOrders from './ModalComponent.js';
import { getIndexElement } from '../functions/searchInObject.js';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        SpoilageList: [],
        SpoilageListFilter: [],
        showModal: false,
        options: []
    }

    async getSpoilageHeader(refresh) {
        await this.handleModalOpen()
        const data = {
            idCompany: Number(getValueCookie('CompanyId'))
        }
        await this.ModalLoading.current.showState(true);
        const buscador = document.getElementById('Spoilage_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.SpoilageList.length <= 0) {
            this.setState({ SpoilageList: [], SpoilageListFilter: [] })
            const result = await getInformationWithData('/invoice/AR/header', data)

            this.setState({ SpoilageList: result.data, SpoilageListFilter: result.data })
        }

        setTimeout(() => { buscador.focus(); }, 300);
        this.ModalLoading.current.showState(false);
    }


    async getAROPtions() {
        const data = {
            idCompany: Number(getValueCookie('CompanyId'))
        }
        const respuesta = await getInformationWithData('/Items/AR', data);

        let options = []
        let count = 1
        for (const item of respuesta.result) {
            let temporal = { label: DeleteCharacter(item.ItemCode, '/'), value: count }
            options.push(temporal)
            count++
        }
        this.setState({ options: options })
    }

    async getInvoiceDetail(InvoiceHeader) {
        await this.getAROPtions()
        const data = {
            InvoiceNo: InvoiceHeader.InvoiceNo,
            idCompany: Number(getValueCookie('CompanyId')),
            CustomerNo: InvoiceHeader.CustomerNo,
            HeaderSeqNo: '000000',
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
        }

        if (this.props.nombrePadre === 'SpoilageListSalesPerson') {
            const res = await getInformationWithData('/invoice/AR/detail', data)
            const customer = await getInformationWithData('/customer/getByCustomerNo', data)


            if (res.status.code === 1 && customer.data.length > 0) {
                let infoCustomer = customer.data[0]
                const Padre = this.props.Padre
                Padre.clearAll()
                let Account = {
                    InvoiceNo: InvoiceHeader.InvoiceNo,
                    InvoiceDate: InvoiceHeader.InvoiceDate,
                    BatchNo: '',
                    InvoiceType: InvoiceHeader.InvoiceType,
                    Type: '',
                    SalespersonNo: InvoiceHeader.SalespersonNo,
                    Comment: '',
                    FreightAmt: 0,
                    Customer: {
                        AddressLine1: infoCustomer.AddressLine1,
                        City: infoCustomer.City,
                        CountryCode: infoCustomer.CountryCode,
                        CreditLimit: infoCustomer.CreditLimit,
                        CustomerName: infoCustomer.CustomerName,
                        CustomerNo: infoCustomer.CustomerNo,
                        EmailAddress: infoCustomer.EmailAddress,
                        FaxNo: infoCustomer.FaxNo,
                        PriceLevel: infoCustomer.PriceLevel,
                        SalespersonNo: infoCustomer.SalespersonNo,
                        State: infoCustomer.State,
                        TaxSchedule: infoCustomer.TaxSchedule,
                        TelephoneNo: infoCustomer.TelephoneNo,
                        TermsCode: infoCustomer.TermsCode,
                        UDF_RUTA: infoCustomer.UDF_RUTA,
                        ZipCode: infoCustomer.ZipCode,
                    },
                    TotalQuantityOrdered: 0,
                    TotalCost: 0,
                    TotalQuantityShipped: 0,
                    TotalWeight: 0,
                    SalesOrderNo: '',
                    ApplyToInvoiceNo: InvoiceHeader.ApplyToInvoiceNo
                }
                let data = []
                let select = { label: null, value: 0 }
                let flag = true
                for (const item of res.data) {
                    if (flag) {
                        const index = getIndexElement(this.state.options, 'label', DeleteCharacter(item.ItemCode, '/'))
                        Account.Type = DeleteCharacter(item.ItemCode, '/')
                        if (index !== -1) {
                            flag = false
                            select = this.state.options[index]
                        }
                    }
                    data.push({
                        ItemCode: item.ItemCode,
                        ItemType: item.ItemType,
                        ItemCodeDesc: item.ItemCodeDesc,
                        ItemComment: item.CommentText,
                        ItemTotalAmt: Math.abs(Number(item.ExtensionAmt)),
                        LineSeqNo: item.LineSeqNo,
                        Visible: Math.abs(Number(item.ExtensionAmt)) !== 0
                    })
                }

                if (select.label === 'SPOILAGE') {
                    const dataSend = {
                        idCompany: Number(getValueCookie('CompanyId')),
                        InvoiceNo: InvoiceHeader.ApplyToInvoiceNo,
                        HeaderSeqNo: '000000'
                    }

                    const result = await getInformationWithData('/invoice/history/detail', dataSend)

                    if (result.status.code === 1) {

                        let prod = []
                        result.data.forEach(item => {

                            const itemJson = {
                                itemCode: item.ItemCode,
                                abbreviatedDesc: item.ItemCodeDesc,
                                upc: item.AliasItemNo,
                                quantity: 0,
                                ShipWeight: item.LineWeight,
                                StandardUnitCost: item.UnitCost,
                                LastTotalUnitCost: item.UnitCost,
                                UnitPrice: item.UnitPrice,
                                upc2: 0,
                                caseupc: 0,
                                BIN: "",
                                totalWeight: Number(item.LineWeight) * Number(item.QuantityShipped),
                                Comment: "",
                                unitCost: Number(item.UnitCost),
                                quantityOrdered: item.QuantityOrdered,
                                quantityReceived: 0,
                                quantityShipped: item.QuantityShipped,
                                totalCost: Number(item.QuantityShipped) * Number(item.UnitPrice),
                                totalFreight: 0,
                                Visible: true,
                                BatchUnits: 0,
                                DiscountUnits: 0,
                                SuggestedDiscount: 0
                            }
                            prod.push(itemJson)
                        });
                        if (prod.length > 0) {
                            prod = await ItemDesc(prod, 'abbreviatedDesc', 'itemCode')
                        } else if (getValueCookie('CompanyId') === '6') {
                           
                            const data2 = {
                                company: getValueCookie('Company'),
                                idcompany: getValueCookie('CompanyId'),
                                priceLevel: null,
                            }
                            const respuesta = await getInformationWithData('/Items/get', data2);
                            data2.company = 'Mayaland Atlanta'
                            data2.idcompany = '3'
                            const respuesta2 = await getInformationWithData('/Items/get', data2);
                            respuesta.status.code = respuesta2.status.code


                            let prodMayalandToInclude = []
                            for (const item of respuesta2.data) {
                                item.IdCompany = 3
                                const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)
                                if (index !== -1) {

                                    await respuesta.data.splice(index, 1)
                                    await prodMayalandToInclude.push(item)
                                }
                            }
                            let acceptProductLines = [{ ProdLine: '2012' }, { ProdLine: '2013' }, { ProdLine: '2014' }]

                            for (let m = respuesta.data.length - 1; m >= 0; m--) {

                                const item = respuesta.data[m]

                                item.IdCompany = 6
                                item.quantityShipped = 0
                                item.quantityReturned = 0
                                item.Total = 0
                                item.ItemComment = ''
                                const prodIndex = getIndexElement(acceptProductLines, 'ProdLine', item.ProductLine)
                                if (prodIndex !== -1) {
                                    if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                                        item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))
                                    }
                                } else {
                                    respuesta.data.splice(m, 1)
                                }

                            }
                            respuesta.data = respuesta.data.concat(prodMayalandToInclude)
                            
                            prod=respuesta.data
                        }

                        Padre.setState({ Products: prod })
                        let detail = []
                        for (const item of data) {
                            let comment = item.ItemComment.split('/')

                            const ind = getIndexElement(prod, 'itemCode', comment[0])
                            if (ind !== -1) {
                                let itemTemp = prod[ind]

                                itemTemp.quantityReturned = Math.abs(Number(item.ItemTotalAmt) / Number(itemTemp.UnitPrice))
                                itemTemp.Total = Math.abs(Number(item.ItemTotalAmt))
                                itemTemp.ItemComment = comment.length === 2 ? comment[1] : ''
                                itemTemp.Visible = Math.abs(Number(item.ItemTotalAmt)) !== 0
                                detail.push(itemTemp)
                            }
                        }
                        Padre.setState({ ARDetail: detail, })

                    }



                } else {
                    Padre.setState({ ARDetail: data, })
                }


                Padre.setState({ Accounts: Account, select: select })
                Padre.refreshInputs()
                Padre.calculateTotals()

            }
        }
        await this.handleModalClose()

    }

    contiene(parametro, busqueda) {
        if (parametro) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        } else {
            return false
        }
    }

    searchInvoice = e => {

        let busqueda = e.target.value;

        let orders = this.state.SpoilageList.filter((order_) => {
            if (((
                this.contiene(order_.InvoiceNo, busqueda) ||
                this.contiene(order_.SoldTo, busqueda)
            ))
            ) {
                return order_
            } else {
                return null
            }
        });
        this.setState({ SpoilageListFilter: orders });
    }
    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }
    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className={this.props.nombrePadre === 'SpoilageListSalesPerson' ? "btn aquaButton btn-lg w-100" : "btn btn-secondary btn-lg w-100"} onClick={() => this.getSpoilageHeader(true)}>{this.props.nombrePadre === 'SpoilageListSalesPerson' ? 'Spoilage List' : 'Posted Spoilages'} <AiOutlineFileText /></button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Invoice List'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className="form-group pt-2">
                        <div className='row form-group pt-4'>

                            <div className='col-sm-12'>
                                <div className="input-group input-group-lg flex-nowrap">
                                    <input className='form-control w-75 display-inline' placeholder='Search by...' id={'Spoilage_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchInvoice} />
                                    <span className="input-group-text">
                                        <button className='btn greenButton btn-lg' onClick={() => this.getSpoilageHeader(true)}><BsArrowRepeat /></button>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div className='row text-center pt-4'>
                            <div className='col-sm-12 tableFixHead'>
                                <table className='table align-middle'>
                                    <thead className='bg-primary text-white thead'>
                                        <tr className='text-center'>
                                            <th className='bg-primary text-white'>Invoice No</th>

                                            <th className='bg-primary text-white'>Sales Person</th>
                                            <th className='bg-primary text-white'>Date</th>
                                            <th className='bg-primary text-white'>Customer No</th>
                                            <th className='bg-primary text-white'>Bill To Name</th>
                                            <th className='bg-primary text-white'></th>
                                            <th className='bg-primary text-white'>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.SpoilageListFilter.map((element, e) => (
                                                <tr className='row_hover' key={e} onClick={() => this.getInvoiceDetail(element)}>
                                                    <td className='text-center'>{element.InvoiceNo}</td>
                                                    <td className='text-center'>{element.SalespersonNo}</td>
                                                    <td className='text-center'>{DateFormatMAS90(element.InvoiceDate)}</td>
                                                    <td className='text-center'>{element.CustomerNo}</td>
                                                    <td className='text-start'>{element.SoldTo}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(element.NonTaxableSalesAmt))}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </ModalOrders>


            </>
        )
    }
}
