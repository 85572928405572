import { MonthInLetters } from "../../../functions/generalFunctions"
import { getIndexElement2 } from "../../../functions/searchInObject"



export class YearMonthDetail {
    constructor(Month, Year, Total) {
        this.MonthNo = Month
        this.MonthName = Month < 13 ? MonthInLetters(Month) : (Month === 14 ? "Average " + Year : "Total")
        this.Year = Year
        this.Total = Total
        this.Count = 0
        this.AVG = this.Count > 0 ? (this.Total / this.Count) : 0
    }

    addTotal(Value) {
        this.Total += Value
    }
    addCount(val) {
        this.Count += val
        this.AVG = Number(Number(this.Count > 0 ? (this.Total / this.Count) : 0).toFixed(0))
    }
}

export class GeneralInventoryOverStock {
    constructor(ItemCode, Description, ProductLine, Detail, GeneralAverage,OnHand000,OnHand001,OnHand002) {
        this.ItemCode = ItemCode
        this.Description = Description
        this.ProductLine = ProductLine
        this.Detail = Detail
        this.TotalAVG = GeneralAverage
        this.OnHand000=OnHand000
        this.OnHand001=OnHand001
        this.OnHand002=OnHand002
        this.TotalOnHand= OnHand000+OnHand001+OnHand002
        this.MOH=0
        this.InvMonth=0
    }


    addEmpMonthYear(Year, Month) {
        const index = getIndexElement2(this.Detail, 'Year', 'MonthNo', Year, Month)
        const index2 = getIndexElement2(this.Detail, 'Year', 'MonthNo', Year, 13)
        const index3 = getIndexElement2(this.Detail, 'Year', 'MonthNo', Year, 14)
        if (index === -1) {
            this.Detail.push(new YearMonthDetail(Month, Year, 0))
            if (index2 === -1) {
                this.Detail.push(new YearMonthDetail(13, Year, 0))
                this.Detail.push(new YearMonthDetail(14, Year, 0))
            }else{
                this.Detail[index3].addCount(1)
            } 
        }
        this.Detail = this.OrderDetail()
        this.CalculateAVG()
    }
    OrderDetail() {
        return this.Detail.sort((a, b) => {

            if (a.Year !== b.Year) {
                return a.Year - b.Year;
            } else {
                return a.MonthNo - b.MonthNo;
            }
        });
    }
    CalculateAVG(){
        let total=0
        let date=new Date()
        let count=0
        for (const per of this.Detail) {
            if(per.MonthNo===14){
                total+= per.AVG
                count +=1
                if(per.MonthNo===date.getMonth()&&per.Year===date.getFullYear()){
                    count-=1
                }
            }
        }
        this.TotalAVG=count!==0?(total/count):0
        this.InvMonth=this.TotalAVG!==0?(this.TotalOnHand/this.TotalAVG):0
        this.InvMonth=this.InvMonth-1
        this.MOH=this.TotalAVG!==0?(this.TotalOnHand/this.TotalAVG):0
        this.Quantity=(this.TotalOnHand-this.TotalAVG).toFixed(0)
        
    }
}