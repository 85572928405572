import React, { Component } from 'react'
import CartaProducto from './CartaProducto'
import { getInformationWithData } from '../services/CABE.js'
import { getValueCookie } from '../services/cookieService';
import LoadingWindow from './LoadingWindow';
import { getIndexElement } from '../functions/searchInObject';
import { confirmCloseAlert } from '../functions/alerts';
import { BsJournals } from "react-icons/bs";
import { BsArrowRepeat } from "react-icons/bs";
import { pricesFormat } from '../functions/pricesFormat';
import Swal from 'sweetalert2'
import ModalOrders from './ModalComponent';

//BsJournals

export default class Catalogue extends Component {

    constructor(props) {
        super(props);
        this.retornarProducto = React.createRef();
        this.ModalLoading = React.createRef();
    }

    state = {
        Catalogo: [],
        ProductosMostrados: [],
        ProductsInSearch:[],
        oldPriceLevel: '',
        oldCustomerNo: '',
        showModal: false,
        enableButton:false,
        currentPage: 0,
        ProductsPerPage: 30,
        TotalPages: 0
    }

    getPriceLevel() {

        if (this.props.PriceLevel) {
            this.setState({ oldPriceLevel: this.props.PriceLevel })
            return this.props.PriceLevel
        } else {
            this.setState({ oldPriceLevel: undefined })
            return undefined
        }
        
    }

    getCustomerNo(){
        
        if(this.props.CustomerNo){
            this.setState({oldCustomerNo:this.props.CustomerNo})
        }else{
            this.setState({oldCustomerNo:''})
        }
        
    }

   
    async obtenerProductos(refresh) {
        await this.handleModalOpen()
        await this.ModalLoading.current.showState(true);
       
        if (refresh || this.state.Catalogo.length <= 0 || this.state.oldPriceLevel !== this.props.PriceLevel||this.props.CustomerNo!==this.state.oldCustomerNo) {
            await this.getCustomerNo()     
            this.setState({ ProductosMostrados: [] });
            const data = {
                company: getValueCookie('Company'),
                idcompany: getValueCookie('CompanyId'),
                priceLevel: this.getPriceLevel(),
                Component: this.props.Component ? this.props.Component : null,
                CustomerNo: this.props.CustomerNo?this.props.CustomerNo:null
            }

            const respuesta = await getInformationWithData('/Items/get', data);
            if (getValueCookie('CompanyId') === '6') {
                data.company = 'Mayaland Atlanta'
                data.idcompany = '3'
                const respuesta2 = await getInformationWithData('/Items/get', data);

                if (getValueCookie('CompanyId') === '6') {
                    for (const item of respuesta2.data) {
                        if (data.priceLevel === '1') {
                            // Si el precio alternativo es menor que el UnitPrice actual, mantenerlo.
                            if (item.UnitPriceALT < item.UnitPrice) {
                                item.UnitPriceALT = Number(pricesFormat(item.UnitPrice));
                            }
                        } else if (data.priceLevel !== '2') {
                            // Para otros niveles de precio (diferentes de 2), ajustar el UnitPrice según UnitPriceALT.
                            if (item.UnitPriceALT) {
                                item.UnitPrice = Number(pricesFormat(item.UnitPriceALT));
                            } else {
                                // ELIMINAR DEL CATÁLOGO
                                item.UnitPrice = 0;
                            }
                        }
                    }
                }

                respuesta.status.code = respuesta2.status.code

                //respuesta.data = respuesta.data.concat(respuesta2.data)
                let prodMayalandToInclude = []
                for (const item of respuesta2.data) {
                    item.IdCompany = 3
                    const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)
                    if (index !== -1) {

                        await respuesta.data.splice(index, 1)
                        await prodMayalandToInclude.push(item)
                    }
                }
                let acceptProductLines = [{ ProdLine: '2012' }, { ProdLine: '2013' }, { ProdLine: '2014' }]

                for (let m = respuesta.data.length - 1; m >= 0; m--) {

                    const item = respuesta.data[m]

                    item.IdCompany = 6
                    const prodIndex = getIndexElement(acceptProductLines, 'ProdLine', item.ProductLine)
                    if (prodIndex !== -1) {
                        if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                            item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))
                        }
                    } else {
                        respuesta.data.splice(m, 1)
                    }

                }
                if (data.priceLevel !== '2') {
                    respuesta.data = respuesta.data.concat(prodMayalandToInclude)
                } else {
                    respuesta.data = prodMayalandToInclude
                }
            }
            if (respuesta.status.code === 1) {
                if (getValueCookie('CompanyId') === '6') {
                    for (const item of respuesta.data) {

                        if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                            //let p=item.LastTotalUnitCost/(0.95)
                            //item.UnitPrice=Number(pricesFormat(p/(0.65)))
                            //item.UnitPrice=p
                            item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))
                        } else {
                            //ELIMINAR DEL CATALOGO
                            //console.log(item)
                        }

                    }
                }

                this.setState({ProductsInSearch:respuesta.data,Catalogo: respuesta.data });
                
            }
            
        }
        await this.setState({ ProductsInSearch: this.state.Catalogo });
        await this.ResetPagination()
        const buscador = document.getElementById('catalogo_busqueda' + this.props.nombrePadre);
        buscador.value = '';
        setTimeout(() => { buscador.focus(); }, 300);
        await this.ModalLoading.current.showState(false);

    }

    buscarProductos = async e => {

        let busqueda = e.target.value;
        let Productos = []

        if (busqueda) {
            Productos = this.state.Catalogo.filter((producto) => {
                if (((
                    this.contiene(producto.itemCode, busqueda)
                    || this.contiene(producto.abbreviatedDesc, busqueda)
                ))
                ) {
                    return producto
                } else {
                    return null
                }
            });
        } else {
            Productos = this.state.Catalogo
        }
        await this.setState({ ProductsInSearch: Productos });
        await this.ResetPagination()
    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        } else {
            return false
        }
    }

    async getProducto(producto) {
        
        let HaveUPC=((producto.upc)||(producto.upc2)||(producto.caseupc))?true:false
        
        //Aqui asignamos el producto al estado del padre
        const Padre = this.props.Padre

        const Products = Padre.state[this.props.Array];



        if (this.props.nombrePadre === 'PickingSystem') {
            producto.HaveUPC=HaveUPC
            producto.BIN = "";
            producto.Comment = "";
            producto.unitCost = producto.LastTotalUnitCost;
            producto.quantityOrdered = 0;
            producto.quantityReceived = 0;
            producto.totalCost = 0;
            producto.totalFreight = 0
            producto.ItemCode = producto.itemCode
            producto.QuantityOrdered = 0;
            producto.QuantityReceived = 0;
            producto.Pallet = '01'
            producto.ItemCodeDesc = (producto.completeDesc && producto.completeDesc !== "null") ? producto.completeDesc : producto.abbreviatedDesc
            producto.LineWeight = producto.ShipWeight
            const index = getIndexElement(Products, 'ItemCode', producto.ItemCode)
            if (index !== -1) {
                confirmCloseAlert('incorrect', 'This product is in the Picking List')
            } else {
                const pickedList = Padre.state["OrderDetailPicked"]
                const index2 = getIndexElement(pickedList, 'ItemCode', producto.ItemCode)
                if (index2 !== -1) {
                    confirmCloseAlert('incorrect', 'This product is in the Picked Up List!')

                } else {
                    Swal.fire({
                        title: `Are you sure to add ${producto.itemCode}?`,
                        text: "You are adding a product which is not in the original purchase order ",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, please add!',
                        cancelButtonText: 'No, cancel!',
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            Products.push(producto);
                            Padre.setState({ [this.props.Array]: Products });
                        }
                    })

                }
            }

        } else if (this.props.nombrePadre === 'SalesOrder' || this.props.nombrePadre === 'Invoice') {

            const index = getIndexElement(Products, 'itemCode', producto.itemCode)

            if (index !== -1) {
                if (Products[index].Visible === 0) {
                    if (this.props.nombrePadre === 'SalesOrder') {
                        Products[index].HaveUPC=HaveUPC
                        Products[index].quantityOrdered = 1
                        Products[index].totalCost = Number(Products[index].quantityOrdered) * Number(Products[index].UnitPrice)
                        
                    } else if (this.props.nombrePadre === 'Invoice') {
                        Products[index].HaveUPC=HaveUPC
                        Products[index].quantityShipped = 1
                        Products[index].RegisterType = 'm'
                        Products[index].totalCost = Number(Products[index].quantityShipped) * Number(Products[index].UnitPrice)
                        Products[index].totalWeight = Number(Products[index].quantityShipped) * Number(Products[index].ShipWeight)
                        Products[index].Pallet=1
                    }

                } else {
                    if (this.props.nombrePadre === 'SalesOrder') {
                        Products[index].HaveUPC=HaveUPC
                        Products[index].quantityOrdered += 1
                        Products[index].totalCost = Number(Products[index].quantityOrdered) * Number(Products[index].UnitPrice)
                       
                    } else if (this.props.nombrePadre === 'Invoice') {
                        Products[index].HaveUPC=HaveUPC
                        Products[index].quantityShipped += 1
                        Products[index].RegisterType = Products[index].RegisterType?Products[index].RegisterType:'m'
                        Products[index].totalCost = Number(Products[index].quantityShipped) * Number(Products[index].UnitPrice)
                        Products[index].totalWeight = Number(Products[index].quantityShipped) * Number(Products[index].ShipWeight)
                        Products[index].Pallet=1
                    }
                }

                Products[index].Visible = 1
                const startOfTheList = Products[index]
                await Products.splice(index, 1)
                await Products.unshift(startOfTheList)
                await Padre.setState({ Products: Products });
                await Padre.calculateTotals()
                await Padre.refreshInputs()

            } else {
                if (getValueCookie('SalesPerson') === '0010') {
                    producto.UnitPrice = producto.LastTotalUnitCost
                }
                producto.HaveUPC=HaveUPC
                producto.BIN = "";
                producto.totalWeight = Number(producto.ShipWeight)
                producto.ShipWeight = Number(producto.ShipWeight)
                producto.Comment = "";
                producto.unitCost = producto.LastTotalUnitCost;
                producto.quantityOrdered = this.props.nombrePadre === 'SalesOrder' ? 1 : 0
                producto.quantityReceived = 0;
                producto.quantityShipped = this.props.nombrePadre === 'Invoice' ? 1 : 0
                producto.totalCost = producto.UnitPrice;
                producto.totalFreight = 0
                producto.Visible = 1
                producto.RegisterType = HaveUPC?'m':'s'


                await Products.unshift(producto);
                await Padre.setState({ Products: Products });
                await Padre.calculateTotals()
                await Padre.refreshInputs()
            }

        } else if (this.props.nombrePadre === 'ProductInformation') {
            producto.isUpdateImg = producto.itemURLImage ? true : false
            await Padre.setState({ Product: producto })
            await Padre.updateUPCInputs(true)

        } else if (this.props.nombrePadre === 'SpoilageSystem'||this.props.nombrePadre === 'InventorySystem') {
            producto.HaveUPC=HaveUPC
            await Padre.selectSpoilageProduct(producto, 'm')
        } else if (this.props.nombrePadre === 'PurchaseReturn') {
            producto.HaveUPC=HaveUPC
            await Padre.addItem(producto)
        } else if(this.props.nombrePadre==='ReturnPO'){
            producto.HaveUPC=HaveUPC
            Padre.addItem2(producto)
        } else if(this.props.nombrePadre==='APurchaseOrder'){
            
            Padre.AddProduct(producto)
        } else {
            producto.HaveUPC=HaveUPC
            producto.BIN = "";
            producto.Comment = "";
            producto.unitCost = producto.LastTotalUnitCost;
            producto.quantityOrdered = 0;
            producto.quantityReceived = 0;
            producto.totalCost = 0;
            producto.totalFreight = 0

            let desc = ''
            desc = (!(producto.completeDesc) || producto.completeDesc === "null") ? producto.abbreviatedDesc : producto.completeDesc

            if (desc) {
                producto.abbreviatedDesc = desc
            }


            Products.push(producto);
            Padre.setState({ products: Products });
        }
        await this.handleModalClose()
        //itemcode ItemCode
    }

    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = async () => {

        await this.setState({ showModal: false })
    }

    async ResetPagination() {
        let totalPages = Math.round(this.state.ProductsInSearch.length / this.state.ProductsPerPage)
        let seeProds = this.state.ProductsInSearch.slice(0, 0 + this.state.ProductsPerPage)
        await this.setState({
            ProductosMostrados: seeProds,
            currentPage: 0,
            ProductsPerPage: 30,
            TotalPages: totalPages
        })

    }

    async nextPage() {
        if ((this.state.currentPage + this.state.ProductsPerPage) <= this.state.ProductsInSearch.length) {
            await this.setState({ currentPage: this.state.currentPage + this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async prevPage() {
        if (this.state.currentPage > 0) {
            await this.setState({ currentPage: this.state.currentPage - this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async updateSeeProducts() {
        let seeProds = this.state.ProductsInSearch.slice(this.state.currentPage, this.state.currentPage + this.state.ProductsPerPage)
        await this.setState({
            ProductosMostrados: seeProds,
        })

    }
    backToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const buscador = document.getElementById('catalogo_busqueda' + this.props.nombrePadre);
        setTimeout(() => { buscador.focus(); }, 300);
    }

    render() {
        return (
            <React.Fragment>
                <button type="button" disabled={this.props.disabled} className="btn catalogueOpen btn-lg w-100" onClick={() => this.obtenerProductos(false)}>Open Catalogue <BsJournals /></button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Catalogue'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className="modal-body ">
                        <div className='row form-group'>
                            <div className="input-group input-group-lg flex-nowrap">
                                <input className='form-control w-75 display-inline' placeholder='Search by ItemCode,BIN,Description...' id={'catalogo_busqueda' + this.props.nombrePadre} onKeyUp={this.buscarProductos} autoComplete={"off"} />
                                <span className="input-group-text">
                                    <button className='btn greenButton btn-lg' onClick={() => this.obtenerProductos(true)}><BsArrowRepeat /></button>
                                </span>
                            </div>
                        </div>
                        <div className='row pt-4'>
                            {

                                this.state.ProductosMostrados.map((producto, i) => (
                                    <div key={i} className='col-sm-6' onClick={() => this.getProducto(producto)}>
                                        <CartaProducto itemURLImage={producto.itemURLImage} ItemCode={producto.itemCode} Description={(producto.completeDesc && producto.completeDesc !== "null") ? producto.completeDesc : producto.abbreviatedDesc} OnHand={producto.quantity} UPC={producto.upc} LastTotalUnitCost={producto.LastTotalUnitCost} UnitPrice={producto.UnitPrice} needPrice={this.props.needPrice} needCost={this.props.needCost} />
                                    </div>

                                ))
                            }
                        </div>
                        <div className='row pt-2'>
                                <div className='col-sm-12 text-center'>
                                    <div className="btn-group btn-group-lg" role="group" aria-label="pagination">
                                        <button type="button" onClick={() => this.prevPage()} className="btn blueButton">Prev</button>
                                        <button type="button" onClick={() => this.nextPage()} className="btn blueButton">Next</button>
                                    </div>
                                </div>

                            </div>
                    </div>

                </ModalOrders>

            </React.Fragment>
        )
    }
}
