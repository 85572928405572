import React, { Component } from 'react'
import { getInformationWithData, getInformationWithDataGlobalSales } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { ActualDate, DateFormatMAS90, FormatQueryReturnDate } from '../functions/dateFormat.js';
import { getPriceMargin, getPurePriceMargin, intervalTimeInDays, NumberFormat } from '../functions/generalFunctions.js';
import { confirmCloseAlert } from '../functions/alerts.js';
import { getIndexElement } from '../functions/searchInObject.js';
import { pricesFormat } from '../functions/pricesFormat.js';
import ModalOrders from './ModalComponent.js';
import "../css/table-responsive.css"

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        salesOrderList: [],
        salesOrderListFilter: []
    }

    async getSalesOrderHeader(refresh) {
        await this.handleModalOpen()
        const buscador = document.getElementById('portalorder_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.salesOrderList.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ salesOrderList: [], salesOrderListFilter: [] });
            const respuesta = await getInformationWithDataGlobalSales('/Sales/get', {
                SalespersonNo: getValueCookie('SalesPerson')
            });

            await this.setState({ salesOrderList: respuesta.SalesOrderList, salesOrderListFilter: respuesta.SalesOrderList });
            this.ModalLoading.current.showState(false);
        }
        buscador.value = '';
        setTimeout(() => { buscador.focus(); }, 300);

    }

    async getSalesOrder(SalesOrderHeader) {

        await this.handleModalClose()
        let state = 0
        if (SalesOrderHeader.OrderType === 'S') {
            state = 'S'
        } else {
            state = 'Q'
        }
        if (!(SalesOrderHeader.Status)) {
            SalesOrderHeader.Status = 0
        }

        if ((this.props.nombrePadre === 'SalesOrderListSO')) {
            SalesOrderHeader.Status = -1
        }
        let TemporalSalesOrderNo = ""
        if (SalesOrderHeader.SalesOrderNo) {
            let SalesOrderProvisonalNo = SalesOrderHeader.SalesOrderNo.split('-')
            TemporalSalesOrderNo = SalesOrderProvisonalNo.length > 1 ? SalesOrderProvisonalNo[0] : SalesOrderHeader.SalesOrderNo
        }


        const data = {
            SalesOrderNo: TemporalSalesOrderNo !== "" ? TemporalSalesOrderNo : SalesOrderHeader.SalesOrderNo,
            OrderNo: SalesOrderHeader.SalesOrderNo,
            CustomerNo: SalesOrderHeader.CustomerNo,
            username: getValueCookie('userName'),
            company: getValueCookie('Company'),
            idCompany: Number(getValueCookie('CompanyId')),
            idcompany: getValueCookie('CompanyId'),
            Mode: true,
            InvoiceType: 1,
            Status: SalesOrderHeader.Status
        }


        this.ModalLoading.current.showState(true);
        const result1 = await getInformationWithDataGlobalSales('/Sales/detail', { SalesOrderNo: TemporalSalesOrderNo !== "" ? TemporalSalesOrderNo : SalesOrderHeader.SalesOrderNo })
        const result2 = await getInformationWithData('/customer/getByCustomerNo', data)
        const balance = await getInformationWithData('/customer/getBalance', data)
        const invent = await getInformationWithData('/Items/get', data)
        for (const item of invent.data) {
            item.IdCompany = 6
        }
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const inventM = await getInformationWithData('/Items/get', data);
            invent.status.code = inventM.status.code
            for (const item of inventM.data) {
                item.IdCompany = 3
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(inventM.data)
        }


        if (result2.status.code === 1 && balance.status.code === 1) {
            const Father = this.props.Padre
            if (this.props.nombrePadre === 'SalesOrderListSO') {
                const CustomerResult = result2.data[0]

                const SalesOrder = {

                    Customer: {
                        AddressLine1: CustomerResult.AddressLine1,
                        City: CustomerResult.City,
                        CustomerName: CustomerResult.CustomerName,
                        CustomerNo: CustomerResult.CustomerNo,
                        EmailAddress: CustomerResult.EmailAddress,
                        PriceLevel: CustomerResult.PriceLevel,
                        SalespersonNo: CustomerResult.SalespersonNo,
                        State: CustomerResult.State,
                        TelephoneNo: CustomerResult.TelephoneNo,
                        ZipCode: CustomerResult.ZipCode,
                    },
                    NoOrder: SalesOrderHeader.SalesOrderNo,
                    Type: state,
                    TotalQuantityOrdered: 0,
                    TotalCost: 0,
                    Date: SalesOrderHeader.OrderDate,
                    Warehouse: SalesOrderHeader.WarehouseCode,
                    Packing: null,
                    WeightOption: null,
                    MAS90Status: SalesOrderHeader.MAS90Status
                }
                let Detail = []
                result1.ProductList.forEach(element => {

                    if (getValueCookie('CompanyId') === '6') {
                        const index = getIndexElement(invent.data, 'itemCode', element.ItemCode)
                        if (index !== -1) {
                            element.UnitCost = invent.data[index].LastTotalUnitCost
                        }
                    }
                   
                    const index2 = getIndexElement(invent.data, 'itemCode', element.ItemCode)
                    let desc = ''
                    let LastTotalUnitCost = 0
                    let comp = 1563
                    let activeItem = true
                    if (index2 !== -1) {

                        desc = (!(invent.data[index2].completeDesc) || invent.data[index2].completeDesc === "null") ? invent.data[index2].abbreviatedDesc : invent.data[index2].completeDesc
                        comp = invent.data[index2].IdCompany ? invent.data[index2].IdCompany : 1563

                        LastTotalUnitCost = invent.data[index2].LastTotalUnitCost
                    } else {
                        activeItem = false
                        desc = element.ItemCodeDesc
                    }

                    const indDupl = getIndexElement(Detail, 'itemCode', element.ItemCode)

                    const itemTemporal = {
                        BIN: "",
                        Comment: getValueCookie('CompanyId') === '6' ? "BOX: " + getPriceMargin(element.UnitPrice, element.UnitCost, SalesOrderHeader.PriceLevel) : element.CommentText,
                        PureMargin: getValueCookie('CompanyId') === '6' ? getPurePriceMargin(element.UnitPrice, element.UnitCost) : 0,
                        LastTotalUnitCost: LastTotalUnitCost,
                        ShipWeight: element.LineWeight,
                        StandardUnitCost: element.UnitCost,
                        UnitPrice: Number(element.UnitPrice).toFixed(2),
                        Visible: (element.QuantityOrdered !== 0 || element.QuantityShipped !== 0) ? 1 : 0,
                        abbreviatedDesc: desc,
                        caseupc: "",
                        itemCode: element.ItemCode,
                        quantity: 0,
                        quantityOrdered: element.QuantityOrdered,
                        LineSequence: element.LineSeqNo,
                        quantityShipped: element.QuantityShipped,
                        quantityReceived: 0,
                        totalCost: ((Number(element.QuantityOrdered) * Number(element.UnitPrice)).toFixed(2)),
                        totalFreight: 0,
                        unitCost: element.UnitCost,
                        upc: "",
                        upc2: "",
                        IdCompany: comp,
                        duplicate: indDupl !== -1 ? 'bg-danger' : ''
                    }
                    if (activeItem) {
                        Detail.push(itemTemporal)
                    }
                });



                balance.data.forEach(element => {
                    element.PaymentDelayDays = intervalTimeInDays(DateFormatMAS90(element.InvoiceDueDate), ActualDate())
                    element.InvoiceDate = DateFormatMAS90(element.InvoiceDate)
                    element.InvoiceDueDate = DateFormatMAS90(element.InvoiceDueDate)
                    element.CustomerName = CustomerResult.CustomerName
                });

                const temporalBalance = Father.state.Balance
                temporalBalance.HeaderBalance = balance.data
                temporalBalance.TermsCode = CustomerResult.TermsCodeDesc


                Father.setState({ Products: Detail, SalesOrder: SalesOrder, Balance: temporalBalance, ConfirmSave: Detail.length })
                Father.refreshInputs()
                Father.calculateTotals()
                Father.getTotalBalance()
                Father.handleModalOpen("showModal1")

            }

        } else {
            confirmCloseAlert('incorrect', 'Whoops something was wrong try again please!')
        }
        this.ModalLoading.current.showState(false);

    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }

    }

    searchSalesOrder = e => {

        let busqueda = e.target.value;

        if (busqueda) {
            let orders = this.state.salesOrderList.filter((order_) => {
                if (((
                    this.contiene(order_.BillToAddress1, busqueda) ||
                    this.contiene(order_.BillToName, busqueda) ||
                    this.contiene(order_.SalespersonNo, busqueda) ||
                    this.contiene(order_.SalesOrderNo, busqueda) ||
                    this.contiene(order_.TermsCode, busqueda) ||
                    this.contiene(order_.CustomerNo, busqueda) ||
                    this.contiene(order_.TypeinLetters, busqueda)
                ))
                ) {
                    return order_
                } else {
                    return null
                }
            });
            this.setState({ salesOrderListFilter: orders });
        } else {
            let temporal = this.state.salesOrderList
            this.setState({ salesOrderListFilter: temporal })
        }
    }

    stateInWords(state) {
        if (state === 1) {
            return 'In process'
        } else if (state === 2) {
            return 'Finished'
        } else {
            return 'Not started'
        }
    }
    Mas90stateInWords(state) {
        if (state === 0) {
            return 'Not Charged'
        } else if (state === 1) {
            return 'Charged'
        }
        return ''
    }

    stateColor(state) {
        if (state === 1) {
            return 'text-warning'
        } else if (state === 2) {
            return 'text-success'
        } else {
            return 'text-danger'
        }
    }
    MAS90stateColor(state) {
        if (state === 0) {
            return 'text-danger'
        } else if (state === 1) {
            return 'text-success'
        }
        return ''

    }


    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className="btn catalogueOpen btn-lg w-100" onClick={() => this.getSalesOrderHeader(true)}>Portal Order List <AiOutlineFileText /></button>
                <LoadingWindow ref={this.ModalLoading} />

                <ModalOrders title={'Sales Order List'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className='row form-group'>
                        <div className="input-group input-group-lg flex-nowrap col-sm-12 pb-3">
                            <input className='form-control w-75 display-inline' placeholder='Search by...' id={'portalorder_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchSalesOrder} />
                            <span className="input-group-text">
                                <button className='btn greenButton btn-lg' onClick={() => this.getSalesOrderHeader(true)}><BsArrowRepeat /></button>
                            </span>
                        </div>


                        <div className='col-sm-12 tableFixHead'>
                            <table className='table align-middle'>
                                <thead className='bg-primary text-center text-white thead'>
                                    <tr>
                                        <th className='bg-primary'>Order No</th>
                                        <th className='bg-primary'>Customer No</th>
                                        <th className='bg-primary'>Customer Name</th>
                                        <th className='bg-primary'>Type</th>
                                        <th className='bg-primary'>Sales Person</th>
                                        <th className='bg-primary'>Date</th>
                                        <th className='bg-primary'></th>
                                        <th className='bg-primary'>Total Amount</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.salesOrderListFilter.map((element, e) => (
                                            <tr className='row_hover' key={e} onClick={() => this.getSalesOrder(element)}>
                                                <td className='text-center'>{element.SalesOrderNo}</td>
                                                <td className='text-center'>{element.CustomerNo}</td>
                                                <td className='text-start'>{element.BillToName}</td>
                                                <td className='text-center'>{element.OrderType}</td>
                                                <td className='text-center'>{element.SalespersonNo}</td>
                                                <td className='text-center'>{FormatQueryReturnDate(element.OrderDate)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(element.TotalAmount))}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>
                    </div>
                </ModalOrders>

            </>
        )
    }
}
