import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import "../css/general-style.css"
import '../css/table-responsive.css'
import { DeleteCharacter } from '../functions/generalFunctions.js';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        ARDivisionList: [],
        ARDivisionListFilter: []
    }

    async getARDivision(refresh) {
        const data = {
            idCompany: Number(getValueCookie('CompanyId'))
        }


        const buscador = document.getElementById('ARDivision_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.ARDivisionList.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ ARDivisionList: [], ARDivisionListFilter: [] });

            const respuesta = await getInformationWithData('/Items/AR', data);

            if (respuesta.status.code === 1) {
                let filter = []
                if (this.props.type === 2) {

                    for (const item of respuesta.result) {
                        if (item.ItemCode === '/FREIGHT') {
                            filter.push(item)
                            break
                        }
                    }
                } else {
                    for (const item of respuesta.result) {
                        if (item.ItemCode !== '/FREIGHT') {
                            filter.push(item)
                        }
                    }


                }

                this.setState({ ARDivisionList: filter, ARDivisionListFilter: filter });

            }
            this.ModalLoading.current.showState(false);
        }

        setTimeout(() => { buscador.focus(); }, 300);

    }

    getOneARDivision(ARDivision) {
        const Father = this.props.Padre
        if (this.props.nombrePadre === 'ARListAccountReceivable') {
            
            let temporal = Father.state.ARDetail
            const temporal2 = Father.state.Accounts
            const data = {
                ItemCode: ARDivision.ItemCode,
                ItemType: ARDivision.ItemType,
                ItemCodeDesc: ARDivision.ItemCodeDesc,
                ItemComment: '',
                ItemTotalAmt: 0,
                Visible:true
            }
            if(temporal2.Type!==DeleteCharacter(ARDivision.ItemCode, '/')){
                temporal=[]
                Father.setState({ ARDetail: [] })
                temporal2.Type = DeleteCharacter(ARDivision.ItemCode, '/')
                
            }
            
            if (ARDivision.ItemCode === '/CREDITCARD FEE'||ARDivision.ItemCode === '/FREIGHT') {
                temporal2.InvoiceType = 'IN'
                Father.setState({ Accounts: temporal2 })
                temporal.push(data)
                Father.setState({ ARDetail: temporal })
                Father.calculateTotals()
            } else if (ARDivision.ItemCode === '/SPOILAGE') {
                temporal2.InvoiceType = 'CM'
                temporal.push(data)
                Father.setState({ Accounts: temporal2,ARDetail: temporal })
                Father.calculateTotals()
            }
            else {
                temporal2.InvoiceType = 'CM'
                Father.setState({ Accounts: temporal2 })
                temporal.push(data)
                Father.setState({ ARDetail: temporal })
                Father.calculateTotals()
            }
        }
    }

    contiene(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }

    searchAR = e => {

        let busqueda = e.target.value;

        let orders = this.state.ARDivisionList.filter((order_) => {
            if (((
                this.contiene(order_.ItemCode, busqueda) ||
                this.contiene(order_.ItemType, busqueda) ||
                this.contiene(order_.ItemCodeDesc, busqueda)
            ))
            ) {
                return order_
            } else {
                return null
            }
        });
        this.setState({ ARDivisionListFilter: orders });
    }

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className={"btn " + this.props.colorButton + " btn-lg w-100"} data-bs-toggle="modal" data-bs-target={"#ARDivisionList" + this.props.nombrePadre} onClick={() => this.getARDivision(true)}>Account Receivable List <AiOutlineFileText /></button>
                <LoadingWindow ref={this.ModalLoading} />
                <div className="modal fade" id={"ARDivisionList" + this.props.nombrePadre} tabIndex="-1" aria-labelledby="ARListModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ARListModalLabel">Account Receivable List </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row form-group pt-4'>

                                    <div className='col-12'>
                                        <div className="input-group input-group-lg flex-nowrap">
                                            <input className='form-control w-75 display-inline' placeholder='Search by...' id={'ARDivision_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchAR} />
                                            <span className="input-group-text">
                                                <button className='btn greenButton btn-lg' onClick={() => this.getARDivision(true)}><BsArrowRepeat /></button>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className='row text-center pt-4'>
                                    <div className='col-12'>
                                        <table className='table align-middle'>
                                            <thead className='bg-primary text-white'>
                                                <tr className='text-center'>
                                                    <td>AR Code</td>
                                                    <td>Type</td>
                                                    <td>Description</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ARDivisionListFilter.map((element, e) => (
                                                        this.props.SelectARDiv===DeleteCharacter(element.ItemCode, '/')?
                                                        <tr data-bs-dismiss="modal" className='row_hover' key={e} onClick={() => this.getOneARDivision(element)}>
                                                            <td className='text-start'>{DeleteCharacter(element.ItemCode, '/')}</td>
                                                            <td className='text-center'>{element.ItemType}</td>
                                                            <td className='text-start'>{element.ItemCodeDesc}</td>

                                                        </tr>
                                                        :<></>
                                                    ))
                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}