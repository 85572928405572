//import Resizer from "react-image-file-resizer";

export async function resizeFile(file, newWidth, newHeight) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Calcular el nuevo tamaño proporcional
                const aspectRatio = img.width / img.height;
                let width = newWidth;
                let height = newHeight;
                if (!width) {
                    width = height * aspectRatio;
                } else if (!height) {
                    height = width / aspectRatio;
                }

                // Establecer el nuevo tamaño del lienzo (canvas)
                canvas.width = width;
                canvas.height = height;

                // Dibujar la imagen en el lienzo con el nuevo tamaño
                ctx.drawImage(img, 0, 0, width, height);

                // Convertir el lienzo a un nuevo archivo Blob
                canvas.toBlob(
                    (blob) => {
                        const resizedFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now(),
                        });
                        resolve(resizedFile);
                    },
                    file.type,
                    1 // Calidad de la imagen (1 es la mejor calidad)
                );
            };
            img.onerror = function (error) {
                reject(error);
            };
            img.src = event.target.result;
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}