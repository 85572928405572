import React, { Component } from 'react'
import "../css/general-style.css"
import { AiFillPrinter } from "react-icons/ai"
import ModalOrders from './ModalComponent'
import styles from '../pdfConfig/pdfInvoice2'
import { Document, PDFViewer, View, Page, Text, Image } from '@react-pdf/renderer'
import { NumberFormat } from '../functions/generalFunctions'
import { getValueCookie } from '../services/cookieService'
import { getActualDateFromPrint } from '../functions/dateFormat'


export class InvoicePDF2 extends Component {
    // eslint-disable-next-line
    constructor(props) {

        super(props)

    }

    state = {
        showModal: false,
        lastRow: 0,


    }

    handleModalOpen = () => {
        this.setState({ showModal: true })
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }




    calculateNewSheet() {
        let arrTam = this.props.bodyTable.length

        while (true) {
            arrTam = arrTam - 27
            if (arrTam <= 27) {
                break
            }
        }
        if (arrTam > 20) {
            return true
        }

        return false
    }

    truncDecimal(n) {
        let t = n.toString();
        let regex = /(\d*.\d{0,1})/;
        return t.match(regex)[0];
    }
    EncabezadoOrder(data, num) {
        let format = []
        let temporal = []
        let cont = 1

        for (const item of data) {
            if (cont === num) {
                temporal.push(item)
                format.push(temporal)
                temporal = []
                cont = 1

            } else {
                cont++
                temporal.push(item)
            }
        }

        return format
    }
    EncabezadoInfo(Llaves, State, Confirm) {

        let resultado = []
        Llaves.forEach(llave => {
            if (llave !== '$') {
                if (this.props.keysDecimalFormat.includes(llave) && State[llave] !== ' ') {
                    resultado.push(NumberFormat(Number(State[llave]).toFixed(2)))
                } else {
                    resultado.push(State[llave])
                }
            } else {
                if (Confirm) {
                    resultado.push(llave)
                } else {
                    resultado.push(' ')
                }



            }


        });


        return resultado
    }


    render() {
        return (
            <React.Fragment>

                <div className="d-grid gap-2">
                    <button hidden={this.props.hidden} id={this.props.id} disabled={this.props.disabled} type="button" onClick={() => this.handleModalOpen()} className={"btn " + this.props.colorButton + " btn-lg"}>{this.props.titleButton} <AiFillPrinter /></button>
                </div>
                <ModalOrders title={this.props.title} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <PDFViewer className="w-100" style={{ minHeight: "90vh" }}>
                        <Document >
                            <Page size="A4" wrap>
                                <View fixed>
                                    <View style={styles.blockHeaderStyle}>
                                        <View style={styles.blockHeader}>
                                            <Image style={styles.BusinessLogo} src={this.props.companyLogo} />
                                        </View>
                                        <View style={styles.blockHeader}>
                                            <View style={styles.bolderStyle}>
                                                <Text style={styles.TypeInvoiceTitle}>{this.props.InvoiceType}</Text>
                                            </View>

                                        </View>
                                        <View style={styles.PageInfoStyle}>
                                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                                                `Page: ${pageNumber} of ${totalPages}`
                                            )} />
                                             <Text style={styles.pageNumber} >
                                                {`Printed By: ${getValueCookie('userName')}`}
                                            </Text>
                                            <Text style={styles.pageNumber} >
                                                {getActualDateFromPrint()}
                                            </Text>

                                        </View>
                                    </View>
                                    <View style={styles.blockHeaderStyle}>
                                        <View style={styles.block50Percent}>
                                            <View style={styles.bolderStyle}>
                                                {this.props.CompanyContactInfo.map((element, e) => (
                                                    <Text key={e} style={styles.HeaderTextStyle}>{element}</Text>
                                                ))}
                                            </View>
                                        </View>
                                        <View style={styles.block50Percent}>
                                            <View style={styles.BoxLeft}>
                                                {
                                                    this.props.Box1.map((element, e) => (
                                                        e === 0 ?
                                                            <View key={e} style={styles.bolderStyle}>
                                                                <Text key={e} style={styles.TextStyleInHeaderBoxes}>{element}</Text>
                                                            </View>
                                                            :
                                                            <Text key={e} style={styles.TextStyleInHeaderBoxes}>{element}</Text>
                                                    ))
                                                }
                                            </View>
                                        </View>

                                    </View>
                                    <View style={styles.Space}></View>
                                    <View style={styles.blockHeaderStyle}>
                                        <View style={styles.block50Percent}>

                                            {this.EncabezadoOrder(this.props.InvoiceHeader, 2).map((element, e) => (
                                                <View style={styles.SubContainerHeader} key={e}>
                                                    {
                                                        element.map((element2, e2) => (
                                                            (e2 === 0) ?
                                                                <View key={e2} style={styles.bolderStyle}>
                                                                    <View style={styles.ColumnaHeader1} key={e2}>
                                                                        <Text >{element2}</Text>
                                                                    </View>
                                                                </View>
                                                                :
                                                                <View style={styles.ColumnaHeader2} key={e2}>
                                                                    <Text >{element2}</Text>
                                                                </View>
                                                        ))
                                                    }
                                                </View>
                                            ))}

                                        </View>
                                        <View style={styles.block50Percent}>

                                        </View>

                                    </View>
                                    <View style={styles.Space}></View>

                                    { /*<View style={styles.Table1Style}>
                                        {
                                            this.EncabezadoOrder(this.props.HeaderOptions, 4).map((element, e) => (
                                                <View key={e} style={styles.Table1File}>
                                                    {

                                                        element.map((element2, e2) => (
                                                            <Text key={e2} style={styles.block22Percent}>{element2}</Text>
                                                        ))}
                                                </View>


                                            ))
                                        }
                                    </View>*/}

                                </View>

                                <View style={styles.Table}>
                                    <View fixed style={styles.CabeceraTabla}>

                                        {
                                            this.props.HeaderDescritpion.map((title, t) => (

                                                <Text style={styles[this.props.StyleDetail[t] + "Header"]} key={t}>{title}</Text>

                                            ))
                                        }
                                    </View>
                                    {
                                        this.props.Detail.map((elemento, e) => (
                                            (e + 1) < this.props.Detail.length && ((e + 1) % this.props.breakpoint !== 0) ?
                                                <View key={e} style={styles.CuerpoTabla}>{
                                                    e % this.props.breakpoint === 0 && e !== 0 ?

                                                        <View key={e} style={styles.FilaBreak}>{

                                                            this.EncabezadoInfo(this.props.DescriptionKeys, elemento, (e + 1) <= this.props.quantSignDolar).map((fila, e2) => (
                                                                (e + 1) <= this.props.quantSignDolar ?
                                                                    <View style={styles[this.props.StyleDetail[e2]]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                                    :
                                                                    <View style={styles[this.props.StyleDetail[e2] + "Clear"]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                            ))
                                                        }</View>



                                                        :

                                                        <View key={e} style={styles.Fila}>{
                                                            this.EncabezadoInfo(this.props.DescriptionKeys, elemento, (e + 1) <= this.props.quantSignDolar).map((fila, e2) => (
                                                                (e + 1) <= this.props.quantSignDolar ?
                                                                    <View style={styles[this.props.StyleDetail[e2]]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                                    :
                                                                    <View style={styles[this.props.StyleDetail[e2] + "Clear"]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                            ))
                                                        }</View>
                                                }
                                                </View>
                                                :
                                                <View key={e} style={styles.CuerpoTablaFinal}>{
                                                    e % this.props.breakpoint === 0 && e !== 0 ?

                                                        <View key={e} style={styles.FilaBreak}>{

                                                            this.EncabezadoInfo(this.props.DescriptionKeys, elemento, (e + 1) <= this.props.quantSignDolar).map((fila, e2) => (
                                                                (e + 1) <= this.props.quantSignDolar ?
                                                                    <View style={styles[this.props.StyleDetail[e2]]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                                    :
                                                                    <View style={styles[this.props.StyleDetail[e2] + "Clear"]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                            ))
                                                        }</View>



                                                        :

                                                        <View key={e} style={styles.Fila}>{
                                                            this.EncabezadoInfo(this.props.DescriptionKeys, elemento, (e + 1) <= this.props.quantSignDolar).map((fila, e2) => (
                                                                (e + 1) <= this.props.quantSignDolar ?
                                                                    <View style={styles[this.props.StyleDetail[e2]]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                                    :
                                                                    <View style={styles[this.props.StyleDetail[e2] + "Clear"]} key={e2}><Text key={e2}>{fila}</Text></View>
                                                            ))
                                                        }</View>
                                                }
                                                </View>
                                        ))
                                    }


                                    <View>
                                        {this.EncabezadoOrder(this.props.bottomInformation, 11).map((element, e) => (
                                            <View key={e} style={styles.CabeceraBottom}>
                                                {
                                                    element.map((title, t) => (
                                                        <Text style={styles[this.EncabezadoOrder(this.props.bottomStyle, 11)[e][t]]} key={t}>{title}</Text>
                                                    ))
                                                }
                                            </View>

                                        ))}



                                        <View style={styles.blockHeaderStyle}>
                                            <Text style={styles.BottomTextStyle}>{this.props.bottomMessage}</Text>
                                        </View>
                                    </View>
                                </View>




                            </Page>
                        </Document>
                    </PDFViewer>
                </ModalOrders>
            </React.Fragment>
        )
    }
}

