import { getValueCookie } from "../services/cookieService"

export function getPrintHeaderCompany() {
    var header = []
    header.push(getValueCookie('fullName'))
    header.push(getValueCookie('address'))
    header.push("Phone: "+getValueCookie('phone'))
    header.push(getValueCookie('website'))
    return header

}


export default {getPrintHeaderCompany}