import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { filterAndSortData } from '../../functions/generalFunctions';
import ExcelDocument from '../ExcelDocument'
import { getDateFromReports, formatInputDate} from '../../functions/dateFormat';
import { getValueCookie } from '../../services/cookieService';
import { getDataSet } from '../../functions/generateDataSetExcel';
export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();


    }
    state = {
        Title: '',
        Data: [],
        useProps: true,
        showModal1: false
    }

    onChange = (e) => {
        if (this.state.useProps) {
            this.setState(prevState => ({
                useProps: !prevState.useProps
            }));
        }
        this.setState({ Data: filterAndSortData(this.props.data1, e) }, () => {
        });

    }

    formatDatePickedUp(data) {
        let temporal = []
        let copy = JSON.parse(JSON.stringify(data))
        copy.forEach(element => {
            //'CustomerNo', 'CustomerName', 'Username', 'Note', 'Date'
            element.LastSoldDate = formatInputDate( element.LastSoldDate);
            temporal.push(element)

        });

        return temporal
    }
    render() {
        const dataToDisplay = this.state.useProps ? this.props.data : this.state.Data;
        return (
            <>

                <div className='row '>
                    <h5>{this.props.Title}</h5>
                    <div className='col-12'>
                        <div className="input-group  pb-3  flex-nowrap" >
                            <input className="form-control  text-center" id='ShortageLevel' min="0" defaultValue={30} onChange={(e) => this.onChange(e.target.value)} type="number" />
                            <span className="input-group-text">days</span>
                        </div>
                    </div>
                    <div className='col-sm-12 backgroundWhite'>

                        <div className='col pt-3 pb-3'>
                            <ExcelDocument hidden={getValueCookie('TypeUser') !== '1'} data={getDataSet(this.formatDatePickedUp(dataToDisplay), ['Item Code', 'Product Line', 'Description', 'On Hand', 'Last Sold Date', '# Days of last sale'], ['ItemCode', 'ProductLineDesc', 'ItemCodeDesc', 'TotalQuantityOnHand', 'LastSoldDate', "NumberofDays"])} sheetname={"Customers"} archname={"Feedback report " + this.state.Title + ' ' + getValueCookie('Company') + " DATE " + getDateFromReports()} />
                        </div>

                        <div className="tableFixHeadSmall ">
                            <table className='table align-middle'>
                                <thead className='bg-primary text-white'>
                                    <tr>
                                        <th className='text-center bg-primary '>Item Code</th>
                                        <th className='text-center bg-primary '>Product Line</th>
                                        <th className='text-center bg-primary '>Description</th>
                                        <th className='text-center bg-primary '>On Hand</th>
                                        <th className='text-center bg-primary '>Last Sold Date</th>
                                        <th className='text-center bg-primary '># Days Of Last Sale</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {Object.keys(dataToDisplay).map((key, k) => (
                                        <tr key={k}>

                                            <td className='text-start'>{dataToDisplay[key].ItemCode}</td>
                                            <td className='text-start'>{dataToDisplay[key].ProductLineDesc}</td>
                                            <td className='text-start'>{dataToDisplay[key].ItemCodeDesc}</td>
                                            <td className='text-center'>{dataToDisplay[key].TotalQuantityOnHand}</td>
                                            <td className='text-center'>{formatInputDate(dataToDisplay[key].LastSoldDate)}</td>
                                            <td className='text-center'>{dataToDisplay[key].NumberofDays}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>


                </div>


            </>
        )
    }
}
