import React, { Component } from 'react'
import "../css/general-style.css"
import '../css/table-responsive.css'
import { OrderPDF } from '../components/OrderPDF';
import { getValueCookie } from '../services/cookieService';
import { getPrintHeaderCompany } from '../functions/companyInformation';
import { InvoicePDF2 } from '../components/InvoicePDF2';



export default class Tempo extends Component {

    state = {

        purchaseOrderHeader: {
            NoOrder: '',
            Carrier: '',
            orderDate: '',
            completionDate: '',
            realCompletionDate: '',
            creationDate: '',
            vendorNo: '',
            vendorName: '',
            comment: '',
            createBy: '',
            State: '',
            totalFreight: 0
        },
        companyPrintHeader: getPrintHeaderCompany(),
        products: [
            {
                "itemCode": "COAX-RG59\n/CCS-SIA\nMESE-CMR\n-WH-PB",
                "abbreviatedDesc": "Cable Coax RG59/CU Siamese 95% CCS 18/2 CM White",
                "BIN": "E025",
                "quantityOrdered": "4",
                "quantityReceived": "4"
            },

        ],
        totals: {
            finalquantityOrderedTotal: 0,
            finalquantityOrderedReceived: 0,
            finalTotalCost: 0
        },
        disableHeader: true,
        secureTransaction: false,
        modalPurchase: false,
        oldPurchaseOrderHeader: [],
        oldPurchaseOrderHeaderFilter: [],
        vendorSelectInformation: [],
        selectOptions: [{ value: 'All', label: 'All' }]
    }
    componentDidMount() {
        
    }



    render() {
        return (
            <div className='purchaseOrderContainer'>


                <OrderPDF id={"PurchaseNotPrices"} disabled={false} colorButton="orangeButton" title="Purchase Order Print"
                    companyLogo={getValueCookie('DocumentsLogo')}
                    OrderTitle="Outbound Order"
                    contactInfo={this.state.companyPrintHeader}
                    OrderInfo1={["Order No: ", "PC101022", "Customer: ", "PC Services", "Order by: ", "Katherine Alarcon", "Status: ", "Complete", "Date: ", "10/10/2022", "", ""]}
                    headerTable={["\n Item Code", "\n Description", "\nBIN", "\n Quantity \n Ordered", "\n Quantity \n Shipped"]}
                    bodyTable={this.state.products}
                    headerBodyTable={["itemCode", "abbreviatedDesc", "BIN", "quantityOrdered", "quantityReceived"]}
                    bottomInfo={["", "\nTOTALS", "", "\n4", "\n4"]}
                    styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo3", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaTextoNormal"]}
                    hidden={false}
                    comment={""}
                    keysDecimalFormat={[]}
                    breakpoint={23}
                    titleButton='Print Order'
                />
                <InvoicePDF2

                    id={'InvoicePrint2'}
                    hidden={this.state.enablePrint}
                    colorButton='purpleButton'
                    titleButton='Invoice Print Without Prices'
                    title='Invoice Print'
                    companyLogo={getValueCookie('DocumentsLogo')}
                    InvoiceType={"Example Invoice"}
                    CompanyContactInfo={['Altitude LLC', '1860 Amwiler Lily Valley Dr', 'Lawrenceville, GA 30045', '(770) 871-5807']}
                    InvoiceHeader={['Invoice No:', "PM01548", 'Date:', "12/09/2022", 'Grocer:', "DP", 'Client No:', "0000152", 'Batch No:', "BN8958"]}
                    Box1={["Order By:","Hyperline de Guatemala", "1 Avenida y 15 calle de la Zona 1 Guatemala","Ciudad de Guatemala, CA 502"]}
                    Box2={["Sent to the address:","Hyperline de Guatemala", "1 Avenida y 15 calle de la Zona 1 Guatemala","Ciudad de Guatemala, CA 502"]}
                    HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", "Pay in Advance"]}
                    DescriptionKeys={["quantityShipped", "itemCode", "SKU", "abbreviatedDesc", "$", "UnitPrice", "$", "totalCost"]}
                    HeaderDescritpion={["Qty", "Code", "SKU", "Description", "", "Price", "", "Amount"]}
                    StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormalCenter", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                    Detail={[]}
                    breakpoint={55}
                    quantSignDolar={[]}
                    keysDecimalFormat={["UnitPrice", "totalCost"]}
                    bottomInformation={[
                        152, "", "Cash", "", "Check", "", "", "Open     Weight: " + 12365, "Net Invoice: ", "$", 23.33,
                        "", "", "", "", "", "", "", "", "Freight: ", "$",100.00,
                        "", "", "", "", "", "", "", "", "Invoice Total: ", "$", 100.56

                    ]}
                    bottomStyle={[
                        "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                        "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                        "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3",
                    ]}
                    bottomMessage={"Returned checks will incur an additional charge of $50.00. Los cheques rechazados tendran un cargo adicional de $50.00. \nNo Returns after 24 hours after delivered, No se aceptan devoluciones despues de 24 horas de entregado."}
               

                />


            </div>
        )
    }
}