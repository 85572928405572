import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import PieGraph from '../PieGraph';
import SmallModal from '../SmallModal';

import { DateFormatMAS90 } from '../../functions/dateFormat';
import { getValueCookie } from '../../services/cookieService';


export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title: '',
        showModal1: false,
        Detail: [],
        Total: 0,


    }
    clearStates() {
        this.setState({
            Detail: [],
            Title: '',
            Total: 0
        })
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearStates()
    }

    getDetail(detail, title, total) {
        this.setState({ Detail: detail.sort((a, b) => b.NonTaxableSalesAmt - a.NonTaxableSalesAmt), Title: title, Total: total })
        this.handleModalOpen("showModal1")
    }

    addMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData((this.props.Date.setMonth(this.props.Date.getMonth() + 1)))
        }

    }
    restMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData((this.props.Date.setMonth(this.props.Date.getMonth() - 1)))
        }
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h5>{this.props.Title}</h5>
                    </div>
                    <div className='col-sm-12'>
                        <div className='tableFixHeadSmall'>
                            <table className='table align-middle'>
                                <thead className='thead'>
                                    <tr className='text-light text-center'>

                                        <th className='bg-dark text-center' colSpan={5}>
                                            <div className='row'>
                                                <div className='col-2' onClick={() => this.restMonth()}><AiFillCaretLeft /></div>
                                                <div className='col-8'>{this.props.Month}</div>
                                                <div className='col-2' onClick={() => this.addMonth()}><AiFillCaretRight /></div>
                                            </div>
                                        </th>

                                    </tr>
                                    <tr className='text-light text-center'>
                                        <th className=' bg-dark'>Vendor No</th>
                                        <th className='bg-dark '>Vendor Name</th>
                                        <th className='bg-dark '></th>
                                        <th className='bg-dark '>Total</th>
                                        <th className='bg-dark '>Percentage</th>

                                    </tr>
                                </thead>
                                <tbody className='tbody bg-light'>
                                    {
                                        this.props.data.Detail.map((element, e) => (
                                            <tr onClick={() => this.getDetail(element.Detail, element.VendorName, element.Total)} key={e}>
                                                <td className=' text-start' >{element.VendorNo}</td>
                                                <td className=' text-start' >{element.VendorName}</td>
                                                <td className=' text-end'>$</td>
                                                <td className=' text-end'>{NumberFormat(pricesFormat(element.Total))}</td>
                                                <td className=' text-center'>{(pricesFormat(element.Percentage)) + "%"}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot bg-light'>
                                    <tr className=''>
                                        <td className='text-center '>TOTAL</td>
                                        <td className=' text-end'></td>
                                        <td className=' text-end'>$</td>
                                        <td className=' text-end'>{NumberFormat(pricesFormat(this.props.data.Total))}</td>
                                        <td className=' text-center'>{"100%"}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='col-sm-12 pb-2 backgroundWhite'>
                        <p className='InputTextBolderSimple'>**The total sales does not include late invoices, spoilage, discounts and credit notes</p>
                    </div>
                    <div className='col-sm-2 pt-3 backgroundWhite'></div>
                    <div className='col-sm-8 pt-3 backgroundWhite'>
                        <PieGraph options={this.props.data.Options} data={this.props.data.DataSet} />
                    </div>
                    <div className='col-sm-2 pt-3 backgroundWhite'></div>

                </div>
                <SmallModal centered={true} size={'xl'} title={'Purchases: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1' />
                        <div className='col-12'>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle'>
                                    <thead className='bg-primary text-white'>
                                        <tr>
                                            <th className='text-center bg-primary '>Purchase Order No</th>
                                            <th className='text-center bg-primary '>Receipt Date</th>
                                            <th className='text-center bg-primary '>Vendor No</th>
                                            <th className='text-center bg-primary '>Vendor Name</th>
                                            <th className='text-center bg-primary '>Order Date</th>
                                            <th className='text-center bg-primary '>Warehouse</th>
                                            <th className='text-center bg-primary '>Comment</th>
                                            <th className='text-center bg-primary '>Invoice Date</th>
                                            <th className='text-center bg-primary '></th>
                                            <th className='text-center bg-primary '>Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Detail.map((order, o) => (
                                            <tr key={o}>
                                                <td className='text-center'>{order.PurchaseOrderNo}</td>
                                                <td className='text-center'>{DateFormatMAS90(order.ReceiptDate)}</td>
                                                <td className='text-center'>{order.VendorNo}</td>
                                                <td className='text-left'>{order.PurchaseName}</td>
                                                <td className='text-center'>{DateFormatMAS90(order.OrderDate)}</td>
                                                <td className='text-center'>{order.WarehouseCode}</td>
                                                <td className='text-left'>{order.Comment}</td>
                                                <td className='text-center'>{DateFormatMAS90(order.InvoiceDate)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.NonTaxableAmt))}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className='bg-primary text-white'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-center textTableSize'>Totals</td>
                                            <td className='text-center textTableSize'></td>
                                            <td className='textTableSize text-end'>$</td>
                                            <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>

            </>
        )
    }
}
