import React, { Component } from 'react'
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import LoadingWindow from '../../../components/LoadingWindow';
import { create_Delete_Update_Information, getInformationWithData } from '../../../services/CABE';
import { getIndexElement } from '../../../functions/searchInObject';
import { IncludeProducts } from './Class';
import { confirmCloseAlert } from '../../../functions/alerts';


export default class NewProductsView extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        FilterProducts: [],
        Products: [],
    }

    clearAll(){
        this.setState({FilterProducts: [],Products: [],})
    }

    async getProductsToInclude() {
        this.ModalLoading.current.showState(true);
        let Products = []
        const data = {
            idcompany: '-1',
        }
        const actualProducts = await getInformationWithData('/Items/get', data)
        data.idcompany = '3'
        const compareProducts = await getInformationWithData('/Items/get', data)

        if (actualProducts.status.code === 1 && compareProducts.status.code === 1) {
            for (const item of compareProducts.data) {
  
                const index = getIndexElement(actualProducts.data, 'itemCode', item.itemCode)
                if (index === -1) {
                    console.log(item)
                    var includeProd = new IncludeProducts(item.itemCode,item.ProductLine, item.ProductLineDesc, item.abbreviatedDesc,item.LastTotalUnitCost)
                    Products.push(includeProd)
                }
            }
        }
        
        this.setState({ FilterProducts: Products, Products: Products })
        this.ModalLoading.current.showState(false);
    }

    async importAllNewProducts(){
        const data={
            Products:this.state.Products
        }
        console.log(data)
        const result= await create_Delete_Update_Information('/Items/sync/Alt',data)
        if(result.status.code===1){
            confirmCloseAlert('correct','The products was include successfully!')
            this.clearAll()
        }else{
            confirmCloseAlert('incorrect','Something went wrong. Try again!')
        }
        

    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }
    searchProducts=e=>{
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.Products
        } else {
            Filter = this.state.Products.filter((detail) => {
                if (this.Contains(detail.ItemCode, searchValue) ||
                    this.Contains(detail.ProductLine, searchValue) ||
                    this.Contains(detail.Description, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterProducts: Filter })
    }


    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Products To Include</p>
                <div className='row pb-2'>
                    <div className='col-12 pb-3'>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-10'>
                                <div className="d-grid gap-2">
                                    <button type="button" onClick={() => this.getProductsToInclude()} className="btn greenButton btn-lg">Search Products To Include</button>
                                </div>
                                <div hidden={this.state.Products.length===0} className='pt-3 pb-3'>
                                    <input onChange={this.searchProducts} className="form-control form-control-lg" placeholder='Search by Item Code, Descritpion, Product Line...' id='searchNewProductSync' type="text" />
                                </div>
                                <div className="d-grid gap-2">
                                    <button hidden={this.state.Products.length===0} type="button" onClick={() => this.importAllNewProducts()} className="btn blueButton btn-lg">Include All Products</button>
                                </div>
                            </div>
                            <div className='col-1'></div>
                        </div>
                    </div>
                    <div hidden={this.state.Products.length===0} className='col-12 tableFixHead'>
                        <table className='table align-middle'>
                            <thead className='thead'>
                                <tr className='bg-primary text-white'>
                                    <th className='text-center bg-primary '>Item Code</th>
                                    <th className='text-center bg-primary '>Product Line</th>
                                    <th className='text-center bg-primary '>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.FilterProducts.map((item, i) => (
                                    <tr key={i} hidden={item.Visible === 0}>
                                        <td className='text-start'>{item.ItemCode}</td>
                                        <td className='text-start'>{item.ProductLine}</td>
                                        <td className='text-start'>{item.Description}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>

            </div>
        )
    }
}