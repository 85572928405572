export class OrderPrimaryVendor {
    PrimaryVendorNo
    PrimartVendorName
    InvoiceNo
    Date
    TotalQuantityOrdered
    TotalQuantityReceived
    TotalAmtOrdered
    Detail

    constructor(PrimaryVendorNo, PrimartVendorName,InvoiceNo) {
        this.PrimaryVendorNo = PrimaryVendorNo
        this.PrimartVendorName = PrimartVendorName
        this.InvoiceNo=InvoiceNo
        this.TotalQuantityOrdered = 0
        this.TotalQuantityReceived=0
        this.TotalAmtOrdered = 0
        this.Date=''
        this.Detail = []
    }

    addQuantityOrdered(quant) {
        this.TotalQuantityOrdered += quant
    }
    addQuantityReceived(quant) {
        this.TotalQuantityReceived += quant
    }
    addAmtValue(value) {
        this.TotalAmtOrdered += value
    }
    removeOldQuantityOrdered(quant) {
        this.TotalQuantityOrdered -= quant
    }
    removeOldQuantityReceived(quant) {
        this.TotalQuantityReceived -= quant
    }
    removeOldAmtValue(value) {
        this.TotalAmtOrdered -= value
    }
    calculateFinalTotals(){
        this.TotalQuantityReceived=0
        this.TotalAmtOrdered=0
        for (const item of this.Detail) {
            this.TotalQuantityReceived+=item.QuantityReceived
            this.TotalAmtOrdered+=item.totalCost
        }
    }

}

export class OrderPrimaryVendorWithInvoiceNo {
    PrimaryVendorNo
    PrimartVendorName
    InvoiceNo
    PurchaseOrderNo
    TotalQuantityOrdered
    TotalQuantityReceived
    TotalAmtOrdered
    Detail

    constructor(PrimaryVendorNo, PrimartVendorName,InvoiceNo,PurchaseOrderNo) {
        this.PrimaryVendorNo = PrimaryVendorNo
        this.PrimartVendorName = PrimartVendorName
        this.InvoiceNo=InvoiceNo
        this.PurchaseOrderNo=PurchaseOrderNo
        this.TotalQuantityOrdered = 0
        this.TotalQuantityReceived=0
        this.TotalAmtOrdered = 0
        this.Detail = []
    }

    addQuantityOrdered(quant) {
        this.TotalQuantityOrdered += quant
    }
    addQuantityReceived(quant) {
        this.TotalQuantityReceived += quant
    }
    addAmtValue(value) {
        this.TotalAmtOrdered += value
    }
    removeOldQuantityOrdered(quant) {
        this.TotalQuantityOrdered -= quant
    }
    removeOldQuantityReceived(quant) {
        this.TotalQuantityReceived -= quant
    }
    removeOldAmtValue(value) {
        this.TotalAmtOrdered -= value
    }
    calculateFinalTotals(){
        this.TotalQuantityReceived=0
        this.TotalAmtOrdered=0
        for (const item of this.Detail) {
            this.TotalQuantityReceived+=item.QuantityReceived
            this.TotalAmtOrdered+=item.totalCost
        }
    }

}




export class ItemDetal {
    ItemCode
    Description
    SalesOrderNo
    Price
    QuantityOrdered
    QuantityReceived
    Total
    ShipWeight
    StandardUnitCost
    LasTotalUnitCost
    UnitCost
    Stock
    totalCost
    Comment
    totalFreight
    AllSalesOrderPerItem
    NewItem


    constructor(ItemCode, Description, SalesOrderNo, Price, QuantityOrdered,QuantityReceived,UnitCost,NewItem,OnHand) {
        this.ItemCode = ItemCode
        this.OnHand=OnHand
        this.Description = Description
        this.SalesOrderNo = SalesOrderNo
        this.Price = Price
        this.QuantityOrdered = QuantityOrdered
        this.QuantityReceived = QuantityReceived
        this.Stock=0
        this.Total = 0
        this.ShipWeight=0
        this.StandardUnitCost=0
        this.LasTotalUnitCost=0
        this.UnitCost=UnitCost
        this.totalCost=0
        this.Comment=''
        this.totalFreight=0
        this.AllSalesOrderPerItem=[]
        this.NewItem=NewItem?false:true
    }

    addQuantityOrdered(value) {
        this.QuantityOrdered += value
    }
    addQuantityReceived(value) {
        this.QuantityReceived += value
    }
    setCurrentTotalByOrdered() {
        this.totalCost = this.QuantityReceived * Number((Number(this.UnitCost)).toFixed(2))
    }
    addSalesOrderItem(order){
        this.AllSalesOrderPerItem.push(order)
    }
    calculateQuantityReceived(val){
       
        this.QuantityReceived=val
    }
    calculateQuantityReceivedPerItem(){
        this.QuantityReceived=0
        this.QuantityReceived+=this.Stock
        for (const Invoice of this.AllSalesOrderPerItem) {
            this.QuantityReceived+=Invoice.QuantityShipped
        }
    }
    calculateStock(){
        let totalSales=0
        for (const Invoice of this.AllSalesOrderPerItem) {
            totalSales+=Invoice.QuantityShipped
        }
        this.Stock=this.QuantityReceived-totalSales
    }
    resetQuantityShipped(){
        for (const order of this.AllSalesOrderPerItem) {
            order.QuantityShipped=0
        }
    }

}

export class PurchaseOrderStructure {
    NoOrder
    Carrier
    orderDate
    completionDate
    realCompletionDate
    creationDate
    vendorNo
    vendorName
    comment
    createBy
    State
    totalFreight

    constructor(NoOrder, Carrier, orderDate, completionDate, realCompletionDate, creationDate, vendorNo, vendorName, comment, createBy, State, totalFreight) {
        this.NoOrder = NoOrder
        this.Carrier = Carrier
        this.orderDate = orderDate
        this.completionDate = completionDate
        this.realCompletionDate = realCompletionDate
        this.creationDate = creationDate
        this.vendorNo = vendorNo
        this.vendorName = vendorName
        this.comment = comment
        this.createBy = createBy
        this.State = State
        this.totalFreight = totalFreight
    }
}
