import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({

    blockHeaderStyle:{
        width:"100%",
        display: "flex",
        flexDirection: "row",
        

    },

    CompanyTitleStyle:{
        fontSize: "40px",
        color: "gray", 
        left: "20px",
        top:"8px" ,
        marginBottom:"15px"
        
    },
    TypeInvoiceTitle:{
       fontSize: "15px", 
       color: "black", 
       left:"82px",
       top:"15px" ,  
    },
    PageInfoStyle:{
        fontSize: "10px", 
        color: "black",
        top:"10px",
        left:"60px"
    },
    blockHeader:{
        width:"33.33%",
    },
    block50Percent:{
        width:"50%",
    },
    block30Percent:{
        width:"30%",
    },
    block70Percent:{
        width:"70%",
    },
    HeaderTextStyle:{
        fontSize: "8px", 
        left: "33px"
    },
    ColumnaHeader2: {
        width: "45%",
        fontSize: "8px",
        textAlign: "left",
        display: "block",
        color: "#000000"
    },
    ColumnaHeader1: {
        width: "40%",
        fontSize: "8px",
        textAlign: "left",
        display: "block",
        color: "#000000"
    },
    SubContainerHeader: {
        width: "70%",
        marginLeft: "35%",
        display: "flex",
        flexDirection: "row",
        marginBottom: "2px",
        color: "rgb(255, 255, 255)"

    },
    TitleBoxes1:{
        fontSize: "11px",
        left:"35px",
        marginBottom:"2px",
        marginTop:"5px"
    },
    TitleBoxes2:{
        fontSize: "11px",
        left:"25px",
        marginBottom:"2px",
        marginTop:"5px"
    },
    BoxLeft: {
        width:"30%",
        border: '2px',
        borderColor: 'black',
        borderStyle: 'solid',
        minHeight: '10px',
        minWidth: '225px',
        marginLeft:"35px",
        
    },
    BoxRight: {
        width:"30%",
        border: '2px',
        borderColor: 'black',
        borderStyle: 'solid',
        minHeight: '10px',
        minWidth: '225px',
        marginLeft:"62px"
    },

    TextStyleInHeaderBoxes:{
        fontSize: "11px",
        marginLeft:"3px"      
    },
    Table1Style:{
        marginLeft:"6%",
        marginRight:"6%",
        marginTop:"2px",
        width:"88%",
        borderColor: 'black',
        borderStyle: 'solid',
        borderBottom: '1.5px',
        borderTop:'1.5px',
        fontSize: "11px",
        
    },
    block22Percent:{
        width:"22%",
        display: "block",
        marginBottom:"5px"
    },
    block22PercentHeader:{
        width:"22%",
        display: "block",
        
    },
    Table1File:{
        width:"100%",  
        display: "flex",
        flexDirection: "row",
        
    },
    Table: {
        width: "88%",
        marginLeft: "6%",
        marginTop: "9px",

    },

    CabeceraTabla: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        borderBottom: '2px',
        borderTop:'2px',
        borderRight:'2px',
        borderColor: 'black',
        borderStyle: 'solid',
    },
    ColumnaCantidadesHeader: {

        width: "7%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",
        borderLeft: '2px',
        borderColor: 'black',
        borderStyle: 'solid',
   
    },
    ColumnaCantidades: {
        width: "7%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",
        borderLeft: '2px',
        borderBottom:'1px',
        borderColor: 'black',
        borderStyle: 'solid',
        
    },
    ColumnaCantidadesClear: {
        width: "7%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",
        borderLeft: '2px',
        borderColor: 'black',
        borderStyle: 'solid',
        
    },
    ColumnaCantidadesBottom: {
        width: "7%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",

    },
    ColumnaNormalHeader: {
        fontSize: "7.5px",
        width: "13%",
        textAlign: "center",
        display: "block",

    },
    ColumnaNormal: {
        fontSize: "7.5px",
        width: "13%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        borderBottom:"1px"
 
    },
    ColumnaNormalClear: {
        fontSize: "7.5px",
        width: "13%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
 
    },
    ColumnaNormalBottom: {
        fontSize: "7.5px",
        width: "13%",
        textAlign: "center",
        display: "block",

    },

    ColumnaNormal2Header: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "center",
        display: "block",

    },
    ColumnaNormal2: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        borderBottom:"1px"
 
    },


    ColumnaNormal2Bottom: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "center",
        display: "block",

    },

    ColumnaNormal2Clear: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",

 
    },

    ColumnaGrandeHeader: {
        width: "45%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",


    },
    ColumnaGrande: {
        width: "45%",
        fontSize: "7.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        borderBottom:"1px"

    },
    ColumnaGrandeClear: {
        width: "45%",
        fontSize: "7.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",

    },
    ColumnaGrandeBottom: {
        width: "45%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",

    },


    ColumnaGrande2Header: {
        width: "60%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",


    },
    ColumnaGrande2: {
        width: "60%",
        fontSize: "7.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        borderBottom:"1px"

    },
    ColumnaGrande2Clear: {
        width: "60%",
        fontSize: "7.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",

    },

    ColumnaMonetariaHeader: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "left",
        display: "block",
        
 
    },
    ColumnaMonetaria: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderBottom:"1px",

    },
    ColumnaMonetariaClear: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",

    },
    ColumnaMonetariaBottom: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",
 
    },
    ColumnaMonetariaRightHeader: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "left",
        display: "block",
        
 
    },
    ColumnaMonetariaRight: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderRight:"2px",
        borderBottom:"1px"

    },
    ColumnaMonetariaRightClear: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderRight:"2px",

    },

    ColumnaMonetariaRightBottom: {
        width: "10%",
        fontSize: "7.5px",
        textAlign: "center",
        display: "block",
 
    },
    SimboloHeader: {
        width: "3.5%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",
  
    },
    Simbolo: {
        width: "3.5%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        borderBottom:"1px"
       
    },
    SimboloClear: {
        width: "3.5%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
       
    },

    SimboloBottom: {
        width: "3.5%",
        fontSize: "7.5px",
        textAlign: "right",
        display: "block",
        
    },
    
    CuerpoTabla: {
        width: "100%",
        textAlign: "center",
        fontSize: "9px",
        
//paddingTop: 50
    },
    CuerpoTablaFinal: {
        width: "100%",
        textAlign: "center",
        fontSize: "9px",
        borderColor: 'black',
        borderStyle: 'solid',
        borderBottom:"2px"
        
//paddingTop: 50
    },
    Fila: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        
    }, FilaBreak: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        paddingTop: "105px"
        
    }
    
    
    ,
    ColumnaNormalCenterHeader: {
        fontSize: "7.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
    
    },
    ColumnaNormalCenter: {
        fontSize: "6.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        borderBottom:"1px"
    },
    ColumnaNormalCenterClear: {
        fontSize: "7.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        
    },
    ColumnaNormalCenterBottom: {
        fontSize: "7.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        
    },

    BottomTextStyle:{
        marginTop:"8px",
        fontSize: "8px",
       
    },
    CabeceraBottom: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        textAlign: "center",
        display: "flex",
        fontSize:"12px",
        flexDirection: "row",
        marginTop:"4px"
    },

    ColumnButtom: {
        width: "12.5%",
        textAlign: "center",
        display: "block",
    },

    NormalButtom: {
        marginTop:"2px",
        width: "7%",
        fontSize: "9px",
        textAlign: "center",
        display: "block",  
    },
    LargeButtom: {
        marginTop:"2px",
        width: "27%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",  
    },
    CheckButtom: {
        width: "4%",
        minHeight:"15px",
        fontSize: "9px",
        textAlign: "center",
        display: "block",
        border: '2px',
        borderColor: 'black',
        borderStyle: 'solid', 
        marginLeft:"2px",
        marginRight:"2px", 
    },
    LineButtom: {
        marginLeft:"2px",
        marginRight:"2px",
        marginTop:"2px",
        width: "20%",
        fontSize: "9px",
        textAlign: "center",
        display: "block",
        borderBottom:"1px",
        borderColor: 'black',
        borderStyle: 'solid',    
    },
    BottomMargin: {
        width:"100%",
        marginTop:"10px",
        minHeight:"50px",
        
    },
    LineButtom2: {
    
        width: "20%",
        fontSize: "9px",
        textAlign: "right",
        display: "block",   
    },
    LineButtom3: {
 
        width: "20%",
        fontSize: "9px",
        textAlign: "right",
        display: "block",   
        borderTop:"1px",
        borderColor: 'black',
        borderStyle: 'solid', 
    },
    LineButtom2Left: {

        width: "18%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",   
    },
    LineButtom3Left: {
        
        width: "18%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",
        borderTop:"1px",
        borderColor: 'black',
        borderStyle: 'solid',     
    },
    
    
    DolarButtomLeft: {

        width: "2%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",   
    },
    DolarButtomLeft2: {
        
        width: "2%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",
        borderTop:"1px",
        borderColor: 'black',
        borderStyle: 'solid',      
    }
    ,
    ClearBottom: {
        marginLeft:"2px",
        marginRight:"2px",
        marginTop:"2px",
        width: "9.5%",
        fontSize: "9px",
        textAlign: "right",
        display: "block",   
    },
    ColumnaNormal2LeftHeader: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "center",
        display: "block",

    },
    ColumnaNormal2Left: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeft:"2px",
        borderBottom:"1px"
 
    },


    ColumnaNormal2LeftBottom: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "center",
        display: "block",

    },

});

export default styles;