import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';



export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {

    }


    render() {
        return (
            <>
                
                    <div className='row '>
                        <div className='col-sm-12'>
                            <h5>{this.props.Title}</h5>
                        </div>
                        <div className='col-sm-12 backgroundWhite'>

                            <div className='pb-3' />
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle'>
                                    <thead className='bg-primary text-white'>
                                        <tr>
                                            <th className='text-center bg-primary '>Customer No</th>
                                            <th className='text-center bg-primary '>Customer Name</th>
                                            <th className='text-center bg-primary '></th>
                                            <th className="text-center bg-primary ">Credit Limit</th>
                                            <th className='text-center bg-primary '></th>
                                            <th className='text-center bg-primary '>Current Balance</th>
                                            <th className='text-center bg-primary '></th>
                                            <th className='text-center bg-primary '>Excess</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.data.map((order, o) => (
                                            <tr key={o}>
                                                <td className='text-center'>{order.CustomerNo}</td>
                                                <td className='text-start'>{order.CustomerName}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.CreditLimit))}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.CurrentBalance))}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.Exceso))}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>


                    </div>
               



            </>
        )
    }
}
