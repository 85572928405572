import React, { Component } from 'react'
import "../css/general-style.css"
import { confirmCloseAlert } from '../functions/alerts';
import { getIndexElement } from '../functions/searchInObject';
import { getInformationWithData } from '../services/CABE';
import { getValueCookie } from '../services/cookieService';
import { AiOutlineSearch } from "react-icons/ai"
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import LoadingWindow from './LoadingWindow';
export default class SearchUPC extends Component {

    constructor(props) {
        super(props);
        this.retornarProducto = React.createRef();
        this.ModalLoading = React.createRef();
    }

    state = {

    }
    onKeyDown = async e => {
        if (e.key === 'Enter'||e.key==='Tab') {
            await this.searchItemUPC(e.target.value)
            e.target.value = '';

        }
    }



    async searchItemUPC(UPC) {
        await this.ModalLoading.current.showState(true);
        const regex=/(\)([0-9])+=).+/g
        if(regex.test(UPC)){
            UPC=UPC.replace(')','(')
            UPC=UPC.replace('=',')')
        }
        const Father = this.props.Padre
        let priceLevel = this.props.priceLevel
        if (!priceLevel) {
            priceLevel = 1
        }
        const data = {
            upc: UPC,
            company: getValueCookie('Company'),
            idCompany: Number(getValueCookie('CompanyId')),
            idcompany: Number(getValueCookie('CompanyId')),
            priceLevel: priceLevel
        }

        const respuesta = await getInformationWithData('/Items/getItemByUpc', data);

        if (respuesta.status.code === 1 && (respuesta.result !== undefined && respuesta.result !== null)) {
            const res = respuesta.result
            if (res.length > 0) {
                let HaveUPC=true
                const state = Father.state
                if (this.props.ComponentName === 'PickingSystem') {
                    if (Father.state.General.Mode === 'S/O') {
                        const index1 = getIndexElement(state.OrderDetailtoPick, 'ItemCode', res[0].itemCode)
                        const index2 = getIndexElement(state.OrderDetailPicked, 'ItemCode', res[0].itemCode)
                        if (index1 !== -1) {
                            state.OrderDetailtoPick[index1].RegisterType = 's'
                            state.OrderDetailtoPick[index1].HaveUPC = HaveUPC
                            await Father.PickItem(state.OrderDetailtoPick[index1],true)
                        } else if (index2 !== -1) {
                            confirmCloseAlert('incorrect', 'The Item: ' + res[0].itemCode + ' was already picked up')
                        } else {
                            confirmCloseAlert('incorrect', 'The Item: ' + res[0].itemCode + 'was not found in ther current order')
                        }
                    } else {
                        const index1 = getIndexElement(state.OrderDetailtoPick, 'ItemCode', res[0].itemCode)
                        const index2 = getIndexElement(state.OrderDetailPicked, 'ItemCode', res[0].itemCode)
                        if (index1 !== -1) {
                            state.OrderDetailtoPick[index1].RegisterType = 's'
                            state.OrderDetailtoPick[index1].HaveUPC = HaveUPC
                            await Father.PickItem(state.OrderDetailtoPick[index1],true)
                        } else if (index2 !== -1) {
                            confirmCloseAlert('incorrect', 'The Item: ' + res[0].itemCode + ' was already picked up')
                        } else {

                            Swal.fire({
                                title: `Are you sure to add ${res[0].itemCode}?`,
                                text: "You are adding a product which is not in the original purchase order ",
                                icon: 'warning',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, please add!',
                                cancelButtonText: 'No, cancel!',
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    const te = res[0]
                                    const prod = {
                                        BIN: "",
                                        Comment: "",
                                        unitCost: te.LastTotalUnitCost,
                                        UnitPrice: te.UnitPrice,
                                        quantityOrdered: 0,
                                        quantityReceived: 0,
                                        totalCost: 0,
                                        totalFreight: 0,
                                        ItemCode: te.itemCode,
                                        QuantityOrdered: 0,
                                        QuantityReceived: 0,
                                        Pallet: '01',
                                        ItemCodeDesc: te.abbreviatedDesc,
                                        LineWeight: te.ShipWeight,
                                        totalWeight: 0,
                                        RegisterType: 's',
                                        HaveUPC:HaveUPC
                                    }
                                    const temporal = state.OrderDetailtoPick
                                    temporal.unshift(prod)

                                    Father.setState({ OrderDetailtoPick: temporal })
                                    Father.PickItem(prod)
                                }
                            })
                        }
                    }

                } else if (this.props.ComponentName === 'Invoice' || this.props.ComponentName === 'SalesOrder') {
                    if(getValueCookie('CompanyId')==='6'){
                        console.log(res[0])
                        if(res[0].UnitPriceALT&& priceLevel !== 2){
                            
                            res[0].UnitPrice=Number(res[0].UnitPriceALT).toFixed(2)
                        }
                    }
                    const index = getIndexElement(state.Products, 'itemCode', res[0].itemCode)
                    const temporal = state.Products
                    if (index !== -1) {

                        if (temporal[index].Visible === 0) {
                            if (this.props.ComponentName === 'SalesOrder') {
                                temporal[index].HaveUPC=HaveUPC
                                temporal[index].quantityOrdered = 1

                                temporal[index].totalCost = Number(temporal[index].quantityOrdered) * Number(temporal[index].UnitPrice)
                            } else if (this.props.ComponentName === 'Invoice') {
                                temporal[index].HaveUPC=HaveUPC
                                temporal[index].RegisterType = 's'
                                temporal[index].quantityShipped = 1
                                temporal[index].totalCost = Number(temporal[index].quantityShipped) * Number(temporal[index].UnitPrice)
                                temporal[index].totalWeight = Number(temporal[index].quantityShipped) * Number(temporal[index].ShipWeight)
                                temporal[index].Pallet=1
                            }

                        } else {
                            if (this.props.ComponentName === 'SalesOrder') {
                                temporal[index].quantityOrdered += 1
                                temporal[index].HaveUPC=true
                                temporal[index].totalCost = Number(temporal[index].quantityOrdered) * Number(temporal[index].UnitPrice)
                                
                            } else if (this.props.ComponentName === 'Invoice') {
                                temporal[index].RegisterType = 's'
                                temporal[index].HaveUPC=HaveUPC
                                temporal[index].quantityShipped += 1
                                temporal[index].totalCost = Number(temporal[index].quantityShipped) * Number(temporal[index].UnitPrice)
                                temporal[index].totalWeight = Number(temporal[index].quantityShipped) * Number(temporal[index].ShipWeight)
                                temporal[index].Pallet=1
                            }
                        }

                        temporal[index].Visible = 1
                        const startOfTheList = temporal[index]
                        await temporal.splice(index, 1)
                        await temporal.unshift(startOfTheList)
                        await Father.setState({ Products: temporal });
                        await Father.calculateTotals()
                        await Father.refreshInputs()

                    } else {

                        const prod = {
                            Comment: "",
                            LastTotalUnitCost: Number(res[0].LastTotalUnitCost),
                            LineSequence: 0,
                            ShipWeight: Number(res[0].ShipWeight),
                            StandardUnitCost: Number(res[0].StandardUnitCost),
                            UnitPrice: Number(res[0].UnitPrice).toFixed(2),
                            Visible: 1,
                            abbreviatedDesc: res[0].abbreviatedDesc,
                            caseupc: UPC,
                            itemCode: res[0].itemCode,
                            quantity: res[0].quantity,
                            quantityOrdered: this.props.ComponentName === 'SalesOrder' ? 1 : 0,
                            quantityReceived: 0,
                            quantityShipped: this.props.ComponentName === 'Invoice' ? 1 : 0,
                            totalCost: Number(res[0].UnitPrice),
                            totalFreight: 0,
                            unitCost: null,
                            upc: UPC,
                            upc2: UPC,
                            totalWeight: Number(res[0].ShipWeight),
                            RegisterType: 's',
                            HaveUPC:HaveUPC
                        }
                        if(getValueCookie('SalesPerson')==='0010'){
                            prod.UnitPrice=prod.LastTotalUnitCost
                            prod.totalCost=prod.LastTotalUnitCost
                        }


                        await temporal.unshift(prod);
                        await Father.setState({ Products: temporal });
                        await Father.calculateTotals()
                        await Father.refreshInputs()
                    }
                } else if (this.props.ComponentName === 'Inventory'||this.props.ComponentName === 'AutomaticPO') {
                    const result = await getInformationWithData('/Items/get', data);
                    
                    if(getValueCookie('CompanyId')==='6'){
                        data.idcompany=3
                        data.idCompany=3
                        const InventM = await getInformationWithData('/Items/get',data)
                        result.status.code=InventM.status.code
                        for (const item of InventM.data) {
                            const index = getIndexElement(result.data, 'itemCode', item.itemCode)
                            if (index !== -1) {
                                await result.data.splice(index, 1)
                            }
                        }
                       
        
                        result.data = result.data.concat(InventM.data)
                        
                    }
                    if (result.status.code === 1) {
                        const index = getIndexElement(result.data, 'itemCode', res[0].itemCode)
                        if(this.props.ComponentName === 'Inventory'){
                            if (index !== -1) {
                                result.data[index].isUpdateImg = result.data[index].itemURLImage ? true : false
                                result.data[index].HaveUPC=HaveUPC
                                await Father.setState({ Product: result.data[index] })
                                await Father.updateUPCInputs(true)
                            } else {
                                const te = Father.state.Product
                                te.itemCode = ''
                                await Father.setState({ Product: te })
                                await Father.updateUPCInputs(true)
                                confirmCloseAlert('incorrect', 'The UPC: ' + UPC + ' was not found, try with another one')
                            }
                        }else if(this.props.ComponentName ==='AutomaticPO'){
                            if (index !== -1) {
                                result.data[index].HaveUPC=HaveUPC
                                result.data[index].UnitPrice=respuesta.result[0].UnitPrice
                                result.data[index].LastTotalUnitCost=respuesta.result[0].LastTotalUnitCost
                                result.data[index].isUpdateImg = result.data[index].itemURLImage ? true : false
                                Father.AddProduct(result.data[index],this.props.extraInfo)
                                
                               
                            } else {
                                confirmCloseAlert('incorrect', 'The UPC: ' + UPC + ' was not found, try with another one')
                            }
                        }
                       
                    }

                } else if (this.props.ComponentName === 'SpoilageSystem'||this.props.ComponentName === 'InventorySystem') {
                    res[0].HaveUPC=HaveUPC
                    Father.selectSpoilageProduct(res[0], 's')
                }else if (this.props.ComponentName==='ReturnPO'){
                    res[0].HaveUPC=HaveUPC
                    Father.addItem2(res[0])
                }

            } else {

                confirmCloseAlert('incorrect', 'The UPC: ' + UPC + ' was not found, try with another one')
            }

        } else {
            confirmCloseAlert('incorrect', 'The UPC: ' + UPC + ' was not found, try with another one')
        }
        this.ModalLoading.current.showState(false);






    }



    render() {
        return (
            
            <div className="input-group input-group-lg pb-3">
                 <LoadingWindow ref={this.ModalLoading} />
                <span className="input-group-text"><AiOutlineSearch /></span>
                <input id={this.props.id?this.props.id:uuidv4()} disabled={this.props.disabled?true:false} type="text" autoComplete='off' placeholder='Search by Item Code, UPC...' className="form-control" onKeyDown={this.onKeyDown} />
            </div>
        )
    }
}
