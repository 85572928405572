import { SalesByCustomerRoute, SalesPerMonth, SalesPerWeek } from "../../components/TableDetailSalesPerRoute/Class";
import { ActualDate, DateFormatMAS90, getActualDateUTC, getWeekNumber } from "../../functions/dateFormat";
import { intervalTimeInDays, intervalTimeInDays2, MonthInLetters, NameSalesPerson, NumberFormat, random_rgba } from "../../functions/generalFunctions";
import { pricesFormat } from "../../functions/pricesFormat";
import { getIndexElement } from "../../functions/searchInObject";
import { getInformationWithData, getInformationWithDataGlobalSales } from "../../services/CABE";
import { getValueCookie } from "../../services/cookieService";
import { GeneralFormatToSend, SalesPerRouteMonth, TitlesSalesPerRoutes } from "./Class";
import { SalesPerCalendarSeptember, SalesPerSeptember } from "./temporalInfoJDL";
import React from "react"
export async function getSalesOrdersPerUser(SalesPerson) {
    const data = {
        SalespersonNo: SalesPerson ? SalesPerson : getValueCookie('SalesPerson'),
        idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
        CustomerNo: '*'
    }

    const SalesOrder = await getInformationWithData('/sales/get/header', data);
    const Invoices = await getInformationWithData('/invoice/header', data);

    if (SalesOrder.status.code === 1) {
        for (const order of SalesOrder.data) {
            order.ColorRow = intervalTimeInDays(order.OrderDate, ActualDate()) >= 7 ? "softGray" : (intervalTimeInDays(order.OrderDate, ActualDate()) > 5 ? "softYellow" : "softGreen")
            order.OrderDate = DateFormatMAS90(order.OrderDate)
            order.TypeinLetters = order.OrderType === 'S' ? "Standard" : "Quote"
            order.ExistInv = getIndexElement(Invoices.data, 'SalesOrderNo', order.SalesOrderNo) === -1 ? 'No' : 'Yes'
            order.DaysSystem = intervalTimeInDays(order.OrderDate, ActualDate())
            order.StatusColor = ((!order.Status) || (order.Status === 0)) ? "text-danger" : (order.Status === 1 ? "text-warning" : "text-success")
            order.StatusText = ((!order.Status) || (order.Status === 0)) ? "Not started" : (order.Status === 1 ? "In process" : "Finished")
        }
        return SalesOrder.data
    } else {
        return []
    }

}

export async function getSalesPerRoute(SalesPerson, DateC) {
    let colorsRoute = [
        { Ruta: 'Warehouse', Color: 'rgba(0, 7, 106, 1)' },
        { Ruta: 'Supermarket', Color: 'rgba(149, 0, 0, 1)' },
        { Ruta: 'Distributor', Color: 'rgba(255, 193, 0, 1)' },
        { Ruta: 'Pallet', Color: 'rgba(33, 103, 0, 1)' }
    ]
    let temp = new Date()
    //let Start = new Date(temp.getFullYear(), temp.getMonth(), 1)
    let Start = new Date(temp.getFullYear(), temp.getMonth(), 1)
    let End = new Date(temp.getFullYear(), temp.getMonth() + 1, 0)

    if (DateC) {
        Start = new Date(DateC.getFullYear(), DateC.getMonth(), 1)
        End = new Date(DateC.getFullYear(), DateC.getMonth() + 1, 0)
    }


    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'), Start.toISOString().split('T')[0], End.toISOString().split('T')[0], (Start.getMonth() + 1))

    const result = await getInformationWithData('/invoice/get/Total/byRoute', data)
    const result2 = await getInformationWithData('/reports/getMeta', data)
    if (SalesPerson === 'JDL' && Start.getMonth() === 9) {
        result.data = result.data.concat(SalesPerSeptember)
    }
    let TotalMeta = 0
    let GeneralTotal = 0
    result.data.forEach(element => {
        GeneralTotal += Number(element.NonTaxableSalesAmt)
    })
    result2.data.forEach(element => {
        TotalMeta += Number(element.Meta)
    });
    let processInfo = []
    let Months = new TitlesSalesPerRoutes(false, (Start.getMonth() + 1))

    if (result.status.code === 1 && result2.status.code === 1) {

        result.data.forEach(row => {
            const indexMeta = getIndexElement(result2.data, 'Ruta', row.UDF_RUTA)
            const index = getIndexElement(processInfo, 'Name', row.UDF_RUTA)
            const date = row.InvoiceDate.split('-')
            if (date.length === 3) {
                const index2 = getIndexElement(Months, 'Month', MonthInLetters(date[1]))
                let total = 0
                if (index2 !== -1) {
                    Months[index2].Total += (Number(row.NonTaxableSalesAmt))
                    Months[index2].TotalMeta = TotalMeta
                    total = Months[index2].Total
                }

                let to = 0
                if (index !== -1) {

                    to = processInfo[index].addData(MonthInLetters(date[1]), row.NonTaxableSalesAmt, indexMeta !== -1 ? result2.data[indexMeta].Meta : 0)
                    processInfo[index].addRow(row)

                } else {
                    let newRoute = new SalesPerRouteMonth(row.UDF_RUTA, (Start.getMonth() + 1))
                    newRoute.addRow(row)
                    to = newRoute.addData(MonthInLetters(date[1]), row.NonTaxableSalesAmt, indexMeta !== -1 ? result2.data[indexMeta].Meta : 0)

                    processInfo.push(newRoute)
                }


                for (const route of processInfo) {
                    const indexMonth = getIndexElement(route.Data, 'Month', MonthInLetters(date[1]))
                    if (indexMonth !== -1 && to > 0) {

                        route.Data[indexMonth].Percentage = Number(total) > 0 ? ((Number(route.Data[indexMonth].Total) / Number(total)) * 100) : 0
                        route.Data[indexMonth].PercentageMeta = TotalMeta > 0 ? ((Number(route.Data[indexMonth].TotalMeta) / TotalMeta) * 100) : 0
                        route.Data[indexMonth].PercentageComplete = Number(route.Data[indexMonth].TotalMeta) > 0 ? ((Number(route.Data[indexMonth].Total)) / (Number(route.Data[indexMonth].TotalMeta))) * 100 : 0
                        if (route.Data[indexMonth].PercentageComplete < 30) {
                            route.Color = 'softGray'
                        } else if (route.Data[indexMonth].PercentageComplete < 99) {
                            route.Color = 'softYellow'
                        } else {
                            route.Color = 'softGreen'
                        }

                    }
                }



                if (index2 !== -1) {
                    const indexColor = getIndexElement(colorsRoute, 'Ruta', row.UDF_RUTA)
                    if (indexColor !== -1) {
                        Months[index2].addRoute(row.UDF_RUTA, GeneralTotal > 0 ? (to / GeneralTotal) * 100 : 0, row.UDF_RUTA, colorsRoute[indexColor].Color)
                    } else {
                        Months[index2].addRoute(row.UDF_RUTA, GeneralTotal > 0 ? (to / GeneralTotal) * 100 : 0, row.UDF_RUTA, random_rgba())
                    }

                }


            }

        });



    }

    processInfo = processInfo.sort((a, b) => {
        return a.Data[0].Total < b.Data[0].Total ? 1 : a.Data[0].Total > b.Data[0].Total ? -1 : 0;
    });



    return { Information: processInfo, Months: Months }
}

export async function getSalesPerRouteAllYear(){
    const result=await getInformationWithDataGlobalSales('/Invoice/SalesByRouteYear',{IdCompany:Number(getValueCookie('CompanyId'))})
    if(result.status.code===1){
        for (const Warehouse of result.data) {
            let Total=0
            for (const Line of Warehouse.Detail) {
                Total+=Line.Total
            }
            Warehouse.Total=Total
        }
            let flag=true
          for (const Warehouse of result.data) {
            if(Warehouse.Route==='Total'){
                flag=true
                
            }else{
                flag=false
            }

            let labels=[]
            let data=[]
            let data2=[]
            let colors=['rgba(39, 58, 245, 0.8)','rgba(136, 39, 245, 0.8)','rgba(196, 39, 245, 0.8)','rgba(245, 39, 234, 0.8)','rgba(245, 39, 88, 0.8)','rgba(245, 161, 39, 0.8)','rgba(161, 245, 39, 0.8)','rgba(100, 191, 32, 0.8)','rgba(32, 191, 110, 0.8)','rgba(32, 176, 191, 0.8)','rgba(32, 126, 191, 0.8)','rgba(32, 40, 191, 0.8)']
            for (const Line of Warehouse.Detail) {
                labels.push(`${Line.MonthNo}/${Line.Year}`)
                data.push(Line.Total)
                //colors.push(random_rgba())
                if(flag){
                    data2.push(Line.Total)
                    
                }else{
                    data2.push((Line.Total/Warehouse.Total)*100)
                }
                
            }



            Warehouse.Graph={
                options:{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Sales of '+Warehouse.Route,
                      },
                      tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ': $ ';
                                }
                                label += NumberFormat(pricesFormat(parseFloat(context.parsed.y.toString().replace(/,/g, '.')))) ;
    
                                return label;
                            },
                        },
                    },
                    },
                    scales: {
                        y:{
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function(value, index, values) {
                                    return '$' + NumberFormat(Number(value).toFixed(0));
                                }
                            }
                        }
                    }
                  },
                  data:{
                    labels:labels,
                    datasets:[{
                        label:'Sales',
                        data:data,
                        backgroundColor:colors
                    }]
                  },
                  reference:React.createRef()

            }
            let LineColor='rgba(191, 106, 32, 0.8)'
            Warehouse.Line={
                options:{
                    responsive: true,
                    plugins: flag?{
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: 'Sales of '+Warehouse.Route,
                        },
                        tooltip: {
                          callbacks: {
                              label: function (context) {
                                  let label = context.dataset.label || '';
                                  if (label) {
                                      label += ': $ ';
                                  }
                                  label += NumberFormat(pricesFormat(parseFloat(context.parsed.y.toString().replace(/,/g, '.')))) ;
                                  
                                  return label;
                              },
                          },
                      },
                      }:{
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: flag?Warehouse.Route:'Sales of '+Warehouse.Route,
                      },
                      tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ':';
                                }
                                label += NumberFormat(pricesFormat(parseFloat(context.parsed.y.toString().replace(/,/g, '.')))) ;
                                label += "%"
                                return label;
                            },
                        },
                    },
                    },
                    scales: flag?{
                        y:{
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function(value, index, values) {
                                    return '$' + (pricesFormat(value));
                                }
                            }
                        }
                    }:{
                        y:{
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function(value, index, values) {
                                    return (Number(value).toFixed(0))+"%";
                                }
                            }
                        }
                    }
                  },
                  data:{
                    labels:labels,
                    datasets:[{
                        label:'Sales',
                        
                        spanGaps: true,
                        data:data2,
                        backgroundColor:LineColor,
                        borderColor:LineColor
                    }]
                  }
            }

          }
          
        return result.data
    }
    return []
    
}

export async function getSalesPerSalesPerson(SalesPerson, DateC) {
    let colorsRoute = [
        { Ruta: NameSalesPerson('AM'), Color: 'rgba(0, 7, 106, 1)' },
        { Ruta: NameSalesPerson('JDL'), Color: 'rgba(149, 0, 0, 1)' },
        { Ruta: NameSalesPerson('AS'), Color: 'rgba(255, 193, 0, 1)' },
        { Ruta: NameSalesPerson('0000'), Color: 'rgba(33, 103, 0, 1)' },
        { Ruta: NameSalesPerson('0010'), Color: 'rgba(33, 105, 0, 1)' }
    ]
    let temp = new Date()
    let Start = new Date(temp.getFullYear(), temp.getMonth(), 1)
    let End = new Date(temp.getFullYear(), temp.getMonth() + 1, 0)

    if (DateC) {
        Start = new Date(DateC.getFullYear(), DateC.getMonth(), 1)
        End = new Date(DateC.getFullYear(), DateC.getMonth() + 1, 0)
    }


    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'), Start.toISOString().split('T')[0], End.toISOString().split('T')[0], (Start.getMonth() + 1))

    const result = await getInformationWithData('/invoice/get/Total/byRoute', data)
    const result2 = await getInformationWithData('/reports/getMetaGroupBySalesPerson', data)

    let TotalMeta = 0
    let GeneralTotal = 0
    result.data.forEach(element => {
        GeneralTotal += Number(element.NonTaxableSalesAmt)
    })
    result2.data.forEach(element => {
        TotalMeta += Number(element.Meta)
    });
    let processInfo = []
    let Months = new TitlesSalesPerRoutes(false, (Start.getMonth() + 1), "SP")

    if (result.status.code === 1 && result2.status.code === 1) {

        result.data.forEach(row => {
            const indexMeta = getIndexElement(result2.data, 'Ruta', row.SalespersonNo)
            const index = getIndexElement(processInfo, 'Name', NameSalesPerson(row.SalespersonNo === "WW"&&getValueCookie('CompanyId')==='3' ? '0000' : row.SalespersonNo))
            const date = row.InvoiceDate.split('-')
            if (date.length === 3) {
                const index2 = getIndexElement(Months, 'Month', MonthInLetters(date[1]))
                let total = 0
                if (index2 !== -1) {
                    Months[index2].Total += (Number(row.NonTaxableSalesAmt))
                    Months[index2].TotalMeta = TotalMeta
                    total = Months[index2].Total
                }

                let to = 0
                if (index !== -1) {

                    to = processInfo[index].addData(MonthInLetters(date[1]), row.NonTaxableSalesAmt, indexMeta !== -1 ? result2.data[indexMeta].Meta : 0, "SP")
                    processInfo[index].addRow(row)

                } else {
                    let newRoute = new SalesPerRouteMonth(NameSalesPerson(row.SalespersonNo === "WW"&&getValueCookie('CompanyId')==='3' ? '0000' : row.SalespersonNo), (Start.getMonth() + 1), "SP")
                    newRoute.addRow(row)
                    to = newRoute.addData(MonthInLetters(date[1]), row.NonTaxableSalesAmt, (indexMeta !== -1 ? result2.data[indexMeta].Meta : 0), "SP")

                    processInfo.push(newRoute)
                }


                for (const route of processInfo) {
                    const indexMonth = getIndexElement(route.Data, 'Month', MonthInLetters(date[1]))
                    if (indexMonth !== -1 && to > 0) {

                        route.Data[indexMonth].Percentage = Number(total) > 0 ? ((Number(route.Data[indexMonth].Total) / Number(total)) * 100) : 0
                        route.Data[indexMonth].PercentageMeta = TotalMeta > 0 ? ((Number(route.Data[indexMonth].TotalMeta) / TotalMeta) * 100) : 0
                        route.Data[indexMonth].PercentageComplete = Number(route.Data[indexMonth].TotalMeta) > 0 ? ((Number(route.Data[indexMonth].Total)) / (Number(route.Data[indexMonth].TotalMeta))) * 100 : 0
                        if (route.Data[indexMonth].PercentageComplete < 30) {
                            route.Color = 'softGray'
                        } else if (route.Data[indexMonth].PercentageComplete < 99) {
                            route.Color = 'softYellow'
                        } else {
                            route.Color = 'softGreen'
                        }

                    }
                }



                if (index2 !== -1) {
                    const indexColor = getIndexElement(colorsRoute, 'Ruta', NameSalesPerson(row.SalespersonNo === "WW"&&getValueCookie('CompanyId')==='3' ? '0000' : row.SalespersonNo))
                    if (indexColor !== -1) {
                        Months[index2].addRoute(NameSalesPerson(row.SalespersonNo === "WW" ? '0000' : row.SalespersonNo), GeneralTotal > 0 ? (to / GeneralTotal) * 100 : 0, NameSalesPerson(row.SalespersonNo === "WW"&&getValueCookie('CompanyId')==='3' ? '0000' : row.SalespersonNo), colorsRoute[indexColor].Color)
                    } else {
                        Months[index2].addRoute(NameSalesPerson(row.SalespersonNo === "WW" ? '0000' : row.SalespersonNo), GeneralTotal > 0 ? (to / GeneralTotal) * 100 : 0, NameSalesPerson(row.SalespersonNo === "WW"&&getValueCookie('CompanyId')==='3' ? '0000' : row.SalespersonNo), random_rgba())
                    }

                }


            }

        });



    }

    processInfo = processInfo.sort((a, b) => {
        return a.Data[0].Total < b.Data[0].Total ? 1 : a.Data[0].Total > b.Data[0].Total ? -1 : 0;
    });
    return { Information: processInfo, Months: Months }
}

export async function getCustomerExcess(SalesPerson) {
    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'))
    const result = await getInformationWithData('/customer/get/Cartera', data)

    if (result.status.code === 1) {
        return result.data
    } else {
        return []
    }
}

export async function getCurrentBalance(SalesPerson) {
    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'))
    const result = await getInformationWithData('/customer/getBalance', data)
    if (result.status.code === 1) {
        let actualDate = ActualDate()
        let info = []
        for (const invoice of result.data) {
            if (intervalTimeInDays2(actualDate, DateFormatMAS90(invoice.InvoiceDueDate)) <= 0) {
                invoice.DaysDlq = intervalTimeInDays(invoice.InvoiceDueDate, getActualDateUTC())
                info.push(invoice)
            }
        }
        return info
    } else {
        return []
    }
}

export async function getCustomerHistory(SalesPerson) {
    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'))
    const result = await getInformationWithDataGlobalSales('/user/History', data)
    const result2 = await getInformationWithDataGlobalSales('/user/NotesLastMonth',data)

    for (const customer of result) {
        const index=getIndexElement(result2.data,'CustomerNo',customer.CustomerNo)
        customer.NumberDays = customer.DateLastActivity ? NumberFormat(Number(intervalTimeInDays(customer.DateLastActivity, getActualDateUTC())) - 1) : "Never"
        if(index!==-1){
            customer.HaveNotes=true
        }else{
            customer.HaveNotes=false
        }
    }
    return result
}

export async function getSalesByCustomer(SalesPerson, DateC) {

    let temp = new Date()
    let Start = new Date(temp.getFullYear(), temp.getMonth(), 1)
    let End = new Date(temp.getFullYear(), temp.getMonth() + 1, 0)

    if (DateC) {
        Start = new Date(DateC.getFullYear(), DateC.getMonth(), 1)
        End = new Date(DateC.getFullYear(), DateC.getMonth() + 1, 0)
    }


    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'), Start.toISOString().split('T')[0], End.toISOString().split('T')[0], (Start.getMonth() + 1))

    const result = await getInformationWithData('/invoice/get/Total/byRoute', data)
    if (SalesPerson === 'JDL' && Start.getMonth() === 9) {
        result.data = result.data.concat(SalesPerSeptember)
    }
    if (result.status.code === 1) {
        let info = new SalesByCustomerRoute()
        for (const Customer of result.data) {
            info.addCustomer(Customer.CustomerNo, Customer.BillToName, Customer.NonTaxableSalesAmt, Customer.UDF_RUTA, Customer)
        }

        return info.Data
    }
    return []
}

export async function getSalesPerMonth(SalesPerson, DateC) {
    let actualDate = new Date()

    let Start = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1)
    let End = new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 0)

    if (DateC) {
        Start = new Date(DateC.getFullYear(), DateC.getMonth(), 1)
        End = new Date(DateC.getFullYear(), DateC.getMonth() + 1, 0)
    }

    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'), Start.toISOString().split('T')[0], End.toISOString().split('T')[0], (Start.getMonth() + 1))

    const result = await getInformationWithDataGlobalSales('/Sales/actualMonth', data)
    if (SalesPerson === 'JDL' && Start.getMonth() === 9) {
        result["01"] = SalesPerCalendarSeptember["01"]
    }



    const keys = Object.keys(result)
    let processData = []
    for (const key of keys) {
        processData.push(new SalesPerMonth(Number(key), result[key].NonTaxableSalesAmt.total, result[key].registros, result[key].detail))
        let week = getWeekNumber(new Date((Start.getMonth() + 1) + "/" + key + "/" + Start.getFullYear()))
        const index = await getIndexElement(processData, 'Week', String(week))
        if (index !== -1) {
            await processData[index].addInfo(result[key].NonTaxableSalesAmt.total, result[key].registros, result[key].detail)
        } else {
            let newWeek = new SalesPerWeek(week)
            await newWeek.addInfo(result[key].NonTaxableSalesAmt.total, result[key].registros, result[key].detail)
            await processData.push(newWeek)
        }
    }


    return processData
}

export async function getShorts(SalesPerson) {
    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), SalesPerson ? SalesPerson : getValueCookie('SalesPerson'))
    const result = await getInformationWithDataGlobalSales('/invoice/AvailableShorts', data)

    if (result) {
        const arreglo = Object.entries(result);
        arreglo.sort((a, b) => b[1].shorts.total - a[1].shorts.total);
        const objetoOrdenado = Object.fromEntries(arreglo);
        return objetoOrdenado
    } else {
        return {}
    }
}

