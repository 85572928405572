import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { getValueCookie } from '../../../services/cookieService';
import { create_Delete_Update_Information, getInformationWithData } from '../../../services/CABE';
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import { getIndexElement } from '../../../functions/searchInObject';
import { pricesFormat } from '../../../functions/pricesFormat';
import { ItemDesc, NumberFormat, OrderArray } from '../../../functions/generalFunctions';
import ExcelDocument from '../../../components/ExcelDocument';
import { getActualDateQuery, getDateFromReports } from '../../../functions/dateFormat';
import { ReportChangePriceMargin, ReportOnlyChangePriceMargin } from '../../../functions/generateDataSetExcel';
import LoadingWindow from '../../../components/LoadingWindow';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import CSVDocument from '../../../components/CSVDocument';
import { confirmCloseAlert } from '../../../functions/alerts';
import ModalOrders from '../../../components/ModalComponent';
import { formatInputDateQuery } from '../../../functions/dateFormat'
export default class CalculateMarginPrices extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        Products: [],
        ProductsFilter: [],
        TableHeaders: [],
        AuxTableHeaders: [],
        PricesLevels: [{ name: 'All', Level: '0' }],
        flag: true,
        bigHead: true,
        minwidth: '200',
        NewWork: false,
        InternalId: 0,
        showModal1: false,
        MarginPricesHistoryHeader: [],

        pageNumers: [],
        currentPage: 1,
        ProductsPerPage: 70,
        lastIndex: 70,
        firstIndex: 0,
        textFilter: ''
    }

    async getProductsMargin() {
        let PricesLevels = [{ name: 'All', Level: '0' }]
        this.ModalLoading.current.showState(true);
        if (getValueCookie('CompanyId') !== '6') {
            const data = {
                idCompany: Number(getValueCookie('CompanyId')),
                type: 'CalculatePriceMargins',
            }
            const result = await getInformationWithData('/Items/margin', data)
            const correlative = await getInformationWithData('/sales/get/correlative', data)
            if (result.status.code === 1) {
                let temporal = []
                console.log(result)
                for (const item of result.result) {
                    const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
                    if (index === -1) {
                        const it = {
                            ItemCode: item.ItemCode,
                            ItemCodeDesc: item.ItemCodeDesc,
                            ProductLine: item.ProductLineDesc,
                            UnitCost: item.LastTotalUnitCost,
                            Change: false,
                            PricesLevels: []

                        }
                        const priceLev = {
                            PriceLevel: item.CustomerPriceLevel,
                            LevelCost: item.DiscountMarkup1 === 0 ? '-' : item.DiscountMarkup1,
                            RealMargin: item.DiscountMarkup1 === 0 ? '-' : ((Number(item.DiscountMarkup1) - Number(item.LastTotalUnitCost)) / (Number(item.DiscountMarkup1))) * 100,
                            NewLevelCost: item.DiscountMarkup1 === 0 ? 0 : item.DiscountMarkup1,
                            NewRealMargin: item.DiscountMarkup1 === 0 ? 0 : ((Number(item.DiscountMarkup1) - Number(item.LastTotalUnitCost)) / (Number(item.DiscountMarkup1))) * 100,

                        }
                        it.PricesLevels.push(priceLev)
                        temporal.push(it)
                    } else {
                        const priceLev = {
                            PriceLevel: item.CustomerPriceLevel,
                            LevelCost: item.DiscountMarkup1 === 0 ? '-' : item.DiscountMarkup1,
                            RealMargin: item.DiscountMarkup1 === 0 ? '-' : ((Number(item.DiscountMarkup1) - Number(temporal[index].UnitCost)) / (Number(item.DiscountMarkup1))) * 100,
                            NewLevelCost: item.DiscountMarkup1 === 0 ? 0 : item.DiscountMarkup1,
                            NewRealMargin: item.DiscountMarkup1 === 0 ? 0 : ((Number(item.DiscountMarkup1) - Number(temporal[index].UnitCost)) / (Number(item.DiscountMarkup1))) * 100,
                        }
                        temporal[index].PricesLevels.push(priceLev)
                    }
                }
                let headers = []
                for (const item of temporal) {
                    for (const pricelevel of item.PricesLevels) {
                        const index = getIndexElement(headers, 'Level', pricelevel.PriceLevel)
                        const index2 = getIndexElement(PricesLevels, 'Level', pricelevel.PriceLevel)
                        if (index === -1) {
                            const head = {
                                Level: pricelevel.PriceLevel,
                                Title1: "Level " + pricelevel.PriceLevel,
                                Title2: "Real Mark-Up Level " + pricelevel.PriceLevel,
                                Title3: "New Level " + pricelevel.PriceLevel,
                                Title4: "New Real Mark-Up Level " + pricelevel.PriceLevel,
                            }
                            headers.push(head)
                        }
                        if (index2 === -1) {
                            PricesLevels.push({ name: 'Price Level ' + pricelevel.PriceLevel, Level: String(pricelevel.PriceLevel) })
                        }
                    }


                }
                for (const item of temporal) {
                    if (item.PricesLevels.length !== headers.length) {
                        for (const head of headers) {
                            const index = getIndexElement(item.PricesLevels, 'PriceLevel', head.Level)
                            if (index === -1) {
                                const priceLev = {
                                    PriceLevel: head.Level,
                                    LevelCost: '-',
                                    RealMargin: '-',
                                    NewLevelCost: 0,
                                    NewRealMargin: 0
                                }
                                item.PricesLevels.push(priceLev)
                            }
                        }
                    }
                }
                temporal = await ItemDesc(temporal, 'ItemCodeDesc', 'ItemCode')
                this.setState({ InternalId: correlative.data[0].correlative, Products: temporal, ProductsFilter: temporal.slice(this.state.firstIndex, this.state.lastIndex), TableHeaders: headers, PricesLevels: PricesLevels, AuxTableHeaders: headers, minwidth: (headers.length * 120) + 'vh', NewWork: true,textFilter:'' })
                document.getElementById("CheckChangeMarginPrice0").checked = true;
                this.checkFilter("CheckChangeMarginPrice0", { name: 'All', Level: '0' })
            }
        } else if (getValueCookie('CompanyId') === '6') {
            const data = {
                company: getValueCookie('Company'),
                idcompany: getValueCookie('CompanyId'),
                priceLevel: '1',
                Component: null,
                type: 'CalculatePriceMargins',
            }

            const invent = await getInformationWithData('/Items/get', data)
            const correlative = await getInformationWithData('/sales/get/correlative', data)
            if (getValueCookie('CompanyId') === '6') {
                data.company = 'Mayaland Atlanta'
                data.idcompany = '3'
                const respuesta2 = await getInformationWithData('/Items/get', data);


                invent.status.code = respuesta2.status.code
                for (const item of respuesta2.data) {
                    const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                    if (index !== -1) {
                        invent.data = invent.data.splice(index, 1)
                    }
                }
                invent.data = invent.data.concat(respuesta2.data)

                if (invent.status.code === 1) {
                    let temporal = []
                    for (const item of invent.data) {
                        const index = getIndexElement(temporal, 'itemCode', item.itemCode)
                        if (index === -1) {
                            const it = {
                                ItemCode: item.itemCode,
                                ItemCodeDesc: item.abbreviatedDesc,
                                ProductLine: item.ProductLineDesc,
                                UnitCost: item.LastTotalUnitCost / 0.95,
                                PricesLevels: []

                            }
                            if (!item.UnitPriceALT) {
                                item.UnitPriceALT = item.UnitPrice
                            }


                            const priceLev = {
                                PriceLevel: '1',
                                LevelCost: item.UnitPriceALT === 0 ? '-' : item.UnitPriceALT,
                                RealMargin: item.UnitPriceALT === 0 ? '-' : ((Number(item.UnitPriceALT) - (Number(item.LastTotalUnitCost) / 0.95)) / (Number(item.UnitPriceALT))) * 100,
                                NewLevelCost: item.UnitPriceALT === 0 ? 0 : item.UnitPriceALT,
                                NewRealMargin: item.UnitPriceALT === 0 ? 0 : ((Number(item.UnitPriceALT) - (Number(item.LastTotalUnitCost) / 0.95)) / (Number(item.UnitPriceALT))) * 100,

                            }
                            if (priceLev.RealMargin > 15 && priceLev.LevelCost > 0) {
                                it.PricesLevels.push(priceLev)
                            }

                            temporal.push(it)
                        } else {
                            const priceLev = {
                                PriceLevel: '1',
                                LevelCost: item.UnitPriceALT === 0 ? '-' : item.UnitPriceALT,
                                RealMargin: item.UnitPriceALT === 0 ? '-' : ((Number(item.UnitPriceALT) - Number(temporal[index].UnitCost)) / (Number(item.UnitPriceALT))) * 100,
                                NewLevelCost: item.UnitPriceALT === 0 ? 0 : item.UnitPriceALT,
                                NewRealMargin: item.UnitPriceALT === 0 ? 0 : ((Number(item.UnitPriceALT) - Number(temporal[index].UnitCost)) / (Number(item.UnitPriceALT))) * 100,
                            }
                            if (priceLev.RealMargin > 15 && priceLev.LevelCost > 0) {
                                temporal[index].PricesLevels.push(priceLev)
                            }
                        }
                    }

                    let headers = []
                    for (const item of temporal) {
                        for (const pricelevel of item.PricesLevels) {
                            const index = getIndexElement(headers, 'Level', pricelevel.PriceLevel)
                            const index2 = getIndexElement(PricesLevels, 'Level', pricelevel.PriceLevel)
                            if (index === -1) {
                                const head = {
                                    Level: pricelevel.PriceLevel,
                                    Title1: "Level " + pricelevel.PriceLevel,
                                    Title2: "Real Mark-Up Level " + pricelevel.PriceLevel,
                                    Title3: "New Level " + pricelevel.PriceLevel,
                                    Title4: "New Real Mark-Up Level " + pricelevel.PriceLevel,
                                }
                                headers.push(head)
                            }

                            if (index2 === -1) {
                                PricesLevels.push({ name: 'Price Level ' + pricelevel.PriceLevel, Level: String(pricelevel.PriceLevel) })
                            }
                        }


                    }
                    for (const item of temporal) {
                        if (item.PricesLevels.length !== headers.length) {
                            for (const head of headers) {
                                const index = getIndexElement(item.PricesLevels, 'PriceLevel', head.Level)
                                if (index === -1) {
                                    const priceLev = {
                                        PriceLevel: head.Level,
                                        LevelCost: '-',
                                        RealMargin: '-',
                                        NewLevelCost: 0,
                                        NewRealMargin: 0
                                    }
                                    item.PricesLevels.push(priceLev)
                                }
                            }
                        }
                    }

                    temporal = await ItemDesc(temporal, 'ItemCodeDesc', 'ItemCode')

                    this.setState({ InternalId: correlative.data[0].correlative, Products: temporal, ProductsFilter: temporal.slice(this.state.firstIndex, this.state.lastIndex), TableHeaders: headers, PricesLevels: PricesLevels, AuxTableHeaders: headers, NewWork: true, textFilter:'' })
                    document.getElementById("CheckChangeMarginPrice0").checked = true;
                    this.checkFilter("CheckChangeMarginPrice0", { name: 'All', Level: '0' })
                }

            }


        }
        this.setState({ PricesLevels: PricesLevels })
        this.pagination()
        this.ModalLoading.current.showState(false);
    }

    contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchPriceMargin = e => {
        const checkboxList = document.getElementsByClassName('PricesLevels')
        let PricesLevels = []
        for (const PriceLevel of checkboxList) {
            if (PriceLevel.checked) {
                PricesLevels.push(PriceLevel.value)
            }
        }

        let search = e;
        this.setState({ textFilter: search })
        if (search) {
            let Products = this.state.Products.slice(this.state.firstIndex, this.state.lastIndex).filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLine, search) ||
                    this.contains(item.ItemCodeDesc, search)

                ))
                ) {
                    return item
                } else {
                    return null
                }
            });
            let temporalProducts = []
            for (const Item of Products) {
                let newPricesLevels = []
                for (const Level of PricesLevels) {
                    const index = getIndexElement(Item.PricesLevels, 'PriceLevel', String(Level))

                    if (index !== -1) {
                        newPricesLevels.push(Item.PricesLevels[index])
                    }
                }

                temporalProducts.push({
                    ItemCode: Item.ItemCode,
                    ItemCodeDesc: Item.ItemCodeDesc,
                    ProductLine: Item.ProductLine,
                    UnitCost: Item.UnitCost,
                    Change: Item.Change,
                    PricesLevels: newPricesLevels,
                    LastDueDate: Item.LastDueDate
                })
            }
            this.setState({ ProductsFilter: temporalProducts });
        } else {
            let Products = this.state.Products.slice(this.state.firstIndex, this.state.lastIndex)
            let temporalProducts = []
            for (const Item of Products) {
                let newPricesLevels = []
                for (const Level of PricesLevels) {
                    const index = getIndexElement(Item.PricesLevels, 'PriceLevel', String(Level))

                    if (index !== -1) {
                        newPricesLevels.push(Item.PricesLevels[index])
                    }
                }

                temporalProducts.push({
                    ItemCode: Item.ItemCode,
                    ItemCodeDesc: Item.ItemCodeDesc,
                    ProductLine: Item.ProductLine,
                    UnitCost: Item.UnitCost,
                    Change: Item.Change,
                    PricesLevels: newPricesLevels,
                    LastDueDate: Item.LastDueDate
                })
            }
            this.setState({ ProductsFilter: temporalProducts });
        }


    }

    OrderPriceMargins(option) {
        var temporal = this.state.ProductsFilter
        switch (option) {
            case "ItemCode":
                temporal = OrderArray(temporal, "ItemCode", this.state.flag)
                this.setState({ ProductsFilter: temporal, flag: !this.state.flag })
                break;
            case "ProductLine":
                temporal = OrderArray(temporal, "ProductLine", this.state.flag)
                this.setState({ ProductsFilter: temporal, flag: !this.state.flag })
                break;
            case "UnitCost":
                temporal = OrderArray(temporal, "UnitCost", this.state.flag)
                this.setState({ ProductsFilter: temporal, flag: !this.state.flag })
                break;
            default:
                break;
        }
    }

    OrderByPriceLevel(position, key) {
        var arreglo = this.state.ProductsFilter
        var l = arreglo.length

        if (this.state.flag) {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if ((arreglo[j].PricesLevels[position][key] === '-' ? '0' : arreglo[j].PricesLevels[position][key]) > (arreglo[j + 1].PricesLevels[position][key] === '-' ? '0' : arreglo[j + 1].PricesLevels[position][key])) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        } else {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if ((arreglo[j].PricesLevels[position][key] === '-' ? '0' : arreglo[j].PricesLevels[position][key]) < (arreglo[j + 1].PricesLevels[position][key] === '-' ? '0' : arreglo[j + 1].PricesLevels[position][key])) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        }

        this.setState({ ProductsFilter: arreglo, flag: !this.state.flag })
    }

    calculateNewPrice(e) {
        let id = e.target.id.split('_')

        if (id.length === 3) {
            let temporal = this.state.ProductsFilter
            let temporalG = this.state.Products
            const index = getIndexElement(temporal, 'ItemCode', id[1])
            const index2 = getIndexElement(temporalG, 'ItemCode', id[1])
            if (index !== -1 && index2 !== -1) {
                let Element = temporal[index]
                let Element2 = temporalG[index2]
                Element.Change = true
                Element2.Change = true
                const indexPrice = getIndexElement(Element.PricesLevels, 'PriceLevel', id[2])
                const indexPrice2 = getIndexElement(Element2.PricesLevels, 'PriceLevel', id[2])
                if (indexPrice !== -1 && indexPrice2 !== -1) {
                    let temporalPrices = Element.PricesLevels[indexPrice]
                    let UnitCost = Element.UnitCost
                    let UnitPrice = Number(e.target.value)
                    temporalPrices.NewLevelCost = Number(e.target.value)
                    if ((Number(UnitPrice)) > 0) {
                        temporalPrices.NewRealMargin = ((Number(UnitPrice) - Number(UnitCost)) / (Number(UnitPrice))) * 100
                    } else {
                        temporalPrices.NewRealMargin = 0
                    }


                    Element.PricesLevels[indexPrice] = temporalPrices
                    Element2.PricesLevels[indexPrice2] = temporalPrices
                }
                temporal[index] = Element
                temporalG[index2] = Element2
                this.setState({ ProductsFilter: temporal, Products: temporalG })

            }
        }
    }


    checkFilter(id, element) {
        //console.log("id:", id, " elemento:", element)
        const checkbox = document.getElementById(id);
        let count = 0
        if (element.Level === '0' && checkbox.checked === true) {
            const checkboxList = document.getElementsByClassName('PricesLevels')
            for (const checkbox of checkboxList) {
                checkbox.checked = true;
                count++
            }

        } else if (element.Level === '0' && checkbox.checked === false) {
            const checkboxList = document.getElementsByClassName('PricesLevels')
            for (const checkbox of checkboxList) {
                checkbox.checked = false;

            }

        } else {

            let cond = true
            const checkboxList = document.getElementsByClassName('PricesLevels')
            for (const checkbox of checkboxList) {
                if ((!checkbox.checked) && (checkbox.id !== 'CheckChangeMarginPrice0')) {
                    cond = false
                    break
                }
            }
            for (const checkbox of checkboxList) {
                if (checkbox.checked && checkbox.id !== 'CheckChangeMarginPrice0') {
                    count++
                }
            }

            let all = document.getElementById('CheckChangeMarginPrice0')
            if (all) {
                all.checked = cond
            }


        }
        this.setState({ minwidth: (120 * count) + 'vh' })
        this.FilterBySelectedPricesLevels()
    }

    FilterBySelectedPricesLevels() {
        const checkboxList = document.getElementsByClassName('PricesLevels')
        let PricesLevels = []
        for (const PriceLevel of checkboxList) {
            if (PriceLevel.checked) {
                PricesLevels.push(PriceLevel.value)
            }
        }
        let temporalHeaders = []
        for (const Level of PricesLevels) {
            const index = getIndexElement(this.state.AuxTableHeaders, 'Level', Level)
            if (index !== -1) {
                temporalHeaders.push(this.state.AuxTableHeaders[index])
            }
        }

        let temporalProducts = []
        for (const Item of this.state.Products.slice(this.state.firstIndex, this.state.lastIndex)) {
            let newPricesLevels = []
            for (const Level of PricesLevels) {
                const index = getIndexElement(Item.PricesLevels, 'PriceLevel', String(Level))

                if (index !== -1) {
                    newPricesLevels.push(Item.PricesLevels[index])
                }
            }

            temporalProducts.push({
                ItemCode: Item.ItemCode,
                ItemCodeDesc: Item.ItemCodeDesc,
                ProductLine: Item.ProductLine,
                UnitCost: Item.UnitCost,
                Change: Item.Change,
                PricesLevels: newPricesLevels,
                LastDueDate: Item.LastDueDate
            })
        }



        this.setState({ TableHeaders: temporalHeaders, ProductsFilter: temporalProducts })
        this.searchPriceMargin(this.state.textFilter)

    }

    getNewPrices() {
        let temporal = []
        for (const Item of this.state.Products) {

            if (Item.Change) {
                for (const Level of Item.PricesLevels) {
                    temporal.push({ ItemCode: Item.ItemCode, Level: Level.PriceLevel, NewCost: Level.NewLevelCost })
                }
            }
        }

        return temporal
    }
    handleModalOpen = async () => {
        this.getMarginPricesHeader()
        await this.setState({ showModal1: true })
    }

    handleModalClose = () => {
        this.setState({ showModal1: false })
    }

    async getMarginPricesHeader() {

        this.ModalLoading.current.showState(true);
        const data = {
            IdCompany: Number(getValueCookie('CompanyId'))
        }
        const result = await getInformationWithData('/Items/Get/NewMarginsHeader', data)
        if (result.status.code === 1) {
            this.setState({ MarginPricesHistoryHeader: result.data })
        } else {
            this.setState({ MarginPricesHistoryHeader: [] })
        }
        this.ModalLoading.current.showState(false);
        //this.searchPriceMargin()
    }

    async SaveWork() {
        this.ModalLoading.current.showState(true)
        const data = {
            NewWork: this.state.NewWork,
            IdCompany: Number(getValueCookie('CompanyId')),
            Detail: this.state.Products,
            UserName: getValueCookie('userName'),
            InternalId: this.state.InternalId,
            Date: getActualDateQuery()
        }
        console.log(this.state.Detail)
        const result = await create_Delete_Update_Information('/Items/Save/NewMargins', data)
        this.ModalLoading.current.showState(false)
        if (result.status.code === 1) {
            confirmCloseAlert('correct', 'Information Saved Successfully!')
        } else {
            confirmCloseAlert('incorrect', 'Whoops something was wrong. Try again!')
        }
    }

    async getMarginPriceDetail(InternalId) {
        let PricesLevels = [{ name: 'All', Level: '0' }]
        this.handleModalClose()
        this.ModalLoading.current.showState(true);
        const data = {
            IdHeader: InternalId,
            IdCompany: Number(getValueCookie('CompanyId'))
        }
        console.log(data)
        const result = await getInformationWithData('/Items/Get/NewMarginsDetail', data)

        if (data.IdCompany === 3) {
            if (result.status.code === 1) {
                let temporal = []

                for (const item of result.data) {
                    const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
                    if (index === -1) {
                        const it = {
                            ItemCode: item.ItemCode,
                            ItemCodeDesc: item.ItemCodeDesc,
                            ProductLine: item.ProductLineDesc,
                            UnitCost: item.LastTotalUnitCost,
                            Change: false,
                            PricesLevels: []

                        }
                        const priceLev = {
                            PriceLevel: String(item.CustomerPriceLevel),
                            LevelCost: item.DiscountMarkup1 === 0 ? '-' : item.DiscountMarkup1,
                            RealMargin: item.DiscountMarkup1 === 0 ? '-' : ((Number(item.DiscountMarkup1) - Number(item.LastTotalUnitCost)) / (Number(item.DiscountMarkup1))) * 100,
                            NewLevelCost: item.DiscountMarkup1 === 0 ? 0 : item.NewUnitPrice,
                            NewRealMargin: item.DiscountMarkup1 === 0 ? 0 : item.NewMarkUp

                        }
                        it.PricesLevels.push(priceLev)
                        temporal.push(it)
                    } else {
                        const priceLev = {
                            PriceLevel: String(item.CustomerPriceLevel),
                            LevelCost: item.DiscountMarkup1 === 0 ? '-' : item.DiscountMarkup1,
                            RealMargin: item.DiscountMarkup1 === 0 ? '-' : ((Number(item.DiscountMarkup1) - Number(temporal[index].UnitCost)) / (Number(item.DiscountMarkup1))) * 100,
                            NewLevelCost: item.DiscountMarkup1 === 0 ? 0 : item.NewUnitPrice,
                            NewRealMargin: item.DiscountMarkup1 === 0 ? 0 : item.NewMarkUp
                        }
                        temporal[index].PricesLevels.push(priceLev)
                    }
                }
                let headers = []
                for (const item of temporal) {
                    for (const pricelevel of item.PricesLevels) {
                        const index = getIndexElement(headers, 'Level', pricelevel.PriceLevel)
                        const index2 = getIndexElement(PricesLevels, 'Level', pricelevel.PriceLevel)
                        if (index === -1) {
                            const head = {
                                Level: pricelevel.PriceLevel,
                                Title1: "Level " + pricelevel.PriceLevel,
                                Title2: "Real Mark-Up Level " + pricelevel.PriceLevel,
                                Title3: "New Level " + pricelevel.PriceLevel,
                                Title4: "New Real Mark-Up Level " + pricelevel.PriceLevel,
                            }
                            headers.push(head)
                        }
                        if (index2 === -1) {
                            PricesLevels.push({ name: 'Price Level ' + pricelevel.PriceLevel, Level: String(pricelevel.PriceLevel) })
                        }
                    }


                }
                for (const item of temporal) {
                    if (item.PricesLevels.length !== headers.length) {
                        for (const head of headers) {
                            const index = getIndexElement(item.PricesLevels, 'PriceLevel', head.Level)
                            if (index === -1) {
                                const priceLev = {
                                    PriceLevel: head.Level,
                                    LevelCost: '-',
                                    RealMargin: '-',
                                    NewLevelCost: 0,
                                    NewRealMargin: 0
                                }
                                item.PricesLevels.push(priceLev)
                            }
                        }
                    }
                }
                temporal = await ItemDesc(temporal, 'ItemCodeDesc', 'ItemCode')
                this.setState({ InternalId: InternalId, Products: temporal, ProductsFilter: temporal.slice(this.state.firstIndex, this.state.lastIndex), TableHeaders: headers, PricesLevels: PricesLevels, AuxTableHeaders: headers, minwidth: (headers.length * 120) + 'vh', NewWork: false, textFilter: '' })
                document.getElementById("CheckChangeMarginPrice0").checked = true;
                this.checkFilter("CheckChangeMarginPrice0", { name: 'All', Level: '0' })
            }
        }

        this.ModalLoading.current.showState(false);
        this.pagination()
    }

    pagination() {
        const temp = []
        for (let i = 1; i <= Math.ceil(this.state.Products.length / this.state.ProductsPerPage); i++) {
            temp.push(i)
        }
        //console.log("hola temp:",temp,"length",this.state.ProductsFilter)
        this.setState({ pageNumers: temp })
    }

    generatePageNumbers() {
        //console.log("generate pagenumber",this.props.pageNumers)
        const pageNumers = this.state.pageNumers
        const { currentPage } = this.state;
        const totalPages = pageNumers.length;

        if (totalPages <= 4) {
            return pageNumers;
        }

        if (currentPage <= 2) {
            return [...pageNumers.slice(0, 2), '...', totalPages];
        }

        if (currentPage >= totalPages - 1) {
            return [...pageNumers.slice(-4)];
        }

        return [currentPage - 1, currentPage, "...", totalPages];
    }

    async onPreviosPage() {
        await this.setState({ currentPage: this.state.currentPage - 1 })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
        this.setState({ ProductsFilter: this.state.Products.slice(this.state.firstIndex, this.state.lastIndex) })
        this.searchPriceMargin(this.state.textFilter)
    }

    async onNextPage() {
        await this.setState({ currentPage: this.state.currentPage + 1 })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
        this.setState({ ProductsFilter: this.state.Products.slice(this.state.firstIndex, this.state.lastIndex) })
        //console.log("aber la word",this.state.textFilter)
        this.searchPriceMargin(this.state.textFilter)
    }

    async onSpecificPage(noPage) {
        await this.setState({ currentPage: noPage })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
        this.setState({ ProductsFilter: this.state.Products.slice(this.state.firstIndex, this.state.lastIndex) })
        this.searchPriceMargin(this.state.textFilter)
    }
    render() {
        const minWidth = this.state.minwidth
        return (
            <div className='conteiner'>

                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Calculate New Price Margins</p>

                <div className='row pt-2'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.handleModalOpen()} type="button" className="btn orangeButton btn-lg">View Saved Work</button>
                                </div>
                            </div>
                            <div className='col-6 pb-4 InputTextBolder text-center'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getProductsMargin()} type="button" className="btn blueButton btn-lg">Reload <BsArrowRepeat /></button>
                                </div>
                            </div>
                            <div className='col-12 pb-4 InputTextBolder text-center'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.SaveWork()} type="button" className="btn brownButton btn-lg">Save Information</button>
                                </div>
                            </div>

                            <div className='col-6 pb-4'>
                                <div className="d-grid gap-2">
                                    <ExcelDocument hidden={this.state.Products.length === 0} archname={'Price Margins ' + getValueCookie('Company') + ' ' + getDateFromReports()} sheetname={'Price Margins ' + getValueCookie('Company')} data={ReportChangePriceMargin(this.state.AuxTableHeaders, this.state.Products, '$')} />
                                </div>
                            </div>
                            <div className='col-6 pb-4'>
                                <div className="d-grid gap-2">
                                    <ExcelDocument buttonTitle={"Download Report Only Changes In XLSX"} hidden={this.state.Products.length === 0} archname={'Price Margins ' + getValueCookie('Company') + ' ' + getDateFromReports()} sheetname={'Price Margins ' + getValueCookie('Company')} data={ReportOnlyChangePriceMargin(this.state.AuxTableHeaders, this.state.Products, '$')} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input type="text" autoComplete='off' className="form-control" id='searchNewPriceMarginItem' value={this.state.textFilter} placeholder='Search Item by ItemCode, Line Product, Description...' onChange={(e) => this.searchPriceMargin(e.target.value)} />
                                </div>
                            </div>
                            <div hidden={this.state.Products.length === 0} className='pt-3 pb-3 col-12'>
                                {
                                    this.state.PricesLevels.map((element, e) => (
                                        <div className="form-check col-12 boldText text-start fontLarger " key={e}>
                                            <label className="form-check-label w-100" >
                                                {element.name}
                                                <input className="form-check-input PricesLevels" type="checkbox" value={element.Level} name={"ChangeMarginPrice" + element.Level} defaultChecked id={'CheckChangeMarginPrice' + element.Level} onChange={() => { this.checkFilter('CheckChangeMarginPrice' + element.Level, element) }} />
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='col-12 pb-3'>
                                <div className="d-grid gap-2">
                                    <CSVDocument
                                        disabled={false}
                                        hidden={this.state.Products.length === 0}
                                        data={this.getNewPrices()}
                                        heads={['ItemCode', 'Level', 'NewCost']}
                                        Titles={['ItemCode', 'Level', 'NewDiscountMarkUp']}
                                        name={'CSVNewPricesLevels' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                    <div className='col-12'>
                        <div className='row' hidden={this.state.Products.length === 0}>
                            <div className='col-12 tableFixHead'>
                                <table className={'table align-middle'} style={{ minWidth }}>
                                    <thead className='thead'>
                                        <tr className='text-light text-center bg-dark'>
                                            <th colSpan={5} className='sticky-col-head colfix' >
                                                <div className='row'>
                                                    <div className=' col-2 ml-auto' onClick={() => this.OrderPriceMargins("ItemCode")}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></div>
                                                    <div className=' col-3' onClick={() => this.OrderPriceMargins("ProductLine")}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></div>
                                                    <div className=' col-4'><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></div>
                                                    <div className=' col-1'></div>
                                                    <div className=' col-2 mr-auto' onClick={() => this.OrderPriceMargins("UnitCost")}><div className='row'><div className='col-12'>Last Unit Cost</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></div>
                                                </div>
                                            </th>
                                            {this.state.TableHeaders.map((head, h) => (
                                                <React.Fragment key={h} >
                                                    <th className={((h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen') + ' '}></th>
                                                    <th className={((h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen') + ' '} onClick={() => this.OrderByPriceLevel(h, "LevelCost")}><div className='row'><div className='col-12'>{head.Title1}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div> </div></th>
                                                    <th className={((h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen') + ' '} onClick={() => this.OrderByPriceLevel(h, "RealMargin")}><div className='row'><div className='col-12'>{head.Title2}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div> </div></th>
                                                    <th className={((h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen') + ' '} onClick={() => this.OrderByPriceLevel(h, "LevelCost")}><div className='row'><div className='col-12'>{head.Title3}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div> </div></th>
                                                    <th className={((h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen') + ' '} onClick={() => this.OrderByPriceLevel(h, "RealMargin")}><div className='row'><div className='col-12'>{head.Title4}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div> </div></th>

                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.ProductsFilter.map((item, i) => (
                                                <tr className={(item.Change ? 'bg-warning' : 'bg-light')} key={i}>
                                                    <td colSpan={5} className={(item.Change ? ' sticky-col bg-warning' : ' sticky-col bg-light')}>
                                                        <div className={'row '}>
                                                            <div className='col-2  positionStartText'>{item.ItemCode}</div>
                                                            <div className='col-3 align-middle positionStartText'>{item.ProductLine}</div>
                                                            <div className='col-4 align-middle positionStartText'>{item.ItemCodeDesc}</div>
                                                            <div className='col-1 pt-3 align-middle positionEndText'>$</div>
                                                            <div className='col-2 pt-3 align-middle  positionEndText'>{NumberFormat(pricesFormat(item.UnitCost))}</div>
                                                        </div>
                                                    </td>

                                                    {item.PricesLevels.map((price) => (
                                                        <React.Fragment key={item.ItemCode + '_' + price.PriceLevel}>
                                                            <td className='text-end '>{price.LevelCost !== '-' ? '$' : '$'}</td>
                                                            <td className='text-end '>
                                                                {price.LevelCost !== '-' ? NumberFormat(pricesFormat(price.LevelCost)) : '0'}
                                                            </td>
                                                            <td className='text-center  '>
                                                                {price.RealMargin !== '-' ? NumberFormat(pricesFormat(price.RealMargin)) + "%" : '0.00%'}
                                                            </td>
                                                            <td className='text-center'>
                                                                <div className="input-group  flex-nowrap">
                                                                    <span className="input-group-text">$</span>
                                                                    <input onChange={(e) => this.calculateNewPrice(e, price.LevelCost)} type="number" step={0.05} autoComplete='off' defaultValue={pricesFormat(price.NewLevelCost)} className="form-control text-center" id={'calculatePriceMargin_' + item.ItemCode + '_' + price.PriceLevel} />
                                                                </div>
                                                            </td>
                                                            <td className='text-center  '>
                                                                {price.NewRealMargin !== '-' ? NumberFormat(pricesFormat(price.NewRealMargin)) + "%" : '0.00%'}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                            <div className='row'>

                                <div className="d-flex justify-content-center" disabled={true}>
                                    <nav aria-label="...">
                                        <ul className="pagination ">
                                            <li className={`page-item${this.state.currentPage === 1 ? ' disabled' : ''}`}>
                                                <button className="page-link" onClick={() => this.onPreviosPage()}>{"<<"}</button>
                                            </li>

                                            {this.generatePageNumbers().map((noPage, index) => (
                                                <li key={index} className={`page-item ${noPage === '...' ? 'disabled' : ''} ${noPage === this.state.currentPage ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => noPage !== '...' && this.onSpecificPage(noPage)}>
                                                        {noPage}
                                                    </button>
                                                </li>
                                            ))}

                                            <li className={`page-item${this.state.currentPage === this.state.pageNumers.length ? ' disabled' : ''}`}>
                                                <button className="page-link" onClick={() => this.onNextPage()}>{">>"}</button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <ModalOrders title={'History of New Margin Prices'} show={this.state.showModal1} close={() => this.handleModalClose()}>
                    <div className='row pt-2'>
                        <div className='col-sm-12'>

                        </div>
                    </div>
                    <div className='row pt-3'>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table'>
                                <thead className='thead'>
                                    <tr className='text-light text-center'>
                                        <th className='bg-dark'>No</th>
                                        <th className='bg-dark'>Creation Date</th>
                                        <th className='bg-dark'>Create By</th>
                                        <th className='bg-dark'>Last Update Date</th>
                                        <th className='bg-dark'>Update By</th>


                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {this.state.MarginPricesHistoryHeader.map((header, h) => (
                                        <tr onClick={() => this.getMarginPriceDetail(header.InternalId)} key={h} className='text-center'>
                                            <td>{header.No}</td>
                                            <td>{formatInputDateQuery(header.CreationDate)}</td>
                                            <td>{header.CreateBy}</td>
                                            <td>{formatInputDateQuery(header.LastUpdateDate)}</td>
                                            <td>{header.UpdateBy}</td>
                                        </tr>
                                    ))}


                                </tbody>
                                <tfoot className='tfoot'>

                                    <tr className='bg-secondary text-light'>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>



                </ModalOrders>


            </div>
        )
    }
}
