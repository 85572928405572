import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineCloudUpload, AiFillSave, AiFillDelete, AiOutlineCloseCircle } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import LoadingWindow from '../../components/LoadingWindow';
import { BsCheckSquare, BsCartPlus } from "react-icons/bs";
import Swal from "sweetalert2";
import { create_Delete_Update_Information, getInformationNoData, getInformationWithData } from '../../services/CABE';
import { getIndexElement, getIndexElement2 } from '../../functions/searchInObject';
import { ItemDetal, OrderPrimaryVendorWithInvoiceNo, PurchaseOrderStructure } from './Class';
import { pricesFormat } from '../../functions/pricesFormat';
import { Delay, DeleteCharacter, DeleteStrangeCharacter, ItemDesc, NumberFormat, OrderArray } from '../../functions/generalFunctions';
import { getValueCookie } from '../../services/cookieService';
import { DateFormatMAS90, FormatQueryReturnDate, FormatQueryReturnDateForReport, getActualDateUTC, getDateFromReports, getDateYearMonthDayDash } from '../../functions/dateFormat';
import { OrderPDF } from '../../components/OrderPDF';
import { getPrintHeaderCompany } from '../../functions/companyInformation';
import ModalOrders from '../../components/ModalComponent';
import { confirmCloseAlert } from '../../functions/alerts';
import SearchUPC from '../../components/SearchUPC';
import Select from 'react-select';
import Catalogue from '../../components/Catalogue';
import ExcelDocument from '../../components/ExcelDocument';
import { getDataSet } from '../../functions/generateDataSetExcel';
export default class AutomaticPurchaseOrder extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        vendorSelectInformation: [],
        AllProccessInformation: [],
        Detail: [],
        companyPrintHeader: getPrintHeaderCompany(),
        showModal1: false,
        showModal2: false,
        showModal3: false,
        showModal4: false,
        ItemOrders: [],
        FilterItemOrders: [],
        SpecificInvoices: [],
        FilterSpecificInvoices: [],
        currentItem: {
            ItemCode: '',
            Description: '',
            HeadInfo: {},
            disabled: false,
            Stock: 0
        },
        currentInvoice: {
            InvoiceNo: '',
            TotalQuantityOrdered: 0,
            TotalQuantityReceived: 0,
            TotalAmtOrdered: 0
        },
        currentInvoiceDetail: [],
        currentFilterInvoiceDetail: [],
        //AGREGADOS
        salesOrderList: [],
        salesOrderListFilter: [],
        selectAllSalesOrder: false,
        Header: {
            PONumber: '',
            POVendorNo: '',
            POVendorName: '',
            SelectedDate: new Date(),
            InvoiceNo: ''
        },
        Totals: {
            QuantityOrdered: 0,
            QuantityReceived: 0,
            TotalCost: 0
        },
        PurchaseOrderDetail: [],
        HistoryPurchaseOrderHeader: [],
        HistoryPurchaseOrderHeaderFilter: []
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        if (modal === "showModal3") {
            this.getPODetailBySalesOrder()
        }
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    ClearAll() {
        this.setState({

            salesOrderList: [],
            salesOrderListFilter: [],
            selectAllSalesOrder: false,
            Header: {
                PONumber: '',
                POVendorNo: '',
                POVendorName: '',
                SelectedDate: new Date(),
                InvoiceNo: ''
            },
            Totals: {
                QuantityOrdered: 0,
                QuantityReceived: 0,
                TotalCost: 0
            },
            PurchaseOrderDetail: [],
            HistoryPurchaseOrderHeader: [],
            HistoryPurchaseOrderHeaderFilter: []
        })
    }


    changesPrincipalAPO2 = async (e, item, VendorNo, id) => {
        const temporal = this.state.PurchaseOrderDetail

        let value = Number(e.target.value)
        switch (id) {
            case "QuantityReceived":
                const index2 = getIndexElement(temporal, 'ItemCode', item.ItemCode)
                if (index2 !== -1) {
                    if (VendorNo !== '0000000') {

                        let iterative = false
                        let completeItems = []
                        if (index2 !== -1) {


                            await temporal[index2].resetQuantityShipped()
                            if (temporal[index2].AllSalesOrderPerItem.length > 0) {

                                if (value > 0) {
                                    while (completeItems.length < temporal[index2].AllSalesOrderPerItem.length && value > 0) {
                                        let percentage = 100 / (temporal[index2].AllSalesOrderPerItem.length > 0 ? (temporal[index2].AllSalesOrderPerItem.length - completeItems.length) : 1)

                                        for (const item of temporal[index2].AllSalesOrderPerItem) {

                                            const temporalInv = temporal[index2].AllSalesOrderPerItem

                                            const pos2 = getIndexElement(temporalInv, 'InvoiceNo', item.InvoiceNo)
                                            if (pos2 !== -1) {
                                                if ((temporalInv[pos2].QuantityShipped < temporalInv[pos2].QuantityOrdered)) {
                                                    let toShipp = 0
                                                    let calculate = this.quantityDispatchPercentage(percentage, value, temporalInv[pos2].QuantityOrdered, false)

                                                    if ((calculate + temporalInv[pos2].QuantityShipped) >= temporalInv[pos2].QuantityOrdered) {
                                                        toShipp = temporalInv[pos2].QuantityOrdered
                                                        if (iterative) {
                                                            if (temporalInv[pos2].QuantityShipped + temporalInv[pos2].QuantityOrdered > temporalInv[pos2].QuantityOrdered) {
                                                                toShipp = temporalInv[pos2].QuantityOrdered - temporalInv[pos2].QuantityShipped
                                                            }
                                                        }


                                                    } else {
                                                        toShipp = calculate
                                                    }
                                                    if (iterative) {
                                                        temporalInv[pos2].QuantityShipped += toShipp
                                                    } else {
                                                        temporalInv[pos2].QuantityShipped = toShipp
                                                    }

                                                    if (temporalInv[pos2].QuantityShipped >= temporalInv[pos2].QuantityOrdered) {
                                                        completeItems.push(item)
                                                    }

                                                    value -= toShipp
                                                    temporal[index2].calculateQuantityReceived(Number(e.target.value))
                                                    temporal[index2].setCurrentTotalByOrdered()
                                                    temporal[index2].calculateStock()


                                                    await this.setState({ PurchaseOrderDetail: temporal })
                                                }
                                            }
                                            if (value > 0) {
                                                iterative = true
                                            }

                                        }
                                    }
                                } else {
                                    for (const item of temporal[index2].AllSalesOrderPerItem) {

                                        const temporalInv = temporal[index2].AllSalesOrderPerItem

                                        const pos2 = getIndexElement(temporalInv, 'InvoiceNo', item.InvoiceNo)
                                        if (pos2 !== -1) {

                                            temporalInv[pos2].QuantityShipped = 0
                                            temporal[index2].calculateQuantityReceived(0)
                                            temporal[index2].setCurrentTotalByOrdered()
                                            temporal[index2].calculateStock()

                                            await this.setState({ PurchaseOrderDetail: temporal })

                                        }


                                    }
                                }
                            } else {
                                temporal[index2].calculateQuantityReceived(value)
                                temporal[index2].setCurrentTotalByOrdered()
                                temporal[index2].calculateStock()

                                await this.setState({ PurchaseOrderDetail: temporal })
                            }



                        }




                    }
                }

                break;
            case "Cost":

                item.UnitCost = value
                item.setCurrentTotalByOrdered()

                const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
                if (index !== -1) {
                    temporal[index] = item
                }
                this.setState({ PurchaseOrderDetail: temporal })
                break;
            default:
                break;
        }
        this.calculateTotals()
    }

    getDetailOfSalesPerItem(item, head) {
        const temporal = this.state.currentItem
        temporal.ItemCode = item.ItemCode
        temporal.Description = item.Description
        temporal.Stock = item.Stock
        temporal.HeadInfo = head
        temporal.disabled = head.POVendorNo === '0000000' ? true : false
        this.setState({ ItemOrders: item.AllSalesOrderPerItem, FilterItemOrders: item.AllSalesOrderPerItem, currentItem: temporal })
        this.handleModalOpen('showModal1')
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchInvoiceAutomaticPO = async e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.ItemOrders
        } else {
            Filter = this.state.ItemOrders.filter((detail) => {
                if (this.Contains(detail.InvoiceNo, searchValue) ||
                    this.Contains(detail.BillToName, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        await this.setState({ FilterItemOrders: Filter })
        await this.refreshInputsModal()
    }

    changeQuantityShipped = async (e, item, head) => {
        const value = Number(e.target.value)
        const vendorNo = head.PrimaryVendorNo
        const temporal = this.state.AllProccessInformation
        if (vendorNo !== '0000000') {
            const index = getIndexElement(temporal, 'PrimaryVendorNo', head.PrimaryVendorNo)
            if (index !== -1) {
                const temporalDetail = temporal[index].Detail
                const index2 = getIndexElement(temporalDetail, 'ItemCode', this.state.currentItem.ItemCode)
                if (index2 !== -1) {
                    const temporalInv = temporalDetail[index2].AllSalesOrderPerItem
                    const index3 = getIndexElement(temporalInv, 'InvoiceNo', item.InvoiceNo)
                    if (index3 !== -1) {
                        temporalInv[index3].QuantityShipped = value
                        temporalDetail[index2].calculateQuantityReceivedPerItem()
                        temporalDetail[index2].setCurrentTotalByOrdered()
                        temporal[index].calculateFinalTotals()
                        //IND
                        if (document.getElementById('APOQReceived_' + this.state.currentItem.ItemCode)) {
                            document.getElementById('APOQReceived_' + this.state.currentItem.ItemCode).value = temporalDetail[index2].QuantityReceived
                        }
                        await this.setState({ AllProccessInformation: temporal })
                    }
                }
            }
        }
    }

    refreshInputsModal() {
        let count = 0
        for (const Inv of this.state.FilterItemOrders) {
            if (document.getElementById('APOQuantityShipped_' + count)) {
                document.getElementById('APOQuantityShipped_' + count).value = Inv.QuantityShipped
                count++
            }
        }
    }

    async saveDispatchItems() {
        let bandera = true
        for (const item of this.state.ItemOrders) {
            const data = {
                OrderNo: item.InvoiceNo,
                ItemCode: this.state.currentItem.ItemCode,
                QuantityRecived: Number(item.QuantityShipped),
                QuantityShipped: Number(item.QuantityShipped),
                Pallet: '01',
                User: 'ALT-' + getValueCookie('userName'),
                DispatchDate: getActualDateUTC(),
                check: 0,
                Mode: 'S/O',
                QuantityOrdered: item.QuantityOrdered,
                UnitPrice: item.UnitPrice,
                ItemCodeDesc: DeleteCharacter(item.ItemCodeDesc, '\''),
                LineWeight: '00',
                RegisterType: 'm'
            }
            await create_Delete_Update_Information('/picklist/set/dispatchItem', data)
            data.check = 1
            const result = await create_Delete_Update_Information('/picklist/set/dispatchItem', data)
            if (result.status.code !== 1) {
                bandera = false
            }
        }

        if (bandera) {
            confirmCloseAlert('correct', 'The distribution was saved successfully!')
        } else {
            confirmCloseAlert('incorrect', 'Whoops something was wrong. Try again!')
        }

    }

    async saveInPrincipalDispatchItems(detail) {
        let bandera = true


        for (const item of detail.AllSalesOrderPerItem) {
            const data = {
                OrderNo: item.InvoiceNo,
                ItemCode: this.state.currentItem.ItemCode,
                QuantityRecived: Number(item.QuantityShipped),
                QuantityShipped: Number(item.QuantityShipped),
                Pallet: '01',
                User: 'ALT-' + getValueCookie('userName'),
                DispatchDate: getActualDateUTC(),
                check: 0,
                Mode: 'S/O',
                QuantityOrdered: item.QuantityOrdered,
                UnitPrice: item.UnitPrice,
                ItemCodeDesc: DeleteCharacter(item.ItemCodeDesc, '\''),
                LineWeight: '00',
                RegisterType: 'm'
            }
            await create_Delete_Update_Information('/picklist/set/dispatchItem', data)
            data.check = 1
            const result = await create_Delete_Update_Information('/picklist/set/dispatchItem', data)
            if (result.status.code !== 1) {
                bandera = false
            }

        }

        if (bandera) {
            confirmCloseAlert('correct', 'The distribution was saved successfully!')
        } else {
            confirmCloseAlert('incorrect', 'Whoops something was wrong. Try again!')
        }

    }

    quantityDispatchPercentage(percentage, value, max, itsFinal) {
        let total = 0
        if (value > 0) {
            total = Math.round(value * (percentage / 100))
            if (itsFinal) {
                return value
            } else {
                if (total !== 0) {
                    return total > max ? max : total
                }
                return 1

            }

        }

        return total
    }


    searchKeyDownInvoice = async (e) => {
        if (e.key === 'Enter') {
            await this.searchInvoice()
        }
    }

    generalQuantity2 = async (e) => {
        let value = Number(e.target.value)


        const vendorNo = this.state.currentItem.HeadInfo.PrimaryVendorNo
        const temporal = this.state.PurchaseOrderDetail
        if (vendorNo !== '0000000') {

            const index2 = getIndexElement(temporal, 'ItemCode', this.state.currentItem.ItemCode)
            let iterative = false
            let completeItems = []
            if (index2 !== -1) {


                await temporal[index2].resetQuantityShipped()
                if (value > 0) {
                    while (completeItems.length < temporal[index2].AllSalesOrderPerItem.length && value > 0) {
                        let percentage = 100 / (this.state.ItemOrders.length > 0 ? (this.state.ItemOrders.length - completeItems.length) : 1)
                        for (const item of this.state.ItemOrders) {

                            const temporalInv = temporal[index2].AllSalesOrderPerItem

                            const index3 = getIndexElement(temporalInv, 'InvoiceNo', item.InvoiceNo)
                            if (index3 !== -1) {
                                if ((temporalInv[index3].QuantityShipped < temporalInv[index3].QuantityOrdered)) {
                                    let toShipp = 0
                                    let calculate = this.quantityDispatchPercentage(percentage, value, temporalInv[index3].QuantityOrdered, false)

                                    if ((calculate + temporalInv[index3].QuantityShipped) > temporalInv[index3].QuantityOrdered) {
                                        toShipp = temporalInv[index3].QuantityOrdered
                                        if (iterative) {
                                            if (temporalInv[index3].QuantityShipped + temporalInv[index3].QuantityOrdered > temporalInv[index3].QuantityOrdered) {
                                                toShipp = temporalInv[index3].QuantityOrdered - temporalInv[index3].QuantityShipped
                                            }
                                        }




                                    } else {
                                        toShipp = calculate

                                    }
                                    if (iterative) {
                                        temporalInv[index3].QuantityShipped += toShipp
                                    } else {
                                        temporalInv[index3].QuantityShipped = toShipp
                                    }

                                    if (temporalInv[index3].QuantityShipped >= temporalInv[index3].QuantityOrdered) {
                                        completeItems.push(item)
                                    }

                                    value -= toShipp
                                    temporal[index2].calculateQuantityReceived(Number(e.target.value))
                                    temporal[index2].setCurrentTotalByOrdered()
                                    temporal[index2].calculateStock()
                                    await this.setState({ PurchaseOrderDetail: temporal })

                                }
                            }
                            if (value > 0) {
                                iterative = true
                            }

                        }
                    }

                } else {
                    for (const item of this.state.ItemOrders) {

                        const temporalInv = temporal[index2].AllSalesOrderPerItem

                        const index3 = getIndexElement(temporalInv, 'InvoiceNo', item.InvoiceNo)
                        if (index3 !== -1) {
                            temporalInv[index3].QuantityShipped = 0
                            temporal[index2].calculateQuantityReceived(0)
                            temporal[index2].setCurrentTotalByOrdered()
                            temporal[index2].calculateStock()

                            await this.setState({ AllProccessInformation: temporal })
                        }

                    }
                }
                const ModalInfo = this.state.currentItem
                ModalInfo.Stock = temporal[index2].Stock
                this.setState({ currentItem: ModalInfo })
                if (document.getElementById('APOQReceived_' + this.state.currentItem.ItemCode)) {
                    document.getElementById('APOQReceived_' + this.state.currentItem.ItemCode).value = temporal[index2].QuantityReceived
                }

            }




        }
        await this.refreshInputsModal()
        this.calculateTotals()

    }

    async searchInvoice() {
        this.ModalLoading.current.showState(true);
        const InvoiceNumber = document.getElementById('searchInvoiceMayalandAPO').value
        const data = {
            InvoiceNo: InvoiceNumber,
            HeaderSeqNo: '000000',
            idCompany: 3,
            CustomerNo: '*',
            idcompany: '3',

        }

        const ActiveInvoice = await getInformationWithData('/invoice/detail', data)

        if (ActiveInvoice.data.length === 0) {
            const PostedInvoice = await getInformationWithData('/invoice/history/detail', data)
            if (PostedInvoice.status.code === 1) {
                ActiveInvoice.data = ActiveInvoice.data.concat(PostedInvoice.data)
            }
        }

        if (ActiveInvoice.data.length !== 0) {
            document.getElementById('searchInvoiceMayalandAPO').value = ''
            await this.addNewPurchaseOrder(ActiveInvoice.data, InvoiceNumber)

        } else {
            confirmCloseAlert('incorrect', `The Invoice: ${InvoiceNumber} was not found.\nPlease try again!`)
        }

        this.ModalLoading.current.showState(false);
    }

    async getNewPOCorrelative() {
        const data = {
            type: 'PurchaseOrder',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)

        if (result.status.code === 1) {
            let orderNumber = "PO"
            for (let a = result.data[0].correlative.toString().length; a < 5; a++) {
                orderNumber += "0"
            }

            orderNumber += result.data[0].correlative.toString()
            return orderNumber
        } else {
            return ""
        }
    }

    async addNewPurchaseOrder(Detail, InvoiceNo) {
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            priceLevel: this.state.PriceLevel,
            Component: null
        }
        const index = getIndexElement(this.state.SpecificInvoices, 'InvoiceNo', InvoiceNo)
        const invent = await getInformationWithData('/Items/get', data)
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const respuesta2 = await getInformationWithData('/Items/get', data);

            if (getValueCookie('CompanyId') === '6' && data.priceLevel !== '2') {
                for (const item of respuesta2.data) {

                    if (item.LastTotalUnitCost || item.LastTotalUnitCost !== 0) {
                        let p = item.LastTotalUnitCost / (0.95)
                        item.UnitPrice = Number(pricesFormat(p / (0.65)))
                    } else {
                        //ELIMINAR DEL CATALOGO

                    }
                }

            }
            invent.status.code = respuesta2.status.code
            for (const item of respuesta2.data) {
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(respuesta2.data)

        }



        if (index === -1) {
            let correlative = await this.getNewPOCorrelative()
            if (correlative !== "") {
                let newPO = new OrderPrimaryVendorWithInvoiceNo('0000000', 'Mayaland Distribution', InvoiceNo, correlative)
                for (const item of Detail) {
                    const itemIndex = getIndexElement(invent.data, 'itemCode', item.ItemCode)
                    let desc = ""
                    let cost = 0
                    if (itemIndex !== -1) {
                        cost = invent.data[itemIndex].LastTotalUnitCost
                        desc = invent.data[itemIndex].completeDesc === "null" ? invent.data[itemIndex].abbreviatedDesc : invent.data[itemIndex].completeDesc
                    } else {
                        desc = item.ItemCodeDesc
                        cost = 0
                    }

                    if (cost) {
                        cost = cost / 0.95
                    }


                    const newItem = new ItemDetal(item.ItemCode, desc, item.InvoiceNo, cost, item.QuantityOrdered, item.QuantityShipped, item.UnitPrice)
                    newItem.setCurrentTotalByOrdered()
                    newPO.addQuantityOrdered(newItem.QuantityOrdered)
                    newPO.addQuantityReceived(newItem.QuantityReceived)
                    newPO.addAmtValue(newItem.totalCost)

                    newItem.addSalesOrderItem(item)
                    newPO.Detail.push(newItem)
                }
                newPO.Detail = await ItemDesc(newPO.Detail, 'Description', 'ItemCode', false)
                let temporal = this.state.SpecificInvoices
                temporal.push(newPO)
                this.setState({ SpecificInvoices: temporal })

            }

        } else {
            confirmCloseAlert('incorrect', `The Invoice: ${InvoiceNo} is already included in the current list of Purchase Orders`)
        }

    }

    async DeleteSpecificPO(PONumber) {
        const index = getIndexElement(this.state.SpecificInvoices, 'PurchaseOrderNo', PONumber)

        if (index !== -1) {
            let temporal = JSON.parse(JSON.stringify(this.state.SpecificInvoices))

            await temporal.splice(index, 1)

            await this.setState({ SpecificInvoices: temporal })
        }
    }

    OpenDetailSpecificPO(AllInfoInvoice) {
        this.handleModalOpen("showModal2")
        const temporal = this.state.currentInvoice
        temporal.InvoiceNo = AllInfoInvoice.InvoiceNo
        temporal.TotalQuantityOrdered = AllInfoInvoice.TotalQuantityOrdered
        temporal.TotalQuantityReceived = AllInfoInvoice.TotalQuantityReceived
        temporal.TotalAmtOrdered = AllInfoInvoice.TotalAmtOrdered
        this.setState(
            {
                currentInvoice: temporal,
                currentInvoiceDetail: AllInfoInvoice.Detail,
                currentFilterInvoiceDetail: AllInfoInvoice.Detail
            }
        )
    }

    async ImportMAS90Mayaland() {
        let temporal = this.state.SpecificInvoices
        let copy = []
        let cont = 0


        await this.ModalLoading.current.showState(true);

        for (const order of temporal) {
            let date = ''
            if (order.Date) {
                if (order.Date !== '') {
                    date = order.Date
                } else {
                    date = getDateYearMonthDayDash()
                }
            } else {
                date = getDateYearMonthDayDash()
            }

            const data = {
                header: new PurchaseOrderStructure(order.PurchaseOrderNo, '', getDateYearMonthDayDash(), getDateYearMonthDayDash(), getDateYearMonthDayDash(), date, order.PrimaryVendorNo, order.PrimartVendorName, '', getValueCookie('userName'), 3, 0),
                products: order.Detail,
                idCompany: Number(getValueCookie('CompanyId')),
                Date: getDateYearMonthDayDash(),
                PurchaseOrderNo: order.PurchaseOrderNo,
                InvoiceNo: order.InvoiceNo
            }

            const result = await create_Delete_Update_Information('/purchase/ImportMAS90', data)
            await Delay(5)

            cont++

            if (result.status.code !== 1) {
                copy.push(order)

            }
            if (cont < this.state.SpecificInvoices.length) {
                await Delay(7)
            }


        }
        await this.setState({ SpecificInvoices: copy })
        await this.ModalLoading.current.showState(false);
        if (copy.length === 0) {
            confirmCloseAlert('correct', 'Purchase Orders Imported to MAS90 Successfully!')
        } else {
            confirmCloseAlert('incorrect', 'Whoops Something was wrong. Please try again!')
        }

    }

    changeDate = (e, Ident, Origin) => {
        if (Origin === 'MayAPO') {
            const temporal = this.state.SpecificInvoices
            const index = getIndexElement(temporal, 'PurchaseOrderNo', Ident)
            if (index !== -1) {
                temporal[index].Date = e.target.value
                this.setState({ SpecificInvoices: temporal })
            }
        } else if (Origin === 'VendAPO') {
            const temporal = this.state.AllProccessInformation
            const index = getIndexElement(temporal, 'PrimaryVendorNo', Ident)
            if (index !== -1) {
                temporal[index].Date = e.target.value
                this.setState({ AllProccessInformation: temporal })
            }
        }
    }


    calculateByBox2 = async (e, des, vendorNo) => {



        let unitPerBox = Number(document.getElementById("unitPerBox_" + des.ItemCode).value);
        let totalUnit = Number(document.getElementById("totalUnits_" + des.ItemCode).value);
        let UnitCost = Number(document.getElementById("unitCost_" + des.ItemCode).value);

        if (unitPerBox && totalUnit) {
            let totalBoxes = totalUnit / unitPerBox
            let BoxCost = UnitCost * unitPerBox

            let newT = {
                target: {
                    value: totalBoxes
                }
            }
            await this.changesPrincipalAPO2(newT, des, vendorNo, "QuantityReceived")
            document.getElementById("APOQReceived_" + des.ItemCode).value = totalBoxes
            if (BoxCost) {
                newT.target.value = BoxCost
                await this.changesPrincipalAPO2(newT, des, vendorNo, "Cost")
                document.getElementById("AP_" + des.ItemCode).value = BoxCost
            }
        }

    }

    async AddProduct(Item, VendorNo) {
        const temporal = this.state.PurchaseOrderDetail




        const index2 = getIndexElement(temporal, 'ItemCode', Item.itemCode)
        if (index2 === -1) {

            let newItem = new ItemDetal(Item.itemCode, Item.abbreviatedDesc, "", Item.UnitPrice, 0, 0, Item.LastTotalUnitCost, true)
            temporal.push(newItem)

            this.setState({ PurchaseOrderDetail: temporal })
        } else {
            confirmCloseAlert('incorrect', `The Item: ${Item.itemCode} is already on in the Purchase Order!`)
        }

    }

    async DeleteAddedItem(VendorNo, ItemCode) {
        const temporal = this.state.PurchaseOrderDetail
        const indexItem = getIndexElement(temporal, 'ItemCode', ItemCode)
        if (indexItem !== -1) {
            temporal.splice(indexItem, 1)
            this.setState({ PurchaseOrderDetail: temporal })
        }

    }

    async getVendors() {
        const data = {
            idCompany: Number(getValueCookie('CompanyId'))
        }
        const result = await getInformationWithData('/vendors/get/vendorsInformation', data)
        if (result.status.code === 1) {
            var arr = []
            for (const vendor of result.data) {
                vendor.name = DeleteCharacter(vendor.name, '\'')
                const ven = {
                    value: vendor.customerNo + '{' + vendor.name,
                    label: vendor.customerNo + " " + vendor.name
                }
                arr.push(ven)
            }
            this.setState({ vendorSelectInformation: arr })

        }
    }


    handleChange(e) {
        const change = e.value.split('{')
        if (change.length === 2) {
            const temporal = this.state.Header
            temporal.POVendorNo = change[0]
            temporal.POVendorName = change[1]
            this.setState({ Header: temporal })
        }
    }

    async getSalesOrder(refresh) {
        await this.handleModalOpen("showModal3")
        const data = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
        }



        if (refresh || this.state.salesOrderList.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ salesOrderList: [], salesOrderListFilter: [] });

            const respuesta = await getInformationWithData('/sales/get/header', data);
            if (respuesta.status.code === 1) {
                let result = respuesta.data
                for (const sale of result) {
                    sale.Check = false
                    if (sale.OrderType === 'S') {
                        sale.TypeinLetters = 'Standard'
                    } else {
                        sale.TypeinLetters = 'Quote'
                    }
                }


                await this.setState({ salesOrderList: result, salesOrderListFilter: result });

            }
            
            this.ModalLoading.current.showState(false);
        }
        await this.setState({ salesOrderListFilter: this.state.salesOrderList });


    }

    checkSpecificSalesOrder = async (e, actualStat, SalesOrderNo) => {
        this.specificSelectInvoice(!actualStat, SalesOrderNo)
        this.updateChecks()
    }

    async specificSelectInvoice(stat, SalesOrderNo) {
        const index1 = getIndexElement(this.state.salesOrderList, 'SalesOrderNo', SalesOrderNo)
        const index2 = getIndexElement(this.state.salesOrderListFilter, 'SalesOrderNo', SalesOrderNo)

        if (index1 !== -1 && index2 !== -1) {
            const temporal = this.state.salesOrderList
            const temporalF = this.state.salesOrderListFilter
            temporal[index1].Check = stat
            temporalF[index2].Check = stat
            await this.setState({ salesOrderList: temporal, salesOrderListFilter: temporalF })

        }
    }

    updateChecks() {
        let all = true
        for (const inv of this.state.salesOrderList) {
            if (!inv.Check) {
                all = false
                break
            }
        }

        this.setState({ selectAllSalesOrder: all })
    }

    checkAllSO = async (e, actualStat) => {
        const temporal = this.state.salesOrderList
        const temporalF = this.state.salesOrderListFilter
        if (!actualStat) {
            temporal.forEach(element => {
                element.Check = true
            })
            temporalF.forEach(element => {
                element.Check = true
            })

        } else {
            temporal.forEach(element => {
                element.Check = false
            })
            temporalF.forEach(element => {
                element.Check = false
            })

        }
        await this.setState({ selectAllSalesOrder: !actualStat, salesOrderList: temporal, salesOrderListFilter: temporalF })
    }

    searchSalesOrder = e => {

        let busqueda = e.target.value;

        if (busqueda) {
            let orders = this.state.salesOrderList.filter((order_) => {
                if (((
                    this.Contains(order_.BillToAddress1, busqueda) ||
                    this.Contains(order_.BillToName, busqueda) ||
                    this.Contains(order_.SalespersonNo, busqueda) ||
                    this.Contains(order_.SalesOrderNo, busqueda) ||
                    this.Contains(order_.TermsCode, busqueda) ||
                    this.Contains(order_.CustomerNo, busqueda) ||
                    this.Contains(order_.TypeinLetters, busqueda)
                ))
                ) {
                    return order_
                } else {
                    return null
                }
            });
            this.setState({ salesOrderListFilter: orders });
        } else {
            let temporal = this.state.salesOrderList
            this.setState({ salesOrderListFilter: temporal })
        }
    }
    searchPurchaseOrder = e => {

        let busqueda = e.target.value;

        if (busqueda) {
            let orders = this.state.HistoryPurchaseOrderHeader.filter((order_) => {
                if (((
                    this.Contains(order_.PurchaseOrderNo, busqueda) ||
                    this.Contains(order_.VendorNo, busqueda) ||
                    this.Contains(order_.VendorName, busqueda) ||
                    this.Contains(order_.InvoiceNo, busqueda)
                ))
                ) {
                    return order_
                } else {
                    return null
                }
            });
            this.setState({ HistoryPurchaseOrderHeaderFilter: orders });
        } else {
            let temporal = this.state.HistoryPurchaseOrderHeader
            this.setState({ HistoryPurchaseOrderHeaderFilter: temporal })
        }
    }

    async getPODetailBySalesOrder() {
        await this.ModalLoading.current.showState(true);
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            priceLevel: this.state.PriceLevel,
            Component: null
        }
        const invent = await getInformationWithData('/Items/get', data)
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const respuesta2 = await getInformationWithData('/Items/get', data);


            invent.status.code = respuesta2.status.code
            for (const item of respuesta2.data) {
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(respuesta2.data)

        }



        let FutureOrders = []
        const result = await getInformationNoData('/invoice/get/Total')
        
        for (const line of result.data) {
            if(line.QuantityOrdered>0){
            const index = getIndexElement(this.state.salesOrderList, 'SalesOrderNo', line.InvoiceNo)
            const index2 = getIndexElement(invent.data, 'itemCode', line.ItemCode)

            let desc = ""
            let cost = 0
            let quant=0
            if (index2 !== -1) {

                cost = invent.data[index2].LastTotalUnitCost
                desc = (!(invent.data[index2].completeDesc) || invent.data[index2].completeDesc === "null") ? invent.data[index2].abbreviatedDesc : invent.data[index2].completeDesc
                quant = invent.data[index2].quantity
            } else {
                desc = line.ItemCodeDesc
                cost = 0
                quant = 0
            }
            if (index !== -1) {

                if (this.state.salesOrderList[index].Check && line.PrimaryVendorNo === this.state.Header.POVendorNo) {

                    const index2 = getIndexElement(FutureOrders, 'ItemCode', line.ItemCode)
                    if (index2 === -1) {
                        const temporalProd = new ItemDetal(line.ItemCode, desc, line.InvoiceNo, line.UnitPrice, line.QuantityOrdered, line.QuantityShipped, cost,null,quant)
                        temporalProd.setCurrentTotalByOrdered()
                        temporalProd.addSalesOrderItem(line)
                        FutureOrders.push(temporalProd)

                    } else {
                        const indexItemOrder = getIndexElement2(FutureOrders[index2].AllSalesOrderPerItem, 'ItemCode', 'InvoiceNo', line.ItemCode, line.InvoiceNo)
                        if (indexItemOrder === -1) {
                            FutureOrders[index2].addQuantityOrdered(line.QuantityOrdered)
                            FutureOrders[index2].addQuantityReceived(line.QuantityShipped)
                            FutureOrders[index2].setCurrentTotalByOrdered()
                            FutureOrders[index2].addSalesOrderItem(line)
                        }

                    }



                }
            }
        
            }
        }
        FutureOrders=OrderArray(FutureOrders,'ItemCode',true)

        this.setState({ PurchaseOrderDetail: FutureOrders })
        this.calculateTotals()
        await this.ModalLoading.current.showState(false);
    }


    calculateTotals() {
        let TotalQuantityOrdered = 0
        let TotalQuantityReceived = 0
        let TotalCost = 0
        for (const line of this.state.PurchaseOrderDetail) {

            TotalQuantityOrdered += line.QuantityOrdered
            TotalQuantityReceived += line.QuantityReceived
            TotalCost += line.totalCost
        }
        const temporal = this.state.Totals
        temporal.QuantityOrdered = TotalQuantityOrdered
        temporal.QuantityReceived = TotalQuantityReceived
        temporal.TotalCost = TotalCost
        this.setState({ Totals: temporal })
    }

    async saveAndChargePOToMAS90(status) {
        Swal.fire({
            icon: 'info',
            title: 'Please confirm that you want to upload these changes to MAS90. Note that this action is final and cannot be undone. Take a moment to review your changes carefully before proceeding. If you are ready, click confirm below. Thank you!',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.saveAutomaticPurchaseOrder(status)
            }
        })
    }

    async saveAutomaticPurchaseOrder(status) {
        await this.ModalLoading.current.showState(true);
        if (this.state.Header.InvoiceNo !== '') {
            await this.GlobalDispatch(this.state.PurchaseOrderDetail)
            let Orders = []
            for (const SalesOrder of this.state.salesOrderList) {

                if (SalesOrder.Check) {
                    Orders.push(SalesOrder)
                }
            }


            const data = {
                PurchaseOrderNo: this.state.Header.PONumber,
                Status: status,
                SalesOrderList: Orders,
                OrderDate: this.formatDate(this.state.Header.SelectedDate),
                InvoiceNo: this.state.Header.InvoiceNo,
                VendorNo: this.state.Header.POVendorNo,
                VendorName: this.state.Header.POVendorName,
                TotalAmount: Number(pricesFormat(this.state.Totals.TotalCost)),
                TotalQuantity: this.state.Totals.QuantityReceived,
                IdCompany: Number(getValueCookie('CompanyId')),
                LastUpdateBy: getValueCookie('userName'),
                Products: this.state.PurchaseOrderDetail
            }


            const result = await create_Delete_Update_Information('/purchase/save', data)
            if (result.status.code === 1) {
                if (status === 0) {
                    confirmCloseAlert('correct', 'The Purchase Order it has been saved successfully!')
                } else {
                    const detailPOMAS90 = {
                        header: new PurchaseOrderStructure(this.state.Header.PONumber, '', this.formatDate(this.state.Header.SelectedDate), this.formatDate(this.state.Header.SelectedDate), this.formatDate(this.state.Header.SelectedDate), this.formatDate(this.state.Header.SelectedDate), this.state.Header.POVendorNo, this.state.Header.POVendorName, '', getValueCookie('userName'), 3, 0),
                        products: this.state.PurchaseOrderDetail,
                        idCompany: Number(getValueCookie('CompanyId')),
                        username: getValueCookie('userName'),
                        cost: this.state.Totals.TotalCost,
                        totalquant: this.state.Totals.QuantityReceived,
                        Freight: 0,
                        PurchaseOrderNo: this.state.Header.PONumber,
                        InvoiceNo: this.state.Header.InvoiceNo
                    }
                    const resultImportMAS90 = await create_Delete_Update_Information('/purchase/ImportMAS90', detailPOMAS90)
                    if (resultImportMAS90.status.code === 1) {
                        confirmCloseAlert('correct', 'Purchase Order imported to MAS90!')
                    }
                }
            } else {
                confirmCloseAlert('incorrect', 'Something went wrong. Try Again!')
            }
        } else {
            confirmCloseAlert('incorrect', 'Please, asign the Invoice No to this Purchase Order!')
        }
        await this.ModalLoading.current.showState(false);

    }
    async GlobalDispatch(Detail) {
        let flag = true
       
        for (const item of Detail) {
            for (const order of item.AllSalesOrderPerItem) {
                
                const data = {
                    OrderNo: order.InvoiceNo,
                    ItemCode: order.ItemCode,
                    QuantityRecived: Number(order.QuantityShipped),
                    QuantityShipped: Number(order.QuantityShipped),
                    Pallet: '01',
                    User: 'ALT-' + getValueCookie('userName'),
                    DispatchDate: getActualDateUTC(),
                    check: 0,
                    Mode: 'S/O',
                    QuantityOrdered: order.QuantityOrdered,
                    UnitPrice: order.UnitPrice,
                    ItemCodeDesc: DeleteCharacter(DeleteStrangeCharacter(order.ItemCodeDesc), '\''),
                    LineWeight: '00',
                    RegisterType: 'm'
                }



                await create_Delete_Update_Information('/picklist/set/dispatchItem', data)

                data.check = 1
                const result = await create_Delete_Update_Information('/picklist/set/dispatchItem', data)
                
                if (result.status.code !== 1) {
                    flag = false
                }
            }
        }

        for (const order of this.state.salesOrderList) {
           
            if(order.Check){
                const validate=await getInformationWithData('/sales/get/balance',{SalesOrderNo:order.SalesOrderNo})
                
                if(validate.data.length>0){
                    const data2 = {
                        OrderNo: order.SalesOrderNo,
                        Status: validate.data[0]?'2':'1',
                        idCompany: getValueCookie('CompanyId'),
                        MODE: 'S/O'
                    }
                    
                    await create_Delete_Update_Information('/picklist/set/PickListState', data2)
                }
            }  
        }
        return flag
    }

    async getPurchaseOrderHeader() {
        this.handleModalOpen("showModal4")
        await this.ModalLoading.current.showState(true);
        const result = await getInformationWithData('/purchase/header/get', null)
        this.setState({ HistoryPurchaseOrderHeader: result.data, HistoryPurchaseOrderHeaderFilter: result.data })
        await this.ModalLoading.current.showState(false);
    }

    async SelectPurchaseOrder(header) {
        const temporal = this.state.Header
        temporal.InvoiceNo = header.InvoiceNo
        temporal.PONumber = header.PurchaseOrderNo
        temporal.POVendorName = header.VendorName
        temporal.POVendorNo = header.VendorNo
        let date = new Date(header.OrderDate)
        temporal.SelectedDate = new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()))
        this.setState({ Header: temporal })
        await this.handleModalClose("showModal4")
        const dataSO = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
        }
        const respuesta = await getInformationWithData('/sales/get/header', dataSO);
        if (respuesta.status.code === 1) {
            let result = respuesta.data
            for (const sale of result) {
                let index = header.SalesOrderList.indexOf(sale.SalesOrderNo)
                sale.Check = index === -1 ? false : true
                if (sale.OrderType === 'S') {
                    sale.TypeinLetters = 'Standard'
                } else {
                    sale.TypeinLetters = 'Quote'
                }
            }
            await this.setState({ salesOrderList: result, salesOrderListFilter: result });
            await this.getPODetailBySalesOrder()
        }

        const result = await getInformationWithData('/purchase/detail/get', { PurchaseOrderNo: header.PurchaseOrderNo })
       

        const Detail = this.state.PurchaseOrderDetail
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            priceLevel: this.state.PriceLevel,
            Component: null
        }
        const invent = await getInformationWithData('/Items/get', data)
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const respuesta2 = await getInformationWithData('/Items/get', data);


            invent.status.code = respuesta2.status.code
            for (const item of respuesta2.data) {
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(respuesta2.data)

        }

        for (const item of result.data) {
            const index2 = getIndexElement(Detail, 'ItemCode', item.ItemCode)
            const index3 = getIndexElement(invent.data, 'itemCode', item.ItemCode)
            let quant=0
            if(index3!==-1){
                quant=invent.data[index3].quantity
            }
            if (index2 === -1) {

                let newItem = new ItemDetal(item.ItemCode, item.ItemCodeDesc, "", 0, item.QuantityOrdered, item.QuantityShipped, item.UnitCost, true,quant)
                newItem.setCurrentTotalByOrdered()
                Detail.push(newItem)
            }

        }
        this.setState({ PurchaseOrderDetail: Detail })
        this.calculateTotals()
    }
    async createNewPurchaseOrder() {
        this.ClearAll()
        let correlative = await this.getNewPOCorrelative()
        const temporal = this.state.Header
        temporal.PONumber = correlative
        this.setState({ Header: temporal })
    }

    formatDate = (date) => {

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    handleDataChange = (e) => {
        const temporal = this.state.Header
        switch (e.target.id) {

            case "APurchaseOrderDate":
                let date = new Date(e.target.value)
                temporal.SelectedDate = new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()))
                break;
            case "APurchaseOrderInvoiceNo":
                temporal.InvoiceNo = e.target.value
                break;
            default:
                break;
        }


        this.setState({ Header: temporal })
    };

    statusPurchaseOrder(status) {
        switch (status) {
            case 0:
                return { color: 'text-warning', text: 'Saved' }
            case 1:
                return { color: 'text-success', text: 'Uploaded to MAS90' }
            default:
                return { color: '', text: '' }

        }
    }


    render() {
        return (
            <div className='purchaseOrderContainer'>
                <LoadingWindow ref={this.ModalLoading} />

                <p className='text-center display-1 pb-2' >Automatic <br />Purchase Order</p>

                <div className='row'>
                    <div className='col-12'>
                        <ul className="nav nav-tabs" id="ScanningControls" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button id='ViewAPOMayaland' className="nav-link active" data-bs-toggle="tab" data-bs-target="#APOMayaland" type="button" role="tab" aria-controls="APOMayaland" aria-selected="true">Mayaland</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button id='ViewAPOEditVendors' className="nav-link" data-bs-toggle="tab" data-bs-target="#APOEditVendors" type="button" role="tab" aria-controls="APOEditVendors" aria-selected="false">Other Vendors</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="allScanningTab">
                            <div className="tab-pane fade show active" id="APOMayaland" role="tabpanel" aria-labelledby="APOMayaland">
                                <div className='row pt-4'>
                                    <div className='col-1'></div>
                                    <div className='col-10'>
                                        <div className='row'>
                                            <div className='col-12 pb-5'>
                                                <div className="input-group input-group-lg flex-nowrap">
                                                    <input onKeyDown={this.searchKeyDownInvoice} className="inputResize form-control form-control-lg" placeholder='Enter Mayaland Invoice...' id='searchInvoiceMayalandAPO' type="text" />
                                                    <span className="input-group-text"><button type="button" onClick={() => this.searchInvoice()} className="btn greenButton btn-lg" >Search <BsSearch /></button></span>
                                                </div>
                                            </div>
                                            <div hidden={this.state.SpecificInvoices.length === 0} className='col-12 tableFixHead'>
                                                <table className='table align-middle'>
                                                    <thead className='thead'>
                                                        <tr className='text-light text-center'>
                                                            <th className='bg-dark' >Purchase Order No</th>
                                                            <th className='bg-dark' >Invoice No</th>
                                                            <th className='bg-dark' >Date</th>
                                                            <th className='bg-dark' >Quantity Ordered</th>
                                                            <th className='bg-dark' >Quantity Shipped</th>
                                                            <th className='bg-dark' ></th>
                                                            <th className='bg-dark' >Total Amount</th>
                                                            <th className='bg-dark' ></th>
                                                            <th className='bg-dark' ></th>
                                                            <th className='bg-dark' ></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className='tbody'>
                                                        {this.state.SpecificInvoices.map((element, e) => (
                                                            <tr key={e}>
                                                                <td className='text-center textTableSize'>{element.PurchaseOrderNo}</td>
                                                                <td className='text-center textTableSize'>{element.InvoiceNo}</td>
                                                                <td className='text-center'>
                                                                    <div className="input-group  input-group-lg">
                                                                        <input onChange={(e) => this.changeDate(e, element.PurchaseOrderNo, 'MayAPO')} type="date" id={'dateMayalandAPO' + e} className="form-control text-center inputResize" />
                                                                    </div>
                                                                </td>
                                                                <td className='text-center textTableSize'>{element.TotalQuantityOrdered}</td>
                                                                <td className='text-center textTableSize'>{element.TotalQuantityReceived}</td>
                                                                <td className='text-end textTableSize'>$</td>
                                                                <td className='text-end textTableSize'>{NumberFormat(pricesFormat(element.TotalAmtOrdered))}</td>
                                                                <td className='text-center textTableSize'>
                                                                    <div className="d-grid gap-2">
                                                                        <button type="button" onClick={() => this.OpenDetailSpecificPO(element)} className="btn aquaButton btn-lg">Detail Order</button>
                                                                    </div>
                                                                </td>
                                                                <td className='text-center textTableSize'>
                                                                    <div className="d-grid gap-2">
                                                                        <button onClick={() => this.DeleteSpecificPO(element.PurchaseOrderNo)} type="button" className="btn redButton btn-lg">Delete Order <AiFillDelete /></button>
                                                                    </div>
                                                                </td>
                                                                <td className='text-center textTableSize'>
                                                                    <div className="d-grid gap-2">
                                                                        <OrderPDF id={"PurchasePrices" + element.PrimaryVendorNo} disabled={false} colorButton="orangeButton" title=""
                                                                            companyLogo={getValueCookie('DocumentsLogo')}
                                                                            OrderTitle="Purchase Order"
                                                                            contactInfo={this.state.companyPrintHeader}
                                                                            OrderInfo1={["Order No: ", element.PurchaseOrderNo, "No Vendor: ", element.PrimaryVendorNo, "Order by: ", getValueCookie('userName'), "Vendor: ", element.PrimartVendorName, "Printed by: ", getValueCookie('userName'), "Date: ", FormatQueryReturnDateForReport(getActualDateUTC())]}
                                                                            headerTable={["\n Item Code", "\n Description", "\n Quantity\n Ordered"]}
                                                                            bodyTable={element.Detail}
                                                                            headerBodyTable={["ItemCode", "Description", "QuantityOrdered"]}
                                                                            bottomInfo={["", "\nTOTALS", "\n" + NumberFormat(element.TotalQuantityOrdered)]}
                                                                            styleColumns={["ColumnaTextoNormal2Left", "ColumnaTextoLargo3", "ColumnaTextoNormal2"]}
                                                                            hidden={false}
                                                                            comment={"Comment: "}
                                                                            breakpoint={56}
                                                                            keysDecimalFormat={[]}
                                                                            titleButton='Print Order'
                                                                        />
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </table>
                                            </div>
                                            <div hidden={this.state.SpecificInvoices.length === 0} className='col-12'>
                                                <div className="d-grid gap-2">
                                                    <button onClick={() => this.ImportMAS90Mayaland()} type="button" className="btn greenButton btn-lg">Import to MAS90 <AiOutlineCloudUpload /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-1'></div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="APOEditVendors" role="tabpanel" aria-labelledby="APOEditVendors">
                                <div className='row pt-4'>
                                    <div className='col-1' />
                                    <div className='col-10 pb-5'>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <div className='col-12 pb-2'>
                                                        <div className="d-grid gap-2">
                                                            <button type="button" className="btn greenButton btn-lg" onClick={() => this.createNewPurchaseOrder()}>New Purchase Order <BsCartPlus /></button>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 text-start pText'><p>Order No:</p></div>
                                                    <div className='col-12'><input className="form-control form-control-lg" value={this.state.Header.PONumber} disabled id='APurchaseOrderNo' type="text" /></div>
                                                    <div className='col-12 pt-3 text-start pText'><p>Invoice No:</p></div>
                                                    <div className='col-12'><input disabled={this.state.Header.POVendorNo === ''} value={this.state.Header.InvoiceNo} onChange={(e) => this.handleDataChange(e)} className="form-control form-control-lg" id='APurchaseOrderInvoiceNo' type="text" /></div>
                                                    <div className='col-12 pt-4'>
                                                        <div className="d-grid gap-2">
                                                            <button disabled={this.state.Header.POVendorNo === ''} onClick={() => this.getSalesOrder()} type="button" className="btn orangeButton btn-lg">Select Sales Order <BsCartPlus /></button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <div className='col-12 pb-2'>
                                                        <div className="d-grid gap-2">
                                                            <button onClick={() => this.getPurchaseOrderHeader()} type="button" className="btn brownButton btn-lg">History <BsCheckSquare /></button>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 text-start pText'><p>Vendor:</p></div>
                                                    <div className='col-12'>
                                                        <Select isDisabled={this.state.Header.PONumber === ''} value={{ label: this.state.Header.POVendorNo + ' ' + this.state.Header.POVendorName, value: this.state.Header.POVendorNo + '{' + this.state.Header.POVendorName }} onMenuOpen={() => this.getVendors()} options={this.state.vendorSelectInformation} className='fs-4 text-start' placeholder="Select Vendor" onChange={this.handleChange.bind(this)} />
                                                    </div>
                                                    <div className='col-12 pt-3 text-start pText'><p>Date:</p></div>
                                                    <div className='col-12'><input value={this.formatDate(this.state.Header.SelectedDate)} disabled={this.state.Header.POVendorNo === ''} className="form-control form-control-lg" id='APurchaseOrderDate' onChange={(e) => this.handleDataChange(e)} type="date" /></div>
                                                    <div className='col-12 pt-4'>
                                                        <Catalogue Array={'PurchaseOrderDetail'} disabled={this.state.Header.PONumber === ''} nombrePadre={"APurchaseOrder"} Padre={this} needCost={true} Component={'APurchaseOrder'} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 pt-5'>
                                                <ExcelDocument
                                                    hidden={this.state.PurchaseOrderDetail.length===0}
                                                    id={"ExcelOrderPerVendor" + this.state.Header.POVendorName}
                                                    data={getDataSet(this.state.PurchaseOrderDetail, ['Item Code', 'Description', 'Quantity Ordered'], ['ItemCode', 'Description', 'QuantityOrdered'])}
                                                    sheetname={"Purchase Order " + this.state.Header.POVendorName}
                                                    archname={"Purchase Order No "+this.state.Header.PONumber+" " + this.state.Header.POVendorName + " " + getValueCookie('Company') + " DATE " + getDateFromReports()}
                                                />
                                            </div>
                                            <div className='col-sm-12 pt-4'>
                                                <SearchUPC ComponentName={'AutomaticPO'} extraInfo={this.state.Header.POVendorNo} disabled={this.state.Header.PONumber === ''} Padre={this} priceLevel={null} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-1' />
                                    <div className='col-sm-12 tableFixHead'>
                                        <table className='table align-middle'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' >Item Code</th>
                                                    <th className='bg-dark' >Description</th>
                                                    <th className='bg-dark' >On Hand</th>
                                                    <th hidden className='bg-dark'>Units Per Box</th>
                                                    <th hidden className='bg-dark'>Total Units</th>
                                                    <th hidden className='bg-dark'>Cost Per Unit</th>
                                                    <th className='bg-dark' >Quantity Ordered</th>
                                                    <th className='bg-dark' >Quantity Received</th>
                                                    <th className='bg-dark' >Unit Cost</th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' >Total</th>
                                                    <th className='bg-dark' ></th>
                                                    <th hidden className='bg-dark'></th>
                                                    <th className='bg-dark'></th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.PurchaseOrderDetail.map((det, d) => (
                                                        <tr key={d}>
                                                            <td className='text-start '>{det.ItemCode}</td>
                                                            <td className='text-start '>{det.Description}</td>
                                                            <td className='text-center '>{det.OnHand}</td>
                                                            <td hidden className='text-center textTableSize'>
                                                                <div className="input-group input-group-lg flex-nowrap">
                                                                    <input disabled={this.state.Header.POVendorNo === "0000000"} type="number" min={0} step={1} id={"unitPerBox_" + det.ItemCode} placeholder="0" defaultValue={0} onChange={(e) => this.calculateByBox2(e, det, this.state.Header.POVendorNo)} className="form-control text-center inputResize" />
                                                                </div>
                                                            </td>
                                                            <td hidden className='text-center textTableSize'>
                                                                <div className="input-group input-group-lg flex-nowrap">
                                                                    <input disabled={this.state.Header.POVendorNo === "0000000"} type="number" min={0} step={1} id={"totalUnits_" + det.ItemCode} placeholder="0" defaultValue={0} onChange={(e) => this.calculateByBox2(e, det, this.state.Header.POVendorNo)} className="form-control text-center inputResize" />
                                                                </div>
                                                            </td>
                                                            <td hidden className='text-center textTableSize'>
                                                                <div className="input-group input-group-lg flex-nowrap">
                                                                    <span className="input-group-text">$</span>
                                                                    <input disabled={this.state.Header.POVendorNo === "0000000"} type="number" min={0} step={1} id={"unitCost_" + det.ItemCode} placeholder="0.00" defaultValue={0} onChange={(e) => this.calculateByBox2(e, det, this.state.Header.POVendorNo)} className="form-control text-center inputResize" />
                                                                </div>
                                                            </td>
                                                            <td className='text-center textTableSize'>{NumberFormat(det.QuantityOrdered)}</td>

                                                            <td className='text-center textTableSize'>
                                                                <div className="input-group input-group-lg flex-nowrap">
                                                                    <input disabled={this.state.Header.POVendorNo === "0000000"} type="number" min={0} step={1} id={"APOQReceived_" + det.ItemCode} defaultValue={det.QuantityReceived} onChange={(e) => this.changesPrincipalAPO2(e, det, this.state.Header.POVendorNo, "QuantityReceived")} className="form-control text-center inputResize" />
                                                                </div>
                                                            </td>
                                                            <td className='text-end'>
                                                                <div className="input-group input-group-lg flex-nowrap">
                                                                    <span className="input-group-text">$</span>
                                                                    <input disabled={this.state.Header.POVendorNo === "0000000"} type="number" min={0} step={0.01} id={"AP_" + det.ItemCode} defaultValue={pricesFormat(det.UnitCost)} onChange={(e) => this.changesPrincipalAPO2(e, det, this.state.Header.POVendorNo, "Cost")} className="form-control text-center inputResize" />
                                                                </div>
                                                            </td>
                                                            <td className='text-end textTableSize'>$</td>
                                                            <td className='text-end textTableSize'>{NumberFormat(pricesFormat(det.totalCost))}</td>
                                                            <td className='text-center'> <button disabled={!det.NewItem} onClick={() => this.getDetailOfSalesPerItem(det, this.state.Header)} type="button" className="btn btn-info btn-lg">Detail</button></td>
                                                            <td hidden className='text-center textTableSize'>
                                                                <div className="d-grid gap-2">
                                                                    <button disabled={!det.NewItem} onClick={() => this.saveInPrincipalDispatchItems(det)} type="button" className="btn greenButton btn-lg">Save</button>
                                                                </div>
                                                            </td>
                                                            <td className='text-center textTableSize'>
                                                                <div className="d-grid gap-2">
                                                                    <button disabled={det.NewItem} onClick={() => this.DeleteAddedItem(this.state.Header.POVendorNo, det.ItemCode)} type="button" className="btn redButton btn-lg"><AiOutlineCloseCircle /></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                            <tfoot className='tfoot'>
                                                <tr className='bg-secondary text-light'>
                                                    <td></td>
                                                    <td className='textTableSize'>TOTAL:</td>
                                                    <td></td>
                                                    <td hidden></td>
                                                    <td hidden></td>
                                                    <td hidden></td>
                                                    <td className='text-center textTableSize'>{NumberFormat(this.state.Totals.QuantityOrdered)}</td>
                                                    <td className='text-center textTableSize'>{NumberFormat(this.state.Totals.QuantityReceived)}</td>
                                                    <td></td>
                                                    <td className='text-center textTableSize'>$</td>
                                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(this.state.Totals.TotalCost))}</td>
                                                    <td></td>
                                                    <td hidden></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className='col-1' />
                                    <div className='col-10'>
                                        <div className='row'>
                                            <div className='col-12 pb-3'>
                                                <div className="d-grid gap-2">
                                                    <button type="button" disabled={this.state.PurchaseOrderDetail.length === 0} onClick={() => this.saveAutomaticPurchaseOrder(0)} className="btn yellowButton btn-lg">Save <AiFillSave /></button>
                                                </div>
                                            </div>
                                            <div className='col-12 pb-3'>
                                                <div className="d-grid gap-2">
                                                    <button type="button" disabled={this.state.PurchaseOrderDetail.length === 0} onClick={() => this.saveAndChargePOToMAS90(1)} className="btn purpleButton btn-lg">Charge to MAS90 <AiFillSave /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-1' />
                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                <ModalOrders title={'Detail of Item: ' + this.state.currentItem.ItemCode + ' in All Invoices'} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Item Code:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.ItemCode}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Description:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.Description}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal '>
                                            <p>Units to add in Stock:</p>
                                        </div>
                                        <div className='col-12 TitleText text-center'>
                                            <p className='text-center'>{this.state.currentItem.Stock}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-12 pb-4'>
                                    <div className='row'>
                                        <div className='col-6 titleTotal'>
                                            <p>Quantity Received:</p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <input onChange={(e) => this.generalQuantity2(e)} className="form-control form-control-lg text-center" placeholder='Quantity Received' type="number" min={0} step={1} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 pb-4'>
                                    <input onChange={this.searchInvoiceAutomaticPO} className="form-control form-control-lg" placeholder='Search by Invoice No, Customer Name' type="text" />
                                </div>
                            </div>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-12 tableFixHead'>
                            <table className='table align-middle'>
                                <thead className='thead'>
                                    <tr className='text-light text-center'>
                                        <th className='bg-dark' >Sales Order No</th>
                                        <th className='bg-dark' >Customer Name</th>
                                        <th className='bg-dark' >Quantity Ordered</th>
                                        <th className='bg-dark' >Quantity Shipped</th>
                                        <th className='bg-dark' >Date</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {
                                        this.state.FilterItemOrders.map((det, d) => (
                                            <tr key={d}>
                                                <td className='text-start textTableSize'>{det.InvoiceNo}</td>
                                                <td className='text-start textTableSize'>{det.BillToName}</td>
                                                <td className='text-center textTableSize'>{NumberFormat(det.QuantityOrdered)}</td>
                                                <td>
                                                    <input id={'APOQuantityShipped_' + d} disabled={this.state.currentItem.disabled} onChange={(e) => this.changeQuantityShipped(e, det, this.state.currentItem.HeadInfo)} defaultValue={det.QuantityShipped} className="form-control form-control-lg text-center" placeholder='0' type="number" />

                                                </td>
                                                <td className='text-center textTableSize'>{DateFormatMAS90(det.InvoiceDate)}</td>
                                            </tr>
                                        ))

                                    }
                                </tbody>

                            </table>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-10 text-center'>
                            <div className="d-grid gap-2">
                                <button onClick={() => this.saveDispatchItems()} disabled={this.state.currentItem.disabled} type="button" className="btn greenButton btn-lg">Save Dispatch <AiFillSave /></button>
                            </div>
                        </div>
                        <div className='col-1'></div>
                    </div>
                </ModalOrders>
                <ModalOrders title={'Detail of Invoice: '} show={this.state.showModal2} close={(modal = "showModal2") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Invoice No:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentInvoice.InvoiceNo}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Total Quantity Ordered:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentInvoice.TotalQuantityOrdered}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Total Quantity Received:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentInvoice.TotalQuantityReceived}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Total Amount:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{NumberFormat(pricesFormat(this.state.currentInvoice.TotalAmtOrdered))}</p>
                                        </div>
                                    </div>
                                </div>



                                <div className='col-12 pb-4'>
                                    <input className="form-control form-control-lg" placeholder='Search by Invoice No, Customer Name' type="text" />
                                </div>
                            </div>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-12 tableFixHead'>
                            <table className='table align-middle'>
                                <thead className='thead'>
                                    <tr className='text-light text-center'>
                                        <th className='bg-dark' >Item Code</th>
                                        <th className='bg-dark' >Description</th>
                                        <th className='bg-dark' >Quantity Ordered</th>
                                        <th className='bg-dark' >Quantity Received</th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark'>Unit Cost</th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark'>Total Cost</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {
                                        this.state.currentFilterInvoiceDetail.map((item, i) => (
                                            <tr key={i}>
                                                <td className='text-start textTableSize'>{item.ItemCode}</td>
                                                <td className='text-start textTableSize'>{item.Description}</td>
                                                <td className='text-center textTableSize'>{item.QuantityOrdered}</td>
                                                <td className='text-center textTableSize'>{item.QuantityReceived}</td>
                                                <td className='text-end textTableSize'>$</td>
                                                <td className='text-end textTableSize'>{NumberFormat(pricesFormat(item.UnitCost))}</td>
                                                <td className='text-end textTableSize'>$</td>
                                                <td className='text-end textTableSize'>{NumberFormat(pricesFormat(item.totalCost))}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>

                    </div>
                </ModalOrders>
                <ModalOrders title={'Sales Order List: '} show={this.state.showModal3} close={(modal = "showModal3") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className="input-group input-group-lg flex-nowrap col-sm-12 pb-3">
                                    <input className='form-control w-75 display-inline' placeholder='Search by Sales Order No, Customer Name...' id='APOSalesOrderSearch' onKeyUp={this.searchSalesOrder} />
                                    <span className="input-group-text">
                                        <button className='btn greenButton btn-lg' onClick={() => this.getSalesOrder(true)}><BsArrowRepeat /></button>
                                    </span>
                                </div>
                                <div className="col-xs-2 pb-3">
                                    <div className="checkbox-inline">
                                        <label className="checkbox-inline TitleText">
                                            <input onChange={(e) => this.checkAllSO(e, this.state.selectAllSalesOrder)} className="form-check-input" type="checkbox" checked={this.state.selectAllSalesOrder} value="" id="APcheckAllSO" />
                                            Select All Sales Order
                                        </label>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table align-middle'>
                                <thead className='bg-primary text-center text-white thead'>
                                    <tr>
                                        <th className='bg-primary'>Order No</th>
                                        <th className='bg-primary'>Type</th>
                                        <th className='bg-primary'>Customer No</th>
                                        <th className='bg-primary'>Customer Name</th>
                                        <th className='bg-primary'>Sales Person</th>
                                        <th className='bg-primary'>Terms Code</th>
                                        <th className='bg-primary'>Date</th>
                                        <th className='bg-primary'></th>
                                        <th className='bg-primary'>Total Amount</th>
                                        <th className='bg-primary'>Select Order</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.salesOrderListFilter.map((element, e) => (
                                            <tr onClick={(e) => this.checkSpecificSalesOrder(e, element.Check, element.SalesOrderNo)} className='row_hover' key={e} >
                                                <td>{element.SalesOrderNo}</td>
                                                <td className='text-center'>{element.TypeinLetters}</td>

                                                {/*<td className={'text-center '+this.MAS90stateColor(element.MAS90Status)}>{this.Mas90stateInWords(element.MAS90Status)}</td>*/}
                                                <td className='text-center'>{element.CustomerNo}</td>
                                                <td className='text-start'>{element.BillToName}</td>

                                                <td className='text-center'>{element.SalespersonNo}</td>
                                                <td className='text-center'>{element.TermsCode}</td>
                                                <td className='text-center'>{DateFormatMAS90(element.OrderDate)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(element.NonTaxableAmt))}</td>
                                                <td className='text-center'>
                                                    <div className="row">
                                                        <div className="col-xs-2">
                                                            <div className="checkbox-inline">
                                                                <input className="form-check-input" type="checkbox" checked={element.Check} onChange={(e) => this.checkSpecificSalesOrder(e, element.Check, element.SalesOrderNo)} value="" id={element.SalesOrderNo} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>

                    </div>
                </ModalOrders>
                <ModalOrders title={'Purchase Order List: '} show={this.state.showModal4} close={(modal = "showModal4") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className="input-group input-group-lg flex-nowrap col-sm-12 pb-3">
                                    <input onKeyUp={this.searchPurchaseOrder} className='form-control w-75 display-inline' placeholder='Search by Purchase Order No, Vendor No, Vendor Name...' id='APOPurchaseOrderSearch' />
                                    <span className="input-group-text">
                                        <button onClick={() => this.getPurchaseOrderHeader()} className='btn greenButton btn-lg' ><BsArrowRepeat /></button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table align-middle'>
                                <thead className='bg-primary text-center text-white thead'>
                                    <tr>
                                        <th className='bg-primary'>{'Purchase\nOrder No'}</th>
                                        <th className='bg-primary'>Order Date</th>
                                        <th className='bg-primary'>Vendor No</th>
                                        <th className='bg-primary'>Vendor Name</th>
                                        <th className='bg-primary'>Invoice No</th>
                                        <th className='bg-primary'>{'Total Quantity\nReceived'}</th>
                                        <th className='bg-primary'></th>
                                        <th className='bg-primary'>Total Amount</th>
                                        <th className='bg-primary'>Status</th>
                                        <th className='bg-primary'>Last Update By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.HistoryPurchaseOrderHeaderFilter.map((element, e) => (
                                            <tr onClick={() => this.SelectPurchaseOrder(element)} key={e}>
                                                <td className='text-start'>{element.PurchaseOrderNo}</td>
                                                <td className='text-center'>{FormatQueryReturnDate(element.OrderDate)}</td>
                                                <td className='text-center'>{element.VendorNo}</td>
                                                <td className='text-center'>{element.VendorName}</td>
                                                <td className='text-center'>{element.InvoiceNo}</td>
                                                <td className='text-center'>{NumberFormat(element.TotalQuantity)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(element.TotalAmount))}</td>
                                                <td className={'text-center ' + this.statusPurchaseOrder(element.Status).color}>{this.statusPurchaseOrder(element.Status).text}</td>
                                                <td className='text-center'>{element.LastUpdateBy}</td>

                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>

                    </div>
                </ModalOrders>
            </div>
        )
    }
}