import React, { Component } from 'react'
import Select from 'react-select';
import Swal from "sweetalert2";
import "../css/general-style.css"
import '../css/table-responsive.css'
import { getValueCookie } from '../services/cookieService'
import { AiOutlineNumber } from "react-icons/ai";
import { BsFillPersonPlusFill } from "react-icons/bs";
import CustomerList from '../components/CustomerList';
import { create_Delete_Update_Information, getInformationWithData, getInformationWithDataGlobalSales } from '../services/CABE';
import { getIndexElement } from '../functions/searchInObject';
import { confirmCloseAlert } from '../functions/alerts';
import LoadingWindow from '../components/LoadingWindow';
import { generateRandomPassword } from '../functions/generalFunctions';


export default class NewCustomer extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }

    state = {
        Customer: {
            CustomerNo: '',
            CustomerName: '',
            EmailAddress: '',
            ContactName: '',
            TelephoneNo: '',
            PriceLevel: '1',
            Fax: '',
            AddressLine1: '',
            ZipCode: '',
            City: '',
            State: '',
            Country: '',
            SalespersonNo: getValueCookie('SalesPerson'),
            Taxable: 'NONTAX',
            TermsCode: '00',
            Route: getValueCookie('CompanyId')==='3'?'Supermarket':'Ruta1',
            New: 1,
            OrderType: 'customerManager',
            CreditLimit: 0
        },
        optionsSelect1: [{ value: 'NONTAX', label: 'NONTAX' }, { value: 'DEFAULT', label: 'DEFAULT' }],
        optionsSelect2: getValueCookie('CompanyId')==='3'? [
            { value: 'Supermarket', label: 'Supermarket' },
            { value: 'Pallet', label: 'Pallet' },
            { value: 'Warehouse', label: 'Warehouse' },
            { value: 'Distributor', label: 'Distributor' },
        ]:[
            { value: 'Ruta1', label: 'Ruta1' },
            { value: 'Ruta2', label: 'Ruta2' },
            { value: 'Ruta3', label: 'Ruta3' },
            { value: 'Ruta4', label: 'Ruta4' },
        ],
        optionsSelect3: getValueCookie('CompanyId')==='3'?[
            { value: '00', label: 'Pay in Advance' },
            { value: '01', label: 'FOB Taiwan' },
            { value: '07', label: 'Net 07 Days' },
            { value: '10', label: 'Net 10 Days' },
            { value: '15', label: 'Net 15 Days' },
            { value: '21', label: 'Net 21 Days' },
            { value: '30', label: 'Net 30 Days' },
            { value: '45', label: 'Net 45 Days' },
        ]:[
            { value: '00', label: 'Pay in Advance' }  
        ],
        optionsSelect4: getValueCookie('CompanyId')==='3'?[
            { value: '1', label: '01' },
            { value: '2', label: '02' },
            { value: '3', label: '03' }
        ]:
        [{ value: '1', label: '01' },
        { value: '2', label: '02' }],
        selectedOption1: { value: 'NONTAX', label: 'NONTAX' },
        selectedOption2: getValueCookie('CompanyId')==='3'?{ value: 'Supermarket', label: 'Supermarket' }:{ value: 'Ruta1', label: 'Ruta1' },
        selectedOption3: { value: '00', label: 'Pay in Advance' },
        selectedOption4: { value: '1', label: '01' },
        securityDisabled: getValueCookie('SalesPerson') === '0000' || getValueCookie('SalesPerson') === 'JC' ? false : true,
        PlatformCheck: false

    }

    async getNewCorrelativeNewCustomer() {
        this.ModalLoading.current.showState(true);
        await this.ClearCustomerForm()
        const data = {
            type: 'Customer',
            idCompany: Number(getValueCookie('CompanyId')),
            SalespersonNo: getValueCookie('SalesPerson')
        }



        const CustomerResult = await getInformationWithData('/customer/getList', data);

        while (true) {
            const result = await getInformationWithData('/sales/get/correlative', data)

            if (result.status.code === 1) {
                let temporal = CustomerResult.data.some((customer) => {
                    return Number(customer.CustomerNo) === Number(result.data[0].correlative)
                }
                )
                if (!temporal) {
                    const temporal = String(result.data[0].correlative).padStart(7,'0')
                    const temp = this.state.Customer
                    temp.CustomerNo = temporal
                    this.setState({ Customer: temp })
                    break
                }

            }

        }

        this.ModalLoading.current.showState(false);
    }

    onTarget = e => {
        const id = e.target.id
        const value = e.target.value
        const temporal = this.state.Customer
        switch (id) {
            case "CustomerNoNewCustomer":
                temporal.CustomerNo = value
                break;
            case "NameNewCustomer":
                temporal.CustomerName = value
                break;
            case "EmailAddressNewCustomer":
                temporal.EmailAddress = value
                break;
            case "ContactNameNewCustomer":
                temporal.ContactName = value
                break;
            case "PhoneNumberNewCustomer":
                temporal.TelephoneNo = value
                break;
            case "FaxNewCustomer":
                temporal.Fax = value
                break;
            case "AddressLineNewCustomer":
                temporal.AddressLine1 = value
                break;
            case "ZipCodeNewCustomer":
                temporal.ZipCode = value
                break;
            case "CityNewCustomer":
                temporal.City = value
                break;
            case "StateNameNewCustomer":
                temporal.State = value
                break;
            case "CountryNewCustomer":
                temporal.Country = value
                break;
            case "CreditLimitNewCustomer":
                temporal.CreditLimit = value
                break;
            default:
                break;
        }
        this.setState({ Customer: temporal })
    }
    async CreateCustomer() {
        if (getValueCookie('SalesPerson') !== '0000') {
            const temporal = this.state.Customer
            /*temporal.CreditLimit = 0
            temporal.PriceLevel = '1'
            temporal.TermsCode = '00'
            */
            this.setState({ Customer: temporal })
        }


        const data = {
            Customer: this.state.Customer,
            idcompany: getValueCookie('CompanyId'),
            SalespersonNo: getValueCookie('SalesPerson'),
            company: getValueCookie('Company'),
        }
        const result = await create_Delete_Update_Information('/customer/set', data)
        if (result.status.code === 1) {
            this.ClearCustomerForm()
            if (data.Customer.New === 0) {
                confirmCloseAlert('correct', 'The Customer was updated successfully!')
            } else {
                confirmCloseAlert('correct', 'The Customer was created successfully!')
            }

        }


    }

    ClearCustomerForm() {

        const clear = {
            CustomerNo: '',
            CustomerName: '',
            EmailAddress: '',
            ContactName: '',
            TelephoneNo: '',
            PriceLevel: '1',
            Fax: '',
            AddressLine1: '',
            ZipCode: '',
            City: '',
            State: '',
            Country: '',
            SalespersonNo: getValueCookie('SalesPerson'),
            Taxable: 'NONTAX',
            TermsCode: '00',
            Route: 'Supermarket',
            New: 1,
            OrderType: 'customerManager',
            CreditLimit: 0
        }
        this.setState({ Customer: clear,PlatformCheck: false })
        document.getElementById('CustomerNoNewCustomer').value = ''
        document.getElementById('NameNewCustomer').value = ''
        document.getElementById('EmailAddressNewCustomer').value = ''
        document.getElementById('ContactNameNewCustomer').value = ''
        document.getElementById('PhoneNumberNewCustomer').value = ''
        document.getElementById('FaxNewCustomer').value = ''
        document.getElementById('AddressLineNewCustomer').value = ''
        document.getElementById('ZipCodeNewCustomer').value = ''
        document.getElementById('CityNewCustomer').value = ''
        document.getElementById('StateNameNewCustomer').value = ''
        document.getElementById('CountryNewCustomer').value = ''
        document.getElementById('CreditLimitNewCustomer').value = 0
        this.StateSelectOption('selectedOption4', 'PriceLevel', 'optionsSelect4')
        this.StateSelectOption('selectedOption3', 'TermsCode', 'optionsSelect3')
        this.StateSelectOption('selectedOption2', 'Route', 'optionsSelect2')
        this.StateSelectOption('selectedOption1', 'Taxable', 'optionsSelect1')
    }
    setValues() {
        document.getElementById('CustomerNoNewCustomer').value = this.state.Customer.CustomerNo
        document.getElementById('NameNewCustomer').value = this.state.Customer.CustomerName
        document.getElementById('EmailAddressNewCustomer').value = this.state.Customer.EmailAddress
        document.getElementById('ContactNameNewCustomer').value = ''
        document.getElementById('PhoneNumberNewCustomer').value = this.state.Customer.TelephoneNo
        document.getElementById('FaxNewCustomer').value = this.state.Customer.Fax
        document.getElementById('AddressLineNewCustomer').value = this.state.Customer.AddressLine1
        document.getElementById('ZipCodeNewCustomer').value = this.state.Customer.ZipCode
        document.getElementById('CityNewCustomer').value = this.state.Customer.City
        document.getElementById('StateNameNewCustomer').value = this.state.Customer.State
        document.getElementById('CountryNewCustomer').value = this.state.Customer.Country
        document.getElementById('CreditLimitNewCustomer').value = this.state.Customer.CreditLimit
        this.StateSelectOption('selectedOption4', 'PriceLevel', 'optionsSelect4')
        this.StateSelectOption('selectedOption3', 'TermsCode', 'optionsSelect3')
        this.StateSelectOption('selectedOption2', 'Route', 'optionsSelect2')
        this.StateSelectOption('selectedOption1', 'Taxable', 'optionsSelect1')
    }
    StateSelectOption(key1, key2, key3) {
        const index = getIndexElement(this.state[key3], 'value', this.state.Customer[key2])

        if (index !== -1) {
            this.setState({ [key1]: this.state[key3][index] })
        } else {
            this.setState({ [key1]: this.state[key3][0] })
        }
    }

    onChangeSelects = (e, id) => {
        const temporal = this.state.Customer
        switch (id) {
            case "Route":
                temporal.Route = e.value
                this.setState({ selectedOption2: e })
                break;
            case "Tax":
                temporal.Taxable = e.value
                this.setState({ selectedOption1: e })
                break;
            case "TermsCode":
                temporal.TermsCode = e.value
                this.setState({ selectedOption3: e })
                break;
            case "PriceLevel":
                temporal.PriceLevel = e.value
                this.setState({ selectedOption4: e })
                break;
            default:
                break;
        }
        this.setState({ Customer: temporal })

    }

    PlatformChange(e) {
        if (this.state.Customer.EmailAddress) {
            if (this.state.PlatformCheck) {
                Swal.fire({
                    title: 'Attention Please!',
                    text: `Are you sure to ${this.state.PlatformCheck ? 'Disable' : 'Enable'} this Customer Profile`,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, please',
                    cancelButtonText: 'No, cancel!',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ PlatformCheck: !e.target.checked })
                        const data = {
                            Customer: this.state.Customer,
                            idcompany: getValueCookie('CompanyId'),
                            SalespersonNo: getValueCookie('SalesPerson'),
                            company: getValueCookie('Company'),
                        }
                        await create_Delete_Update_Information('/customer/set', data)
                    }
                })

            } else {

                Swal.fire({
                    title: 'Attention Please!',
                    text: "This Customer not have a user to Sales Portal.\nWould you like to create it?",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, please',
                    cancelButtonText: 'No, cancel!',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let password = generateRandomPassword()
                        const data2 = {
                            Customer: this.state.Customer,
                            idcompany: getValueCookie('CompanyId'),
                            SalespersonNo: getValueCookie('SalesPerson'),
                            company: getValueCookie('Company'),
                        }
                        await create_Delete_Update_Information('/customer/set', data2)
                        const data = {
                            Customer: {
                                CustomerNo: this.state.Customer.CustomerNo,
                                CustomerName: this.state.Customer.CustomerName,
                                AddressLine: this.state.Customer.AddressLine1,
                                City: this.state.Customer.City,
                                State: this.state.Customer.State,
                                ZipCode: this.state.Customer.ZipCode,
                                Country: this.state.Customer.Country,
                                Email: this.state.Customer.EmailAddress,
                                Password: password,
                                TelephoneNo: this.state.Customer.TelephoneNo,
                                SalespersonNo: this.state.Customer.SalespersonNo,
                                LevelPrice: this.state.Customer.PriceLevel,
                                RouteNo: this.routeCode(this.state.Customer.Route),
                                TermsCode: this.state.Customer.TermsCode,
                                CurrentLimit: this.state.Customer.CreditLimit,
                                CurrentBalance: 0
                            }

                        }
                        const result = await getInformationWithDataGlobalSales('/user/set', data)
                        
                        if(!result.Error){
                            Swal.fire({
                                title: 'Customer Info',
                                html:`<p>Username: ${this.state.Customer.EmailAddress}<br/>Password: ${password}</p>`,
                                icon: 'info',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            })
                            this.setState({ PlatformCheck: true })
                        }else{
                            confirmCloseAlert('incorrect', 'Whoops Something going wrong. Please ')
                            this.setState({ PlatformCheck: false })
                        }
                       


                        
                    } else {
                        this.setState({ PlatformCheck: false })
                    }
                })
            }
        } else {
            confirmCloseAlert('incorrect', 'To create or enable a Customer Profile you need a Email Address')
        }



    }

    routeCode(Route) {
        switch (Route) {
            case "Pallet":
                return 1
            case "Warehouse":
                return 2
            case "Distributor":
                return 3
            case "Supermarket":
                return 4
            default:
                return 1
        }
    }

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Customer Management</p>
                <div className='row pb-2'>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-10'>
                        <div className='row pt-2'>
                            <div className='col-sm-12'>
                                <CustomerList disabled={false} headerName='' nombrePadre="Customer" Padre={this} />
                            </div>
                        </div>
                        <div className='row pt-2'>
                            <div className='col-sm-6'>

                                <div className='col-sm-12 InputText'>
                                    <p>Customer No:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled type="text" autoComplete='off' className="form-control" id='CustomerNoNewCustomer' value={this.state.Customer.CustomerNo} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="d-grid gap-2">
                                        <button onClick={() => this.getNewCorrelativeNewCustomer()} type="button" className="btn greenButton btn-lg">New Customer No <AiOutlineNumber /></button>

                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Name:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='NameNewCustomer' defaultValue={this.state.Customer.CustomerName} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Email Address:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='EmailAddressNewCustomer' defaultValue={this.state.Customer.EmailAddress} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div hidden className='col-sm-12 InputText'>
                                    <p>Contact Name:</p>
                                </div>
                                <div hidden className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='ContactNameNewCustomer' onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Phone Number:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='PhoneNumberNewCustomer' defaultValue={this.state.Customer.TelephoneNo} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>FAX:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='FaxNewCustomer' onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Route:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <Select isDisabled={this.state.Customer.CustomerNo === ''} value={this.state.selectedOption2} options={this.state.optionsSelect2} className='fs-4 text-start' placeholder="Select option" onChange={(e) => this.onChangeSelects(e, "Route")} />
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Credit Limit:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg flex-nowrap">
                                        <span className="input-group-text">$</span>
                                        <input disabled={this.state.securityDisabled || this.state.Customer.CustomerNo === ''} type="number" autoComplete='off' className="form-control text-end" id='CreditLimitNewCustomer' min={0} step={0.01} defaultValue={this.state.Customer.CreditLimit} onChange={this.onTarget} />
                                    </div>

                                </div>
                                <div  className='col-sm-12 InputText'>
                                    <p>Online Platform:</p>

                                </div>
                                <div  className='col-sm-12 pb-3 text-center'>
                                    <div className="TitleText form-check input-group-lg form-switch form-check-inline">
                                        <input className="form-check-input checkboxSize" type="checkbox" id="ActiveCustomerPlatform" onChange={(e) => this.PlatformChange(e)} checked={this.state.PlatformCheck} />
                                    </div>
                                </div>

                            </div>

                            <div className='col-sm-6'>
                                <div className='col-sm-12 InputText'>
                                    <p>Address Line:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='AddressLineNewCustomer' defaultValue={this.state.Customer.AddressLine1} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Zip Code:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='ZipCodeNewCustomer' defaultValue={this.state.Customer.ZipCode} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>City:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='CityNewCustomer' defaultValue={this.state.Customer.City} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>State Name:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='StateNameNewCustomer' defaultValue={this.state.Customer.State} onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Country:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled={this.state.Customer.CustomerNo === ''} type="text" autoComplete='off' className="form-control" id='CountryNewCustomer' onChange={this.onTarget} />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Sales Person:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <div className="input-group input-group-lg">
                                        <input disabled type="text" autoComplete='off' value={this.state.Customer.SalespersonNo} className="form-control" id='SalesPersonNewCustomer' />
                                    </div>
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>TAXABLE:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <Select isDisabled={this.state.Customer.CustomerNo === ''} value={this.state.selectedOption1} options={this.state.optionsSelect1} className='fs-4 text-start' placeholder="Select option" onChange={(e) => this.onChangeSelects(e, "Tax")} />
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Terms Code:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <Select isDisabled={this.state.securityDisabled || this.state.Customer.CustomerNo === ''} value={this.state.selectedOption3} options={this.state.optionsSelect3} className='fs-4 text-start' placeholder="Select option" onChange={(e) => this.onChangeSelects(e, "TermsCode")} />
                                </div>
                                <div className='col-sm-12 InputText'>
                                    <p>Price Level:</p>
                                </div>
                                <div className='col-sm-12 pb-3'>
                                    <Select isDisabled={this.state.securityDisabled || this.state.Customer.CustomerNo === ''} value={this.state.selectedOption4} options={this.state.optionsSelect4} className='fs-4 text-start' placeholder="Select option" onChange={(e) => this.onChangeSelects(e, "PriceLevel")} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 pt-3 pb-3'>
                                <div className="d-grid gap-2">
                                    <button hidden={this.state.Customer.New === 0} type="button" onClick={() => this.CreateCustomer()} className="btn blueButton btn-lg">Create Customer <BsFillPersonPlusFill /> </button>
                                    <button hidden={this.state.Customer.New === 1} type="button" onClick={() => this.CreateCustomer()} className="btn yellowButton btn-lg">Update Customer <BsFillPersonPlusFill /> </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-1'></div>
                </div>

            </div>
        )
    }
}