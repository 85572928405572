import React, { Component } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { getIndexElement } from '../../functions/searchInObject';
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { MonthInLetters, NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';

import SmallModal from '../SmallModal';
import { DateFormatMAS90 } from '../../functions/dateFormat';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
        );
    }

    state = {
        MonthNo: 0,
        Invoices: [],
        Total: 0,
        Year: 0,
        showModal1: false,
        Route: ''
    }

    onClick = async (e, reference, Title) => {
        let Position = await getElementAtEvent(reference.current, e)
        const index = await getIndexElement(this.props.Data, 'Route', Title)

        if (index !== -1) {
            let temporal = this.props.Data[index].Detail[Position[0].index]
            if (Title !== 'Total') {
                this.setState({
                    MonthNo: temporal.MonthNo,
                    Invoices: temporal.Invoices.sort((a, b) => b.NonTaxableSalesAmt - a.NonTaxableSalesAmt),
                    Total: temporal.Total,
                    Year: temporal.Year,
                    Route: Title
                })
                this.handleModalOpen("showModal1")
            } else {
                let Data = [];
                for (const item of temporal.Invoices) {
                    const index2 = getIndexElement(Data, 'Route', item.UDF_RUTA)
                    if (index2 !== -1) {
                        Data[index2].Total += item.NonTaxableSalesAmt
                    } else {
                        Data.push({ Route: item.UDF_RUTA, Total: item.NonTaxableSalesAmt })
                    }
                }
                this.setState({
                    MonthNo: temporal.MonthNo,
                    Invoices: Data.sort((a, b) => b.Total - a.Total),
                    Total: temporal.Total,
                    Year: temporal.Year,
                    Route: Title
                })
                this.handleModalOpen("showModal1")
            }
        }
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({
            MonthNo: 0,
            Invoices: [],
            Total: 0,
            Year: 0,
            showModal1: false,
            Route: ''
        })
        this.setState({ [modal]: false })

    }

    render() {
        return (
            <>
                <div className='row '>
                    <div className='col-sm-12'>
                        <h5>{this.props.Title}</h5>
                    </div>
                    <div className='col-sm-12 pb-3 backgroundWhite'>
                        <div className='row'>
                            {
                                this.props.Data.map((element, e) => (
                                    <React.Fragment key={e}>
                                        <div className='col-sm-12 text-center'>
                                            <h5>{element.Route}</h5>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Bar ref={element.Graph.reference} onClick={(e) => this.onClick(e, element.Graph.reference, element.Route)} options={element.Graph.options} data={element.Graph.data} />
                                        </div>
                                        <div className='col-sm-6'>
                                            <Line options={element.Line.options} data={element.Line.data} />
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <SmallModal centered={true} size={'xl'} title={'Sales of Route: ' + this.state.Route + ` on ${MonthInLetters(this.state.MonthNo)} ${this.state.Year}`} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1' />
                        <div className='col-10'>
                            <div className="tableFixHeadSmall ">
                                {this.state.Route === 'Total' ?
                                    <table className='table align-middle'>
                                        <thead className='bg-primary text-white'>
                                            <tr>
                                                <th className='text-center bg-primary '>Route</th>
                                                <th className='text-center bg-primary '></th>
                                                <th className='text-center bg-primary '>Total</th>
                                                <th className='text-center bg-primary '>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Invoices.map((order, o) => (
                                                <tr key={o}>
                                                    <td className='text-center'>{order.Route}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(order.Total))}</td>
                                                    <td className='text-center'>{NumberFormat(pricesFormat(((order.Total) / (this.state.Total)) * 100)) + "%"}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot className='tfoot'>
                                            <tr className='bg-primary text-white'>
                                                <td className='text-center textTableSize'>Totals</td>
                                                <td className='textTableSize text-end'>$</td>
                                                <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>
                                                <td className='text-center textTableSize'>100 %</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    :
                                    <table className='table align-middle'>
                                        <thead className='bg-primary text-white'>
                                            <tr>
                                                <th className='text-center bg-primary '>Invoice No</th>
                                                <th className='text-center bg-primary '>Route</th>
                                                <th className='text-center bg-primary '>Type</th>
                                                <th className='text-center bg-primary '>Customer No</th>
                                                <th className='text-center bg-primary '>Customer Name</th>
                                                <th className='text-center bg-primary '>Sales Person</th>
                                                <th className='text-center bg-primary '>Date</th>
                                                <th className='text-center bg-primary '></th>
                                                <th className='text-center bg-primary '>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Invoices.map((order, o) => (
                                                <tr key={o}>
                                                    <td className='text-center'>{order.InvoiceNo}</td>
                                                    <td className='text-center'>{order.UDF_RUTA}</td>
                                                    <td className='text-center'>{order.InvoiceType}</td>
                                                    <td className='text-center'>{order.CustomerNo}</td>
                                                    <td className='text-center'>{order.BillToName}</td>
                                                    <td className='text-center'>{order.SalespersonNo}</td>
                                                    <td className='text-center'>{DateFormatMAS90(order.InvoiceDate)}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(order.NonTaxableSalesAmt))}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot className='tfoot'>
                                            <tr className='bg-primary text-white'>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='text-center textTableSize'>Totals</td>
                                                <td className='text-center textTableSize'>{this.state.TotalQuantity}</td>
                                                <td className='textTableSize text-end'>$</td>
                                                <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                }
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>
            </>
        )
    }
}
