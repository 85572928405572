//import { MonthInLetters } from "../../functions/generalFunctions";

import { getInformationWithData } from "../../services/CABE";
import { getValueCookie } from "../../services/cookieService";
import { GeneralFormatToSend, PurchaseByVendors } from "./Class";


export async function getPurchaseDetail(DateC) {
    let temp = new Date()
    let Start = new Date(temp.getFullYear(), temp.getMonth(), 1)
    let End = new Date(temp.getFullYear(), temp.getMonth() + 1, 0)

    if (DateC) {
        Start = new Date(DateC.getFullYear(), DateC.getMonth(), 1)
        End = new Date(DateC.getFullYear(), DateC.getMonth() + 1, 0)
    }
    
    const data = new GeneralFormatToSend(getValueCookie('CompanyId'), '', Start.toISOString().split('T')[0], End.toISOString().split('T')[0], (Start.getMonth() + 1))
    const result = await getInformationWithData('/purchase/get/Total/byVendor', data)
    
    let processData=new PurchaseByVendors()
    result.data.forEach((PurchaseOrder)=>(
        processData.addData(PurchaseOrder.VendorNo,PurchaseOrder.PurchaseName,PurchaseOrder.NonTaxableAmt,PurchaseOrder)
    ))
    processData.generateGraph()
    processData.Detail.sort((a, b) => b.Total - a.Total);
    return processData
}