import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import PieGraph from '../PieGraph';
import SmallModal from '../SmallModal';
import { getValueCookie } from '../../services/cookieService';


export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title: '',
        showModal1: false,
        Detail: [],
        Total: 0

    }
    clearStates() {
        this.setState({
            Detail: [],
            Title: '',
            Total: 0
        })
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearStates()
    }
    getTotals() {
        let total = 0
        for (const row of this.state.Detail) {
            total += row.NonTaxableSalesAmt
        }
        this.setState({ Total: total })
    }

    async getDetail(detail, title) {
        await this.setState({ Detail: detail.sort((a, b) => b.NonTaxableSalesAmt - a.NonTaxableSalesAmt), Title: title })
        await this.getTotals()
        await this.handleModalOpen("showModal1")
    }
    addMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData(this.props.SalesPerson, (this.props.Date.setMonth(this.props.Date.getMonth() + 1)))
        }

    }
    restMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData(this.props.SalesPerson, (this.props.Date.setMonth(this.props.Date.getMonth() - 1)))
        }
    }


    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h5>{this.props.Title}</h5>
                    </div>
                    <div className='col-sm-12 pb-2 backgroundWhite'>
                        <div className='pb-3' />
                        {getValueCookie('TypeUser') !== '1' ?
                            <div className='tableFixHeadSmall'>
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>

                                            <th className='bg-dark text-center' colSpan={7}>{this.props.Month}</th>

                                        </tr>
                                        <tr className='text-light text-center'>
                                            <th className=' bg-dark'>Route Type</th>
                                            <th className='bg-dark '></th>
                                            <th className='bg-dark '>Total Sales </th>
                                            <th className='bg-dark '>Percentage</th>
                                            <th className='bg-dark '></th>
                                            <th className='bg-dark '>{"Goal"}</th>
                                            <th className='bg-dark '>Complete Goal Percentage</th>
                                            <th hidden className='bg-dark '>Total of Sales</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.props.data.Information.map((element, e) => (
                                                <tr onClick={() => this.getDetail(element.Detail, element.Name)} key={e} className={element.Color}>
                                                    <td className=' text-start' >{element.Name}</td>
                                                    {
                                                        element.Data.map((amount, a) => (
                                                            <React.Fragment key={a + 585 + 5}>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(amount.Total))}</td>
                                                                <td className=' text-center'>{(pricesFormat(amount.Percentage)) + "%"}</td>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(amount.TotalMeta))}</td>
                                                                <td className=' text-center'>{(pricesFormat(amount.PercentageComplete)) + "%"}</td>

                                                            </React.Fragment>
                                                        ))
                                                    }
                                                    <td hidden className=' text-center' >{element.Detail.length}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className=''>
                                            <td className='text-center '>TOTAL</td>
                                            <React.Fragment>
                                                {this.props.data.Months.map((month, m) => (
                                                    <React.Fragment key={m}>
                                                        <td className=' text-end'>$</td>
                                                        <td className=' text-end'>{NumberFormat(pricesFormat(month.Total))}</td>
                                                        <td className=' text-center'>100%</td>
                                                        <td className=' text-end'>$</td>
                                                        <td className=' text-end'>{NumberFormat(pricesFormat(month.TotalMeta))}</td>
                                                        <td className=' text-center'>{month.TotalMeta > 0 ? NumberFormat(pricesFormat((month.Total / month.TotalMeta) * 100)) + "%" : "0.00%"}</td>

                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            :
                            <div className='tableFixHeadSmall'>
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th className='bg-dark text-center' colSpan={7}>
                                                <div className='row'>
                                                    <div className='col-2' onClick={() => this.restMonth()}><AiFillCaretLeft /></div>
                                                    <div className='col-8'>{this.props.Month}</div>
                                                    <div className='col-2' onClick={() => this.addMonth()}><AiFillCaretRight /></div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr className='text-light text-center'>
                                            <th className=' bg-dark'>Route Type</th>
                                            <th className='bg-dark '></th>
                                            <th className='bg-dark '>Total Sales </th>
                                            <th className='bg-dark '>Percentage</th>
                                            <th className='bg-dark '></th>
                                            <th className='bg-dark '>{"Goal"}</th>
                                            <th className='bg-dark '>Complete Goal Percentage</th>
                                            <th hidden className='bg-dark '>Total of Customer</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.props.data.Information.map((element, e) => (
                                                <tr onClick={() => this.getDetail(element.Detail, element.Name)} key={e} className={element.Color}>
                                                    <td className=' text-start' >{element.Name}</td>
                                                    {
                                                        element.Data.map((amount, a) => (
                                                            <React.Fragment key={a + 585 + 5}>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(amount.Total))}</td>
                                                                <td className=' text-center'>{(pricesFormat(amount.Percentage)) + "%"}</td>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(amount.TotalMeta))}</td>
                                                                <td className=' text-center'>{(pricesFormat(amount.PercentageComplete)) + "%"}</td>

                                                            </React.Fragment>
                                                        ))
                                                    }
                                                    <td hidden className=' text-start' >{element.Detail.length}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className=''>
                                            <td className='text-center '>TOTAL</td>
                                            <React.Fragment>
                                                {this.props.data.Months.map((month, m) => (
                                                    <React.Fragment key={m}>
                                                        <td className=' text-end'>$</td>
                                                        <td className=' text-end'>{NumberFormat(pricesFormat(month.Total))}</td>
                                                        <td className=' text-center'>100%</td>
                                                        <td className=' text-end'>$</td>
                                                        <td className=' text-end'>{NumberFormat(pricesFormat(month.TotalMeta))}</td>
                                                        <td className=' text-center'>{month.TotalMeta > 0 ? NumberFormat(pricesFormat((month.Total / month.TotalMeta) * 100)) + "%" : "0.00%"}</td>

                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        }
                    </div>
                    <div className='col-sm-12 pb-2 backgroundWhite'>
                        <p className='InputTextBolderSimple'>**The total sales does not include late invoices, spoilage, discounts and credit notes</p>
                    </div>
                    {

                        this.props.data.Months.map((element, e) => (
                            <React.Fragment key={e}>
                                <div className='col-sm-2 pt-3 backgroundWhite'></div>
                                <div className='col-sm-8 pt-3 backgroundWhite'>
                                    <PieGraph options={element.Options} data={element.DataSet} />
                                </div>
                                <div className='col-sm-2 pt-3 backgroundWhite'></div>
                            </React.Fragment>
                        ))
                    }
                </div>
                <SmallModal centered={true} size={'xl'} title={'Sales: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1' />
                        <div className='col-12'>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle'>
                                    <thead className='bg-primary text-white'>
                                        <tr>
                                            <th className='text-center bg-primary '>Invoice No</th>
                                            <th className='text-center bg-primary '>Invoice Type</th>
                                            <th className='text-center bg-primary '>Customer No</th>
                                            <th className='text-center bg-primary '>Customer Name</th>
                                            <th className='text-center bg-primary '>Route</th>
                                            <th className='text-center bg-primary '></th>
                                            <th className='text-center bg-primary '>Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Detail.map((order, o) => (
                                            <tr key={o}>
                                                <td className='text-center'>{order.InvoiceNo}</td>
                                                <td className='text-center'>{order.InvoiceType}</td>
                                                <td className='text-center'>{order.CustomerNo}</td>
                                                <td className='text-left'>{order.BillToName}</td>
                                                <td className='text-left'>{order.UDF_RUTA}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.NonTaxableSalesAmt))}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className='bg-primary text-white'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-center textTableSize'>Totals</td>
                                            <td className='text-center textTableSize'></td>
                                            <td className='textTableSize text-end'>$</td>
                                            <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>

                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>

            </>
        )
    }
}
