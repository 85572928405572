import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import "../../../css/inventory-style.css"
import { v4 as uuidv4 } from 'uuid';
import { getValueCookie } from '../../../services/cookieService';
import { getInformationWithData } from '../../../services/CABE';
//import { getIndexElement } from '../../../functions/searchInObject'

import LoadingWindow from '../../../components/LoadingWindow';
import { HeaderHistoryPurchaseOrder, Item } from './Class';
import { getIndexElement, getIndexElement2 } from '../../../functions/searchInObject';
import { ItemDesc, OrderArray } from '../../../functions/generalFunctions';
import ExcelDocument from '../../../components/ExcelDocument';
import { getDateFromReports } from '../../../functions/dateFormat';
import { getDataSet, getDataSetHistoryPO } from '../../../functions/generateDataSetExcel';
//import { confirmCloseAlert } from '../../../functions/alerts';
export default class HistoryPurchaseOrderView extends Component {
    constructor(props) {
        super(props)

        this.ModalLoading = React.createRef();

    }

    state = {
        HeadTable: {
            Year: [],
            Months: [],
            Dates: [],
            GeneralHeaders: []
        },
        NotProcessData: [],
        Data: [],
        FilterData: [],
        order: false,
        Totals: []
    }
    searchHistoryPO = async e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.Data
        } else {
            Filter = this.state.Data.filter((detail) => {
                if (
                    this.Contains(detail.ItemCode, searchValue) ||
                    this.Contains(detail.Description, searchValue) ||
                    this.Contains(detail.ProductLine, searchValue) ||
                    this.Contains(detail.VendorName, searchValue)
                ) {
                    return detail
                } else {
                    return null
                }
            })
        }
        await this.setState({ FilterData: Filter })
        await this.getTotals()
    }
    Contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }
    OrderInformation(array, key, type) {
        const order = OrderArray(array, key, this.state.order)
        this.setState({ [type]: order, order: !this.state.order })

    }
    getTotals() {
        const temporal = this.state.FilterData
        let Totals = []
        for (const item of temporal) {
            for (const order of item.Detail) {

                const index = getIndexElement(Totals, 'NoOrder', order.NoOrder)
                if (index === -1) {
                    let add = {
                        NoOrder: order.NoOrder,
                        Total: (order.QuantityReceived === -1 || (!order.QuantityReceived) ? 0 : order.QuantityReceived)
                    }
                    Totals.push(add)
                } else {
                    Totals[index].Total += (order.QuantityReceived === -1 || (!order.QuantityReceived) ? 0 : order.QuantityReceived)
                }
            }
        }
        this.setState({ Totals: Totals })
    }


    async getPurchaseOrderHistory() {
        this.ModalLoading.current.showState(true);
        const data = {
            Start: document.getElementById('startPurchaseHistoryDate').value,
            End: document.getElementById('endPurchaseHistoryDate').value,
            idCompany: Number(getValueCookie('CompanyId'))
        }
        const result = await getInformationWithData('/purchase/get/detail/reportTime', data)
        if (result.status.code === 1) {
            this.setState({ NotProcessData: result.data })
            let header = new HeaderHistoryPurchaseOrder()
            let body = []
            for (const order of result.data) {
                header.addDates(order.OrderDate, order.OrderNo, order.Comment)
                const index = getIndexElement2(body, 'ItemCode', 'VendorNo', order.ItemCode, order.VendorNo)
                if (index === -1) {
                    let product = new Item(order.ItemCode, '', '', order.VendorNo, order.VendorName)
                    product.addOrder(order.OrderNo, order.QuantityReceived ? order.QuantityReceived : -1, order.OrderDate, order.QuantityOrdered ? order.QuantityOrdered : -1)
                    body.push(product)
                } else {
                    body[index].addOrder(order.OrderNo, order.QuantityReceived ? order.QuantityReceived : -1, order.OrderDate, order.QuantityOrdered ? order.QuantityOrdered : -1)
                }

            }

            for (let det of body) {

                for (const hed of header.Dates) {
                    const index = getIndexElement2(det.Detail, 'NoOrder', 'Date', hed.OrderNo, hed.Date)

                    if (index === -1) {
                        det.addSpace(hed.OrderNo, hed.Date)
                    }
                }
                det.orderDetail(header.GeneralHeaders)
            }

            body = await ItemDesc(body, 'Description', 'ItemCode', true)
            console.log(body)
            this.setState({ HeadTable: header, FilterData: body, Data: body })
        }
        await this.getTotals()
        this.ModalLoading.current.showState(false);
    }

    SelectOrderInHistory(array, title, order,parameter) {
        let key = ''
        switch (title) {
            case "Item Code":
                key = "ItemCode"
                break;
            case "Description":
                key = "Description"
                break;
            case "Primary Vendor":
                key = "VendorName"
                break;
            case "Product Line":
                key = "ProductLine"
                break;
            default:
                key = "PO"
                break;
        }

        if (key !== 'PO') {
            this.OrderInformation(array, key, order)
        } else {
            this.OrderByPurchaseOrders(array, title, order,parameter)
        }
    }

    async OrderByPurchaseOrders(array, orderNo, order,key) {
        let m = array.length
        const index = getIndexElement(array[0].Detail, 'NoOrder', orderNo)
        for (let i = 0; i < m; i++) {
            for (let j = 0; j < m - 1 - i; j++) {
                if (index !== -1) {
                    if (order) {
                        if (array[j].Detail[index][key] > array[j + 1].Detail[index][key]) {
                            [array[j], array[j + 1]] = await [array[j + 1], array[j]];
                        }
                    } else {
                        if (array[j].Detail[index][key] < array[j + 1].Detail[index][key]) {
                            [array[j], array[j + 1]] = await [array[j + 1], array[j]];
                        }

                    }
                }
            }
        }
        await this.setState({ FilterData: array, order: !order })
    }


    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Report of History Purchase Orders</p>
                <div className='row pb-2'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <p>Start:</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='startPurchaseHistoryDate' type="date" />
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <ExcelDocument
                                            hidden={this.state.Data.length === 0}
                                            archname={'History Purchase Orders' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname2={['All Data History Purchase Orders ' + getValueCookie('Company')]}
                                            sheetname={'History Purchase Orders ' + getValueCookie('Company')}
                                            data={getDataSetHistoryPO(this.state.HeadTable, this.state.FilterData, this.state.Totals)}
                                            data2={[getDataSet(this.state.NotProcessData, ["OrderNo", "OrderDate", "VendorNo", "VendorName", "ItemCode", "QuantityOrdered", "QuantityReceived", "UnitCost", "OriginalUnitCost", "Status"], ["OrderNo", "OrderDate", "VendorNo", "VendorName", "ItemCode", "QuantityOrdered", "QuantityReceived", "UnitCost", "OriginalUnitCost", "Status"])]}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row TitleText'>

                                    <div className='col-12'>
                                        <p>End:</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='endPurchaseHistoryDate' type="date" />
                                        </div>
                                    </div>
                                    <div className='col-12 pb-4 InputTextBolder text-center'>
                                        <div className="d-grid gap-2">
                                            <button onClick={() => this.getPurchaseOrderHistory()} type="button" className="btn blueButton btn-lg">Reload <BsArrowRepeat /></button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pb-2'>
                            <div className='col-12'>
                                <div hidden={this.state.Data.length === 0} className="input-group input-group-lg pb-4" >
                                    <input onChange={this.searchHistoryPO} type="text" autoComplete='off' className="form-control" id='searchPurchaseHistoryDate' placeholder='Search Item by ItemCode, Line Product, Description...' />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-1'></div>
                    <div hidden={this.state.Data.length === 0} className='col-12 tableFixHead'>
                        <table className="table  align-middle">
                            <thead className='text-center'>
                                <tr className='bg-primary text-white'>
                                    <th className={'text-center bg-secondary'} colSpan={8}></th>
                                    {this.state.HeadTable.Year.map((element, e) => (
                                        <th className={'text-center bg-secondary'} key={uuidv4()} colSpan={element.SpanCol}>{element.Year}</th>
                                    ))}
                                </tr>
                                <tr className='bg-primary text-white'>
                                    <th className={'text-center bg-secondary'} colSpan={8}></th>
                                    {this.state.HeadTable.Months.map((element, e) => (
                                        <th className={'text-center bg-primary'} key={uuidv4()} colSpan={element.SpanCol}>{element.Month}</th>
                                    ))}
                                </tr>
                                <tr className='bg-primary text-white'>
                                    <th className={'text-center bg-secondary'} colSpan={8}></th>
                                    {this.state.HeadTable.Dates.map((element, e) => (
                                        <th colSpan={2} className={'text-center bg-primary'} key={uuidv4()} >{element.Date}</th>
                                    ))}
                                </tr>
                                <tr className='bg-primary text-white'>
                                    {this.state.HeadTable.GeneralHeaders.map((element, e) => (
                                        e <= 3 ?
                                            <>
                                                <th colSpan={2} key={uuidv4()} className={'text-center bg-primary'} >
                                                    <div className='row'><div className='col-12'></div><div className='col-12'></div></div>
                                                </th>
                                            </>
                                            :
                                            <th colSpan={2} key={uuidv4()} onClick={() => this.SelectOrderInHistory(this.state.FilterData, element.OrderNo, this.state.order)} className={'text-center bg-primary'} >
                                                <div className='row'><div className='col-12'>{element.OrderNo}</div><div className='col-12'>{element.Comment}</div></div>
                                            </th>

                                    ))}
                                </tr>
                                <tr className='bg-primary text-white'>
                                    {this.state.HeadTable.GeneralHeaders.map((element, e) => (
                                        e <= 3 ?
                                            <>
                                                <th colSpan={2} key={uuidv4()} onClick={() => this.SelectOrderInHistory(this.state.FilterData, element.OrderNo, this.state.order,"")} className={'text-center bg-primary'} >
                                                    <div className='row'><div className='col-12'>{element.OrderNo + element.Comment}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div>
                                                </th>
                                            </>
                                            :
                                            <>
                                                <th key={uuidv4()} onClick={() => this.SelectOrderInHistory(this.state.FilterData, element.OrderNo, this.state.order,"QuantityOrdered")} className={'text-center bg-primary'} >
                                                    <div className='row'><div className='col-12'>{"Quantity\nOrdered"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div>
                                                </th>
                                                <th key={uuidv4()} onClick={() => this.SelectOrderInHistory(this.state.FilterData, element.OrderNo, this.state.order,"QuantityReceived")} className={'text-center bg-primary'} >
                                                    <div className='row'><div className='col-12'>{"Quantity\nReceived"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div>
                                                </th>
                                            </>


                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.FilterData.map((element, e) => (
                                    <tr key={uuidv4()}>
                                        <td colSpan={2} className='text-start'>{element.ItemCode}</td>
                                        <td colSpan={2} className='text-start'>{element.Description}</td>
                                        <td colSpan={2} className='text-start'>{element.VendorName}</td>
                                        <td colSpan={2} className='text-start'>{element.ProductLine}</td>
                                        {element.Detail.map((orders, o) => (
                                            <>
                                                <td key={uuidv4()} className='text-center'>{(!(orders.QuantityOrdered) && orders.QuantityOrdered !== 0) ? '0' : (orders.QuantityOrdered === -1 ? '0' : orders.QuantityOrdered)}</td>
                                                <td key={uuidv4()} className='text-center'>{(!(orders.QuantityReceived) && orders.QuantityReceived !== 0) ? '0' : (orders.QuantityReceived === -1 ? '0' : orders.QuantityReceived)}</td>
                                            </>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot className='tfoot'>
                                <tr className='bg-dark text-white'>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-center textTableSize'>TOTALS</td>
                                    {this.state.Totals.map((total, t) => (
                                        <td className='text-center textTableSize' key={uuidv4()}>{total.Total}</td>
                                    ))}
                                </tr>

                            </tfoot>
                        </table>

                    </div>
                </div>



            </div>
        )
    }

}