import { DeleteCharacter } from "./generalFunctions"

export function getDataSet(info, headers, keys) {
    var dataset = [{
        columns: [],
        data: []
    }]


    for (const column of headers) {
        const data = {
            title: column, width: { wch: 30 },
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "FFFFFF" }
                },
                font: {
                    color: { rgb: "FFFFFF" }
                }
            }
        }

        dataset[0].columns.push(data)
    }

    for (const data of info) {
        var temp = []
        for (const key of keys) {
            let val2
            if (data[key] || (data[key] === 0)) {
                if (isNaN(Number(data[key]))) {
                    val2 = String(data[key])
                } else {
                    val2 = Number(data[key])
                }
            } else {
                val2 = "null"
            }

            const col = {
                value: (val2 === "null" || val2 === "undefined") ? ' ' : val2,
                style: {
                    font: { sz: "10" },
                    alignment: { horizontal: "left" }
                }
            }
            if(key==="UnitPrice"){
               col.style.numFmt= "$0.00"
               col.style.alignment.horizontal="right"
            }
            
            temp.push(col)
        
        }
        dataset[0].data.push(temp)

    }

    return dataset
}

export function getDataSetShorts(years, months, realTitles, alldata, totals) {

    const tableHeader = {
        columns: [{ title: " " }, { title: " " }, { title: " " }, { title: " " }],
        data: [[{ value: " " }, { value: " " }, { value: " " }, { value: " " }]]
    }

    var flag = true
    for (const year of years) {
        if (year.year !== '') {
            var middle = (year.span / 2)
            for (let a = 1; a < (year.span - 1); a++) {

                if (Math.trunc(middle) === a) {
                    tableHeader.columns.push({
                        title: year.year, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                } else {
                    tableHeader.columns.push({
                        title: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            }
                            ,
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                }

            }
            flag = !flag
        }

    }
    var cont = 0
    var advance = 0
    var flag2 = true
    for (let a = 0; a < tableHeader.data[0].length; a++) {
        if (a >= 4) {
            if (cont === 0 && advance < months.length) {
                tableHeader.data[0][a].value = months[advance].month
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "BC8F8F"
                }
                advance++
            }

            if (cont === 1) {
                cont = 0
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
                flag2 = !flag2
            } else {
                cont++
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
            }
        }

    }
    var data2 = []
    var count4 = 0
    cont = 1
    flag = true
    for (const el of realTitles) {
        for (const title of el.title) {
            if (title !== '') {
                data2.push({
                    value: title,
                    style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "00008B" : count4 <= 3 ? "708090" : "000000" },
                            bgColor: { rgb: "000000" }
                        }
                        ,
                        font: {
                            color: { rgb: "FFFFFF" },
                            bold: true
                        }
                    }
                })
            }

            if (flag) {
                cont++
            }

            if (cont === 5) {
                flag = false
            }
            if (!flag) {
                if (count4 === 6) {
                    count4 = 1
                } else {
                    count4++
                }
            }
        }


    }

    tableHeader.data.push(data2)

    var ident = 1
    flag = true
    for (const item of alldata) {
        var data3 = [{
            value: ident, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }, {
            value: item.ItemCode,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }

        }, {
            value: item.ProductLine,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }, {
            value: item.ItemCodeDesc,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }]
        ident++
        for (const year of item.Detail) {
            for (const month of year.Months) {
                if (month.shorts === '-') {
                    for (let c = 0; c < 2; c++) {
                        data3.push({
                            value: '0', style: {
                                alignment: { horizontal: "center" },
                                fill: {
                                    patternType: "solid",
                                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                    bgColor: { rgb: "000000" }
                                }

                            }
                        })
                    }
                } else {
                    data3.push({
                        value: month.shorts, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                    /*data3.push({
                        value: month.dolar, style: {
                            alignment: { horizontal: "right" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })*/
                    data3.push({
                        value: month.SalesPrice, style: {
                            alignment: { horizontal: "right" },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                }
            }
        }

        tableHeader.data.push(data3)
        flag = !flag
    }
    let totalsdoc = [{
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: "TOTALS", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }]
    flag = true
    for (const total of totals) {
        if (total !== '$') {
            if (flag) {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "center" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            } else {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "right" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            }
        }
    }

    tableHeader.data.push(totalsdoc)

    return [tableHeader]

}


export function getDataSetSales(years, months, realTitles, alldata, totals) {

    const tableHeader = {
        columns: [{ title: " " }, { title: " " }, { title: " " }, { title: " " }],
        data: [[{ value: " " }, { value: " " }, { value: " " }, { value: " " }]]
    }

    var flag = true
    for (const year of years) {
        if (year.year !== '') {
            var middle = (year.span / 2)
            for (let a = 1; a < (year.span - 1); a++) {

                if (Math.trunc(middle) === a) {
                    tableHeader.columns.push({
                        title: year.year, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                } else {
                    tableHeader.columns.push({
                        title: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            }
                            ,
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                }

            }
            flag = !flag
        }

    }
    var cont = 0
    var advance = 0
    var flag2 = true
    for (let a = 0; a < tableHeader.data[0].length; a++) {
        if (a >= 4) {
            if (cont === 0 && advance < months.length) {
                tableHeader.data[0][a].value = months[advance].month
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "BC8F8F"
                }
                advance++
            }

            if (cont === 1) {
                cont = 0
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
                flag2 = !flag2
            } else {
                cont++
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
            }
        }

    }
    var data2 = []
    var count4 = 0
    cont = 1
    flag = true
    for (const el of realTitles) {
        for (const title of el.title) {
            if (title !== '') {
                data2.push({
                    value: title,
                    style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "00008B" : count4 <= 3 ? "708090" : "000000" },
                            bgColor: { rgb: "000000" }
                        }
                        ,
                        font: {
                            color: { rgb: "FFFFFF" },
                            bold: true
                        }
                    }
                })
            }

            if (flag) {
                cont++
            }

            if (cont === 5) {
                flag = false
            }
            if (!flag) {
                if (count4 === 6) {
                    count4 = 1
                } else {
                    count4++
                }
            }
        }


    }

    tableHeader.data.push(data2)

    var ident = 1
    flag = true
    for (const item of alldata) {
        var data3 = [{
            value: ident, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }, {
            value: item.ItemCode,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }

        }, {
            value: item.ProductLine,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }, {
            value: item.ItemCodeDesc,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }]
        ident++
        for (const year of item.Detail) {
            for (const month of year.Months) {
                if (month.QuantityShipped === '-') {
                    for (let c = 0; c < 2; c++) {
                        data3.push({
                            value: '0', style: {
                                alignment: { horizontal: "center" },
                                fill: {
                                    patternType: "solid",
                                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                    bgColor: { rgb: "000000" }
                                }

                            }
                        })
                    }
                } else {
                    data3.push({
                        value: month.QuantityShipped, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                    /*data3.push({
                        value: month.dolar, style: {
                            alignment: { horizontal: "right" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })*/
                    data3.push({
                        value: month.shorts, style: {
                            alignment: { horizontal: "right" },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                }
            }
        }

        tableHeader.data.push(data3)
        flag = !flag
    }
    let totalsdoc = [{
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: "TOTALS", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }]
    flag = true
    for (const total of totals) {
        if (total !== '$') {
            if (flag) {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "center" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            } else {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "right" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            }
        }
    }

    tableHeader.data.push(totalsdoc)

    return [tableHeader]

}

export function getDataSetSales2(years, months, realTitles, alldata, totals) {

    const tableHeader = {
        columns: [{ title: " " }, { title: " " }, { title: " " }],
        data: [[{ value: " " }, { value: " " }, { value: " " }]]
    }

    var flag = true
    for (const year of years) {
        if (year.year !== '') {
            var middle = (year.span / 2)
            for (let a = 1; a < (year.span - 1); a++) {

                if (Math.trunc(middle) === a) {
                    tableHeader.columns.push({
                        title: year.year, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                } else {
                    tableHeader.columns.push({
                        title: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            }
                            ,
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                }

            }
            flag = !flag
        }

    }
    var cont = 0
    var advance = 0
    var flag2 = true
    for (let a = 0; a < tableHeader.data[0].length; a++) {
        if (a >= 4) {
            if (cont === 0 && advance < months.length) {
                tableHeader.data[0][a].value = months[advance].month
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "BC8F8F"
                }
                advance++
            }

            if (cont === 1) {
                cont = 0
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
                flag2 = !flag2
            } else {
                cont++
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
            }
        }

    }
    var data2 = []
    var count4 = 0
    cont = 1
    flag = true
    for (const el of realTitles) {
        for (const title of el.title) {
            if (title !== '') {
                data2.push({
                    value: title,
                    style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "00008B" : count4 <= 3 ? "708090" : "000000" },
                            bgColor: { rgb: "000000" }
                        }
                        ,
                        font: {
                            color: { rgb: "FFFFFF" },
                            bold: true
                        }
                    }
                })
            }

            if (flag) {
                cont++
            }

            if (cont === 5) {
                flag = false
            }
            if (!flag) {
                if (count4 === 6) {
                    count4 = 1
                } else {
                    count4++
                }
            }
        }


    }

    tableHeader.data.push(data2)

    var ident = 1
    flag = true
    for (const item of alldata) {
        var data3 = [{
            value: ident, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }, {
            value: item.CustomerNo,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }

        }, {
            value: item.CustomerName,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }]
        ident++
        for (const year of item.Detail) {
            for (const month of year.Months) {
                if (month.QuantityShipped === '-') {
                    for (let c = 0; c < 2; c++) {
                        data3.push({
                            value: '0', style: {
                                alignment: { horizontal: "center" },
                                fill: {
                                    patternType: "solid",
                                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                    bgColor: { rgb: "000000" }
                                }

                            }
                        })
                    }
                } else {
                    data3.push({
                        value: month.QuantityShipped, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                    /*data3.push({
                        value: month.dolar, style: {
                            alignment: { horizontal: "right" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })*/
                    data3.push({
                        value: month.shorts, style: {
                            alignment: { horizontal: "right" },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                }
            }
        }

        tableHeader.data.push(data3)
        flag = !flag
    }
    let totalsdoc = [{
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: "TOTALS", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }]
    flag = true
    for (const total of totals) {
        if (total !== '$') {
            if (flag) {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "center" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            } else {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "right" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            }
        }
    }

    tableHeader.data.push(totalsdoc)

    return [tableHeader]

}

export function getDataSetSales3(years, months, realTitles, alldata, totals) {

    const tableHeader = {
        columns: [{ title: " " }, { title: " " }, { title: " " }],
        data: [[{ value: " " }, { value: " " }, { value: " " }]]
    }

    var flag = true
    for (const year of years) {
        if (year.year !== '') {
            var middle = (year.span / 4)
            for (let a = 1; a <= Math.trunc(year.span / 2); a++) {

                if (Math.trunc(middle) === a || Math.trunc(middle) === 0) {
                    tableHeader.columns.push({
                        title: year.year, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                } else {
                    tableHeader.columns.push({
                        title: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            }
                            ,
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                }

            }
            flag = !flag
        }

    }
    var cont = 0
    var advance = 0
    var flag2 = true
    for (let a = 0; a < tableHeader.data[0].length; a++) {
        if (a >= 3) {
            if (cont === 0 && advance < months.length) {
                tableHeader.data[0][a].value = months[advance].month
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "BC8F8F"
                }
                advance++
            }

            if (cont === 0) {
                cont = 0
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
                flag2 = !flag2
            } else {
                cont++
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
            }
        }

    }
    var data2 = []
    var count4 = 0
    cont = 1
    flag = true
    for (const el of realTitles) {
        for (const title of el.title) {
            if (title !== '') {
                data2.push({
                    value: title,
                    style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "00008B" : count4 <= 3 ? "708090" : "000000" },
                            bgColor: { rgb: "000000" }
                        }
                        ,
                        font: {
                            color: { rgb: "FFFFFF" },
                            bold: true
                        }
                    }
                })
            }

            if (flag) {
                cont++
            }

            if (cont === 5) {
                flag = false
            }
            if (!flag) {
                if (count4 === 6) {
                    count4 = 1
                } else {
                    count4++
                }
            }
        }


    }

    tableHeader.data.push(data2)

    var ident = 1
    flag = true
    for (const item of alldata) {
        var data3 = [{
            value: ident, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }, {
            value: item.CustomerNo,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }

        }, {
            value: item.CustomerName,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }]
        ident++
        for (const year of item.Detail) {
            for (const month of year.Months) {
                if (month.QuantityShipped !== '-') {

                    data3.push({
                        value: month.shorts, style: {
                            alignment: { horizontal: "right" },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                }
            }
        }

        tableHeader.data.push(data3)
        flag = !flag
    }
    let totalsdoc = [{
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: "TOTALS", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }]
    flag = true
    for (const total of totals) {
        if (total !== '$') {
            if (flag) {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "center" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            } else {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "right" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            }
        }
    }

    tableHeader.data.push(totalsdoc)

    return [tableHeader]

}

export function getDataSetSales4(years, months, realTitles, alldata, totals) {

    const tableHeader = {
        columns: [{ title: " " }, { title: " " }, { title: " " }],
        data: [[{ value: " " }, { value: " " }, { value: " " }]]
    }

    var flag = true
    for (const year of years) {
        if (year.year !== '') {
            var middle = (year.span / 4)



            for (let a = 1; a <= Math.trunc(year.span / 2); a++) {

                if (Math.trunc(middle) === a || Math.trunc(middle) === 0) {
                    tableHeader.columns.push({
                        title: year.year, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                } else {
                    tableHeader.columns.push({
                        title: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            }
                            ,
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                }

            }
            flag = !flag
        }

    }
    var cont = 0
    var advance = 0
    var flag2 = true
    for (let a = 0; a < tableHeader.data[0].length; a++) {
        if (a >= 3) {

            if (cont === 0 && advance < months.length) {
                tableHeader.data[0][a].value = months[advance].month
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "BC8F8F"
                }
                advance++
            }

            if (cont === 0) {
                cont = 0
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
                flag2 = !flag2
            } else {
                cont++
                if (flag2) {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "708090"
                } else {
                    tableHeader.data[0][a].style.fill.fgColor.rgb = "000000"
                }
            }
        }

    }
    var data2 = []
    var count4 = 0
    cont = 1
    flag = true
    for (const el of realTitles) {
        for (const title of el.title) {
            if (title !== '') {
                data2.push({
                    value: title,
                    style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "00008B" : count4 <= 3 ? "708090" : "000000" },
                            bgColor: { rgb: "000000" }
                        }
                        ,
                        font: {
                            color: { rgb: "FFFFFF" },
                            bold: true
                        }
                    }
                })
            }

            if (flag) {
                cont++
            }

            if (cont === 3) {
                flag = false
            }
            if (!flag) {
                if (count4 === 6) {
                    count4 = 1
                } else {
                    count4++
                }
            }
        }


    }

    tableHeader.data.push(data2)

    var ident = 1
    flag = true
    for (const item of alldata) {
        var data3 = [{
            value: ident, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }, {
            value: item.ItemCode,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }

        }, {
            value: item.ItemCodeDesc,
            style: {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                    bgColor: { rgb: "000000" }
                }
            }
        }]
        ident++
        for (const year of item.Detail) {
            for (const month of year.Months) {
                if (month.QuantityShipped !== '-') {

                    data3.push({
                        value: month.shorts, style: {
                            alignment: { horizontal: "right" },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    })
                }
            }
        }

        tableHeader.data.push(data3)
        flag = !flag
    }
    let totalsdoc = [{
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: " ", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }, {
        value: "TOTALS", style: {
            alignment: { horizontal: "center" },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },

            fill: {
                patternType: "solid",
                fgColor: { rgb: "1E1750" },
                bgColor: { rgb: "000000" }
            }
        }
    }]
    flag = true
    for (const total of totals) {
        if (total !== '$') {
            if (flag) {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "center" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            } else {
                totalsdoc.push(
                    {
                        value: Number(DeleteCharacter(total, ',')), style: {
                            alignment: { horizontal: "right" },
                            font: {
                                bold: true,
                                color: { rgb: "FFFFFF" }
                            },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "1E1750" },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
                flag = !flag
            }
        }
    }

    tableHeader.data.push(totalsdoc)

    return [tableHeader]

}


export function ReportPriceMargin(header, body, simbolMoney) {
    const dataSet = {
        columns: [],
        data: []
    }
    var constHeaders = ['Item Code', 'Description', 'Product Line', ' ', 'Last Unit Cost']
    constHeaders.forEach(title => {
        dataSet.columns.push({
            title: title, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        })
    })
    var flag = true
    for (const head of header) {
        dataSet.columns.push({
            title: " ", width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: head.Title1, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: head.Title2, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        })
        flag = !flag
    }

    flag = true
    for (const item of body) {
        let temporal = []
        temporal.push(
            {
                value: item.ItemCode, style: {
                    alignment: { horizontal: "left" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                        bgColor: { rgb: "000000" }
                    }
                }
            },
            {
                value: item.ProductLine, style: {
                    alignment: { horizontal: "left" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                        bgColor: { rgb: "000000" }
                    }
                }
            },
            {
                value: item.ItemCodeDesc, style: {
                    alignment: { horizontal: "left" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                        bgColor: { rgb: "000000" }
                    }
                }
            },

            {
                value: simbolMoney, style: {
                    alignment: { horizontal: "right" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                        bgColor: { rgb: "000000" }
                    }
                }
            },
            {
                value: item.UnitCost, style: {
                    alignment: { horizontal: "right" },
                    numFmt: "0.00",
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                        bgColor: { rgb: "000000" }
                    }
                }
            }
        )
        for (const level of item.PricesLevels) {
            temporal.push(
                {
                    value: level.LevelCost !== '-' ? simbolMoney : '$', style: {
                        alignment: { horizontal: "right" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },
                {
                    value: level.LevelCost !== '-' ? Number(Number(level.LevelCost).toFixed(2)) : '0', style: {
                        alignment: { horizontal: level.LevelCost !== '-' ? "right" : "center" },
                        numFmt: "0.00",
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },
                {
                    value: level.RealMargin !== '-' ? Number(level.RealMargin).toFixed(2) + "%" : '0', style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: flag ? "FFFFFF" : "EAEAEA" },
                            bgColor: { rgb: "000000" }
                        }
                    }
                }
            )
        }


        dataSet.data.push(temporal)
        flag = !flag
    }


    return [dataSet]



    /* return  [
         {
             columns: [{title:"Name"}, {title:"Salary"}, {title:"Sex"}],
             data: [
                 [{ value:"Johnson"}, {value:30000}, {value:"Male"}],
                 [{value:"Monika"}, {value:355000}, {value:"Female"}],
                 [{value:"Konstantina"}, {value:20000}, {value:"Female"}],
                 [{value:"John"}, {value:250000}, {value:"Male"}],
                 [{value:"Josef"}, {value:450500}, {value:"Male"}],
             ]
         },
         
         {
             xSteps: 1, // Will start putting cell with 1 empty cell on left most
             ySteps: 5, //will put space of 5 rows,
             columns: [{title:"Name"}, {title:"Department"}],
             data: [
                 [{value:"Johnson"}, {value:"Finance"}],
                 [{value:"Monika"}, {value:"IT"}],
                 [{value:"Konstantina"}, {value:"IT Billing"}],
                 [{value:"John"}, {value:"HR"}],
                 [{value:"Josef"}, {value:"Testing"}],
             ]
         }
     ];*/
}


export function getDataSetHistoryPO(header, body, Totals) {

    const tableHeader = {
        columns: [{ title: " ", width: { wch: 20 } }, { title: " ", width: { wch: 40 } }, { title: " ", width: { wch: 40 } }, { title: " ", width: { wch: 40 } }],
        data: [[{ value: " " }, { value: " " }, { value: " " }, { value: " " }]]
    }

    var flag = true
    for (const year of header.Year) {
        if (year.Year !== '') {
            let middle = (year.SpanCol / 2)
            for (let a = 0; a < (year.SpanCol); a++) {

                if (Math.trunc(middle) === a) {
                    tableHeader.columns.push({
                        title: year.Year, style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                } else {
                    tableHeader.columns.push({
                        title: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: flag ? "FF8C00" : "808000" },
                                bgColor: { rgb: "000000" }
                            }
                            ,
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                    tableHeader.data[0].push({
                        value: " ", style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "" },
                                bgColor: { rgb: "" }
                            },
                            font: {
                                color: { rgb: "FFFFFF" },
                                bold: true
                            }
                        }
                    })
                }

            }
            flag = !flag
        }

    }
    flag = true
    let pos = 4
    for (const month of header.Months) {
        if (month.Month !== '') {
            let middle = (month.SpanCol / 2)
            for (let a = 0; a < (month.SpanCol); a++) {

                if (Math.trunc(middle) === a) {
                    tableHeader.data[0][pos].value = month.Month
                    if (flag) {
                        tableHeader.data[0][pos].style.fill.fgColor.rgb = "708090"
                    } else {
                        tableHeader.data[0][pos].style.fill.fgColor.rgb = "000000"
                    }
                } else {
                    if (flag) {
                        tableHeader.data[0][pos].style.fill.fgColor.rgb = "708090"
                    } else {
                        tableHeader.data[0][pos].style.fill.fgColor.rgb = "000000"
                    }
                }
                pos++
            }
            flag = !flag
        }

    }
    let includeData = [{ value: " " }, { value: " " }, { value: " " }, { value: " " }]
    for (const date of header.Dates) {
        if (date.Date !== '') {
            includeData.push({
                value: date.Date, style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "0F005C" },
                        bgColor: { rgb: "" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        bold: true
                    }
                }
            })
            includeData.push({
                value: " ", style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "0F005C" },
                        bgColor: { rgb: "" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        bold: true
                    }
                }
            })
        }

    }
    tableHeader.data.push(includeData)
    includeData = []
    let index = 0
    for (const title of header.GeneralHeaders) {
        if (title !== '') {
            includeData.push({
                value: index < 4 ? " " : title.OrderNo, style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "0F005C" },
                        bgColor: { rgb: "" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        bold: true
                    }
                }
            })
            if (index > 3) {
                includeData.push({
                    value: title.Comment, style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "0F005C" },
                            bgColor: { rgb: "" }
                        },
                        font: {
                            color: { rgb: "FFFFFF" },
                            bold: true
                        }
                    }
                })
            }
            index++

        }

    }
    tableHeader.data.push(includeData)
    includeData = []
    index = 0
    for (const title of header.GeneralHeaders) {
        if (title !== '') {
            includeData.push({
                value: index < 4 ? title.OrderNo : "Quantity Ordered", style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "0F005C" },
                        bgColor: { rgb: "" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        bold: true
                    }
                }
            })
            if (index > 3) {
                includeData.push({
                    value: "Quantity Received", style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "0F005C" },
                            bgColor: { rgb: "" }
                        },
                        font: {
                            color: { rgb: "FFFFFF" },
                            bold: true
                        }
                    }
                })
            }
            index++

        }

    }
    tableHeader.data.push(includeData)

    for (const item of body) {
        includeData = []
        includeData.push({
            value: item.ItemCode, style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFFFF" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        includeData.push({
            value: item.Description, style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFFFF" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        includeData.push({
            value: item.VendorName, style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFFFF" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        includeData.push({
            value: item.ProductLine, style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFFFF" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        for (const orders of item.Detail) {
            includeData.push({
                value: (!(orders.QuantityOrdered) && orders.QuantityOrdered !== 0) ? '-' : (orders.QuantityOrdered === -1 ? '0' : orders.QuantityOrdered), style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "FFFFFF" },
                        bgColor: { rgb: "" }
                    },
                    font: {
                        color: { rgb: "000000" },
                        bold: false
                    }
                }
            })
            includeData.push({
                value: (!(orders.QuantityReceived) && orders.QuantityReceived !== 0) ? '-' : (orders.QuantityReceived === -1 ? '0' : orders.QuantityReceived), style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "FFFFFF" },
                        bgColor: { rgb: "" }
                    },
                    font: {
                        color: { rgb: "000000" },
                        bold: false
                    }
                }
            })
        }
        tableHeader.data.push(includeData)
    }

    includeData = [
        {
            value: " ", style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: false
                }
            }
        },
        {
            value: " ", style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: false
                }
            }
        },
        {
            value: " ", style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: false
                }
            }
        },
        {
            value: "TOTALS:", style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }
    ]



    for (const total of Totals) {
        includeData.push({
            value: total.Total, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        })
    }
    tableHeader.data.push(includeData)

    return [tableHeader]

}



export function ReportChangePriceMargin(header, body, simbolMoney) {
    const dataSet = {
        columns: [],
        data: []
    }
    var constHeaders = ['Item Code', 'Description', 'Product Line', 'Last Unit Cost']
    constHeaders.forEach(title => {
        dataSet.columns.push({
            title: title, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        })
    })
    var flag = true
    for (const head of header) {
        dataSet.columns.push({
            title: head.Title1, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: head.Title2, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: head.Title3, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        },
            {
                title: head.Title4, width: { wch: 20 }, style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "030e6c" : "117015" },
                        bgColor: { rgb: "000000" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        bold: true
                    }
                }
            }

        )
        flag = !flag
    }

    flag = true
    for (const item of body) {
        let temporal = []
        temporal.push(
            {
                value: item.ItemCode, style: {
                    alignment: { horizontal: "left" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                        bgColor: { rgb: "000000" }
                    }
                }
            },
            {
                value: item.ProductLine, style: {
                    alignment: { horizontal: "left" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                        bgColor: { rgb: "000000" }
                    }
                }
            },
            {
                value: item.ItemCodeDesc, style: {
                    alignment: { horizontal: "left" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                        bgColor: { rgb: "000000" }
                    }
                }
            },
            {
                value: item.UnitCost, style: {
                    alignment: { horizontal: "right" },
                    numFmt: "$0.00",
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                        bgColor: { rgb: "000000" }
                    }
                }
            }
        )
        for (const level of item.PricesLevels) {
            temporal.push(

                {
                    value: level.LevelCost !== '-' ? Number(Number(level.LevelCost).toFixed(2)) : '0', style: {
                        alignment: { horizontal: level.LevelCost !== '-' ? "right" : "center" },
                        numFmt: "$0.00",
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },
                {
                    value: level.RealMargin !== '-' ? Number(level.RealMargin).toFixed(2) + "%" : '0', style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },
                {
                    value: level.NewLevelCost !== '-' ? Number(Number(level.NewLevelCost).toFixed(2)) : '0', style: {
                        alignment: { horizontal: level.LevelCost !== '-' ? "right" : "center" },
                        numFmt: "$0.00",
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },
                {
                    value: level.NewRealMargin !== '-' ? Number(level.NewRealMargin).toFixed(2) + "%" : '0', style: {
                        alignment: { horizontal: "center" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                }
            )
        }


        dataSet.data.push(temporal)
        flag = !flag
    }


    return [dataSet]

}

export function ReportOnlyChangePriceMargin(header, body, simbolMoney) {
    const dataSet = {
        columns: [],
        data: []
    }
    var constHeaders = ['Item Code', 'Description', 'Product Line', 'Last Unit Cost']
    constHeaders.forEach(title => {
        dataSet.columns.push({
            title: title, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "000000" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        })
    })
    var flag = true
    for (const head of header) {
        dataSet.columns.push({
            title: head.Title1, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: head.Title2, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: head.Title3, width: { wch: 20 }, style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "030e6c" : "117015" },
                    bgColor: { rgb: "000000" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        },
            {
                title: head.Title4, width: { wch: 20 }, style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "030e6c" : "117015" },
                        bgColor: { rgb: "000000" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        bold: true
                    }
                }
            }

        )
        flag = !flag
    }

    flag = true
    for (const item of body) {
        let temporal = []
        if (item.Change) {
            temporal.push(
                {
                    value: item.ItemCode, style: {
                        alignment: { horizontal: "left" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },
                {
                    value: item.ProductLine, style: {
                        alignment: { horizontal: "left" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },
                {
                    value: item.ItemCodeDesc, style: {
                        alignment: { horizontal: "left" },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                },


                {
                    value: item.UnitCost, style: {
                        alignment: { horizontal: "right" },
                        numFmt: "$0.00",
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                            bgColor: { rgb: "000000" }
                        }
                    }
                }
            )
            for (const level of item.PricesLevels) {
                temporal.push(

                    {
                        value: level.LevelCost !== '-' ? Number(Number(level.LevelCost).toFixed(2)) : '0', style: {
                            alignment: { horizontal: level.LevelCost !== '-' ? "right" : "center" },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    },
                    {
                        value: level.RealMargin !== '-' ? Number(level.RealMargin).toFixed(2) + "%" : '0', style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    },
                    {
                        value: level.NewLevelCost !== '-' ? +Number(Number(level.NewLevelCost).toFixed(2)) : '0', style: {
                            alignment: { horizontal: level.LevelCost !== '-' ? "right" : "center" },
                            numFmt: "$0.00",
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    },
                    {
                        value: level.NewRealMargin !== '-' ? Number(level.NewRealMargin).toFixed(2) + "%" : '0', style: {
                            alignment: { horizontal: "center" },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: item.Change ? 'FAD900' : (flag ? "FFFFFF" : "EAEAEA") },
                                bgColor: { rgb: "000000" }
                            }
                        }
                    }
                )
            }


            dataSet.data.push(temporal)
            flag = !flag
        }
    }


    return [dataSet]

}


export function getDataSetOverstockInv(years, titles, alldata, lowlevel, higlevel) {

    const tableHeader = {
        columns: [{
            title: " ",
            width: { wch: 20 },
            style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "0b0347" },
                    bgColor: { rgb: "0b0347" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: " ",
            width: { wch: 40 },
            style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "0b0347" },
                    bgColor: { rgb: "0b0347" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            title: " ",
            width: { wch: 20 },
            style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "0b0347" },
                    bgColor: { rgb: "0b0347" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }],
        data: [[{
            value: "Item Code", style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "0b0347" },
                    bgColor: { rgb: "ffffff" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            value: "Description", style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "0b0347" },
                    bgColor: { rgb: "ffffff" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }, {
            value: "Product Line", style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "0b0347" },
                    bgColor: { rgb: "ffffff" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }
            }
        }]]
    }
    let flag = true
    for (const year of years) {
        tableHeader.columns.push({
            title: String(year.Year),
            width: { wch: 20 },
            style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "000000" : "003d10" },
                    bgColor: { rgb: flag ? "000000" : "003d10" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }

            }
        })
        for (let a = 0; a < year.spanCol; a++) {
            tableHeader.columns.push({
                title: " ",
                width: { wch: 20 },
                style: {
                    alignment: { horizontal: "center" },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: flag ? "000000" : "003d10" },
                        bgColor: { rgb: flag ? "000000" : "003d10" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        bold: true
                    }

                }
            })
        }
        flag = !flag
    }
    tableHeader.columns.push({
        title: " ",
        width: { wch: 20 },
        style: {
            alignment: { horizontal: "center" },
            fill: {
                patternType: "solid",
                fgColor: { rgb: "0b0347" },
                bgColor: { rgb: "0b0347" }
            },
            font: {
                color: { rgb: "FFFFFF" },
                bold: true
            }
        }
    })
    tableHeader.columns.push({
        title: " ",
        width: { wch: 20 },
        style: {
            alignment: { horizontal: "center" },
            fill: {
                patternType: "solid",
                fgColor: { rgb: "0b0347" },
                bgColor: { rgb: "0b0347" }
            },
            font: {
                color: { rgb: "FFFFFF" },
                bold: true
            }
        }
    })
    tableHeader.columns.push({
        title: " ",
        width: { wch: 20 },
        style: {
            alignment: { horizontal: "center" },
            fill: {
                patternType: "solid",
                fgColor: { rgb: "0b0347" },
                bgColor: { rgb: "0b0347" }
            },
            font: {
                color: { rgb: "FFFFFF" },
                bold: true
            }
        }
    })
    flag = true
    for (const title of titles) {
        tableHeader.data[0].push({
            value: String(title), style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: flag ? "000000" : "003d10" },
                    bgColor: { rgb: flag ? "000000" : "003d10" }
                },
                font: {
                    color: { rgb: "FFFFFF" },
                    bold: true
                }

            }
        })
        flag = String(title).includes('Average') ? !flag : flag
    }
    tableHeader.data[0].push({
        value: "General Average", style: {
            alignment: { horizontal: "center" },
            fill: {
                patternType: "solid",
                fgColor: { rgb: "0b0347" },
                bgColor: { rgb: "ffffff" }
            },
            font: {
                color: { rgb: "FFFFFF" },
                bold: true
            }
        }
    })
    tableHeader.data[0].push({
        value: "Total On Hand", style: {
            alignment: { horizontal: "center" },
            fill: {
                patternType: "solid",
                fgColor: { rgb: "0b0347" },
                bgColor: { rgb: "ffffff" }
            },
            font: {
                color: { rgb: "FFFFFF" },
                bold: true
            }
        }
    })
    tableHeader.data[0].push({
        value: "Month On Hand", style: {
            alignment: { horizontal: "center" },
            fill: {
                patternType: "solid",
                fgColor: { rgb: "0b0347" },
                bgColor: { rgb: "ffffff" }
            },
            font: {
                color: { rgb: "FFFFFF" },
                bold: true
            }
        }
    })
    tableHeader.data[0].push({
        value: "OverStock/Shortage", style: {
            alignment: { horizontal: "center" },
            fill: {
                patternType: "solid",
                fgColor: { rgb: "0b0347" },
                bgColor: { rgb: "ffffff" }
            },
            font: {
                color: { rgb: "FFFFFF" },
                bold: true
            }
        }
    })

    for (const data of alldata) {
        let temporal = []
        temporal.push({
            value: data.ItemCode,
            style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                    bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        temporal.push({
            value: data.Description,
            style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                    bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        temporal.push({
            value: data.ProductLine,
            style: {
                alignment: { horizontal: "left" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                    bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        for (const monthdata of data.Detail) {
            temporal.push({
                value: (monthdata.MonthNo === 14 ? monthdata.AVG : monthdata.Total),
                style: {
                    alignment: { horizontal: "center" },
                    numFmt: "0.00",
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                        bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                    },
                    font: {
                        color: { rgb: "000000" },
                        bold: false
                    }
                }
            })
        }
        temporal.push({
            value: data.TotalAVG,
            style: {
                alignment: { horizontal: "center" },
                numFmt: "0.00",
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                    bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        temporal.push({
            value: data.TotalOnHand,
            style: {
                alignment: { horizontal: "center" },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                    bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        temporal.push({
            value: data.MOH,
            style: {
                alignment: { horizontal: "center" },
                numFmt: "0.00",
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                    bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        temporal.push({
            value: data.InvMonth,
            style: {
                alignment: { horizontal: "center" },
                numFmt: "0.00",
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) },
                    bgColor: { rgb: getColorLevelOverStock(lowlevel, higlevel, Number(data.InvMonth)) }
                },
                font: {
                    color: { rgb: "000000" },
                    bold: false
                }
            }
        })
        tableHeader.data.push(temporal)
    }


    return [tableHeader]

}

function getColorLevelOverStock(lowlevel, higlevel, Level) {
    if (Level < lowlevel) {
        return "fdff9e"
    } else if (Level >= lowlevel && Level <= higlevel) {
        return "8bf786"
    } else {
        return "f78686"
    }
}

export default { getDataSet }