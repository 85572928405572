import React,{ Component } from "react";
import { createContext } from "react";

export const AuthContext=createContext()

export const AuthConsumer=AuthContext.Provider
export const AuthProvider=AuthContext.Consumer


export default class GlobalProvider extends Component{
     state={
        user:{
            saludo:'WDW'
        }
    }

    setCookie = (user) => {
        this.setState((prevState) => ({ user }))
    }

render(){
    const {children}=this.props
    const {setCookie}=this
    const {user}=this.state
    return(
    
    <AuthContext.Provider value={{setCookie,user}}>
       {children}
    </AuthContext.Provider>
    
    )
}
}


