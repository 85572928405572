import { MonthInLetters } from "../../functions/generalFunctions"
import { pricesFormat } from "../../functions/pricesFormat"
import { getIndexElement } from "../../functions/searchInObject"
import { getValueCookie } from "../../services/cookieService"

export class GeneralFormatToSend {
    idCompany
    SalespersonNo

    constructor(idCompany, SalespersonNo,Start,End,MonthNo) {
        
        this.idCompany = Number(idCompany)
        this.SalespersonNo = SalespersonNo
        this.Start=Start
        this.End=End
        this.MonthNo=MonthNo
       
    }
}


export class SalesPerRouteMonth {
    Name
    Color
    Data

    constructor(Name,MonthNo,Type) {
        this.Name = Name
        this.Detail=[]
        this.Color=''
        this.Data = this.InitData(false,MonthNo,Type)
    }

    addData(Month, Total,TotalMeta,Type) {
        let to = 0
        const index = getIndexElement(this.Data, 'Month', Month)
        if (index !== -1) {
            this.Data[index].Total += Number(Total)
            this.Data[index].TotalMeta=Number(TotalMeta)
            to = this.Data[index].Total

        } else {
            let temporal=new RoutePerMonth(Month, Total,Type)
            temporal.TotalMeta=Number(TotalMeta)
            this.Data.push(temporal)
            to = Total
        }

        return to
    }
    InitData(use,MonthNo,Type) {
        let Data = []

        let end = MonthNo
        if (getValueCookie('SalesPerson') === '0000'&&use) {
            for (let a = 1; a <= end; a++) {
                Data.push(new RoutePerMonth(MonthInLetters(a), 0,Type))
            }
        } else {
            Data.push(new RoutePerMonth(MonthInLetters(end), 0,Type))
        }


        return Data
    }
    addRow(row){
        this.Detail.push(row)
    }
}

export class RoutePerMonth {
    Month
    Total
    Percentage
    TotalMeta
    PercentageMeta
    PercentageComplete
    DataSet
    Options
    PositionsRoutes = []

    constructor(Month, Total,Type) {
        this.Month = Month
        this.Total = Number(Total)
        this.Percentage = 0
        this.TotalMeta = 0
        this.PercentageMeta = 0
        this.PercentageComplete = 0
        this.DataSet = {
            labels: [],
            datasets: [
                {
                    label: "",
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    labels: [],
                },
            ]

        }

        this.Options = {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: Type==="SP"?'Sales by SalesPerson in: ' + this.Month:'Sales by Distribution Channel in: ' + this.Month,
                    font: {
                        size: 20, // Ajusta el tamaño del título aquí
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || '';
                            if(context.label){
                                label+=context.label
                            }
                            if (label) {
                                label += ': ';
                            }

                            
                            label +=context.parsed+'%'
                            return label;
                        },
                    },
                },
            },
            is3D : true

        };


    }
    addRoute(Route, Total, Percentage, Color) {
        const index = getIndexElement(this.PositionsRoutes, 'Route', Route)
        if (index !== -1) {
            this.DataSet.datasets[0].data[index] = (pricesFormat(Total))
            this.DataSet.labels[index] = Percentage
        } else {

            this.DataSet.datasets[0].data.push((pricesFormat(Total)))
            this.DataSet.labels.push(Percentage)
            this.DataSet.datasets[0].backgroundColor.push(Color)
            this.DataSet.datasets[0].borderColor.push(Color)
            this.DataSet.datasets[0].labels.push(Percentage)
            this.PositionsRoutes.push({ Route: Route })
        }

    }
}

export class TitlesSalesPerRoutes {

    constructor(use,MonthNo,Type) {
        let Data = []

        let end = MonthNo

        if (getValueCookie('SalesPerson') === '0000'&&use) {
            for (let a = 1; a <= end; a++) {
                Data.push(new RoutePerMonth(MonthInLetters(a), 0,Type))
            }
        } else {
            Data.push(new RoutePerMonth(MonthInLetters(end), 0,Type))
        }


        return Data
    }
}

