import React, { Component } from 'react'
import { BsFillTrashFill } from 'react-icons/bs';
import Catalogue from '../../components/Catalogue';
import LoadingWindow from '../../components/LoadingWindow';
import ModalOrders from '../../components/ModalComponent';
import SearchUPC from '../../components/SearchUPC';
import { AiOutlineCloseCircle, AiTwotoneSave } from "react-icons/ai"
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { DateFormatMAS90, FormatQueryReturnDate, FormatQueryReturnDateForReport, getActualDateUTC } from '../../functions/dateFormat';
import { getIndexElement } from '../../functions/searchInObject';
import { getInformationWithData } from '../../services/CABE';
import { getValueCookie } from '../../services/cookieService';
import { confirmCloseAlert } from '../../functions/alerts';
import { OrderPDF } from '../../components/OrderPDF';
import { getPrintHeaderCompany } from '../../functions/companyInformation';
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
export default class InventorySystem extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        Products: [],
        showModal1: false,
        enablePrint: false,
        CurrentItem: {
            ItemCode: '',
            Description: '',
            onHand: 0,
            QuantitySpoilage: 0,
            UnitPrice: '',
            ImgURL: '',
            RegisterType: '',
            ProductLine: '',
            Comment: '',
            LastTotalUnitCost: 0
        },
        Spoilage: {
            SpoilageNo: '',
            UserName: getValueCookie('userName'),
            Date: '',
            TotalQuantity: 0,
            Comment: ''
        },
        companyPrintHeader: getPrintHeaderCompany(),
    }


    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        if (modal === "showModal1") {
            this.clearCurrentItem()
        }

    }

    clearCurrentItem() {
        this.setState({
            CurrentItem: {
                ItemCode: '',
                Description: '',
                onHand: 0,
                QuantitySpoilage: 0,
                UnitPrice: '',
                ImgURL: '',
                RegisterType: '',
                ProductLine: '',
                Comment: '',
                LastTotalUnitCost: 0
            }
        })
    }

    async selectSpoilageProduct(item, Type) {
        const ind = getIndexElement(this.state.Products, 'ItemCode', item.itemCode)

        if (ind === -1) {
            const data = {
                company: getValueCookie('Company'),
                idcompany: getValueCookie('CompanyId'),
                Component: null
            }

            const respuesta = await getInformationWithData('/Items/get', data);
            let url = null
            let desc = item.abbreviatedDesc
            const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)

            if (index !== -1) {
                let product = respuesta.data[index]

                if (product.itemURLImage) {
                    const date = new Date().getTime()
                    url = `${product.itemURLImage}?${date}`
                }
                desc = (product.completeDesc && product.completeDesc !== "null") ? product.completeDesc : product.abbreviatedDesc
            }

            this.handleModalOpen('showModal1')
            this.setState({
                CurrentItem: {
                    ItemCode: item.itemCode,
                    Description: desc,
                    onHand: item.quantity,
                    QuantitySpoilage: 0,
                    UnitPrice: item.UnitPrice,
                    ImgURL: url,
                    RegisterType: Type,
                    ProductLine: item.ProductLineDesc,
                    LastTotalUnitCost: item.LastTotalUnitCost,
                    Comment: ''
                }
            })
        } else {
            let prod = this.state.Products[ind]
            console.log(prod)
            this.handleModalOpen('showModal1')
            this.setState({
                CurrentItem: {
                    ItemCode: prod.ItemCode,
                    Description: prod.Description,
                    onHand: prod.onHand,
                    QuantitySpoilage: '12',
                    UnitPrice: prod.UnitPrice,
                    ImgURL: prod.ImgURL,
                    RegisterType: prod.RegisterType,
                    ProductLine: prod.ProductLine,
                    LastTotalUnitCost: prod.LastTotalUnitCost,
                    Comment: prod.Comment
                }
            })

        }

    }

    async getCorrelative() {
        const data = {
            type: 'InventorySystem',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)
        if (result.status.code === 1) {
            const temporal = this.state.Spoilage
            temporal.SpoilageNo = 'RG' + result.data[0].correlative
            this.setState({ Spoilage: temporal })
        }
    }
    clearAll() {
        this.setState({
            Products: [],
            CurrentItem: {
                ItemCode: '',
                Description: '',
                onHand: 0,
                QuantityShipRec: 0,
                UnitPrice: '',
                ImgURL: '',
                RegisterType: '',
                ProductLine: '',
                LastTotalUnitCost: 0,
                Comment: ''
            },
            enablePrint: false,
            Spoilage: {
                SpoilageNo: '',
                UserName: getValueCookie('userName'),
                Date: '',
                TotalQuantity: 0,
                Comment: ''
            }
        })
        document.getElementById('CommentSpoilageSystem').value=''
        document.getElementById('DateSpoilageSystem').value=''
    }
    async NewSpoilage() {
        await this.clearAll()
        await this.getCorrelative()
    }
    async AddSpoilageItem() {
        let item = this.state.CurrentItem
        item.countBy = getValueCookie('userName')
        item.DispatchDate = getActualDateUTC()
        let temporal = this.state.Products
        const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
        if (index === -1) {
            temporal.push(item)
        } else {
            temporal[index] = item
        }
        await this.setState({ Products: temporal })
        await this.getTotals()
        await this.handleModalClose("showModal1")
    }

    async DeleteSpoilageItems(item) {
        let temporal = this.state.Products
        const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
        if (index !== -1) {
            await temporal.splice(index, 1)
            await this.setState({ Products: temporal })
            await this.getTotals()
        }
    }

    onChangeHeader = e => {
        const id = e.target.id
        const value = e.target.value
        const temporal = this.state.Spoilage
        switch (id) {
            case "DateSpoilageSystem":
                temporal.Date = value
                break;
            case "CommentSpoilageSystem":
                temporal.Comment = value
                break;
            default:
                break;
        }
        this.setState({ Spoilage: temporal })
    }

    onChangeDetail = e => {
        const id = e.target.id
        const value = e.target.value
        const temporal = this.state.CurrentItem
        switch (id) {
            case "spoilageQuantityItem":
                temporal.QuantitySpoilage = Number(value)
                break;
            case "CommentItemSpoilageSystem":
                temporal.Comment = value
                break;
            default:
                break;
        }
        this.setState({ CurrentItem: temporal })
    }

    async changeProd(item) {
        const index = getIndexElement(this.state.Products, 'ItemCode', item.ItemCode)
        if (index !== -1) {
            let prod = this.state.Products[index]
            this.handleModalOpen('showModal1')
            await this.setState({
                CurrentItem: {
                    ItemCode: prod.ItemCode,
                    Description: prod.Description,
                    onHand: prod.onHand,
                    QuantitySpoilage: prod.QuantitySpoilage,
                    UnitPrice: prod.UnitPrice,
                    ImgURL: prod.ImgURL,
                    RegisterType: prod.RegisterType,
                    ProductLine: prod.ProductLine,
                    LastTotalUnitCost: prod.LastTotalUnitCost,
                    Comment: prod.Comment
                }
            })
        }
    }

    async saveSpoilage() {
        const data = {
            Spoilage: this.state.Spoilage,
            Products: this.state.Products,
            UserName: getValueCookie('userName')
        }
        console.log(data)
        this.setState({ enablePrint: true })
        confirmCloseAlert('correct', 'The Spoilage has been registered successfully!')
    }

    getTotals() {
        const temporal = this.state.Spoilage
        temporal.TotalQuantity = 0
        for (const iterator of this.state.Products) {
            temporal.TotalQuantity += iterator.QuantitySpoilage
        }
        this.setState({ Spoilage: temporal })
    }

    formatSpoilageDetail() {
        let Format = []
        for (const item of this.state.Products) {
            const data = {
                ItemCode: item.ItemCode,
                ProductLine: item.ProductLine,
                Description: item.Description,
                QuantitySpoilage: NumberFormat(pricesFormat(item.QuantitySpoilage)),
                Comment: item.Comment,
                countBy: item.countBy,
                DispatchDate: FormatQueryReturnDateForReport(item.DispatchDate)
            }
            Format.push(data)
        }
        return Format
    }

    render() {
        return (
            <div>
                
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Inventory System</p>
                <div className='row'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-12 pb-3'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.NewSpoilage()} type="button" className="btn orangeButton btn-lg">New Registry <BsFillTrashFill /></button>
                                </div>
                            </div>
                            <div className='col-6 pb-5'>
                                <div className='row'>
                                    <div className='col-12 text-start pText'><p>Registry No:</p></div>
                                    <div className='col-12'>
                                        <input value={this.state.Spoilage.SpoilageNo} className="form-control form-control-lg" disabled id='NoSpoilageSystem2' type="text" />
                                    </div>
                                </div>

                            </div>
                            <div className='col-6 pb-3'>
                                <div className='row'>
                                    <div className='col-12 text-start pText'><p>Date :</p></div>
                                    <div className='col-12'>
                                        <input onChange={this.onChangeHeader} disabled={this.state.Spoilage.SpoilageNo === ''} className="form-control form-control-lg" id='DateSpoilageSystem' type="date" />
                                    </div>
                                </div>

                            </div>
                            <div className='col-12 pb-5'>
                                <div className='row'>
                                    <div className='col-12 text-start pText'><p>Comment :</p></div>
                                    <div className='col-12 textAreaGeneral'>
                                        <textarea onChange={this.onChangeHeader} disabled={this.state.Spoilage.SpoilageNo === ''} className="form-control" id="CommentSpoilageSystem" placeholder='Insert comment...' rows="3"></textarea>
                                    </div>
                                </div>

                            </div>
                            <div className='col-12 pb-3'>
                                <Catalogue Array={''} nombrePadre={"InventorySystem"} Padre={this} />
                            </div>
                            <div className='col-12 pb-3'>
                                <SearchUPC ComponentName={'InventorySystem'} Padre={this} />
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                    <div className='col-12 tableFixHead tb-1'>
                        <table className='table align-middle'>
                            <thead>
                                <tr className='text-light text-center'>
                                    <th className='bg-secondary'>Item Code</th>
                                    <th className='bg-secondary'>Product Line</th>
                                    <th className='bg-secondary'>Description </th>
                                    <th className='bg-secondary'>Quantity</th>
                                    <th className='bg-secondary'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Products.map((item, i) => (
                                    <tr onClick={() => this.changeProd(item)} key={i}>
                                        <td className='text-start textTableSize'>{item.ItemCode}</td>
                                        <td className='text-start textTableSize'>{item.ProductLine}</td>
                                        <td className='text-start textTableSize'>{item.Description}</td>
                                        <td className='text-center textTableSize'>{NumberFormat(pricesFormat(item.QuantitySpoilage))}</td>
                                        <td className='text-center textTableSize'>
                                            <button onClick={() => this.DeleteSpoilageItems(item)} className='btn btn-danger'><AiOutlineCloseCircle /></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot className='tfoot'>

                                <tr className='bg-dark text-light text-center'>
                                    <td></td>
                                    <td></td>
                                    <td className='textTableSize'>TOTAL:</td>
                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(this.state.Spoilage.TotalQuantity))}</td>
                                    <td></td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="d-grid gap-2">
                                    <button type="button" className="btn blackButton btn-lg" onClick={() => this.saveSpoilage()}>{"Save Register"} <AiTwotoneSave /></button>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="d-grid gap-2">
                                    <OrderPDF
                                        id={"PrintSpoilageSystem"} disabled={!this.state.enablePrint} colorButton="purpleButton" title="Print Order"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Register Detail"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Register No: ", this.state.Spoilage.SpoilageNo, "Create by: ", this.state.Spoilage.UserName, "Date:", DateFormatMAS90(this.state.Spoilage.Date), "Printed by: ", getValueCookie('userName'), "Printed Date: ", FormatQueryReturnDate(getActualDateUTC()), "", ""]}
                                        headerTable={["\nItem Code", "\nProduct Line", "\nDescription", "\nQuantity", "\nComment", "\nUser\nName", "\nDate"]}
                                        bodyTable={this.formatSpoilageDetail()}
                                        headerBodyTable={["ItemCode", "ProductLine", "Description", "QuantitySpoilage", "Comment", "countBy", "DispatchDate"]}
                                        bottomInfo={["", "TOTALS:", "", NumberFormat(pricesFormat(this.state.Spoilage.TotalQuantity)), "", "", ""]}
                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoNormal", "ColumnaTextoLargo", "ColumnaCantidades", "ColumnaTextoLargo", "ColumnaTextoNormal", "ColumnaTextoNormal"]}
                                        hidden={false}
                                        comment={this.state.Spoilage.Comment}
                                        breakpoint={57}
                                        keysDecimalFormat={[""]}
                                        titleButton='Print Register'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                </div>

                <ModalOrders title={''} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Item Code:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.CurrentItem.ItemCode}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Description:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.CurrentItem.Description}</p>
                                        </div>
                                        <div className='col-12 pt-2 titleTotal'>
                                            <p>Quantity:</p>
                                        </div>
                                        <div className='col-12 input-group input-group-lg pt-2 pb-3'>
                                            <input defaultValue={this.state.CurrentItem.QuantitySpoilage} onChange={this.onChangeDetail} type="number" min={0} step={1} autoComplete='off' className="form-control text-center" id='spoilageQuantityItem' placeholder={'0'} />
                                        </div>
                                        <div className='col-12 pt-2 titleTotal'>
                                            <p>Comment:</p>
                                        </div>
                                        <div className='col-12 textAreaGeneral'>
                                            <textarea defaultValue={this.state.CurrentItem.Comment} onChange={this.onChangeDetail} className="form-control" id="CommentItemSpoilageSystem" placeholder='Insert comment...' rows="4"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Product Line:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.CurrentItem.ProductLine}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>On Hand:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p className='text-center'>{this.state.CurrentItem.onHand}</p>
                                        </div>
                                        <div className='col-12'>
                                            <div className='ItemImg AlignImgCenter'>
                                                <img key={this.state.CurrentItem.ImgURL} className='pb-5 pt-5 text-center' id='ShortsProductImg' src={this.state.CurrentItem.ImgURL !== '' ? this.state.CurrentItem.ImgURL : '/assets/notavailable.png'} alt='ProductImg' />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-12 pt-2'>
                                    <button onClick={() => this.AddSpoilageItem()} type="button" className="btn greenButton btn-lg w-100">Enter</button>
                                </div>


                                <div className='col-12 pt-2'>
                                    <button type="button" className="btn btn-secondary btn-lg w-100" onClick={() => this.handleModalClose("showModal1")}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-1'></div>
                    </div>
                </ModalOrders>

            </div>
        )
    }
}