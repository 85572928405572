import React, { Component } from "react";
import '../../css/table-responsive.css'
import "../../css/general-style.css"
import { AuthContext } from "../../services/auth/authContext";
import { getValueCookie } from "../../services/cookieService";
import AdminLobby from "./AdminLobby";
import PersonalLobby from "./PersonalLobby";

export default class Lobby extends Component {
    static contextType = AuthContext

    state = {
        ListOfCorporative: ['0000', 'WW'],
    }

    render() {
        return (
            <div >
                <div className="text-start pb-5">
                    <h1>Welcome</h1>
                    {getValueCookie('TypeUser') !== '1'&&getValueCookie('TypeUser') !== '12'?       
                          <PersonalLobby SalesPerson={getValueCookie('SalesPerson')}/>
                        : <AdminLobby SalesPersonList={getValueCookie('CompanyId')==='3'?['AM','JDL','AS','0000','0010','TOT']:['WW','JC','TOT']}/>}
                </div>
            </div>
        )
    }
}