

export class ScanningByItemCode {
    ItemCode
    ProdLine
    Description
    TotalScanningProds
    TotalManualProds
    Total
    PercentageManual
    PercentageScanning
    Orders
    HaveUPC

    constructor(ItemCode, ProdLine, Description, HaveUPC) {
        this.ItemCode = ItemCode
        this.ProdLine = ProdLine
        this.Description = Description
        this.TotalManualProds = 0
        this.TotalScanningProds = 0
        this.Total = 0
        this.PercentageManual = 0
        this.PercentageScanning = 0
        this.Orders = []
        this.HaveUPC = HaveUPC ? 'Yes' : 'No'
    }

    addItemCode(prod) {
        if (prod.RegisterType === 's') {
            this.TotalScanningProds += 1
        } else {
            this.TotalManualProds += 1
        }
        this.Orders.push(prod)
        this.Total = this.Orders.length

        if (this.Total !== 0) {
            this.PercentageManual = (this.TotalManualProds / this.Total) * 100
            this.PercentageScanning = (this.TotalScanningProds / this.Total) * 100
        }
    }

}

export class ScanningByLineProd {
    ProdLine
    TotalScanningProds
    TotalManualProds
    GeneralProdList
    Total
    TotalUPC
    PercentageManual
    PercentageScanning
    constructor(ProdLine) {
        this.ProdLine = ProdLine
        this.TotalManualProds = 0
        this.TotalScanningProds = 0
        this.Total = 0
        this.TotalUPC = 0
        this.PercentageManual = 0
        this.PercentageScanning = 0
        this.GeneralProdList = []
    }

    addItemToProdLine(prod) {
        if (prod.haveUPC === 'Yes') {
            this.TotalUPC += 1
            if (prod.RegisterType === 's') {
                this.TotalScanningProds += 1
            } else {
                this.TotalManualProds += 1
            }
        }

        this.GeneralProdList.push(prod)
        this.Total = this.GeneralProdList.length

        if (this.TotalUPC !== 0) {
            this.PercentageManual = (this.TotalManualProds / this.TotalUPC) * 100
            this.PercentageScanning = (this.TotalScanningProds / this.TotalUPC) * 100
        }
    }
}

export class ScanningByOrderNo {
    OrderNo
    Type
    Date
    TotalScanningProds
    CustomerName
    TotalManualProds
    GeneralList
    Total
    PercentageManual
    PercentageScanning
    User
    TotalUPC

    constructor(OrderNo, Type, Date, User, CustomerName) {
        this.OrderNo = OrderNo
        this.Type = Type
        this.Date = Date
        this.CustomerName = CustomerName
        this.TotalScanningProds = 0
        this.TotalManualProds = 0
        this.Total = 0
        this.PercentageManual = 0
        this.PercentageScanning = 0
        this.GeneralList = []
        this.User = User
        this.TotalUPC = 0
    }

    addNewProd(prod) {

        if (prod.haveUPC === 'Yes') {
            this.TotalUPC += 1
            if (prod.RegisterType === 's') {
                this.TotalScanningProds += 1
            } else {
                this.TotalManualProds += 1
            }
        }
        this.GeneralList.push(prod)
        this.Total = this.GeneralList.length

        if (this.TotalUPC !== 0) {
            this.PercentageManual = (this.TotalManualProds / this.TotalUPC) * 100
            this.PercentageScanning = (this.TotalScanningProds / this.TotalUPC) * 100
        }

    }
}

export class UsersScanningReport {
    DispatchUser
    PorcentajeEcaneos
    PorcentajeManual
    Total
    TotalEscaneos
    TotalManuales
    TotalUPC
    Detail

    constructor(DispatchUser) {
        this.DispatchUser = DispatchUser
        this.PorcentajeEcaneos = 0
        this.PorcentajeManual = 0
        this.Total = 0
        this.TotalEscaneos = 0
        this.TotalManuales = 0
        this.TotalUPC = 0
        this.Detail = []
    }
}

export class GeneralInformation {
    OrderNo
    Type
    CustomerNo
    CustomerName
    ItemCode
    ProdLine
    ItemCodeDesc
    UserName
    RegisterType
    DispatchQuantity
    Date
    HaveUPC

    constructor(OrderNo, Type, CustomerNo, CustomerName, ItemCode,ProdLine,ItemCodeDesc, UserName, RegisterType, DispatchQuantity,Date,HaveUPC) {
        this.OrderNo = OrderNo
        this.Type = Type
        this.CustomerNo = CustomerNo
        this.CustomerName = CustomerName
        this.ItemCode = ItemCode
        this.ProdLine=ProdLine
        this.ItemCodeDesc = ItemCodeDesc
        this.UserName = UserName
        this.RegisterType = RegisterType
        this.DispatchQuantity = DispatchQuantity
        this.Date=Date
        this.HaveUPC=HaveUPC
    }
}