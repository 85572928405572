import React, { Component } from 'react'
import Swal from "sweetalert2";
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import "../../css/pickingSystem-style.css"
import Select from 'react-select';
import { AiOutlineCloseCircle, AiTwotoneSave } from "react-icons/ai"
import Catalogue from '../../components/Catalogue'
import { getValueCookie } from '../../services/cookieService'
import { create_Delete_Update_Information, getInformationWithData, getInformationWithDataGlobalSales } from '../../services/CABE'
import { DateFormatMAS90, DeleteQueryFormat, FormatQueryReturnDateForReport, getActualDateQuery, getActualDateUTC, getDateYearMonthDayDash } from '../../functions/dateFormat';
import LoadingWindow from '../../components/LoadingWindow';
import { confirmCloseAlert } from '../../functions/alerts';
import { getPrintHeaderCompany } from '../../functions/companyInformation';
import { OrderPDF } from '../../components/OrderPDF';
import SmallModal from '../../components/SmallModal';
import { pricesFormat } from '../../functions/pricesFormat';
import { DecimalPart, getPriceMargin, getPurePriceMargin, MobileDisp, NumberFormat, OrderArray, validateNumbersForQuantitys } from '../../functions/generalFunctions';
import SearchUPC from '../../components/SearchUPC';
import { getIndexElement } from '../../functions/searchInObject';
import Catalogue2 from '../../components/Catalogue2.0/Catalogue2.0';
import { DownloadOrderPDF } from '../../components/DownloadOrderPDF';
import PortalOrderList from '../../components/PortalOrderList';
import { SalesOrder, SalesOrderDetail } from './Class';


export default class PortalSalesOrder extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }

    state = {
        disable: false,
        ConfirmSave: 0,
        Products: [],
        SalesOrder: {

            Customer: {
                AddressLine1: '',
                City: '',
                CustomerName: '',
                CustomerNo: '',
                EmailAddress: '',
                PriceLevel: undefined,
                SalespersonNo: '',
                State: '',
                TelephoneNo: '',
                ZipCode: '',
                uniqueTrack: '',
            },

            NoOrder: '',
            Type: 'S',
            TotalQuantityOrdered: 0,
            TotalCost: 0,
            TotalWeight: 0,
            Date: getDateYearMonthDayDash(),
            Warehouse: '000',
            Packing: null,
            WeightOption: null,
            MAS90Status: 0,
            TotalLines: 0

        },
        Balance: {
            HeaderBalance: [],
            TermsCode: '',
            TotalBalance: 0,
            SelectedInvoice: '',
            Detail: []
        },

        companyPrintHeader: getPrintHeaderCompany(),
        showModal1: false,
        confirmMargin: false,
        NotZeroUnitPrice: true,
        selectOptions1: [

            { value: 'Transparent Stretch Wrap', label: 'Transparent Stretch Wrap' },
            { value: 'Black Stretch Wrap', label: 'Black Stretch Wrap' },
            { value: 'Not Packing (Customer Collect) ', label: 'Not Packing (Customer Collect)' }
        ],
        selectOptions2: [
            { value: '003', label: 'B&B Miami Warehouse Price Level 4' },//4
            { value: '000', label: 'Mayaland Atlanta Warehouse Price Level 3' }//5
        ],
        selectOptions3: [

            { value: 'Weighed', label: 'Weighed' },
            { value: 'No need to be Weighed', label: 'No need to be Weighed' }
        ]



    }

    setupBeforeUnloadListener = () => {
        if (this.state.Products.length > 0) {
            window.addEventListener("beforeunload", (ev) => {
                ev.preventDefault();
                ev.returnValue = '';
                return this.doSomethingBeforeUnload();
            });
        }
    };

    async getNewCorrelativeSalesOrder() {
        this.clearSalesOrder()
        const data = {
            type: 'SalesOrder',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)
        if (result.status.code === 1) {
            const temporal = this.state.SalesOrder
            temporal.NoOrder = (getValueCookie('CompanyId') === '6' ? 'AL' : 'PM') + result.data[0].correlative
            this.setState({ SalesOrder: temporal })
        }
    }

    valueRadioButton = async e => {
        let stat = e.target.value
        const temporal = this.state.SalesOrder
        temporal.Type = stat
        this.setState({ SalesOrder: temporal })
    }

    calculateTotals() {
        let totalQuantity = 0
        let totalCost = 0
        let totalWeight = 0
        let totalPrice = 0
        const temporal = this.state.Products
        const temporal2 = this.state.SalesOrder

        for (const item of temporal) {

            totalPrice += Number(item.LastTotalUnitCost) * Number(item.quantityOrdered)
            totalQuantity += Number(item.quantityOrdered)
            totalCost += Number(item.totalCost)
            totalWeight += Number(item.quantityOrdered) * Number(item.ShipWeight)
        }
        temporal2.TotalQuantityOrdered = totalQuantity
        temporal2.TotalCost = totalCost
        temporal2.TotalWeight = totalWeight
        temporal2.Customer.uniqueTrack = getPriceMargin(totalCost, totalPrice, temporal2.Customer.PriceLevel)
        this.setState({ SalesOrder: temporal2 })
        this.AutoSave()
    }

    onTarget = async (e, item) => {

        const temporal = this.state.Products
        const ident = e.target.id.split('_')
        const index = temporal.indexOf(item)

        if (index !== -1) {
            if (ident[0] === "quantityOrderedPortalOrder") {
                let quant = Number(e.target.value)

                if (!validateNumbersForQuantitys(quant)) {
                    let parteDecimal = 1 - DecimalPart(quant)
                    quant += parteDecimal
                    document.getElementById(e.target.id).value = quant
                }

                temporal[index].quantityOrdered = Number(quant)
                temporal[index].totalCost = (Number(quant) * Number(temporal[index].UnitPrice))
                this.setState({ Products: temporal, ConfirmSave: 0 })
                this.calculateTotals()
            } else if (ident[0] === "unitPricePortalOrder") {
                temporal[index].UnitPrice = Number(e.target.value)
                temporal[index].totalCost = (Number(e.target.value) * Number(temporal[index].quantityOrdered))
                if (getValueCookie('CompanyId') === '6') {
                    temporal[index].Comment = "BOX:" + getPriceMargin(temporal[index].UnitPrice, temporal[index].unitCost, this.state.SalesOrder.Customer.PriceLevel)
                    temporal[index].PureMargin = getPurePriceMargin(temporal[index].UnitPrice, temporal[index].unitCost)

                }
                this.setState({ Products: temporal, ConfirmSave: 0 })
                this.calculateTotals()
            }
        }
        await this.AutoSave()
    }

    clearSalesOrder() {
        const clear = {
            ConfirmSave: 0,
            confirmMargin: false,
            NotZeroUnitPrice: true,
            Customer: {
                AddressLine1: '',
                City: '',
                CustomerName: '',
                CustomerNo: '',
                EmailAddress: '',
                PriceLevel: undefined,
                SalespersonNo: '',
                State: '',
                TelephoneNo: '',
                ZipCode: '',
                uniqueTrack: '',
            },
            TotalLines: 0,
            NoOrder: '',
            Type: 'S',
            TotalQuantityOrdered: 0,
            TotalCost: 0,
            TotalWeight: 0,
            Date: getDateYearMonthDayDash(),
            Warehouse: '000',
            Packing: null,
            WeightOption: null,
            MAS90Status: 0
        }
        this.setState({ SalesOrder: clear, Products: [] })
        localStorage.removeItem('PortalOrderSave')
    }

    addComentary(item) {
        let comm = item.Comment
        Swal.fire({
            title: 'Product: ' + item.itemCode,
            html: `<div class='textAreaGeneral textAreaComment'>
                    <textarea id='comentOfPOrtalOrder' rows="10" cols="50">${comm}</textarea>
                   </div>
            `,
            backdrop: true,
            showCancelButton: true,
            confirmButtonText: 'Save Commentary',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const temporal = this.state.Products
                const index = temporal.indexOf(item)
                if (index !== -1) {
                    const coment = Swal.getPopup().querySelector('#comentOfSalesOrder').value
                    temporal[index].Comment = coment
                    this.setState({ Products: temporal })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        this.AutoSave()
    }

    removeProduct(item) {
        let temporal = this.state.Products
        const index = temporal.indexOf(item)
        if (index !== -1) {
            temporal[index].quantityOrdered = 0
            temporal[index].totalCost = 0
            temporal[index].Visible = 0
            for (let a = 0; a < temporal.length; a++) {
                document.getElementById("quantityOrderedPortalOrder_" + a).value = temporal[a].quantityOrdered
                document.getElementById("unitPricePortalOrder_" + a).value = temporal[a].UnitPrice
            }
            this.setState({ Products: temporal })
            this.calculateTotals()
        }
        this.AutoSave()
    }

    refreshInputs() {
        const temporal = this.state.Products
        for (let a = 0; a < temporal.length; a++) {
            document.getElementById("quantityOrderedPortalOrder_" + a).value = temporal[a].quantityOrdered
            document.getElementById("unitPricePortalOrder_" + a).value = temporal[a].UnitPrice
        }
    }

    AsignLineSequence() {
        const head = this.state.SalesOrder
        if (getValueCookie('CompanyId') === '6') {
            const temporal = OrderArray(JSON.parse(JSON.stringify(this.state.Products)), 'IdCompany', true)
            let confirmMargin = false
            let NotZeroUnitPrice = true
            let count = 1
            let activeLines = 0
            for (const item of temporal) {
                item.LineSequence = count
                item.quantityShipped = 0
                count++
                if (!confirmMargin) {
                    confirmMargin = item.PureMargin > 20 ? false : true
                }
                if (item.UnitPrice <= 0) {
                    NotZeroUnitPrice = false
                }
                if (item.Visible !== 0) {
                    activeLines++
                }
            }
            head.TotalLines = activeLines
            this.setState({ confirmMargin: confirmMargin, NotZeroUnitPrice: NotZeroUnitPrice, SalesOrder: head })
            return temporal
        } else {
            const temporal = this.state.Products
            let NotZeroUnitPrice = true
            let count = 1
            let activeLines = 0
            for (const item of temporal) {
                item.LineSequence = count
                item.quantityShipped = 0
                count++
                if (item.UnitPrice <= 0) {
                    NotZeroUnitPrice = false
                }
                if (item.Visible !== 0) {
                    activeLines++
                }
            }
            head.TotalLines = activeLines
            this.setState({ NotZeroUnitPrice: NotZeroUnitPrice, SalesOrder: head })
            return temporal
        }
    }

    detailToPrint() {
        let items = []
        this.state.Products.forEach(element => {
            if (element.Visible === 1) {
                element.abbreviatedDesc = (element.completeDesc && element.completeDesc !== "null") ? element.completeDesc : element.abbreviatedDesc
                items.push(element)
            }
        });
        return items
    }

    async createSalesOrder(LoadMas90) {
        await this.AsignLineSequence()
        Swal.fire({
            title: 'Are you sure to save this order?',
            text: "",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText: 'Yes, please',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const temporal = JSON.parse(JSON.stringify(this.state.SalesOrder))

                temporal.Detail = this.AsignLineSequence()
                temporal.idCompany = getValueCookie('CompanyId')
                temporal.username = getValueCookie('userName')
                temporal.company = getValueCookie('Company')
                temporal.OrderType = 'SalesOrder'
                temporal.MAS90Status = LoadMas90
                temporal.Packing = this.state.SalesOrder.Packing + ";" + this.state.SalesOrder.WeightOption
                temporal.Date = DeleteQueryFormat(temporal.Date)
                if (getValueCookie('CompanyId') !== '6' || (getValueCookie('CompanyId') === '6' && !this.state.confirmMargin)) {

                    if ((this.state.SalesOrder.Packing && this.state.SalesOrder.WeightOption) || getValueCookie('CompanyId') === '6') {
                        if (this.state.NotZeroUnitPrice) {
                            console.log(temporal)
                            const result = await create_Delete_Update_Information('/sales/set/order', temporal)
                            if (result.status.code === 1) {
                                this.setState({ ConfirmSave: this.state.Products.length })
                                await this.SaveAndPrint()
                                await confirmCloseAlert('correct', 'The order was saved successfully!')
                                localStorage.removeItem('PortalOrderSave')
                                await this.ChangeStatusSalesOrder(2, 1)
                            } else {
                                confirmCloseAlert('incorrect', 'Whoops something is wrong with the system, please call system support.')
                            }
                        } else {
                            Swal.fire({
                                title: 'Some products have Unit Price on Zero.\nPlease Fix that!',
                                text: "",
                                icon: 'warning',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton: true,
                                confirmButtonText: 'OK',
                                cancelButtonText: 'No, cancel!',
                            })
                        }
                    } else {
                        if (!this.state.SalesOrder.Packing) {
                            Swal.fire({
                                title: 'You need select a Type of Packing to Save this Sales Order!',
                                text: "",
                                icon: 'error',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            })
                        } else {
                            Swal.fire({
                                title: 'You need select if the order are Weighed or No need to be Weighed',
                                text: "",
                                icon: 'error',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            })
                        }
                    }

                } else {
                    Swal.fire({
                        title: 'Some products have Problem with the Unit Tracking.\nDo you like to continue?',
                        text: "",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, please',
                        cancelButtonText: 'No, cancel!',
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            if (this.state.NotZeroUnitPrice) {
                                const result = await create_Delete_Update_Information('/sales/set/order', temporal)
                                if (result.status.code === 1) {
                                    this.setState({ ConfirmSave: this.state.Products.length })
                                    await this.SaveAndPrint()
                                    await confirmCloseAlert('correct', 'The order was saved successfully!')
                                    localStorage.removeItem('PortalOrderSave')
                                    await this.ChangeStatusSalesOrder(2, 1)
                                } else {
                                    confirmCloseAlert('incorrect', 'Whoops something is wrong with the system, please call system support.')
                                }
                            } else {
                                Swal.fire({
                                    title: 'Some products have Unit Price on Zero.\nPlease Fix that!',
                                    text: "",
                                    icon: 'warning',
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    showCancelButton: false,
                                    confirmButtonText: 'OK',
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    componentDidMount() {
        if (localStorage.getItem('PortalOrderSave')) {
            let infoRec = JSON.parse(localStorage.getItem('PortalOrderSave'));
            if (infoRec) {
                this.setState({ Products: infoRec.Products, SalesOrder: infoRec.SalesOrder })
            }
        }
        window.addEventListener('storage', this.handleStorageChange);
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }

    AutoSave() {
        localStorage.setItem('PortalOrderSave', JSON.stringify(this.state))
    }

    TypeInLetters(type) {
        if (type === 'S') {
            return 'Standard'
        } else {
            return 'Quote'
        }
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
    }

    handleStorageChange = async (event) => {
        if (event.key === 'PortalOrderSave') {
            let infoRec = JSON.parse(localStorage.getItem('PortalOrderSave'));
            if (infoRec) {
                if (infoRec.SalesOrder.NoOrder === this.state.SalesOrder.NoOrder) {
                    await this.setState({ Products: infoRec.Products, SalesOrder: infoRec.SalesOrder })
                    await this.refreshInputs()
                }
            }
        }
    }

    getTotalBalance() {
        let currentBalance = 0
        this.state.Balance.HeaderBalance.forEach(line => {
            currentBalance += Number(line.Balance)
        });
        const temporal = this.state.Balance
        temporal.TotalBalance = currentBalance
        this.setState({ Balance: temporal })
    }

    async getDetailBalance(LineBalance) {
        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            InvoiceNo: LineBalance.InvoiceNo
        }
        this.ModalLoading.current.showState(true);
        const result = await getInformationWithData('/invoice/history/getInvoicePayment', data)
        if (result.status.code === 1) {
            const temporal = this.state.Balance
            temporal.Detail = result.data
            temporal.SelectedInvoice = LineBalance.InvoiceNo
            this.setState({ Balance: temporal })
        }
        this.ModalLoading.current.showState(false);
    }

    validationNewSalesOrder() {
        if (this.state.Products.length !== this.state.ConfirmSave) {
            Swal.fire({
                title: 'Are you sure to Start a new Sales Order, you have a Sales Order open and unsave?',
                icon: 'info',
                showDenyButton: true,
                confirmButtonText: `Yes, I'm sure.`,
                denyButtonText: `Not`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.getNewCorrelativeSalesOrder()
                }
            })
        } else {
            this.getNewCorrelativeSalesOrder()
        }
    }

    onChangeSelects = async (e, id) => {
        const temporal = this.state.SalesOrder
        switch (id) {
            case "Warehouse":
                temporal.Warehouse = e.value
                this.setState({ SalesOrder: temporal })
                this.changePriceLevel(e.value)
                break;
            case "Packing":
                temporal.Packing = e.value
                this.setState({ SalesOrder: temporal })
                break;
            case "Weight":
                temporal.WeightOption = e.value
                this.setState({ SalesOrder: temporal })
                break;
            default:
                break;
        }
    }

    changePriceLevel(id) {
        const temporal = this.state.SalesOrder
        switch (id) {
            case "003":
                temporal.Customer.PriceLevel = '4'
                this.setState({ SalesOrder: temporal })
                break;
            case "000":
                temporal.Customer.PriceLevel = '3'
                this.setState({ SalesOrder: temporal })
                break;

            default:
                break;
        }
    }

    async OnlySaveSO() {
        const temporal = this.state.SalesOrder
        temporal.Detail = this.AsignLineSequence()
        temporal.idCompany = getValueCookie('CompanyId')
        temporal.username = getValueCookie('userName')
        temporal.company = getValueCookie('Company')
        temporal.OrderType = 'SalesOrder'
        await create_Delete_Update_Information('/sales/set/order', temporal)

    }

    async SaveAndPrint() {
        let button = document.getElementById('PortalOrderPrint')
        if (button) {
            button.click()
        }
    }

    disable() {
        this.setState({ disable: true })
    }

    denySalesOrder() {

        Swal.fire({
            title: 'Are you sure to Deny this Sales Order?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: `Yes, I'm sure.`,
            denyButtonText: `Not`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.ChangeStatusSalesOrder(3, 0)
            }
        })


    }

    async ChangeStatusSalesOrder(status, MAS90Status) {
        const temporal = this.state.SalesOrder

        let Detail = []
        let count = 1
        for (const Line of this.state.Products) {
            Detail.push(
                new SalesOrderDetail(count, Line.itemCode, (Line.completeDesc !== "null" && Line.completeDesc) ? Line.completeDesc : Line.abbreviatedDesc, Line.quantityOrdered, Line.UnitPrice, Line.ShipWeight, Line.Comment, Line.quantityOrdered * Line.ShipWeight, Line.quantityOrdered * Line.UnitPrice)
            )
            count++
        }
        let Order = new SalesOrder(temporal.NoOrder, temporal.Customer.CustomerNo, temporal.Customer.CustomerName, temporal.Customer.SalespersonNo, status, temporal.Type, '00', getActualDateQuery(), temporal.TotalWeight, temporal.TotalCost, temporal.Packing + ';' + temporal.WeightOption, MAS90Status, temporal.Warehouse, Detail)
        await getInformationWithDataGlobalSales('/Sales/set', Order)
    }

    render() {
        return (
            <div >
                <LoadingWindow ref={this.ModalLoading} />

                <p className='text-center display-1 pb-2' >Portal Sales Order</p>
                <div className='row pt-5 pb-2'>
                    <div className='col-sm-12 grayBackground'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='row pt-4 pb-4 text-center'>
                                            <div className='col-sm-12 pb-4 InputTextBolder text-center'>
                                                <div className="d-grid gap-2">
                                                    <PortalOrderList disabled={false} headerName='' nombrePadre="SalesOrderListSO" Padre={this} />
                                                </div>
                                            </div>
                                            <div className='col-sm-12'>
                                                <div className='row'>
                                                    <div className='col-5 InputTextBolder text-start'><p>Sales Order No: </p></div>
                                                    <div className='col-7 textTableSize text-start'>{this.state.SalesOrder.NoOrder}</div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12'>
                                                <div className='row'>
                                                    <div className='col-5 InputTextBolder text-start'><p>Sales Person No: </p></div>
                                                    <div className='col-7 textTableSize text-start'>{this.state.SalesOrder.Customer.SalespersonNo}</div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12'>
                                                <div className='row'>
                                                    <div className='col-5 InputTextBolder text-start'><p>Price Level: </p></div>
                                                    <div className='col-7 textTableSize text-start'>{this.state.SalesOrder.Customer.PriceLevel}</div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12' hidden={getValueCookie('CompanyId') === '6'}>
                                                <div className='row' >
                                                    <div className='col-12 InputTextBolder text-start'><p>Type of Packaging: </p></div>
                                                    <div className='col-12 pb-3'>
                                                        <Select key={this.state.SalesOrder.Packing} value={this.state.selectOptions1[(getIndexElement(this.state.selectOptions1, 'value', this.state.SalesOrder.Packing))]} isDisabled={this.state.SalesOrder.NoOrder === ''} className='fs-4 text-start' options={this.state.selectOptions1} onChange={(e) => this.onChangeSelects(e, "Packing")} placeholder="Select Type of Packaging" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12' hidden={getValueCookie('CompanyId') === '6'}>
                                                <div className='row' >
                                                    <div className='col-12 InputTextBolder text-start'><p>Need to be Weighed?: </p></div>
                                                    <div className='col-12 pb-3'>
                                                        <Select key={this.state.SalesOrder.WeightOption} value={this.state.selectOptions3[(getIndexElement(this.state.selectOptions3, 'value', this.state.SalesOrder.WeightOption))]} isDisabled={this.state.SalesOrder.NoOrder === ''} className='fs-4 text-start' options={this.state.selectOptions3} onChange={(e) => this.onChangeSelects(e, "Weight")} placeholder="Select Weight Option" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-1'></div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='row pb-2 '>
                                    <div className='col-12 grayBackground'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <div className='row'>
                                                    <div className='col-sm-12 pt-2 TitleText InputTextBolder'>
                                                        <p>Type:</p>
                                                    </div>
                                                    <div className='col-4'></div>
                                                    <div className='col-4  pb-3'>
                                                        <div className="form-check">
                                                            <input className="form-check-input textRadioButtonSize" value={'S'} onChange={this.valueRadioButton} id="PortalOrderStand" checked={this.state.SalesOrder.Type === 'S'} type="radio" name="typePortalOrder" />
                                                            <label className="form-check-label textRadioButtonSize InputTextBolderSimple" htmlFor="PortalOrderStand"  >
                                                                Standard
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input textRadioButtonSize" value={'Q'} onChange={this.valueRadioButton} id="PortalOrderQuot" checked={this.state.SalesOrder.Type === 'Q'} type="radio" name="typePortalOrder" />
                                                            <label className="form-check-label textRadioButtonSize InputTextBolderSimple" htmlFor='PortalOrderQuot' >
                                                                Quote
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row pt-5 pb-2'>
                    <div className='col-sm-6'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>Customer No: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.CustomerNo}</div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>Name: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.CustomerName}</div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>Address Line: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.AddressLine1}</div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>City: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.City}</div>
                                </div>
                            </div>
                            <div hidden={getValueCookie('CompanyId') !== '6'} className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>Unique Tracking: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.uniqueTrack}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>Zip Code: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.ZipCode}</div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>State: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.State}</div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>Telphone No: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.TelephoneNo}</div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-6 InputTextBolder'><p>Email Address: </p></div>
                                    <div className='col-6 textTableSize'>{this.state.SalesOrder.Customer.EmailAddress}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row pb-2'>
                    <div hidden={Number(getValueCookie('SubCompanyId')) !== 5}>
                        <div className='col-sm-12 InputTextBolder'><p>Warehouse: </p></div>
                        <div className='col-sm-12 pb-3'>
                            <Select value={this.state.selectOptions2[(getIndexElement(this.state.selectOptions2, 'value', this.state.SalesOrder.Warehouse))]} isDisabled={false} className='fs-4 text-start' options={this.state.selectOptions2} onChange={(e) => this.onChangeSelects(e, "Warehouse")} placeholder="Select Warehouse" />
                        </div>
                    </div>
                    <div className='col-sm-6 pt-2'>
                        <div className="input-group input-group-lg flex-nowrap">
                            <SearchUPC ComponentName={'SalesOrder'} disabled={this.state.SalesOrder.Customer.CustomerNo === ''} Padre={this} priceLevel={this.state.SalesOrder.Customer.PriceLevel} />
                        </div>
                    </div>
                    <div className='col-sm-6 pt-2'>
                        {getValueCookie('CompanyId') === '6' ?
                            <Catalogue2 CustomerNo={this.state.SalesOrder.Customer.CustomerNo} hidden={false} id='PortalCatalogue2' PriceLevel={this.state.SalesOrder.Customer.PriceLevel} Array={'Products'} nombrePadre={"SalesOrder"} colorButton='blueButton' Padre={this} disabled={this.state.SalesOrder.Customer.CustomerNo === ''} titleButton='Open Catalogue' needPrice={getValueCookie('SalesPerson') === '0010' ? false : true} needCost={getValueCookie('SalesPerson') === '0010' ? true : false} />
                            :
                            <Catalogue CustomerNo={this.state.SalesOrder.Customer.CustomerNo} onClick={() => this.disable()} PriceLevel={this.state.SalesOrder.Customer.PriceLevel} Array={'Products'} disabled={this.state.SalesOrder.Customer.CustomerNo === '' || this.state.disable} nombrePadre={"SalesOrder"} Padre={this} needPrice={getValueCookie('SalesPerson') === '0010' ? false : true} needCost={getValueCookie('SalesPerson') === '0010' ? true : false} />
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 tableFixHead'>
                        <table className='table align-middle'>
                            <thead className='thead'>
                                <tr className='bg-primary text-white'>
                                    <th className='text-center bg-primary '>Item Code</th>
                                    <th className='text-center bg-primary '>Description</th>
                                    <th className='text-center bg-primary '>Quantity Ordered</th>
                                    <th className='text-center bg-primary '>Weight (lbs)</th>
                                    <th className='text-center bg-primary '>Total <br />Weight (lbs)</th>
                                    <th className='text-center bg-primary '>Unit Price</th>
                                    <th className='text-center bg-primary '></th>
                                    <th className='text-center bg-primary '>Total</th>
                                    <th className='text-center bg-primary '></th>
                                    <th className='text-center bg-primary '></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Products.map((item, i) => (
                                    <tr key={i} hidden={item.Visible === 0} className={item.duplicate ? item.duplicate : (item.UnitPrice <= 0 ? 'bg-warning' : '')}>
                                        <td className='text-start'>{item.itemCode}</td>
                                        <td className='text-start'>{(item.completeDesc !== "null" && item.completeDesc) ? item.completeDesc : item.abbreviatedDesc}</td>
                                        <td>
                                            <div className="input-group  input-group-lg">
                                                <input type="number" id={'quantityOrderedPortalOrder_' + i} min={0} defaultValue={item.quantityOrdered} onChange={(e) => this.onTarget(e, item)} className="form-control text-center inputResize" />
                                            </div>
                                        </td>
                                        <td className='text-center textTableSize text-secondary' >{NumberFormat(pricesFormat(item.ShipWeight)) + ' lbs'}</td>
                                        <td className='text-center textTableSize text-secondary'> {NumberFormat(pricesFormat(item.quantityOrdered * item.ShipWeight)) + ' lbs'}</td>
                                        <td >
                                            <div className="input-group input-group-lg flex-nowrap">
                                                <span className="input-group-text">$</span>
                                                <input type="number" id={'unitPricePortalOrder_' + i} min={0} step={0.01} defaultValue={Number(item.UnitPrice).toFixed(2)} onChange={(e) => this.onTarget(e, item)} className="form-control text-end inputResize" />
                                            </div>
                                        </td>
                                        <td className='text-end textTableSize text-success'>
                                            $
                                        </td>
                                        <td className='text-end textTableSize text-success'>{NumberFormat(Number(item.totalCost).toFixed(2))}</td>
                                        <td>
                                            <div className="d-grid gap-1">
                                                <button type="button" onClick={() => this.addComentary(item)} className="btn orangeButton btn-lg text-wrap">Comment</button>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-grid gap-2">
                                                <button type="button" onClick={() => this.removeProduct(item)} className="btn redButton btn-lg"><AiOutlineCloseCircle /></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot className='tfoot'>
                                <tr className='bg-dark text-white'>
                                    <td></td>
                                    <td className='text-center textTableSize'>Totals</td>
                                    <td className='text-center textTableSize'>{NumberFormat(this.state.SalesOrder.TotalQuantityOrdered)}</td>
                                    <td></td>
                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(this.state.SalesOrder.TotalWeight) + ' lbs')} </td>
                                    <td></td>
                                    <td className='text-end textTableSize'>$</td>
                                    <td className='text-end textTableSize'>{NumberFormat(pricesFormat(this.state.SalesOrder.TotalCost))}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className='row pb-2'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="d-grid gap-2">
                                    <button disabled={this.state.Products.length === 0} type="button" onClick={() => this.createSalesOrder(1)} className="btn greenButton btn-lg"><AiTwotoneSave /> Accept Order</button>
                                </div>
                            </div>
                            <div className='col-12 pt-2'>
                                <div className="d-grid gap-2">
                                    <button disabled={this.state.Products.length === 0} type="button" onClick={() => this.denySalesOrder()} className="btn redButton btn-lg"><AiTwotoneSave /> Reject Order</button>
                                </div>
                            </div>
                            <div className='col-12'>
                                {
                                    MobileDisp() ?
                                        <OrderPDF id={"PortalOrderPrint"} colorButton="orangeButton" title="Sales Order Print"
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            OrderTitle="Sales Order"
                                            contactInfo={this.state.companyPrintHeader}
                                            OrderInfo1={["Order No:", this.state.SalesOrder.NoOrder, "Sales Person:", this.state.SalesOrder.Customer.SalespersonNo,
                                                "Customer No:", this.state.SalesOrder.Customer.CustomerNo, "Date:", DateFormatMAS90(this.state.SalesOrder.Date),
                                                "Customer Name:", this.state.SalesOrder.Customer.CustomerName, "Print By:", getValueCookie('userName'),
                                                "Phone:", this.state.SalesOrder.Customer.TelephoneNo, "Printing Date:", FormatQueryReturnDateForReport(getActualDateUTC()),
                                                "Address:", this.state.SalesOrder.Customer.AddressLine1, "Type:", this.TypeInLetters(this.state.SalesOrder.Type),
                                                "City:", this.state.SalesOrder.Customer.City, "Zip Code:", this.state.SalesOrder.Customer.ZipCode,
                                                "State:", this.state.SalesOrder.Customer.State, "", ""
                                            ]}
                                            headerTable={["Item Code", "", "Description", "", "Quantity \n Ordered", "", "Unit \n Price", "", "Total"]}
                                            bodyTable={this.detailToPrint()}
                                            headerBodyTable={["itemCode", "", "abbreviatedDesc", "", "quantityOrdered", "dolar", "UnitPrice", "dolar", "totalCost"]}
                                            bottomInfo={["", "", "", "TOTALS", NumberFormat(this.state.SalesOrder.TotalQuantityOrdered), "", "", "$", NumberFormat(pricesFormat(this.state.SalesOrder.TotalCost))]}
                                            styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoNormalLeft", "ColumnaTextoLargo2", "ColumnaTextoNormalLeft", "ColumnaCantidades", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios"]}
                                            hidden={true}
                                            comment={""}
                                            breakpoint={52}
                                            keysDecimalFormat={["UnitPrice", "totalCost"]}
                                            disabled={this.state.SalesOrder.Customer.CustomerNo === ''}
                                            titleButton='Print Order'
                                        />
                                        :
                                        <DownloadOrderPDF id={"PortalOrderPrint"} colorButton="orangeButton" title="Sales Order Print"
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            OrderTitle="Sales Order"
                                            contactInfo={this.state.companyPrintHeader}
                                            OrderInfo1={["Order No:", this.state.SalesOrder.NoOrder, "Sales Person:", this.state.SalesOrder.Customer.SalespersonNo,
                                                "Customer No:", this.state.SalesOrder.Customer.CustomerNo, "Date:", DateFormatMAS90(this.state.SalesOrder.Date),
                                                "Customer Name:", this.state.SalesOrder.Customer.CustomerName, "Print By:", getValueCookie('userName'),
                                                "Phone:", this.state.SalesOrder.Customer.TelephoneNo, "Printing Date:", FormatQueryReturnDateForReport(getActualDateUTC()),
                                                "Address:", this.state.SalesOrder.Customer.AddressLine1, "Type:", this.TypeInLetters(this.state.SalesOrder.Type),
                                                "City:", this.state.SalesOrder.Customer.City, "Zip Code:", this.state.SalesOrder.Customer.ZipCode,
                                                "State:", this.state.SalesOrder.Customer.State, "", ""
                                            ]}
                                            docname={this.state.SalesOrder.NoOrder}
                                            headerTable={["Item Code", "", "Description", "", "Quantity \n Ordered", "", "Unit \n Price", "", "Total"]}
                                            bodyTable={this.detailToPrint()}
                                            headerBodyTable={["itemCode", "", "abbreviatedDesc", "", "quantityOrdered", "dolar", "UnitPrice", "dolar", "totalCost"]}
                                            bottomInfo={["", "", "", "TOTALS", NumberFormat(this.state.SalesOrder.TotalQuantityOrdered), "", "", "$", NumberFormat(pricesFormat(this.state.SalesOrder.TotalCost))]}
                                            styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoNormalLeft", "ColumnaTextoLargo2", "ColumnaTextoNormalLeft", "ColumnaCantidades", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios"]}
                                            hidden={true}
                                            comment={""}
                                            breakpoint={52}
                                            keysDecimalFormat={["UnitPrice", "totalCost"]}
                                            disabled={this.state.SalesOrder.Customer.CustomerNo === ''}
                                            titleButton='Print Order'
                                        />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                </div>
                <SmallModal centered={false} size={'xl'} title={'Balance: ' + this.state.SalesOrder.Customer.CustomerName} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row pt-2'>
                        <div className='col-sm-12 pb-2'>
                            <h3>Terms Code: {this.state.Balance.TermsCode}</h3>
                        </div>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table'>
                                <thead className='thead'>
                                    <tr className='bg-dark text-white'>
                                        <th className='text-center bg-dark'>Invoice No</th>
                                        <th className='text-center bg-dark'>Date</th>
                                        <th className='text-center bg-dark'>Due Date</th>
                                        <th className='text-center bg-dark'>Payment Delay Days</th>
                                        <th className='text-center bg-dark'>Customer Name</th>
                                        <th className='text-center bg-dark'>Sales Person</th>
                                        <th></th>
                                        <th className='text-center bg-dark'>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Balance.HeaderBalance.map((element, e) => (
                                            <tr className={element.InvoiceNo === this.state.Balance.SelectedInvoice ? 'bg-warning' : ''} onClick={() => this.getDetailBalance(element)} key={e}>
                                                <td className='text-start'>{element.InvoiceNo}</td>
                                                <td className='text-center'>{element.InvoiceDate}</td>
                                                <td className='text-center'>{element.InvoiceDueDate}</td>
                                                <td className='text-center'>{element.PaymentDelayDays}</td>
                                                <td className='text-center'>{this.state.SalesOrder.Customer.CustomerName}</td>
                                                <td className='text-center'>{element.SalespersonNo}</td>
                                                <td>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(element.Balance))}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr className='bg-secondary text-center text-white'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>TOTAL:</td>
                                        <td>$</td>
                                        <td className='text-end'>{NumberFormat(pricesFormat(this.state.Balance.TotalBalance))}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='col-sm-12'>
                            <h3 className='text-start'>Detail: {this.state.Balance.SelectedInvoice}</h3>
                        </div>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table'>
                                <thead className='thead'>
                                    <tr className='bg-dark text-white'>
                                        <th className='text-center bg-dark'>Invoice No</th>
                                        <th className='text-center bg-dark'>Invoice Type</th>
                                        <th className='text-center bg-dark'>Transaction Type</th>
                                        <th className='text-center bg-dark'>Transaction Date</th>
                                        <th></th>
                                        <th className='text-center bg-dark'>Transaction Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Balance.Detail.map((element, e) => (
                                            <tr key={e}>
                                                <td className='text-start'>{element.InvoiceNo}</td>
                                                <td className='text-center'>{element.InvoiceType}</td>
                                                <td className='text-center'>{element.TransactionType}</td>
                                                <td className='text-center'>{DateFormatMAS90(element.TransactionDate)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(element.TransactionAmt))}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot'>
                                </tfoot>
                            </table>
                        </div>
                        <div className='col-sm-6 pt-2'>
                            {
                                MobileDisp() ?
                                    <OrderPDF id={"CurrentBalancePrintPortalOrder"} colorButton="orangeButton" title="Sales Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Current Balance"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={[
                                            "Customer No:", this.state.SalesOrder.Customer.CustomerNo, "Print By:", getValueCookie('userName'),
                                            "Customer Name:", this.state.SalesOrder.Customer.CustomerName, "Printing Date:", FormatQueryReturnDateForReport(getActualDateUTC()),
                                            "Phone:", this.state.SalesOrder.Customer.TelephoneNo, "Terms Code:", this.state.Balance.TermsCode,
                                            "Address:", this.state.SalesOrder.Customer.AddressLine1, "Zip Code:", this.state.SalesOrder.Customer.ZipCode,
                                            "City", this.state.SalesOrder.Customer.City, "", ""
                                        ]}
                                        headerTable={["Invoice No", "Date", "Due\nDate", "Payment Delay Days", "Customer\nName", "Sales\nPerson", "", "Balance"]}
                                        bodyTable={this.state.Balance.HeaderBalance}
                                        headerBodyTable={["InvoiceNo", "InvoiceDate", "InvoiceDueDate", "PaymentDelayDays", "CustomerName", "SalespersonNo", "dolar", "Balance"]}
                                        bottomInfo={["", "", "", "", "", "TOTAL", "$", NumberFormat(pricesFormat(this.state.Balance.TotalBalance))]}
                                        styleColumns={["ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaCantidades", "ColumnaTextoLargo2", "ColumnaCantidades", "Dolar", "ColumnaPrecios"]}
                                        hidden={false}
                                        comment={""}
                                        keysDecimalFormat={["Balance"]}
                                        breakpoint={28}
                                        disabled={false}
                                        titleButton='Print Balance'
                                    />
                                    :
                                    <DownloadOrderPDF id={"CurrentBalancePrintPortalOrder"} colorButton="orangeButton" title="Sales Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        docname={'Balance ' + this.state.SalesOrder.Customer.CustomerName}
                                        OrderTitle="Current Balance"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={[
                                            "Customer No:", this.state.SalesOrder.Customer.CustomerNo, "Print By:", getValueCookie('userName'),
                                            "Customer Name:", this.state.SalesOrder.Customer.CustomerName, "Printing Date:", FormatQueryReturnDateForReport(getActualDateUTC()),
                                            "Phone:", this.state.SalesOrder.Customer.TelephoneNo, "Terms Code:", this.state.Balance.TermsCode,
                                            "Address:", this.state.SalesOrder.Customer.AddressLine1, "Zip Code:", this.state.SalesOrder.Customer.ZipCode,
                                            "City", this.state.SalesOrder.Customer.City, "", ""
                                        ]}
                                        headerTable={["Invoice No", "Date", "Due\nDate", "Payment Delay Days", "Customer\nName", "Sales\nPerson", "", "Balance"]}
                                        bodyTable={this.state.Balance.HeaderBalance}
                                        headerBodyTable={["InvoiceNo", "InvoiceDate", "InvoiceDueDate", "PaymentDelayDays", "CustomerName", "SalespersonNo", "dolar", "Balance"]}
                                        bottomInfo={["", "", "", "", "", "TOTAL", "$", NumberFormat(pricesFormat(this.state.Balance.TotalBalance))]}
                                        styleColumns={["ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaCantidades", "ColumnaTextoLargo2", "ColumnaCantidades", "Dolar", "ColumnaPrecios"]}
                                        hidden={false}
                                        comment={""}
                                        keysDecimalFormat={["Balance"]}
                                        breakpoint={28}
                                        disabled={false}
                                        titleButton='Print Balance'
                                    />
                            }
                        </div>
                        <div className='col-sm-6 pt-2'>
                            <div className="d-grid gap-2">
                                <button type="button" onClick={() => this.handleModalClose("showModal1")} className="btn redButton btn-lg">Close</button>
                            </div>
                        </div>
                    </div>
                </SmallModal>
            </div>
        )
    }
}