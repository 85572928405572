import React, { Component } from 'react'
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import LoadingWindow from '../../../components/LoadingWindow';
import { BsCash } from "react-icons/bs";
import CustomerList from '../../../components/CustomerList';
import InvoicePerCustomer from '../../../components/InvoicesPerCustomer/InvoicesPerCustomer';
import { NumberFormat, OrderArray } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { getValueCookie } from '../../../services/cookieService';
import { create_Delete_Update_Information, getInformationWithData } from '../../../services/CABE';
import Select from 'react-select';
import { getIndexElement } from '../../../functions/searchInObject';
import { confirmCloseAlert } from '../../../functions/alerts';
export default class PaymentRecord extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        Payment: {
            DepositNo: '',
            Comment: '',
            BankCode: '',
            BankName: '',
            Date: '',
            CashDepositAmount: 0,
            CheckNo: '',
            Customer: {
                AddressLine1: '',
                City: '',
                CustomerName: '',
                CustomerNo: '',
                EmailAddress: '',
                PriceLevel: undefined,
                SalespersonNo: '',
                State: '',
                TelephoneNo: '',
                ZipCode: '',
                uniqueTrack: '',
            },
            Invoices: [],

        }
    }

    clearAll(){
        this.setState({
            Payment: {
                DepositNo: '',
                Comment: '',
                BankCode: '',
                BankName: '',
                Date: '',
                CashDepositAmount: 0,
                CheckNo: '',
                Customer: {
                    AddressLine1: '',
                    City: '',
                    CustomerName: '',
                    CustomerNo: '',
                    EmailAddress: '',
                    PriceLevel: undefined,
                    SalespersonNo: '',
                    State: '',
                    TelephoneNo: '',
                    ZipCode: '',
                    uniqueTrack: '',
                },
                Invoices: [],
    
            }
        })
        document.getElementById('CheckNoPaymentRecord').value=''
        document.getElementById('DatePaymentRecord').value=''
        document.getElementById('DepositNoPaymentRecord').value=''
        document.getElementById('PaymentRecordComment').value=''
        document.getElementById('CashDepositAmountPaymentRecord').value=''
        
    }

    async CreateNewPayment() {
        await this.clearAll()
        const data = {
            type: 'PaymentNo',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)
        if (result.status.code === 1) {
            const temporal = this.state.Payment
            temporal.CheckNo = 'M' + result.data[0].correlative.toString()
            this.setState({ Payment: temporal })
            document.getElementById('CheckNoPaymentRecord').value = 'M' + result.data[0].correlative.toString()
        }
    }

    async SavePaymentRecord() {
        const data = {
            Payment: this.state.Payment,
            idCompany: Number(getValueCookie('CompanyId')),
            username: getValueCookie('userName'),
            OrderType: 'Payment'
        }
        console.log(data)
     
        const result = await create_Delete_Update_Information('/invoice/payment/set', data)
        if (result.status.code === 1) {
            confirmCloseAlert('correct','The Payment was registered successfully!')
        }else{
            confirmCloseAlert('incorrect','Whoops, Something is wrong. Please, Try Again!')
        }
        
    }

    handleChange(e) {
        const change = e.value.split('{')
        if (change.length === 2) {
            const temporal = this.state.Payment
            temporal.BankCode = change[0]
            temporal.BankName = change[1]

            this.setState({ Payment: temporal })
        }
    }

    changeInputs = e => {
        const id = e.target.id
        const value = e.target.value
        const temporal = this.state.Payment
        switch (id) {
            case "DepositNoPaymentRecord":
                temporal.DepositNo = value
                this.setState({ Payment: temporal })
                break;
            case "PaymentRecordComment":
                temporal.Comment = value
                this.setState({ Payment: temporal })
                break;
            case "DatePaymentRecord":
                temporal.Date = value
                this.setState({ Payment: temporal })
                break;
            case "CashDepositAmountPaymentRecord":
                temporal.CashDepositAmount = Number(value)
                this.setState({ Payment: temporal })
                this.AsignAutomaticAumount(Number(value))
                break;
            default:
                break;
        }
    }
    changeAmtInputs = (e, InvoiceNo) => {
        const temporal = this.state.Payment
        const index = getIndexElement(temporal.Invoices, 'InvoiceNo', InvoiceNo)
        const value = Number(e.target.value)
        if (index !== -1) {
            temporal.Invoices[index].Amount = value
        }
        this.setState({ Payment: temporal })
        //this.refreshInputs()
    }

    async AsignAutomaticAumount() {
        let amt = this.state.Payment.CashDepositAmount
        const invoices = this.state.Payment
        let temporal = JSON.parse(JSON.stringify(this.state.Payment.Invoices))
        temporal = OrderArray(temporal, 'Balance', false)
        for (const invoice of temporal) {
            const index = getIndexElement(invoices.Invoices, 'InvoiceNo', invoice.InvoiceNo)
            if (amt > 0) {

                if (index !== -1) {
                    if (invoices.Invoices[index].Balance > amt) {
                        invoices.Invoices[index].Amount = amt
                        amt = 0
                    } else {
                        invoices.Invoices[index].Amount = invoices.Invoices[index].Balance
                        amt = amt - invoices.Invoices[index].Balance
                    }
                }
            } else {
                invoices.Invoices[index].Amount = 0
            }
        }
        await this.setState({ Payment: invoices })
        await this.refreshInputs()

    }

    refreshInputs() {
        for (const invoice of this.state.Payment.Invoices) {
            if (document.getElementById('AmtToPay_' + invoice.InvoiceNo)) {
                document.getElementById('AmtToPay_' + invoice.InvoiceNo).value = pricesFormat(invoice.Amount)
            }
        }
    }


    render() {
        return (
            <div className='PaymentRecordContainer'>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Payment Record</p>
                <div className='row pt-2 pb-2'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row pb-4'>
                                    <div className='col-12'>
                                        <div className="d-grid gap-2">
                                            <button onClick={() => this.CreateNewPayment()} type="button" className="btn purpleButton btn-lg">New Payment Record <BsCash /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-12 text-start pText'><p>Check No:</p></div>
                                    <div className='col-12'>
                                        <input className="form-control form-control-lg" value={this.state.Payment.CheckNo} disabled id='CheckNoPaymentRecord' type="text" />
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-12 text-start pText'><p>Banck Code:</p></div>
                                    <div className='col-12'>
                                        <Select onChange={this.handleChange.bind(this)} isDisabled={this.state.Payment.CheckNo===''} value={{ label: this.state.Payment.BankCode + ' ' + this.state.Payment.BankName, value: this.state.Payment.BankCode + ' ' + this.state.Payment.BankName }} options={[{ label: 'A Bank of America', value: 'A{Bank of America' }]} className='fs-4 text-start' placeholder="Select Vendor" />

                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-12 text-start pText'><p>Date:</p></div>
                                    <div className='col-12'>
                                        <input disabled={this.state.Payment.CheckNo===''} onChange={this.changeInputs} className="form-control form-control-lg" id='DatePaymentRecord' type="date" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row pb-4'>
                                    <div className='col-12'>
                                        <div className='d-grid gap-2'>
                                            <CustomerList disabled={this.state.Payment.CheckNo===''} headerName='Customer' nombrePadre="Payment" Padre={this} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-12 text-start pText'><p>Deposit No:</p></div>
                                    <div className='col-12'>
                                        <input disabled={this.state.Payment.CheckNo===''} onChange={this.changeInputs} className="form-control form-control-lg" id='DepositNoPaymentRecord' type="text" />
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-12 text-start pText'><p>Comment:</p></div>
                                    <div className='col-12 textAreaGeneral'>
                                        <textarea disabled={this.state.Payment.CheckNo===''} onChange={this.changeInputs} className="form-control" id='PaymentRecordComment' rows="4"></textarea>
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-12 text-start pText'><p>Cash Deposit Amount:</p></div>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg flex-nowrap">
                                            <span className="input-group-text">$</span>
                                            <input disabled={this.state.Payment.CheckNo===''} onChange={this.changeInputs} className="form-control text-end form-control-lg inputResize" placeholder='0.00' id='CashDepositAmountPaymentRecord' type="number" min={0.00} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 pt-5 pb-3'>
                                <div className='row backgroundGray'>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>Customer No: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.CustomerNo}</div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>Name: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.CustomerName}</div>
                                                </div>

                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>Address Line: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.AddressLine1}</div>
                                                </div>

                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>City: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.City}</div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>Zip Code: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.ZipCode}</div>
                                                </div>

                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>State: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.State}</div>
                                                </div>

                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>Telphone No: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.TelephoneNo}</div>
                                                </div>

                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 InputTextBolder'><p>Email Address: </p></div>
                                                    <div className='col-6 textTableSize'>{this.state.Payment.Customer.EmailAddress}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='co-12 pb-4'>
                                <div className="d-grid gap-2">
                                    <InvoicePerCustomer FatherName="Payment" Father={this} Detail="Invoices" CustomerName={this.state.Payment.Customer.CustomerName} CustomerNo={this.state.Payment.Customer.CustomerNo} disabled={this.state.Payment.Customer.CustomerNo === ''} buttonText="Customer Actual Balance" buttonProperties={"orangeButton"} />
                                </div>
                            </div>
                            <div className="col-12 tableFixHead">
                                <table className='table align-middle'>
                                    <thead className='thead'>

                                        <tr className='bg-dark text-white'>
                                            <th className='text-center bg-dark'>Invoice No</th>
                                            <th className='text-center bg-dark'>Date</th>
                                            <th className='text-center bg-dark'>Due Date</th>
                                            <th className='text-center bg-dark'>Payment Delay Days</th>
                                            <th className='text-center bg-dark'></th>
                                            <th className='text-center bg-dark'>Balance</th>
                                            <th className='text-center bg-dark'>Amount to Pay</th>

                                        </tr>

                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.Payment.Invoices.map((element, e) => (
                                                <tr key={e}>

                                                    <td className='text-start'>{element.InvoiceNo}</td>
                                                    <td className='text-center'>{element.InvoiceDate}</td>
                                                    <td className='text-center'>{element.InvoiceDueDate}</td>
                                                    <td className='text-center'>{element.PaymentDelayDays}</td>
                                                    <td>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(element.Balance))}</td>
                                                    <td>
                                                        <div className="input-group input-group-lg flex-nowrap">
                                                            <span className="input-group-text">$</span>
                                                            <input onChange={(e) => this.changeAmtInputs(e, element.InvoiceNo)} type="number" id={'AmtToPay_' + element.InvoiceNo} min={0} step={0.01} defaultValue={pricesFormat(element.Amount)} className="form-control text-end inputResize" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12'>

                                <div className="d-grid gap-2">
                                    <button disabled={this.state.Payment.CheckNo===''} onClick={() => this.SavePaymentRecord()} type="button" className="btn greenButton btn-lg">Save Payment <BsCash /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                </div>

            </div>
        )
    }
}