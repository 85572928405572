import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setNewCookie = async (identification, value, timeExpiration) => {
    var tiempo = 24 * 60 * 60 * 1000;
    var expires = new Date(Date.now() + tiempo);

    cookies.set(identification, value, {
        path: "/",
        expires: expires,
        httpOnly: false,
        secure: false,
        sameSite: 'lax'
    });
}

export const getValueCookie = (identification) => {
    return cookies.get(identification)
}

export const removeCookie = (identification) => {
    cookies.remove(identification)
}

export default { setNewCookie, getValueCookie, removeCookie }