import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { getValueCookie } from '../../services/cookieService';
import { getInformationWithData } from '../../services/CABE';
import "../../css/inventory-style.css"
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { getIndexElement } from '../../functions/searchInObject';
import { pricesFormat } from '../../functions/pricesFormat';
import { ItemDesc, NumberFormat, OrderArray } from '../../functions/generalFunctions';
import { v4 as uuidv4 } from 'uuid';
import ExcelDocument from '../../components/ExcelDocument';
import { getDateFromReports } from '../../functions/dateFormat';
import { ReportPriceMargin } from '../../functions/generateDataSetExcel';
import LoadingWindow from '../../components/LoadingWindow';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
export default class MargenesPrecio extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        Products: [],
        ProductsFilter: [],
        TableHeaders: [],
        flag: true
    }

    async getProductsMargin() {
        this.ModalLoading.current.showState(true);
        if (getValueCookie('CompanyId') !== '6') {
            const data = {
                idCompany: Number(getValueCookie('CompanyId'))
            }
            const result = await getInformationWithData('/Items/margin', data)

            if (result.status.code === 1) {
                let temporal = []

                for (const item of result.result) {
                    const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
                    if (index === -1) {
                        const it = {
                            ItemCode: item.ItemCode,
                            ItemCodeDesc: item.ItemCodeDesc,
                            ProductLine: item.ProductLineDesc,
                            UnitCost: item.LastTotalUnitCost,
                            PricesLevels: []

                        }
                        const priceLev = {
                            PriceLevel: item.CustomerPriceLevel,
                            LevelCost: item.DiscountMarkup1 === 0 ? '-' : item.DiscountMarkup1,
                            RealMargin: item.DiscountMarkup1 === 0 ? '-' : ((Number(item.DiscountMarkup1) - Number(item.LastTotalUnitCost)) / (Number(item.DiscountMarkup1))) * 100
                        }
                        it.PricesLevels.push(priceLev)
                        temporal.push(it)
                    } else {
                        const priceLev = {
                            PriceLevel: item.CustomerPriceLevel,
                            LevelCost: item.DiscountMarkup1 === 0 ? '-' : item.DiscountMarkup1,
                            RealMargin: item.DiscountMarkup1 === 0 ? '-' : ((Number(item.DiscountMarkup1) - Number(temporal[index].UnitCost)) / (Number(item.DiscountMarkup1))) * 100
                        }
                        temporal[index].PricesLevels.push(priceLev)
                    }
                }
                let headers = []
                for (const item of temporal) {
                    for (const pricelevel of item.PricesLevels) {
                        const index = getIndexElement(headers, 'Level', pricelevel.PriceLevel)
                        if (index === -1) {
                            const head = {
                                Level: pricelevel.PriceLevel,
                                Title1: "Level " + pricelevel.PriceLevel,
                                Title2: "Real Mark-Up Level " + pricelevel.PriceLevel
                            }
                            headers.push(head)
                        }
                    }


                }
                for (const item of temporal) {
                    if (item.PricesLevels.length !== headers.length) {
                        for (const head of headers) {
                            const index = getIndexElement(item.PricesLevels, 'PriceLevel', head.Level)
                            if (index === -1) {
                                const priceLev = {
                                    PriceLevel: head.Level,
                                    LevelCost: '-',
                                    RealMargin: '-'
                                }
                                item.PricesLevels.push(priceLev)
                            }
                        }
                    }
                }
                temporal=await ItemDesc(temporal,'ItemCodeDesc','ItemCode')
                this.setState({ Products: temporal, ProductsFilter: temporal, TableHeaders: headers })
            }
        } else if (getValueCookie('CompanyId') === '6') {
            const data = {
                company: 'Altitude Atlanta',
                idcompany: '6',
                priceLevel: 1,
                Component: null
            }

            const invent = await getInformationWithData('/Items/get', data)
            
                //data.company = 'Mayaland Atlanta'
                //data.idcompany = '3'
                const respuesta2 = await getInformationWithData('/Items/get', data);

            
                invent.status.code = respuesta2.status.code
                for (const item of respuesta2.data) {
                    const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                    if (index !== -1) {
                        invent.data = invent.data.splice(index, 1)
                    }
                }
                invent.data = invent.data.concat(respuesta2.data)
           
                if(invent.status.code===1){
                    let temporal = []
                    for (const item of invent.data) {
                        const index = getIndexElement(temporal, 'itemCode', item.itemCode)
                        if (index === -1) {
                            const it = {
                                ItemCode: item.itemCode,
                                ItemCodeDesc: item.abbreviatedDesc,
                                ProductLine: item.ProductLineDesc,
                                UnitCost: item.LastTotalUnitCost/0.95,
                                PricesLevels: []
    
                            }
                            if(!item.UnitPriceALT){
                                item.UnitPriceALT=item.UnitPrice
                            }


                            const priceLev = {
                                PriceLevel: '1',
                                LevelCost: item.UnitPriceALT === 0 ? '-' : item.UnitPriceALT,
                                RealMargin: item.UnitPriceALT === 0 ? '-' : ((Number(item.UnitPriceALT) - (Number(item.LastTotalUnitCost)/0.95)) / (Number(item.UnitPriceALT))) * 100
                            }
                            it.PricesLevels.push(priceLev)
                            temporal.push(it)
                        } else {
                            const priceLev = {
                                PriceLevel: '1',
                                LevelCost: item.UnitPriceALT === 0 ? '-' : item.UnitPriceALT,
                                RealMargin: item.UnitPriceALT === 0 ? '-' : ((Number(item.UnitPriceALT) - Number(temporal[index].UnitCost)) / (Number(item.UnitPriceALT))) * 100
                            }
                            temporal[index].PricesLevels.push(priceLev)
                        }
                    }

                    let headers = []
                    for (const item of temporal) {
                        for (const pricelevel of item.PricesLevels) {
                            const index = getIndexElement(headers, 'Level', pricelevel.PriceLevel)
                            if (index === -1) {
                                const head = {
                                    Level: pricelevel.PriceLevel,
                                    Title1: "Level " + pricelevel.PriceLevel,
                                    Title2: "Real Mark-Up Level " + pricelevel.PriceLevel
                                }
                                headers.push(head)
                            }
                        }
    
    
                    }
                    for (const item of temporal) {
                        if (item.PricesLevels.length !== headers.length) {
                            for (const head of headers) {
                                const index = getIndexElement(item.PricesLevels, 'PriceLevel', head.Level)
                                if (index === -1) {
                                    const priceLev = {
                                        PriceLevel: head.Level,
                                        LevelCost: '-',
                                        RealMargin: '-'
                                    }
                                    item.PricesLevels.push(priceLev)
                                }
                            }
                        }
                    }

                    temporal=await ItemDesc(temporal,'ItemCodeDesc','ItemCode')        
    
                    this.setState({ Products: temporal, ProductsFilter: temporal, TableHeaders: headers })
                }

            


        }

        this.ModalLoading.current.showState(false);
    }

    contains(parametro, busqueda) {
        if(parametro&&busqueda){
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchPriceMargin = e => {

        let search = e.target.value;
        if(search){
            let Products = this.state.Products.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLine, search) ||
                    this.contains(item.ItemCodeDesc, search)
    
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });
            this.setState({ ProductsFilter: Products });
        }else{
            let Products=this.state.Products
            this.setState({ ProductsFilter: Products });
        }

    }

    OrderPriceMargins(option) {
        var temporal = this.state.ProductsFilter
        switch (option) {
            case "ItemCode":
                temporal = OrderArray(temporal, "ItemCode", this.state.flag)
                this.setState({ ProductsFilter: temporal, flag: !this.state.flag })
                break;
            case "ProductLine":
                temporal = OrderArray(temporal, "ProductLine", this.state.flag)
                this.setState({ ProductsFilter: temporal, flag: !this.state.flag })
                break;
            case "UnitCost":
                temporal = OrderArray(temporal, "UnitCost", this.state.flag)
                this.setState({ ProductsFilter: temporal, flag: !this.state.flag })
                break;
            default:
                break;
        }
    }

    OrderByPriceLevel(position, key) {
        var arreglo = this.state.ProductsFilter
        var l = arreglo.length

        if (this.state.flag) {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if ((arreglo[j].PricesLevels[position][key] === '-' ? '0' : arreglo[j].PricesLevels[position][key]) > (arreglo[j + 1].PricesLevels[position][key] === '-' ? '0' : arreglo[j + 1].PricesLevels[position][key])) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        } else {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if ((arreglo[j].PricesLevels[position][key] === '-' ? '0' : arreglo[j].PricesLevels[position][key]) < (arreglo[j + 1].PricesLevels[position][key] === '-' ? '0' : arreglo[j + 1].PricesLevels[position][key])) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        }

        this.setState({ ProductsFilter: arreglo, flag: !this.state.flag })
    }


    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Report of Price Margins</p>

                <div className='row pt-2'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="d-grid gap-2">
                                    <ExcelDocument hidden={this.state.Products.length === 0} archname={'Price Margins ' + getValueCookie('Company') + ' ' + getDateFromReports()} sheetname={'Price Margins ' + getValueCookie('Company')} data={ReportPriceMargin(this.state.TableHeaders, this.state.Products, '$')} />
                                </div>
                            </div>
                            <div className='col-6 pb-4 InputTextBolder text-center'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getProductsMargin()} type="button" className="btn blueButton btn-lg">Reload <BsArrowRepeat /></button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input type="text" autoComplete='off' className="form-control" id='searchPriceMarginItem' placeholder='Search Item by ItemCode, Line Product, Description...' onChange={this.searchPriceMargin} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                    <div className='col-12'>
                        <div className='row' hidden={this.state.Products.length === 0}>
                            <div className='col-12 tableFixHead'>
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th className='bg-dark' onClick={() => this.OrderPriceMargins("ItemCode")}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th className='bg-dark' onClick={() => this.OrderPriceMargins("ProductLine")}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th className='bg-dark'><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th className='bg-dark'></th>
                                            <th className='bg-dark' onClick={() => this.OrderPriceMargins("UnitCost")}><div className='row'><div className='col-12'>Last Unit Cost</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            {this.state.TableHeaders.map((head, h) => (
                                                <React.Fragment key={h} >
                                                    <th className={(h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen'}></th>
                                                    <th className={(h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen'} onClick={() => this.OrderByPriceLevel(h, "LevelCost")}><div className='row'><div className='col-12'>{head.Title1}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div> </div></th>
                                                    <th className={(h + 1) % 2 === 1 ? 'backgroundBlue' : 'backgroundGreen'} onClick={() => this.OrderByPriceLevel(h, "RealMargin")}><div className='row'><div className='col-12'>{head.Title2}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div> </div></th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.ProductsFilter.map((item, i) => (
                                                <tr key={i}>
                                                    <td className='text-start'>{item.ItemCode}</td>
                                                    <td className='text-start'>{item.ProductLine}</td>
                                                    <td className='text-start'>{item.ItemCodeDesc}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(item.UnitCost))}</td>
                                                    {item.PricesLevels.map((price) => (
                                                        <React.Fragment key={uuidv4()}>
                                                            <td className='text-end'>{price.LevelCost !== '-' ? '$' : '$'}</td>
                                                            <td className='text-end'>
                                                                {price.LevelCost !== '-' ? NumberFormat(pricesFormat(price.LevelCost)) : '0'}
                                                            </td>
                                                            <td className='text-center'>
                                                                {price.RealMargin !== '-' ? NumberFormat(pricesFormat(price.RealMargin)) + "%" : '0.00%'}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
