export class ListModules{
    constructor(){
        this.List=[]
    }
    addModule(Id,Name,Status){
        this.List.push(new Module(Id,Name,Status))
    }

}

export class Module{
    constructor(Id,Name,Status){
        this.Id=Id
        this.Name=Name
        this.Status=Status===0?true:false
    }
}

