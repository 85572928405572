import { getIndexElement } from "../../functions/searchInObject"

export class SalesByCustomerRoute{
    constructor(){
        this.Data=[]
    }

    addCustomer(CustomerNo,CustomerName,Total,Route,Customer){
        const index=getIndexElement(this.Data,'CustomerNo',CustomerNo)
        if(index!==-1){
            this.Data[index].addTotal(Total,Customer)
        }else{
            this.Data.push(new DetailSalesByCustomerRoute(CustomerNo,CustomerName,Total,Route,Customer))
        }
        this.Data.sort((a, b) => b.Total - a.Total)
    }
}

export class DetailSalesByCustomerRoute{
    constructor(CustomerNo,CustomerName,Total,Route,Customer){
        this.Route=Route
        this.CustomerNo=CustomerNo
        this.CustomerName=CustomerName
        this.Total=Total
        this.TotalNo=1
        this.Detail=[]
        this.Detail.push(Customer)
    }
    addTotal(Total,Customer){
        this.Total+=Total
        this.TotalNo+=1
        this.Detail.push(Customer)
       
    }
    
}

export class SalesPerMonth{
    constructor(day,total,totalsales,detail){
        this.day=day
        this.total=total
        this.totalsales=totalsales
        this.detail=detail
    }
}

export class SalesPerWeek{
    constructor(Week){
        this.Week=Week
        this.total=0
        this.totalsales=0
        this.detail=[]
    }

    addInfo(total,totalsales,detail){
        this.total+=total
        this.totalsales+=totalsales
        this.detail=this.detail.concat(detail)
    }


}