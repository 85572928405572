import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'

import SmallModal from '../SmallModal';
import { DateFormatMAS90,DeleteQueryFormat } from '../../functions/dateFormat';



export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title:'',
        Data:[],
        showModal1:false,
        OnHand:'',
        Descrip:''
    }

    getSpecificData(title,data,onHand,descrip){
        console.log(data)
        this.setState({
            Title:title,
            Data:data,
            OnHand:onHand,
            Descrip:descrip
        })
        this.handleModalOpen("showModal1")
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearState()
    }

    clearState(){
        this.setState({
            Title:'',
            Data:[]  
        })
    }



    render() {
        return (
            <>
                
                    <div className='row '>
                        <div className='col-sm-12'>
                            <h5>{this.props.Title}</h5>
                        </div>
                        <div className='col-sm-12 backgroundWhite'>

                            <div className='pb-3' />
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle'>
                                    <thead className='bg-primary text-white'>
                                        <tr>
                                            <th className='text-center bg-primary '>Item Code</th>
                                            <th className='text-center bg-primary '>Description</th>
                                            <th className='text-center bg-primary '>Short</th>
                                            <th className='text-center bg-primary '>On Hand</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(this.props.data).map((key, k) => (
                                            <tr onClick={()=>this.getSpecificData(key,this.props.data[key].detail,this.props.data[key].TotalQuantityOnHand,this.props.data[key].ItemCodeDesc)} key={k}>
                                                <td className='text-start'>{key}</td>
                                                <td className='text-start'>{this.props.data[key].ItemCodeDesc}</td>
                                                <td className='text-center'>{this.props.data[key].shorts.total}</td>
                                                <td className='text-center'>{this.props.data[key].TotalQuantityOnHand}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>


                    </div>
               
                    <SmallModal centered={true} size={'xl'} title={'ItemCode: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <h4>{"On Hand: "+this.state.OnHand}</h4>
                        <h4>{"Description: "+ this.state.Descrip}</h4>
                        <div className='col-1' />


                        <div className='col-12'>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle'>
                                    <thead className='bg-primary text-white'>
                                        <tr>
                                            <th className='text-center bg-primary '>Invoice No</th>
                                            <th className='text-center bg-primary '>Customer No</th>
                                            <th className='text-center bg-primary '>Customer Name</th>
                                            <th className='text-center bg-primary '>Invoce Date</th>
                                            <th className='text-center bg-primary '>Date of Last Purchase Order</th>
                                            <th className='text-center bg-primary '>Route</th>
                                            <th className='text-center bg-primary '>Quantity Ordered</th>
                                            <th className='text-center bg-primary '>Quantity Shipped</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Data.map((order, o) => (
                                            <tr key={o}>
                                                <td className='text-center'>{order.InvoiceNo}</td>
                                                <td className='text-center'>{order.CustomerNo}</td>
                                                <td className='text-left'>{order.BillToName}</td>
                                                <td className='text-center'>{DateFormatMAS90(order.InvoiceDate)}</td>
                                                <td className='text-center'>{DateFormatMAS90(DeleteQueryFormat(order.LastPODate))}</td>
                                                <td className='text-center'>{order.UDF_RUTA}</td>
                                                <td className='text-center'>{order.QuantityOrdered}</td>
                                                <td className='text-center'>{order.QuantityShipped}</td>
                                            </tr> 
                                        ))}
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>


            </>
        )
    }
}
