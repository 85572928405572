import { DateFormatMAS90 } from "../../../functions/dateFormat"

export class InventoryProductLine {
    Name
    LastDate
    TotalItem
    TotalCountItem
    TotalOKItem
    TotalWrongItem
    BinaryAccuracy
    ValuedAccuracy
    Advance
    Accumulated
    ListProducts
    TotalCost
    TotalValued
    KPI


    constructor(Name) {
        this.Name = Name
        this.LastDate = 'Not Counting Yet'
        this.TotalItem = 0
        this.TotalCountItem = 0
        this.TotalOKItem = 0
        this.TotalWrongItem = 0
        this.BinaryAccuracy = 0.00
        this.Advance = 0.00
        this.Accumulated = 0.00
        this.ListProducts=[]
        this.TotalCost=0
        this.TotalValued=0
        this.ValuedAccuracy=0
        this.KPI=0
    }

    addItem(){
        this.TotalItem+=1
    }
    addCountItem(){
        this.TotalCountItem+=1
    }
    addOkItem(){
        this.TotalOKItem+=1
    }
    addWrongItem(){
        this.TotalWrongItem+=1
    }
    calculateBinaryAccuracy(){
        let real= this.TotalCountItem===0?1:(this.TotalWrongItem/this.TotalCountItem)
        this.BinaryAccuracy=100-(real*100)
    }
    calculateValuedAccuracy(){
        if(this.TotalValued!==0){
            this.ValuedAccuracy=(1+(this.TotalCost/this.TotalValued))*100
        }
    }
    calculateAdvance(totalItems){
        this.Advance=(this.TotalCountItem/totalItems)*100
    }
    calculateTotalCost(value){
        this.TotalCost+=value        
    }
    calculateTotalValued(){
        this.TotalValued=0
        for (const item of this.ListProducts) {
            this.TotalValued+=item.TotalValued
        }
    }
    calculateKPI(){
        this.KPI=(this.BinaryAccuracy*0.3)+(this.ValuedAccuracy*0.7)
    }
}

export class AveragePerDay{
    Day
    Date
    TotalCost

    constructor(Date,TotalCost){
        this.Date=DateFormatMAS90(Date)
        this.TotalCost=TotalCost
    }

    addCost(value){
        this.TotalCost+=value
    }
}