export class WeightPerInvoice{
    InvoiceNo
    TotalWeight
    Detail
    TotalQuantity
    TotalAmt
    constructor(InvoiceNo){
        this.InvoiceNo=InvoiceNo
        this.TotalWeight=0
        this.TotalQuantity=0
        this.TotalAmt=0
        this.Detail=[]
    }

    calculateTotalWeight(){
        let weight=0
        let TotalQuantity=0
        let TotalAmt=0
        for (const item of this.Detail) {
            weight+=(Number(item.LineWeight)*Number(item.QuantityShipped))
            TotalQuantity+=Number(item.QuantityShipped)
            TotalAmt+=Number(item.ExtensionAmt)
        }
        
        this.TotalWeight=weight
        this.TotalQuantity=TotalQuantity
        this.TotalAmt=TotalAmt
    }
    
}