import React, { Component } from 'react';
import { fetchData, sendOrderData } from '../../services/apiService';
import LoadingWindow from '../../components/LoadingWindow';
import "../../css/general-style.css"
import '../../css/table-responsive.css'

export default class LogsPage extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
        this.state = {
            data: null,
            error: null,
            loading: false,
            searchQuery: '',
            initialLoad: true,
        }
    }

    componentDidMount() {
        this.getData('');
    }

    async getData(searchQuery) {
        this.setState({ loading: true });
        try {
            const { data } = await fetchData(searchQuery);
            this.setState({ data: data, loading: false, initialLoad: false });
        } catch (error) {
            this.setState({ error, loading: false, initialLoad: false });
        }
    }

    handleInputChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    }

    handleSearch = () => {
        this.getData(this.state.searchQuery);
    }

   handleButtonClick = async (object) => {
    object.replace(/(\r\n|\n|\r)/gm, '').replace(/\s{2,}/g, ' ')
    const response = await sendOrderData({ data: object })
    console.log(response)
    }

    handleCopyButtonClick = async (object) => {
        navigator.clipboard.writeText(object)
        .then(() => {
        })
        .catch(err => {
            console.error('Error al copiar el texto: ', err);
            alert('Error al copiar el texto.');
        });
    }

    render() {
        const { data, error, loading, searchQuery, initialLoad } = this.state;

        if (loading) {
            return <LoadingWindow ref={this.ModalLoading} />
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        }

        const noDataMessage = !initialLoad && (!data || data.length === 0) ? (
            <p>No data available</p>
        ) : null;

        const getSalesOrder = (object) => {
            let parsedObject = JSON.parse(object)
            return parsedObject.NoOrder || parsedObject.Invoice.InvoiceNo
        }
        return (
            <div className="container mt-4">
                <h1>Logs Documents</h1>

                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control mb-2"
                        value={searchQuery}
                        onChange={this.handleInputChange}
                        placeholder="Enter order number"
                    />
                    <button className="btn btn-primary" onClick={this.handleSearch}>Search</button>
                </div>

                {noDataMessage}

                {data && data.length > 0 && (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Type Object</th>
                                <th>Created At</th>
                                <th>Invoice/Sales Order</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.user_name}</td>
                                    <td>{item.type_object}</td>
                                    <td>{new Date(item.created_at).toLocaleString()}</td>
                                    <td>{getSalesOrder(item.object)}</td>
                                    <td>{}</td>
                                    <td>
                                        <button
                                            className="btn btn-info"
                                            onClick={() => this.handleButtonClick(item.object)}
                                        >
                                            Action
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-info"
                                            onClick={() => this.handleCopyButtonClick(item.object)}
                                        >
                                            Copy SalesOrder
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>)
    }
}
