import { FormatQueryReturnDate } from "../../../functions/dateFormat"
import { MonthInLetters } from "../../../functions/generalFunctions"
import { getIndexElement, getIndexElement2 } from "../../../functions/searchInObject"

export class Item{
    ItemCode
    ProductLine
    Description
    VendorNo
    VendorName
    Detail
    ExistDates

    constructor(ItemCode,ProductLine,Description,VendorNo,VendorName){
        this.ItemCode=ItemCode
        this.ProductLine=ProductLine
        this.Description=Description
        this.VendorNo=VendorNo
        this.VendorName=VendorName
        this.Detail=[]
        this.ExistDates=[]
    }
    addOrder(NoOrder,QuantityReceived,Date,QuantityOrdered){
        let Order={
            NoOrder:NoOrder,
            QuantityOrdered:QuantityOrdered,
            QuantityReceived:QuantityReceived,
            Date:FormatQueryReturnDate(Date)
        }
        this.Detail.push(Order)
        this.ExistDates.push(Order.Date)
    }
    addSpace(NoOrder,Date){
        let Order={
            NoOrder:NoOrder,
            QuantityOrdered:-1,
            QuantityReceived:-1,
            Date:Date
        }
        this.Detail.push(Order)
    }
    orderDetail(header){
            let Orders=[]
            for (let a = 4; a < header.length; a++) {
                for (const item of this.Detail) {
                    if(item.NoOrder===header[a].OrderNo){
                        Orders.push(item)
                        break
                    }
                }

            }
            this.Detail=Orders
        }
    
}

export class HeaderHistoryPurchaseOrder{
    Year
    Months
    Dates         
    GeneralHeaders

    constructor(){
        this.Year=[]
        this.Months=[]
        this.Dates=[]             
        this.GeneralHeaders=[{OrderNo:'Item Code',Comment:''},{OrderNo:'Description',Comment:''},{OrderNo:'Primary Vendor',Comment:''},{OrderNo:'Product Line',Comment:''}]
    }

    addDates(Date,OrderNo,Comment){
        const index=getIndexElement2(this.Dates,'Date','OrderNo',FormatQueryReturnDate(Date),OrderNo)
        let d=FormatQueryReturnDate(Date).split('/')
        if(index===-1){
            let data={
                Date:FormatQueryReturnDate(Date),
                OrderNo:OrderNo
            }
            this.Dates.push(data)
            this.GeneralHeaders.push({OrderNo:OrderNo,Comment:"\n"+(Comment?String(Comment).toLocaleUpperCase():"")})
            if(d.length===3){
                let year=d[2]
                let month=MonthInLetters(d[0])
                const indexM=getIndexElement2(this.Months,'Month','Year',month,year)
                const indexY=getIndexElement(this.Year,'Year',year)
                if(indexM===-1){
                    let m=new Month(month,year,'')
                    this.Months.push(m)
                }else{
                    this.Months[indexM].addSpanCol()
                }

                if(indexY===-1){
                    let m=new Years(year,'')
                    this.Year.push(m)
                }else{
                    this.Year[indexY].addSpanCol()
                }
            }
        }
    }
}

export class Years{
    Year
    Color
    SpanCol

    constructor(Year,Color){
        this.Year=Year
        this.Color=Color
        this.SpanCol=2
    }

    addSpanCol(){
        this.SpanCol+=2
    }
}


export class Month{
    Month
    Year
    Color
    SpanCol

    constructor(Month,Year,Color){
        this.Month=Month
        this.Year=Year
        this.Color=Color
        this.SpanCol=2
    }

    addSpanCol(){
        this.SpanCol+=2
    }
}