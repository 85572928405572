import React from "react"

export class Routes {
    constructor(Route, options, LineOptions) {
        this.Route = Route
        this.OptionsLineGraph = options
        this.DataLine = LineOptions
        this.Reference= React.createRef()
    }
}

export class ProdLine {
    constructor(Route, ProdLine, Options, LineOptions) {
        this.Route = Route
        this.ProdLine = ProdLine
        this.Title = Route + '-' + ProdLine
        this.OptionsLineGraph = Options
        this.DataLine = LineOptions
        this.Reference = React.createRef()
        this.Data = []
    }
}


export class OptionsLineGraph {
    constructor(title) {
        this.responsive = true
        this.plugins = {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.parsed.y.toString().replace(/,/g, '.');
                        label +='%'
                        return label;
                    },
                },
            },
        }
        this.scales = {
            y: {
                suggestedMin: 0,

            }
        }





    }
}

export class LineGraphData {
    constructor(labels, label, data, color) {

        this.labels = labels
        this.datasets = [
            {
                fill: false,
                label: label,
                data: data,
                borderColor: color,
                backgroundColor: color,
                spanGaps: true
            },
        ]
    }
}


export class ProductLineFormat {
    constructor(ProdLine, Route) {
        this.ProdLine = ProdLine
        this.Route = Route
        this.Dates = []
        this.Values = []

    }
}


export class GroupLineGraphData {
    constructor() {

        this.labels = []
        this.datasets = []
    }

    addNewDataSet(label, data, color,labels) {
        let dataTemp=[]
        
        for (const Label of this.labels) {
            const index=labels.findIndex(function (elem) {
                return elem === Label;
            });
            if (index!==-1) {
                dataTemp.push(data[index])
            }else{
                dataTemp.push(null)
            }
        }

        let temporal = {
            fill: false,
            label: label,
            data: dataTemp,
            borderColor: color,
            backgroundColor: color,
            spanGaps: true
        }
        this.datasets.push(temporal)
    }

    addLabels(labels) {
        
        for (const Label of labels) {
            if (!this.labels.includes(Label)) {
                this.labels.push(Label);
                this.labels.sort();
            }

        }
    }
}

export class AgroupRoutes{
    constructor(route,data,color,dates){
        this.Route=route
        this.Data=data
        this.Color=color
        this.Dates=dates
    }
}
