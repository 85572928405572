import { getPriceMargin, getPurePriceMargin } from "../../functions/generalFunctions"
import { getValueCookie } from "../../services/cookieService"

export class productLine {
    ProductLineName
    constructor(ProductLineName) {
        this.ProductLineName = ProductLineName
    }

}

export class Item {
    itemCode
    abbreviatedDesc
    upc
    quantity
    ShipWeight
    StandardUnitCost
    LastTotalUnitCost
    UnitPrice
    ProductLine
    ProductLineDesc
    upc2
    caseupc
    itemURLImage
    //Value display
    InputValue
    //ELEMENTOS EXTRA SALESORDER
    BIN
    totalWeight
    ShipWeight
    Comment
    unitCost
    quantityOrdered
    quantityReceived
    quantityShipped
    totalCost
    totalFreight
    Visible
    IdCompany

    constructor(itemCode, abbreviatedDesc, upc, quantity, ShipWeight, StandardUnitCost, LastTotalUnitCost, UnitPrice, ProductLine, ProductLineDesc, upc2, caseupc, itemURLImage,InputValue,IdCompany) {
        this.itemCode = itemCode
        this.abbreviatedDesc = abbreviatedDesc
        this.upc = upc
        this.quantity = quantity
        this.ShipWeight = ShipWeight
        this.StandardUnitCost = StandardUnitCost
        this.LastTotalUnitCost = LastTotalUnitCost
        this.UnitPrice = UnitPrice
        this.ProductLine = ProductLine
        this.ProductLineDesc = ProductLineDesc
        this.upc2 = upc2
        this.caseupc = caseupc
        this.itemURLImage = itemURLImage
        //ELEMENTOS EXTRA SALESORDER
        this.BIN = ""
        this.totalWeight = 0
        this.InputValue = InputValue
        this.Comment = ""
        this.PureMargin=0
        this.unitCost = 0
        this.quantityOrdered = 0
        this.quantityReceived = 0
        this.quantityShipped = 0
        this.totalCost = 0
        this.totalFreight = 0
        this.Visible = 0
        this.IdCompany=IdCompany
    }

    preparItemSalesOrder(value,priceLevel){
        this.quantityOrdered = value
        this.Visible = 1
        this.totalCost= Number(this.UnitPrice)*Number(value)
        this.unitCost=this.LastTotalUnitCost
        this.totalWeight=Number(this.ShipWeight)*Number(value)
        this.Comment="BOX:"+getPriceMargin(this.UnitPrice,this.unitCost,priceLevel)
        this.PureMargin=getPurePriceMargin(this.UnitPrice,this.unitCost)
        if(getValueCookie('SalesPerson')==='0010'){
            this.totalCost=this.LastTotalUnitCost
            this.UnitPrice=this.LastTotalUnitCost
        }

    }

    preparItemInvoice(value,priceLevel){
        this.quantityShipped=value
        this.Visible = 1
        this.totalCost=Number(this.UnitPrice)*Number(value)
        this.unitCost=this.LastTotalUnitCost
        this.totalWeight=Number(this.ShipWeight)*Number(value)
        this.Comment="BOX:"+getPriceMargin(this.UnitPrice,this.unitCost,priceLevel)
        this.PureMargin=getPurePriceMargin(this.UnitPrice,this.unitCost)
        if(getValueCookie('SalesPerson')==='0010'){
            this.totalCost=this.LastTotalUnitCost
            this.UnitPrice=this.LastTotalUnitCost
        }
    }

    preparItemPurchase(value,State){
        
        if(State==='1'||State===''){
            this.quantityOrdered = value;
            this.quantityReceived=0;
        }else{
            this.quantityOrdered =0;
            this.quantityReceived =value;
        }
        this.BIN = "";
            this.Comment = "";
            this.unitCost = this.LastTotalUnitCost;
            this.totalCost = Number(this.LastTotalUnitCost)*Number(value);
            this.totalFreight = 0
    }

    changeCatalogueItem(quantity,visible,totalCost,unitCost,totalWeight,type){
        if(type==='Invoice'){
            this.quantityShipped=quantity
        }else if(type==='SalesOrder'){
            this.quantityOrdered=quantity
        }
        
        this.Visible = visible
        this.totalCost=totalCost
        this.unitCost=unitCost
        this.totalWeight=totalWeight
        this.Comment="BOX:"+getPriceMargin(this.UnitPrice,this.unitCost)
        this.PureMargin=getPurePriceMargin(this.UnitPrice,this.unitCost)
    }



}