import React, { Component } from 'react'
import LoadingWindow from '../../../components/LoadingWindow';
import { BsFileArrowUpFill, BsFileArrowDownFill, BsArrowCounterclockwise } from "react-icons/bs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, Filler } from 'chart.js';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { BsArrowRepeat } from "react-icons/bs";
import { AgroupRoutes, GroupLineGraphData, LineGraphData, OptionsLineGraph, ProdLine, ProductLineFormat, Routes } from "./Class";
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import { getValueCookie } from '../../../services/cookieService';
import { getInformationWithDataGlobalSales } from '../../../services/CABE';
import { NumberFormat, OrderArrayASCDESC, random_rgba } from '../../../functions/generalFunctions';
import { getIndexElement } from '../../../functions/searchInObject';
import { pricesFormat } from '../../../functions/pricesFormat';
import { DateFormatMAS90, formatDateByLine } from '../../../functions/dateFormat';

export default class GeneralMarginPrices extends Component {
    constructor(props) {
        super(props)
        this.chartRef = React.createRef()
        this.ModalLoading = React.createRef();
        ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
            LinearScale,
            BarElement,
            Title, CategoryScale,
            LinearScale,
            PointElement,
            LineElement,

            Filler,
        );
    }
    state = {
        Routes: [],
        Order:true,
        ProductLines: [],
        FilterProductLines: [],
        CheckRoutes: [],
        CheckProdLines: [],
        AllData: null,
        ItemPerDay: [],
        TitleItemPerDay: {
            ProductLine: '',
            Route: '',
            Date: '',
        },
        ScrollY:null,
        ActiveTab:'MarginsPerRoute'
    }

    checkFilterRouter(id, Route) {
        const checkbox = document.getElementById(id);

        if (Route === 'All' && checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportGMRoutesP1')
            for (const checkbox of checkboxList) {
                checkbox.checked = true;
            }
        } else if (Route === 'All' && !checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportGMRoutesP1')
            for (const checkbox of checkboxList) {
                checkbox.checked = false;
            }
        } else {
            let cond = true
            const checkboxList = document.getElementsByClassName('ReportGMRoutesP1')
            for (const checkbox of checkboxList) {
                if ((!checkbox.checked) && (checkbox.id !== 'CheckGMP1All')) {
                    cond = false
                    break
                }
            }
            let all = document.getElementById('CheckGMP1All')
            if (all) {
                all.checked = cond
            }


        }

    }
    checkFilterRouterP2(id, Route) {
        const checkbox = document.getElementById(id);

        if (Route === 'All' && checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportGMRoutesP2')
            for (const checkbox of checkboxList) {
                checkbox.checked = true;
            }
        } else if (Route === 'All' && !checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportGMRoutesP2')
            for (const checkbox of checkboxList) {
                checkbox.checked = false;
            }
        } else {
            let cond = true
            const checkboxList = document.getElementsByClassName('ReportGMRoutesP2')
            for (const checkbox of checkboxList) {
                if ((!checkbox.checked) && (checkbox.id !== 'CheckGMP2All')) {
                    cond = false
                    break
                }
            }
            let all = document.getElementById('CheckGMP2All')
            if (all) {
                all.checked = cond
            }


        }
        this.FilterBySelectedRouteAndProdLine()
    }

    onClick = (e, reference, Title) => {

        let Label = reference.current.data.datasets[0].label
        let Position = getElementAtEvent(reference.current, e)
        if (Position.length > 0) {
            let PosIndex = Position[0].index
            const index = getIndexElement(this.state.ProductLines, 'Title', Label)
            if (index !== -1) {
                const Title = this.state.TitleItemPerDay
                let temporal = formatDateByLine(this.state.ProductLines[index].DataLine.labels[PosIndex])
                let info = this.state.AllData[this.state.ProductLines[index].Route]["detail"][temporal]["detail"][this.state.ProductLines[index].ProdLine].detail
                for (const Line of info) {
                    Line.Margin=pricesFormat((Line.ExtensionAmt - Line.TotalCost) / (Line.ExtensionAmt) * 100)
                }
                Title.Route = this.state.ProductLines[index].Route
                Title.ProductLine = this.state.ProductLines[index].ProdLine
                Title.Date = DateFormatMAS90(temporal)
                this.setState({ ItemPerDay: info, TitleItemPerDay: Title })
                let button = document.getElementById('GMPerItem')
                if (button) {
                    button.click()
                }
            }
        }

    }

    specificDetailByRoute = (e, reference, Title) => {
        if (Title !== "Total") {
            let Position = getElementAtEvent(reference.current, e)
            if (Position.length > 0) {
                let indexDataSet = Position[0].datasetIndex
                let PosIndex = Position[0].index
                let Label = reference.current.data.datasets[indexDataSet].label
                const index = getIndexElement(this.state.Routes, 'Route', Title)
                if (index !== -1) {
                    const Title = this.state.TitleItemPerDay
                    let temporal = formatDateByLine(this.state.Routes[index].DataLine.labels[PosIndex])
                    let specificDate = this.state.AllData[Label]["detail"][temporal]["detail"]
                    let info = []
                    for (const ProductLine of Object.keys(specificDate)) {
                        info = info.concat(specificDate[ProductLine].detail)
                    }
                    for (const Line of info) {
                        Line.Margin=pricesFormat(((Line.ExtensionAmt - Line.TotalCost) / (Line.ExtensionAmt)) * 100)
                    }
                    Title.Route = Label
                    Title.ProductLine = ''
                    Title.Date = DateFormatMAS90(temporal)
                    this.setState({ ItemPerDay: info, TitleItemPerDay: Title })
                    let button = document.getElementById('GMPerItem')
                    if (button) {
                        this.ActiveTab("MarginsPerItem")
                        button.click()
                    }
                }
            }
        }


    }


    checkFilterProdLineP2(id, Route) {
        const checkbox = document.getElementById(id);

        if (Route === 'All' && checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportGMProdLineP2')
            for (const checkbox of checkboxList) {
                checkbox.checked = true;
            }
        } else if (Route === 'All' && !checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportGMProdLineP2')
            for (const checkbox of checkboxList) {
                checkbox.checked = false;
            }
        } else {
            let cond = true
            const checkboxList = document.getElementsByClassName('ReportGMProdLineP2')
            for (const checkbox of checkboxList) {
                if ((!checkbox.checked) && (checkbox.id !== 'CheckGMProdLineP2')) {
                    cond = false
                    break
                }
            }
            let all = document.getElementById('CheckGMProdLineP2')
            if (all) {
                all.checked = cond
            }


        }
        this.FilterBySelectedRouteAndProdLine()
    }

    OrderInformation(key){
        let order=this.state.Order?'asc':'desc'
        let temporal=OrderArrayASCDESC(this.state.ItemPerDay,key,order)
        this.setState({ItemPerDay:temporal,Order:!this.state.Order})
    }

    async getInformation() {
        let Start = document.getElementById('ReportGMStartDate').value
        let End = document.getElementById('ReportGMEndDate').value
        const data = {
            Start: Start,
            End: End,
            idCompany: Number(getValueCookie('CompanyId'))
        }
        this.ModalLoading.current.showState(true);
        const result = await getInformationWithDataGlobalSales('/Invoice/report/InvoiceMarginByRoute', data)
        let CheckData = []
        let ByProdLines = []
        let CheckRoutes = [{ name: 'All' }]
        let CheckProdLines = [{ name: 'All' }]
        if (result) {
            let RoutesArray = Object.keys(result)
            let allRoutes = new Routes("All Margins", new OptionsLineGraph("All Margins"), new GroupLineGraphData())
            let AgroupData=[]
            for (const Route of RoutesArray) {
               
                if (Route !== "Total") {
                    CheckRoutes.push({ name: Route })
                    let labels = Object.keys(result[Route]["detail"])

                    let data = []
                    let ProdLines = []

                    for (const Label of labels) {
                        data.push(Number(result[Route]["detail"][Label]["PromMarkup"]).toFixed(2))
                        let productLines = Object.keys(result[Route]["detail"][Label]["detail"])
                        for (const ProductLine of productLines) {
                            const index = getIndexElement(ProdLines, 'ProdLine', ProductLine)
                            const index2 = getIndexElement(CheckProdLines, 'name', ProductLine)

                            if (index2 === -1) {
                                CheckProdLines.push({ name: ProductLine })
                            }

                            if (index === -1) {
                                let newP = new ProductLineFormat(ProductLine, Route)
                                newP.Dates.push(DateFormatMAS90(Label))
                                newP.Values.push(Number(result[Route]["detail"][Label]["detail"][ProductLine]["PromMarkup"]).toFixed(2))
                                ProdLines.push(newP)
                            } else {
                                ProdLines[index].Dates.push(DateFormatMAS90(Label))
                                ProdLines[index].Values.push(Number(result[Route]["detail"][Label]["detail"][ProductLine]["PromMarkup"]).toFixed(2))
                            }
                        }
                    }
                    for (const Prod of ProdLines) {
                        ByProdLines.push(new ProdLine(Route, Prod.ProdLine, new OptionsLineGraph(Route + '-' + Prod.ProdLine), new LineGraphData(Prod.Dates, Route + '-' + Prod.ProdLine, Prod.Values, random_rgba())))
                    }
                    let colorRoute = random_rgba()

                    let newDate = []
                    for (const date of labels) {
                        newDate.push(DateFormatMAS90(date))
                    }
                    allRoutes.DataLine.addLabels(newDate)
                    AgroupData.push(new AgroupRoutes(Route, data, colorRoute, newDate))
           } else {
                    let labels = Object.keys(result[Route])
                    let data = []
                    for (const Label of labels) {
                        data.push(Number(result[Route][Label]["PromMarkup"]).toFixed(2))
                    }
                    let newDate = []
                    for (const date of labels) {
                        newDate.push(DateFormatMAS90(date))
                    }
                    CheckData.unshift(new Routes(Route, new OptionsLineGraph(Route), new LineGraphData(newDate, Route, data, random_rgba())))
                }
            }
            for (const Data of AgroupData) {
                allRoutes.DataLine.addNewDataSet(Data.Route,Data.Data,Data.Color,Data.Dates)
            }


            CheckData.push(allRoutes)
        }
        this.setState({ Routes: CheckData, ProductLines: ByProdLines, FilterProductLines:ByProdLines, CheckRoutes: CheckRoutes, CheckProdLines: CheckProdLines, AllData: result })
        this.ModalLoading.current.showState(false);
    }

     BackToP(id) {
        let button = document.getElementById(id)
        this.setState({ActiveTab:"MarginsPerProductLine"})
        if (button) {
             button.click()
            setTimeout(() => {  
                this.Scroll()
              }, 1000);
        }
        
       
    }

    FilterBySelectedRouteAndProdLine(){
        const checkRoute = document.getElementsByClassName('ReportGMRoutesP2')
        const checkLines = document.getElementsByClassName('ReportGMProdLineP2')
        let Items=[]
        for (const Route of checkRoute) {
            for (const ProdLine of checkLines) {
                if(Route.checked&&ProdLine.checked)
                    Items.push(Route.name+'-'+ProdLine.name)
            }
        }
        let SelectGraphs=[]
        for (const Graph of Items) {
            const index=getIndexElement(this.state.ProductLines,'Title',Graph)
            if(index!==-1){
                SelectGraphs.push(this.state.ProductLines[index])
            }
            
        }

        this.setState({FilterProductLines:SelectGraphs})

    }

    componentDidMount() {
        window.addEventListener('scroll', this.setScrollPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.setScrollPosition);
    }

    setScrollPosition=()=>{
        if(this.state.ActiveTab==="MarginsPerProductLine"){
        let posY=window.scrollY
        this.setState({ScrollY:posY})
        }
    }
    ActiveTab(name){
        this.setState({ActiveTab:name})
        if(name==="MarginsPerProductLine"){
            setTimeout(() => {  
                this.Scroll()
              }, 1000);
        }
    }
    Scroll(){
        window.focus()
        window.scrollTo(0, this.state.ScrollY);
    }


    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >General Margin Prices</p>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-sm-1' />
                            <div className='col-sm-10'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='row TitleText'>
                                            <div className='col-12'>
                                                <p>Start:</p>
                                            </div>
                                            <div className='col-12'>
                                                <div className="input-group input-group-lg pb-4" >
                                                    <input className="form-control form-control-lg" id='ReportGMStartDate' type="date" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row TitleText'>
                                            <div className='col-12'>
                                                <p>End:</p>
                                            </div>
                                            <div className='col-12'>
                                                <div className="input-group input-group-lg pb-4" >
                                                    <input className="form-control form-control-lg" id='ReportGMEndDate' type="date" />
                                                </div>
                                            </div>
                                            <div className='col-12 pb-4 InputTextBolder text-center'>
                                                <div className="d-grid gap-2">

                                                    <button onClick={() => this.getInformation()} type="button" className="btn blueButton btn-lg">Search Information <BsArrowRepeat /></button>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-1' />
                        </div>
                    </div>
                    <div hidden={this.state.AllData===null} className='col-12'>
                        <ul className="nav nav-tabs" id="ScanningControls" role="tablist">
                            <li hidden={false} className="nav-item" role="presentation">
                                <button onClick={()=>this.ActiveTab("MarginsPerRoute")} id='GMPerRoute' className="nav-link active" data-bs-toggle="tab" data-bs-target="#ReportGMPerRoute" type="button" role="tab" aria-controls="ReportGMPerRoute" aria-selected="true">Margins Per Route</button>
                            </li>
                            <li hidden={false} className="nav-item" role="presentation">
                                <button onClick={()=>this.ActiveTab("MarginsPerProductLine")} id='GMPerProductLine' className="nav-link" data-bs-toggle="tab" data-bs-target="#ReportGMPerProductLine" type="button" role="tab" aria-controls="ReportGMPerProductLine" aria-selected="false">Margins Per Product Lines</button>
                            </li>
                            <li hidden={false} className="nav-item" role="presentation">
                                <button onClick={()=>this.ActiveTab("MarginsPerItem")} hidden id='GMPerItem' className="nav-link" data-bs-toggle="tab" data-bs-target="#ReportGMPerItem" type="button" role="tab" aria-controls="ReportGMPerItem" aria-selected="false">Margins Per Item</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="allScanningTab">
                            <div className="tab-pane fade show active" id="ReportGMPerRoute" role="tabpanel" aria-labelledby="ReportGMPerRoute">
                                <div className='row pt-4  pb-3'>
                                    <div className='col-sm-1' />
                                    {/*<div className='col-sm-2'>
                                        <div className='row'>
                                            {this.state.CheckRoutes.map((element, e) => (
                                                <div className="form-check col-12 text-start fontLarger textRadioButtonSize " key={e}>
                                                    <label className="form-check-label w-100" >
                                                        {element.name}
                                                        <input className="form-check-input ReportGMRoutesP1" type="checkbox" name={element.name} onChange={() => this.checkFilterRouter('CheckGMP1' + element.name, element.name)} defaultChecked id={'CheckGMP1' + element.name} />
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                            </div>*/}
                                    <div className='col-sm-10'>
                                        <div className='row'>
                                            {
                                                this.state.Routes.map((Route, R) => (
                                                    <div className='col-sm-6' key={Route.Route + '-' + Route.ProdLine}>
                                                        <Line onClick={(e) => this.specificDetailByRoute(e, Route.Reference, Route.Route)} ref={Route.Reference} options={Route.OptionsLineGraph} data={Route.DataLine} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='col-sm-1' />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ReportGMPerProductLine" role="tabpanel" aria-labelledby="ReportGMPerProductLine">
                                <div className='row pt-4 pb-3'>
                                    
                                    <div className='col-sm-1' />
                                    <div className='col-sm-2'>

                                        <div className='row'>
                                            <div className='col-12 pb-3'>
                                                <h3>Routes:</h3>
                                            </div>
                                            {this.state.CheckRoutes.map((element, e) => (
                                                <div className="form-check col-12 text-start fontLarger textRadioButtonSize " key={"P2" + e}>
                                                    <label className="form-check-label w-100" >
                                                        {element.name}
                                                        <input className="form-check-input ReportGMRoutesP2" type="checkbox" name={element.name} onChange={() => this.checkFilterRouterP2('CheckGMP2' + element.name, element.name)} defaultChecked id={'CheckGMP2' + element.name} />
                                                    </label>
                                                </div>
                                            ))}
                                            <div className='col-12 pt-3 pb-3'>
                                                <h3>Product Lines:</h3>
                                            </div>
                                            {this.state.CheckProdLines.map((element, e) => (
                                                <div className="form-check col-12 text-start fontLarger textRadioButtonSize " key={"RoutesP2" + e}>
                                                    <label className="form-check-label w-100" >
                                                        {element.name}
                                                        <input className="form-check-input ReportGMProdLineP2" type="checkbox" name={element.name} onChange={() => this.checkFilterProdLineP2('CheckGMProdLineP2' + element.name, element.name)} defaultChecked id={'CheckGMProdLineP2' + element.name} />
                                                    </label>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                    <div className='col-sm-8'>
                                        <div className='row'>
                                            {
                                                this.state.FilterProductLines.map((ProductLine, R) => (
                                                    <div key={ProductLine.Route + '-' + ProductLine.ProdLine} className={'col-sm-6 '+ProductLine.Route + '-' + ProductLine.ProdLine}>
                                                        <Line ref={ProductLine.Reference} onClick={(e) => this.onClick(e, ProductLine.Reference)} options={ProductLine.OptionsLineGraph} data={ProductLine.DataLine} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='col-sm-1' />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ReportGMPerItem" role="tabpanel" aria-labelledby="ReportGMPerItem">
                                <div className='row pt-4  pb-3'>

                                    <div className='col-sm-1' />
                                    <div className='col-sm-10 pb-3'>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <div className='col-12 pb-2'>
                                                        <div className="d-grid gap-2">
                                                            <button type="button" className="btn orangeButton btn-lg" onClick={() => this.BackToP(this.state.TitleItemPerDay.ProductLine===''?'GMPerRoute':'GMPerProductLine')}>Return <BsArrowCounterclockwise /></button>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 titleTotal'>
                                                        <p>Route: </p>
                                                    </div>
                                                    <div className='col-12 TitleText'>
                                                        <p>{this.state.TitleItemPerDay.Route}</p>
                                                    </div>
                                                    <div className='col-12 titleTotal'>
                                                        <p>Date: </p>
                                                    </div>
                                                    <div className='col-12 TitleText'>
                                                        <p>{this.state.TitleItemPerDay.Date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div hidden={this.state.TitleItemPerDay.ProductLine===''} className='col-sm-6'>
                                                <div className='col-12 titleTotal'>
                                                    <p>Product Line: </p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.TitleItemPerDay.ProductLine}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-1' />
                                    <div className='col-sm-1' />
                                    <div className='col-sm-10 tableFixHead'>
                                        <table className='table align-middle'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th onClick={()=>this.OrderInformation('InvoiceNo')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Invoice No</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('CustomerNo')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Customer No</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>
                                                    <th onClick={()=>this.OrderInformation('SalespersonNo')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Sales Person</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('BillToName')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Customer Name</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>
                                                    <th onClick={()=>this.OrderInformation('ProductLineDesc')} hidden={this.state.TitleItemPerDay.ProductLine!==''} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Product Line</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('ItemCode')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>ItemCode</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('ItemCodeDesc')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Description</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('QuantityShipped')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Quantity Shipped</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'></div>
                                                        </div>

                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('UnitPrice')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Unit Price</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'></div>
                                                        </div>

                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('UnitCost')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Unit Cost</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'></div>
                                                        </div>

                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('ExtensionAmt')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Extension Amt</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>

                                                    <th className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'></div>
                                                        </div>

                                                    </th>

                                                    <th onClick={()=>this.OrderInformation('TotalCost')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Total Cost</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>
                                                    <th onClick={()=>this.OrderInformation('Margin')} className='bg-dark'>
                                                        <div className='row'>
                                                            <div className='col-12'>Margin</div>
                                                        </div>
                                                        <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.ItemPerDay.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className='text-start'>{item.InvoiceNo}</td>
                                                            <td className='text-center'>{item.CustomerNo}</td>
                                                            <td className='text-center'>{item.SalespersonNo}</td>
                                                            <td className='text-start'>{item.BillToName}</td>
                                                            <td hidden={this.state.TitleItemPerDay.ProductLine!==''} className='text-start'>{item.ProductLineDesc}</td>
                                                            <td className='text-start'>{item.ItemCode}</td>
                                                            <td className='text-start'>{item.ItemCodeDesc}</td>
                                                            <td className='text-center'>{pricesFormat(item.QuantityShipped)}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(item.UnitPrice))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(item.UnitCost))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(item.ExtensionAmt))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(item.TotalCost))}</td>
                                                            <td className='text-center'>{item.Margin + '%'}</td>

                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-sm-1' />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
