import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { DateFormatMAS90, /*getDateYearMonthDayDash*/ } from '../functions/dateFormat.js';
import "../css/general-style.css"
import '../css/table-responsive.css'
import { pricesFormat } from '../functions/pricesFormat.js';
import { /*ItemDesc,*/ NumberFormat } from '../functions/generalFunctions.js';
import ModalOrders from './ModalComponent.js';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        PurchaseOrderListVendor: [],
        PurchaseOrderListVendorFilter: [],
        showModal:false
    }

    async getPurchaseOrdersHeader(refresh) {
        await this.handleModalOpen()
        const data = {
            VendorNo: this.props.VendorNo,
            IdCompany: getValueCookie('CompanyId')
        }


        const buscador = document.getElementById('PurchaseOrderVendor_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.PurchaseOrderListVendor.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ PurchaseOrderListVendor: [], PurchaseOrderListVendorFilter: [] });

            const respuesta = await getInformationWithData('/purchase/history/header', data);
            if (respuesta.status.code === 1) {

                this.setState({ PurchaseOrderListVendor: respuesta.data, PurchaseOrderListVendorFilter: respuesta.data });

            }
            this.ModalLoading.current.showState(false);
        }

        setTimeout(() => { buscador.focus(); }, 300);

    }

    async getPurchaseDetail(PurchaseHeader) {
        //Aqui asignamos el producto al estado del padre
        const Father = this.props.Padre
        this.ModalLoading.current.showState(true);
      
            const data = {
                PurchaseOrderNo: PurchaseHeader.PurchaseOrderNo,
                IdCompany: getValueCookie('CompanyId')
            }
            const result = await getInformationWithData('/purchase/history/detail', data)
            
            let processData=[]
            for (const item of result.data) {
                if(item.QuantityReceived>0){
                    processData.push(item)
                }
            }
            if (result.status.code === 1) {
                switch(this.props.nombrePadre){
                    case "PurchaseOrderReturn":
                        await Father.setInvoiceDetail(PurchaseHeader,processData)
                        break;
                    default:
                        break
                }
            }

        this.ModalLoading.current.showState(false);
        await this.handleModalClose()
    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false

    }

    searchInvoice = e => {

        let busqueda = e.target.value;
        let orders = []
        if (busqueda) {
            orders = this.state.PurchaseOrderListVendor.filter((order_) => {

                if (((
                    this.contiene(order_.LastInvoiceNo, busqueda) ||
                    this.contiene(order_.PurchaseOrderNo, busqueda) ||
                    this.contiene(order_.VendorNo, busqueda)||
                    this.contiene(order_.PurchaseName, busqueda)
                ))
                ) {
                    return order_
                } else {
                    return null
                }
            });
        } else {
            orders = this.state.PurchaseOrderListVendor
        }

        this.setState({ PurchaseOrderListVendorFilter: orders });
    }

    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className="btn blackButton btn-lg w-100" onClick={() => this.getPurchaseOrdersHeader(true)}>{this.props.buttonTitle} <AiOutlineFileText /></button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Invoice List'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className="modal-body">
                        <div className='row form-group pt-4'>

                            <div className='col-sm-12 pb-4'>
                                <div className="input-group input-group-lg flex-nowrap">
                                    <input className='form-control w-75 display-inline' placeholder='Search by...' id={'PurchaseOrderVendor_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchInvoice} />
                                    <span className="input-group-text">
                                        <button className='btn greenButton btn-lg' onClick={() => this.getPurchaseOrdersHeader(true)}><BsArrowRepeat /></button>
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-12 tableFixHead'>
                                <table className='table align-middle '>
                                    <thead className='bg-primary text-white thead'>
                                        <tr className='text-center'>
                                            <th className='bg-primary'>Purchase Order No</th>
                                            <th className='bg-primary'>Invoice No</th>
                                            <th className='bg-primary'>Date</th>
                                            <th className='bg-primary'>Vendor No</th>
                                            <th className='bg-primary'>Purchase Name</th>
                                            <th className='bg-primary'></th>
                                            <th className='bg-primary'>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.PurchaseOrderListVendorFilter.map((element, e) => (
                                                <tr  className='row_hover' key={e} onClick={() => this.getPurchaseDetail(element)}>
                                                    <td className='text-start'>{element.PurchaseOrderNo}</td>
                                                    <td className='text-center'>{element.LastInvoiceNo}</td>
                                                    <td className='text-center'>{DateFormatMAS90(element.PurchaseOrderDate)}</td>
                                                    <td className='text-center'>{element.VendorNo}</td>
                                                    <td className='text-start'>{element.PurchaseName}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(element.NonTaxableAmt))}</td>

                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>

                        </div>
                        
                    </div>
                </ModalOrders>

            </>
        )
    }
}
