import React, { Component } from 'react'
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import "../../../css/pickingSystem-style.css"
import LoadingWindow from '../../../components/LoadingWindow';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { BsArrowRepeat } from "react-icons/bs";
import { getValueCookie } from '../../../services/cookieService';
import { getInformationWithData } from '../../../services/CABE';
import { getIndexElement } from '../../../functions/searchInObject';
import { TrackingDetail, TrackingSalesOrder } from './Class';
import { getPriceMargin, NumberFormat, OrderArray } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
export default class TrackingNumberReportView extends Component {

    state = {
        SalesOrder: [],
        SalesOrderFilter: [],
        Invoice: [],
        InvoiceFilter: [],
        SelectSO: '',
        DetailSO: [],
        FilterDetailSO: [],
        order: true

    }


    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }

    async getDetailSO(SalesOrderHeader) {
        let TemporalSalesOrderNo = ""
        if (SalesOrderHeader.SalesOrderNo) {
            let SalesOrderProvisonalNo = SalesOrderHeader.SalesOrderNo.split('-')
            TemporalSalesOrderNo = SalesOrderProvisonalNo.length > 1 ? SalesOrderProvisonalNo[0] : SalesOrderHeader.SalesOrderNo
        }


        const data = {
            SalesOrderNo: TemporalSalesOrderNo !== "" ? TemporalSalesOrderNo : SalesOrderHeader.SalesOrderNo,
            OrderNo: SalesOrderHeader.SalesOrderNo,
            CustomerNo: SalesOrderHeader.CustomerNo,
            username: getValueCookie('userName'),
            company: getValueCookie('Company'),
            idCompany: Number(getValueCookie('CompanyId')),
            idcompany: getValueCookie('CompanyId'),
            InvoiceType: 1,
            Status: SalesOrderHeader.Status
        }
        const detail = await getInformationWithData('/sales/get/detail', data)
     
        if (detail.status.code === 1) {
            let response = []
            response = response.concat(detail.data.Tabla2)
            let newTabla1 = []

            detail.data.Tabla1.forEach(element => {
                element.LastTotalUnitCost = element.LastUnitCost
                element.itemCode = element.ItemCode

                newTabla1.push(element)
            }
            );
            
            
            response = response.concat(newTabla1)
            return response
        } else {
            return []
        }

    }

    async getTrackingSO() {
        this.ModalLoading.current.showState(true);
        const data = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
        }
        let AllSalesOrder = []

        const respuesta = await getInformationWithData('/sales/get/header', data);
        if (respuesta.status.code === 1) {



            const data2 = {
                company: getValueCookie('Company'),
                idcompany: getValueCookie('CompanyId'),
                priceLevel: '1',
                Component: null
            }


            const invent = await getInformationWithData('/Items/get', data2)
            
            if (getValueCookie('CompanyId') === '6') {
                data2.company = 'Mayaland Atlanta'
                data2.idcompany = '3'
                const inventM = await getInformationWithData('/Items/get', data2);

                invent.status.code = inventM.status.code
                for (const item of inventM.data) {
                    const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                    if (index !== -1) {
                        await invent.data.splice(index, 1)
                    }
                }
                invent.data = invent.data.concat(inventM.data)
            }




            for (const SalesOrderHeader of respuesta.data) {
                let NewSO = new TrackingSalesOrder(SalesOrderHeader.SalesOrderNo, SalesOrderHeader.OrderType, SalesOrderHeader.OrderDate, SalesOrderHeader.BillToName, SalesOrderHeader.CustomerNo)

                let items = await this.getDetailSO(SalesOrderHeader)
                
                for (const item of items) {

                    const index2 = getIndexElement(invent.data, 'itemCode', item.ItemCode)
                    
                    let temporalItem = {}
                    if (index2 !== -1) {
                        let infoItem = invent.data[index2]
                        temporalItem = new TrackingDetail(item.ItemCode, infoItem.ProductLineDesc, infoItem.abbreviatedDesc, item.QuantityOrdered, item.QuantityShipped, getPriceMargin(item.UnitPrice,infoItem.LastTotalUnitCost, null))
                     
                        NewSO.addProd((Number(item.UnitPrice) * item.QuantityOrdered), (Number(infoItem.LastTotalUnitCost) * item.QuantityOrdered))
                        NewSO.Detail.push(temporalItem)
                    }else{
                       
                    }

                    
                }
                

                AllSalesOrder.push(NewSO)
            }
            this.setState({ SalesOrder: AllSalesOrder, SalesOrderFilter: AllSalesOrder })
            
        }
        this.ModalLoading.current.showState(false);
    }

    SelectSODetail(SalesOrder) {
       
        this.setState({
            SelectSO: SalesOrder.SalesOrderNo,
            DetailSO: SalesOrder.Detail,
            FilterDetailSO: SalesOrder.Detail
        })
    }

    OrderInformation(array, key, type) {
        const order = OrderArray(array, key, this.state.order)
        this.setState({ [type]: order, order: !this.state.order })

    }

    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchTracking = e => {
        const searchValue = e.target.value
        const id = e.target.id
        let Filter = []
        switch (id) {
            case "searchSalesOrderTracking":

                if (searchValue === '') {
                    Filter = this.state.SalesOrder
                } else {
                    Filter = this.state.SalesOrder.filter((detail) => {
                        if (
                            this.Contains(detail.SalesOrderNo, searchValue) ||
                            this.Contains(detail.CustomerNo, searchValue) ||
                            this.Contains(detail.CustomerName, searchValue)
                        ) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ SalesOrderFilter: Filter })
                break;
            case "searchSalesOrderDetailTracking":
                if (searchValue === '') {
                    Filter = this.state.DetailSO
                } else {
                    Filter = this.state.DetailSO.filter((detail) => {
                        if (
                            this.Contains(detail.ItemCode, searchValue) ||
                            this.Contains(detail.ProdLine, searchValue) ||
                            this.Contains(detail.Description, searchValue)
                        ) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ FilterDetailSO: Filter })

                break;
            default:
                break;

        }
    }

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Tracking Number Report</p>
                <div className='row'>

                    <div className='col-12'>
                        <ul className="nav nav-tabs" id="ScanningControls" role="tablist">
                            <li hidden={false} className="nav-item" role="presentation">
                                <button id='ReportTrackingSalesOrder' className="nav-link active" data-bs-toggle="tab" data-bs-target="#TrackingSalesOrder" type="button" role="tab" aria-controls="TrackingSalesOrder" aria-selected="true">Sales Order</button>
                            </li>
                            <li hidden={true} className="nav-item" role="presentation">
                                <button id='ReportTrackingInvoice' className="nav-link" data-bs-toggle="tab" data-bs-target="#TrackingInvoice" type="button" role="tab" aria-controls="TrackingInvoice" aria-selected="false">Invoices</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="allScanningTab">
                            <div className="tab-pane fade show active" id="TrackingSalesOrder" role="tabpanel" aria-labelledby="TrackingSalesOrder">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-5'>
                                        <div className="d-grid gap-2">
                                            <button onClick={() => this.getTrackingSO()} type="button" className="btn blueButton btn-lg">Search Information <BsArrowRepeat /></button>
                                        </div>
                                    </div>
                                    <div hidden={this.state.SalesOrder.length===0} className='col-12 pb-3'>
                                        <input onChange={this.searchTracking} className="form-control form-control-lg" placeholder='Search by Order No, Customer No, Customer Name...' id='searchSalesOrderTracking' type="text" />
                                    </div>
                                    <div hidden={this.state.SalesOrder.length===0}  className='col-12 tableFixHead pb-2'>
                                        <table className='table align-middle'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'SalesOrderNo', 'SalesOrderFilter')} className='bg-dark'><div className='row'><div className='col-12'>Sales Order No</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'Type', 'ModalBoSalesOrderFilterdyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Type</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'CustomerNo', 'SalesOrderFilter')} className='bg-dark'><div className='row'><div className='col-12'>Customer No</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'CustomerName', 'SalesOrderFilter')} className='bg-dark'><div className='row'><div className='col-12'>Customer Name</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'Date', 'SalesOrderFilter')} className='bg-dark'><div className='row'><div className='col-12'>Order Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'TrackingNo', 'SalesOrderFilter')} className='bg-dark'><div className='row'><div className='col-12'>Unique Tracking No</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'TrackingNo', 'SalesOrderFilter')} className='bg-dark'><div className='row'><div className='col-12'></div><div className='col-12'></div></div></th>
                                                    <th onClick={() => this.OrderInformation(this.state.SalesOrderFilter, 'TrackingNo', 'SalesOrderFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Amount</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>

                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.SalesOrderFilter.map((det, i) => (
                                                        <tr onClick={() => this.SelectSODetail(det)} className={this.state.SelectSO === det.SalesOrderNo ? 'bg-warning' : ''} key={i}>
                                                            <td className='text-center'>{det.SalesOrderNo}</td>
                                                            <td className='text-center'>{det.Type}</td>
                                                            <td className='text-center'>{det.CustomerNo}</td>
                                                            <td className='text-start'>{det.CustomerName}</td>
                                                            <td className='text-center'>{det.Date}</td>
                                                            <td className='text-center'>{det.TrackingNo}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(det.TotalUnitCost))}</td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                    <div className='col-12 pb-3' hidden={this.state.DetailSO.length === 0}>
                                        <div className='row'>
                                            <div className='col-12 pb-3 titleTotal'>
                                                <p>{'Detail of the Order: ' + this.state.SelectSO}</p>
                                            </div>
                                            <div className='col-12 pb-3'>
                                                <input onChange={this.searchTracking} className="form-control form-control-lg" placeholder='Search by Item Code, Product Line, Description...' id='searchSalesOrderDetailTracking' type="text" />
                                            </div>
                                            <div className='col-12 tableFixHead pb-3'>
                                                <table className='table align-middle'>
                                                    <thead className='thead'>
                                                        <tr className='text-light text-center'>
                                                            <th onClick={() => this.OrderInformation(this.state.FilterDetailSO, 'ItemCode', 'FilterDetailSO')} className='bg-dark'><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.FilterDetailSO, 'ProdLine', 'FilterDetailSO')} className='bg-dark'><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.FilterDetailSO, 'Description', 'FilterDetailSO')} className='bg-dark'><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.FilterDetailSO, 'QuantityOrdered', 'FilterDetailSO')} className='bg-dark'><div className='row'><div className='col-12'>Quantity Ordered</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.FilterDetailSO, 'TrackingNo', 'FilterDetailSO')} className='bg-dark'><div className='row'><div className='col-12'>BOX</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='tbody'>
                                                        {
                                                            this.state.FilterDetailSO.map((det, i) => (
                                                                <tr key={i}>
                                                                    <td className='text-start'>{det.ItemCode}</td>
                                                                    <td className='text-start'>{det.ProdLine}</td>
                                                                    <td className='text-start'>{det.Description}</td>
                                                                    <td className='text-center'>{det.QuantityOrdered}</td>
                                                                    <td className='text-center'>{det.TrackingNo}</td>

                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="TrackingInvoice" role="tabpanel" aria-labelledby="TrackingInvoice">
                                <div className='row pt-4'>
                                    <div className='col-12'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
