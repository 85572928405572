import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({

    bolderStyle:{
        fontFamily:'Helvetica-Bold'
    },
    BusinessLogo: {
        width: "90px",
        height: "30px",
        top: "5px",
        left:"5px",
        marginBottom:"5px"
    },
    blockHeaderStyle:{
        display: "flex",
        flexDirection: "row",
        fontFamily:'Helvetica-Bold'
    },
    CompanyTitleStyle:{
        fontSize: "5px",
        textAlign:"center",
        marginBottom:"0.5px",
        fontFamily:'Helvetica-Bold'
    },
    blockHeader:{
        width:"100%",
    },


    generalHeaderStyle:{
        display: "flex",
        flexDirection: "row",
        width:"86%",
        marginLeft:"7%",
        marginRight:"7%"
        
    },
    generalTextLeftStyle:{
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"62%"
        
    },

    MarginBottom:{
        marginBottom:"5px"
    },

    generalBolderTextLeftStyle:{
        fontFamily:'Helvetica-Bold',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"38%"
        
    },
    QuantityColumnHeader:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"center",
        marginBottom:"0.5px",
        width:"8%"
        
    },
    CodeColumnHeader:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"center",
        marginBottom:"0.5px",
        width:"33%"
        
    },
    PriceColumnHeader:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"center",
        marginBottom:"0.5px",
        width:"17%"
        
    },
    LongColumnHeader:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"center",
        marginBottom:"0.5px",
        width:"70%"
        
    },
    MiddleColumnHeader:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"center",
        marginBottom:"0.5px",
        width:"45%"
        
    },
    SignColumnHeader:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"center",
        marginBottom:"0.5px",
        width:"5%"
        
    },

    QuantityColumn:{
        fontFamily:'Courier',
        fontSize: "4px",
        textAlign:"center",
        marginBottom:"0.5px",
        width:"8%"
        
    },
    CodeColumn:{
        fontFamily:'Courier',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"33%"
        
    },

    LongColumn:{
        fontFamily:'Courier',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"70%"
        
    },

    MiddleColumn:{
        fontFamily:'Courier',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"45%"
        
    },
    SignColumn:{
        fontFamily:'Courier',
        fontSize: "4px",
        textAlign:"right",
        marginBottom:"0.5px",
        width:"5%"
        
    },
    PriceColumn:{
        fontFamily:'Courier',
        fontSize: "4px",
        textAlign:"right",
        marginBottom:"0.5px",
        width:"17%"
        
    },



    QuantityColumnBottom:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"22.5%"
        
    },
    CodeColumnBottom:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"22.5%"
        
    },
    LongColumnBottom:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"50%"
        
    },
    MiddleColumnBottom:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"left",
        marginBottom:"0.5px",
        width:"50%"
        
    },
    SignColumnBottom:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"right",
        marginBottom:"0.5px",
        width:"5%"
        
    },
    PriceColumnBottom:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"right",
        marginBottom:"0.5px",
        width:"22.5%"
        
    },

    CommentStyle:{
        fontFamily:'Courier-Bold',
        fontSize: "4px",
        textAlign:"justify",
        marginBottom:"0.5px",
        width:"100%" 
    },


});

export default styles;