//Invoices and Spoilage
import React, { Component } from 'react'
import Swal from "sweetalert2";
import "../css/general-style.css"
import '../css/table-responsive.css'
import LoadingWindow from '../components/LoadingWindow';
import { AiOutlineFileAdd, AiOutlineCloseCircle } from "react-icons/ai";
import CustomerList from '../components/CustomerList';
import InvoiceListCustomerNo from '../components/InvoiceListCustomerNo';
import { getValueCookie } from '../services/cookieService';
import { create_Delete_Update_Information, getInformationWithData } from '../services/CABE';
import { Contains, DecimalPart, DeleteCharacter, MobileDisp, NumberFormat, validateNumbersForQuantitys } from '../functions/generalFunctions';
import ARDivisionList from '../components/ARDivisionList';
import { pricesFormat } from '../functions/pricesFormat';
import { DateFormatMAS90, getDateYearMonthDayDash } from '../functions/dateFormat';
import { confirmCloseAlert } from '../functions/alerts';
import { InvoicePDF } from '../components/InvoicePDF';
import { InvoicePDF2 } from '../components/InvoicePDF2';
import { InvoiceZebra } from '../components/InvoiceZebra/InvoiceZebra';
import { DownloadInvoicePDF2 } from '../components/DownloadInvoicePDF2';
import { DownloadInvoicePDF } from '../components/DownloadInvoicePDF';
import Select from 'react-select';
import SpoilageListSalesPerson from '../components/SpoilageListSalesPerson';
import CatalogueSpoilage from '../components/CatalogueSpoilage/CatalogueSpoilage';
export default class AccountsReceivable extends Component {
    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }

    state = {
        Accounts: {
            InvoiceNo: '',
            InvoiceDate: getDateYearMonthDayDash(),
            BatchNo: '',
            InvoiceType: '',
            Type: '',
            SalespersonNo: '',
            Comment: '',
            FreightAmt: 0,
            Customer: {
                AddressLine1: '',
                City: '',
                CountryCode: '',
                CreditLimit: 0,
                CustomerName: '',
                CustomerNo: '',
                EmailAddress: '',
                FaxNo: '',
                PriceLevel: '',
                SalespersonNo: '',
                State: '',
                TaxSchedule: '',
                TelephoneNo: '',
                TermsCode: '',
                UDF_RUTA: '',
                ZipCode: '',
            },
            TotalQuantityOrdered: 0,
            TotalCost: 0,
            TotalQuantityShipped: 0,
            TotalWeight: 0,
            SalesOrderNo: '',
            ApplyToInvoiceNo: ''
        },
        FilterProducts: [],
        Products: [],
        ARDetail: [],
        PrintDetail: [],
        confirmPrint: true,
        options: [],
        select: {
            label: null, value: null
        }
    }


    async getNewCorrelativeInvoiceNumber() {

        const data = {
            type: 'InvoiceNumber',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)
        if (result.status.code === 1) {
            const temporal = 'PM' + result.data[0].correlative
            const temp = this.state.Accounts
            temp.InvoiceNo = temporal
            this.setState({ Accounts: temp })
        }
    }

    onTarget = (e, item) => {
        const searchValue = e.target.value;
        const idInput = e.target.id.split('_')
        if (idInput.length === 1) {
            switch (idInput[0]) {
                case 'SearchItemAccountsReceivable':
                    let FiltProducts = this.state.Products.filter((item) => {
                        if (((
                            Contains(item.itemCode, searchValue) ||
                            Contains(item.abbreviatedDesc, searchValue)
                        ))
                        ) {
                            return item
                        } else {
                            return null
                        }
                    });
                    this.setState({ FilterProducts: FiltProducts });
                    break;
                default:
                    break;
            }
        } else {
            const temporal = this.state.ARDetail
            const index = temporal.indexOf(item)
            if (index !== -1) {
                if (idInput[0] === 'totalAmountAR') {
                    temporal[index].ItemTotalAmt = Number(searchValue)
                    this.setState({ ARDetail: temporal })
                    this.calculateTotals()
                }
            }
        }




    }

    addComentary(item) {
        let comm = item.ItemComment
        Swal.fire({
            title: 'Product: ' + DeleteCharacter(item.itemCode, '/'),
            html: `<div class='textAreaGeneral textAreaComment'>
                    <textarea id='comentOfAR' rows="10" cols="50">${comm}</textarea>
                   </div>
            `,
            backdrop: true,
            showCancelButton: true,
            confirmButtonText: 'Save Commentary',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const temporal = this.state.ARDetail
                const index = temporal.indexOf(item)
                if (index !== -1) {
                    const coment = Swal.getPopup().querySelector('#comentOfAR').value
                    temporal[index].ItemComment = coment
                    this.setState({ ARDetail: temporal })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    removeAR(item) {

        const temporal = this.state.ARDetail
        const index = temporal.indexOf(item)
        if (index !== -1) {
            //temporal.splice(index, 1)

            if (this.state.select.label === 'SPOILAGE') {
                temporal[index].quantityShipped = item.quantityShipped
                temporal[index].quantityReturned = 0
                temporal[index].UnitPrice = item.UnitPrice
                temporal[index].Total = 0
                temporal[index].ItemComment = ''
            } else {
                temporal[index].ItemComment = " "
                temporal[index].ItemTotalAmt = 0
            }
            temporal[index].Visible = false
            this.setState({ ARDetail: temporal })
            this.refreshInputs()
        }
        this.calculateTotals()

    }



    async AuthorizeAR() {
        if (this.state.Accounts.SalespersonNo === '') {
            let temp = this.state.Accounts
            temp.SalespersonNo = getValueCookie('SalesPerson')
            this.setState({ Accounts: temp })
        }


        let detail = []
        let printdetail = []
        let cont = 1
        if (this.state.select.label === 'SPOILAGE') {

            for (const item of this.state.ARDetail) {

                detail.push({
                    ItemCode: '/SPOILAGE',
                    ItemType: '3',
                    ItemCodeDesc: 'Spoilage',
                    ItemComment: item.itemCode + '/' + item.ItemComment,
                    ItemTotalAmt: item.Total,
                    LineSeqNo: cont
                })
                if (item.Visible) {

                    printdetail.push({
                        ItemCode: '/SPOILAGE',
                        ItemCode2: item.itemCode,
                        Description: (item.completeDesc !== "" && item.completeDesc !== null && item.completeDesc !== undefined) ? item.completeDesc : item.abbreviatedDesc,
                        UnitPrice: Number(item.UnitPrice).toFixed(2),
                        quantityReturned: item.quantityReturned,
                        ItemType: '3',
                        ItemCodeDesc: 'Spoilage',
                        ItemComment: item.itemCode + '/' + item.ItemComment,
                        Comment:item.ItemComment,
                        ItemTotalAmt: item.Total,
                        LineSeqNo: cont
                    })
                }
                cont++
            }

        } else {
            detail = this.state.ARDetail
            for (const item of detail) {
                item.LineSeqNo = cont
                if (item.Visible) {
                    printdetail.push(item)
                }
                cont++
            }
        }
        let temporal = this.state.Accounts
        temporal.InvoiceType = this.state.select.label === 'SPOILAGE' || this.state.select.label === 'SALES DISCOUNT' ? 'CM' : 'IN'

        const data = {
            Account: temporal,
            Products: this.state.Products,
            ARDetail: detail,
            OrderType: "InvoiceAR",
            idCompany: Number(getValueCookie('CompanyId')),
            username: getValueCookie('userName'),
            company: getValueCookie('Company')
        }


        const result = await create_Delete_Update_Information('/invoice/setAR', data)
        await this.setState({ PrintDetail: printdetail })


        if (result.status.code === 1) {

            confirmCloseAlert('correct', 'The Account Receivable was saved successfully!')
            this.printAlert()

        } else {
            confirmCloseAlert('incorrect', 'Whoops something is wrong. Please try again!')
        }

    }

    clearAll() {
        this.setState({
            Accounts: {
                InvoiceNo: '',
                InvoiceDate: getDateYearMonthDayDash(),
                BatchNo: '',
                InvoiceType: '',
                Type: '',
                SalespersonNo: '',
                Comment: '',
                FreightAmt: 0,
                Customer: {
                    AddressLine1: '',
                    City: '',
                    CountryCode: '',
                    CreditLimit: 0,
                    CustomerName: '',
                    CustomerNo: '',
                    EmailAddress: '',
                    FaxNo: '',
                    PriceLevel: '',
                    SalespersonNo: '',
                    State: '',
                    TaxSchedule: '',
                    TelephoneNo: '',
                    TermsCode: '',
                    UDF_RUTA: '',
                    ZipCode: '',
                },
                TotalQuantityOrdered: 0,
                TotalCost: 0,
                TotalQuantityShipped: 0,
                TotalWeight: 0,
                SalesOrderNo: '',
                ApplyToInvoiceNo: ''
            },
            FilterProducts: [],
            Products: [],
            ARDetail: [],
            PrintDetail: [],
            confirmPrint: true
        })

    }
    textTermsCode(num) {
        switch (Number(num)) {
            case 0:
                return 'Pay in Advance'
            case 1:
                return 'FOB Taiwan'
            case 7:
                return 'Net 07 Days'
            case 10:
                return 'Net 10 Days'
            case 15:
                return 'Net 15 Days'
            case 21:
                return 'Net 21 Days'
            case 30:
                return 'Net 30 Days'
            case 45:
                return 'Net 45 Days'
            default:
                return ''
        }

    }

    printDetail(type) {
        let detail = []

        if (type === 'Zebra') {
            this.state.PrintDetail.forEach(element => {
                const It = {
                    ItemCode: DeleteCharacter(element.ItemCode, '/'),
                    ItemCodeDesc: element.ItemCodeDesc,
                    ItemComment: element.ItemComment,
                    Comment:element.Comment,
                    ItemTotalAmt: element.ItemTotalAmt,
                    ItemType: element.ItemType,
                    ItemCode2: element.ItemCode2,
                    Description: element.Description,
                    UnitPrice: element.UnitPrice,
                    quantityReturned: element.quantityReturned,
                }
                detail.push(It)
            });

        } else {

            this.state.PrintDetail.forEach(element => {
                const It = {
                    ItemCode: DeleteCharacter(element.ItemCode, '/'),
                    ItemCodeDesc: element.ItemCodeDesc,
                    ItemComment: element.ItemComment,
                    Comment:element.Comment,
                    ItemTotalAmt: element.ItemTotalAmt,
                    ItemType: element.ItemType,
                    ItemCode2: element.ItemCode2,
                    Description: element.Description,
                    UnitPrice: element.UnitPrice,
                    quantityReturned: element.quantityReturned,

                }
                detail.push(It)
            });
            const tamBreak = 55
            const res = detail.length % tamBreak
            for (let a = 0; a < tamBreak - res; a++) {
                const itemJson = {
                    ItemCode: " ",
                    ItemCodeDesc: " ",
                    ItemComment: " ",
                    Comment:" ",
                    ItemTotalAmt: " ",
                    ItemType: " ",
                    ItemCode2: " ",
                    Description: " ",
                    UnitPrice: " ",
                    quantityReturned: " ",
                }
                detail.push(itemJson)

            }
        }






        return detail
    }

    realLongDetail() {
        let cont = 0
        this.state.PrintDetail.forEach(() => {
            cont++

        });
        return cont
    }

    calculateTotals() {

        let totalCost = 0
        let quantity = 0
        let Shipped = 0
        const temporal = this.state.ARDetail
        const temporal2 = this.state.Accounts
        if (this.state.select.label === 'SPOILAGE') {
            for (const item of temporal) {
                quantity += Number(item.quantityReturned)
                Shipped += Number(item.quantityShipped)
                totalCost += Number(item.Total)
            }

            temporal2.TotalCost = totalCost.toFixed(2)
            temporal2.TotalQuantityShipped = Shipped.toFixed(2)
            temporal2.TotalQuantityOrdered = quantity.toFixed(2)
            this.setState({ Accounts: temporal2 })

        } else {

            for (const item of temporal) {

                totalCost += Number(item.ItemTotalAmt)
            }

            temporal2.TotalCost = totalCost.toFixed(2)
            temporal2.TotalQuantityShipped = Shipped.toFixed(2)
            temporal2.TotalQuantityOrdered = quantity.toFixed(2)
            this.setState({ Accounts: temporal2 })
        }

    }

    printAlert() {
        Swal.fire({
            title: 'Do you want to print this Accounts Receivable:',
            showDenyButton: true,
            showConfirmButton: true,
            denyButtonText: 'No, thanks!',
            confirmButtonText: 'Yes, please',
            confirmButtonColor: '#26117A',
            denyButtonColor: '#D05416',

        }).then(async (result) => {
            if (result.isConfirmed) {
                if (document.getElementById('InvoicePrintAR')) {
                    var button1 = document.getElementById('InvoicePrintAR')
                    if (button1) {
                        await button1.click()
                    }
                }
            } else if (result.isDenied) {
                //this.clearAll()
            }
        })
    }

    openPrint() {

        if (document.getElementById('InvoicePrintAR')) {
            let button = document.getElementById('InvoicePrintAR')
            button.click()

        }
    }
    handleChange(e) {
        const change = e
        this.setState({ select: change })
        this.clearAll()
    }

    refreshInputs() {
        const temporal = this.state.ARDetail
        if (this.state.select.label === 'SPOILAGE') {
            for (let a = 0; a < temporal.length; a++) {

                document.getElementById("quantityReturnedSpoilage_" + a).value = temporal[a].quantityReturned
                document.getElementById("unitPriceSpoilage_" + a).value = temporal[a].UnitPrice
            }
        } else {
            for (let a = 0; a < temporal.length; a++) {
                document.getElementById("totalAmountAR_" + a).value = temporal[a].ItemTotalAmt
            }
        }

    }

    changeSpoilageDetail = async (e, item) => {
        const temporal = this.state.ARDetail
        const ident = e.target.id.split('_')
        const index = temporal.indexOf(item)

        if (index !== -1) {
            if (ident[0] === "quantityReturnedSpoilage") {
                let quant = Number(e.target.value)

                if (!validateNumbersForQuantitys(quant)) {
                    let parteDecimal = 1 - DecimalPart(quant)
                    quant += parteDecimal
                    document.getElementById(e.target.id).value = quant
                }

                temporal[index].quantityReturned = Number(quant)
                temporal[index].Total = (Number(quant) * Number(temporal[index].UnitPrice))
                this.setState({ ARDetail: temporal })
                this.calculateTotals()
            } else if (ident[0] === "unitPriceSpoilage") {
                temporal[index].UnitPrice = Number(e.target.value)
                temporal[index].Total = (Number(e.target.value) * Number(temporal[index].quantityReturned))

                this.setState({ ARDetail: temporal })
                this.calculateTotals()
            }


        }

    }

    async getAROPtions() {
        const data = {
            idCompany: Number(getValueCookie('CompanyId'))
        }
        const respuesta = await getInformationWithData('/Items/AR', data);

        let options = []
        let count = 1
        for (const item of respuesta.result) {
            let temporal = { label: DeleteCharacter(item.ItemCode, '/'), value: count }
            options.push(temporal)
            if (this.state.select.label === null && temporal.label === 'SPOILAGE') {

                this.setState({ select: temporal })
            }
            count++
        }
        this.setState({ options: options })
    }



    render() {
        return (
            <div>

                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Accounts Receivable</p>
                <div className='row pb-5'>
                    <div className='col-1' />
                    <div className='col-10'>
                        <Select onMenuOpen={() => this.getAROPtions()} value={this.state.select} options={this.state.options} className='fs-4 text-start' placeholder="Select Mode" onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className='col-1' />
                </div>
                <div className='row pb-2'>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-10'>
                        <div className='row pb-5'>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className="col-sm-12 input-group input-group-lg">
                                        <input type="text" autoComplete='off' id='SearchCustomerNoAccountsReceivable' className="form-control" placeholder='Search Customer...' />
                                    </div>
                                    <div className='col-sm-12 pt-2 text-center'>
                                        <CustomerList disabled={this.state.select.label === null} headerName='Customer' nombrePadre="Accounts" Padre={this} ident="AccountCustomerList1" />
                                    </div>
                                    <div className='col-sm-6 pt-3 InputTextBolder'>
                                        <p>Invoice No:</p>
                                    </div>
                                    <div className='col-sm-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.InvoiceNo}</p>
                                    </div>
                                    <div hidden={this.state.select.label === 'FREIGHT'} className='col-sm-6 pt-3 InputTextBolder'>
                                        <p>Apply to Invoice:</p>
                                    </div>
                                    <div hidden={this.state.select.label === 'FREIGHT'} className='col-sm-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.ApplyToInvoiceNo}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-sm-12 d-grid gap-2'>
                                        <InvoiceListCustomerNo hidden={this.state.select.label === 'FREIGHT'} disabled={this.state.Accounts.Customer.CustomerNo === ''} CustomerNo={this.state.Accounts.Customer.CustomerNo} headerName='' buttonTitle="Select Invoice to Apply" nombrePadre="AccountsListCustomer" Padre={this} />
                                    </div>
                                    <div hidden={false} className='col-sm-12 d-grid gap-2 pt-2'>
                                        <SpoilageListSalesPerson hidden={false} disabled={false} headerName='' nombrePadre="SpoilageListSalesPerson" Padre={this} />
                                    </div>
                                    <div hidden={true} className='col-sm-12 d-grid gap-2 pt-2'>
                                        <SpoilageListSalesPerson hidden={true} disabled={true} headerName='' nombrePadre="SpoilageListHistorySalesPerson" Padre={this} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row grayBackground'>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Customer No:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.Customer.CustomerNo}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Name:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.Customer.CustomerName}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Zip Code:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.Customer.ZipCode}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>State:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.Customer.State}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>City:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.Customer.City}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Telephone No:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Accounts.Customer.TelephoneNo}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <div hidden={this.state.Accounts.ApplyToInvoiceNo === ''} className='row pt-4'>
                                    <div className='col-sm-12'>
                                        <p className='text-start display-5 pb-2' >Invoice Detail</p>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-12 pt-3 pb-3 d-grid gap-2'>
                                        <ARDivisionList SelectARDiv={this.state.select.label} type={this.state.select.value} hidden={this.state.select.label === 'SPOILAGE' || this.state.Accounts.InvoiceNo === ''} disabled={this.state.Accounts.Customer.CustomerNo === ''} headerName='' nombrePadre="ARListAccountReceivable" colorButton='yellowButton' Padre={this} />
                                    </div>

                                    <div className='col-sm-12 pt-3 pb-3 d-grid gap-2'>
                                        <CatalogueSpoilage hidden={this.state.select.label !== 'SPOILAGE' || this.state.Accounts.InvoiceNo === ''} Invoice={this.state.Accounts.ApplyToInvoiceNo} detail={this.state.Products} mode={this.state.select.label} Padre={this} needPrice={true} needCost={false} />
                                    </div>
                                    {this.state.select.label === 'SPOILAGE' ?
                                        <div className='col-sm-12 tableFixHead'>
                                            <table className='table align-middle'>
                                                <thead className='thead'>
                                                    <tr className='bg-dark text-white'>
                                                        <th className='text-center bg-dark '>ItemCode</th>
                                                        <th className='text-center bg-dark '>Description</th>
                                                        <th className='text-center bg-dark '>Quantity Shipped</th>
                                                        <th className='text-center bg-dark '>Quantity Returned</th>
                                                        <th className='text-center bg-dark '>Unit Price</th>
                                                        <th className='text-center bg-dark '></th>
                                                        <th className='text-center bg-dark '>Total Amt</th>
                                                        <th className='text-center bg-dark '>Comment</th>
                                                        <th className='text-center bg-dark '></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.ARDetail.map((item, i) => (
                                                            <tr hidden={!item.Visible} key={i}>
                                                                <td className='text-start'>{item.itemCode}</td>
                                                                <td className='text-start'>{(item.completeDesc !== "null" && item.completeDesc) ? item.completeDesc : item.abbreviatedDesc}</td>
                                                                <td className='text-center textTableSize text-secondary'> {pricesFormat(item.quantityShipped)}</td>
                                                                <td>
                                                                    <div className="input-group  input-group-lg">
                                                                        <input type="number" id={'quantityReturnedSpoilage_' + i} min={0} defaultValue={item.quantityReturned} onChange={(e) => this.changeSpoilageDetail(e, item)} className="form-control text-center inputResize" />
                                                                    </div>
                                                                </td>


                                                                <td >
                                                                    <div className="input-group input-group-lg flex-nowrap">
                                                                        <span className="input-group-text">$</span>
                                                                        <input type="number" id={'unitPriceSpoilage_' + i} min={0} step={0.01} defaultValue={Number(item.UnitPrice).toFixed(2)} onChange={(e) => this.changeSpoilageDetail(e, item)} className="form-control text-end inputResize" />
                                                                    </div>
                                                                </td>
                                                                <td className='text-end textTableSize text-success'>
                                                                    $
                                                                </td>
                                                                <td className='text-end textTableSize text-success'>{NumberFormat(Number(item.Total).toFixed(2))}</td>
                                                                <td>
                                                                    <div className="d-grid gap-1">
                                                                        <button type="button" onClick={() => this.addComentary(item)} className="btn orangeButton btn-lg text-wrap">Comment</button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-grid gap-2">
                                                                        <button type="button" onClick={() => this.removeAR(item)} className="btn redButton btn-lg"><AiOutlineCloseCircle /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                                <tfoot className='tfoot'>
                                                    <tr className='bg-dark text-white'>
                                                        <td className=''></td>
                                                        <td className='text-end'>TOTALS</td>
                                                        <td className='text-center'>{this.state.Accounts.TotalQuantityShipped}</td>
                                                        <td className='text-center'>{this.state.Accounts.TotalQuantityOrdered}</td>
                                                        <td className=''></td>
                                                        <td className='text-end'>$</td>
                                                        <td className='text-end'>{this.state.Accounts.TotalCost}</td>
                                                        <td className=''></td>
                                                        <td className=''></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        :
                                        <div className='col-sm-12 tableFixHead'>
                                            <table className='table align-middle'>
                                                <thead className='thead'>
                                                    <tr className='bg-dark text-white'>
                                                        <th className='text-center bg-dark '>AR Code</th>
                                                        <th className='text-center bg-dark '>Type</th>
                                                        <th className='text-center bg-dark '>Description</th>
                                                        <th className='text-center bg-dark '>Total Amount</th>
                                                        <th className='text-center bg-dark '></th>
                                                        <th className='text-center bg-dark '></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.ARDetail.map((item, i) => (
                                                            <tr hidden={!item.Visible} key={i}>
                                                                <td className='text-start'>{DeleteCharacter(item.ItemCode, '/')}</td>
                                                                <td className='text-center'>{item.ItemType}</td>
                                                                <td className='text-start'>{item.ItemCodeDesc}</td>
                                                                <td >
                                                                    <div className="input-group input-group-lg flex-nowrap">
                                                                        <span className="input-group-text">$</span>
                                                                        <input type="number" id={'totalAmountAR_' + i} min={0} step={0.01} defaultValue={Number(item.ItemTotalAmt).toFixed(2)} onChange={(e) => this.onTarget(e, item)} className="form-control text-end" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-grid gap-1">
                                                                        <button type="button" onClick={() => this.addComentary(item)} className="btn orangeButton btn-lg text-wrap">Comment</button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-grid gap-2">
                                                                        <button type="button" onClick={() => this.removeAR(item)} className="btn redButton btn-lg"><AiOutlineCloseCircle /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                                <tfoot className='tfoot'>
                                                    <tr></tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    }

                                </div>

                            </div>
                        </div>

                    </div>
                    <div className='col-sm-1'></div>

                    <div className='col-sm-1'></div>
                    <div hidden={this.state.Accounts.InvoiceNo === ''} className='col-sm-10'>
                        <div className='row'>
                            <div className='col-sm-12 d-grid gap-2 pb-2'>
                                <button disabled={!this.state.confirmPrint} type="button" className="btn greenButton btn-lg" onClick={() => this.AuthorizeAR()}>Save Invoice And Print <AiOutlineFileAdd /></button>
                            </div>
                            <div className='col-sm-6 d-grid gap-2 pb-2'>
                                {getValueCookie('CompanyId') === '6' ?
                                    (MobileDisp() ?
                                        <InvoicePDF2
                                            id={'InvoicePrintAR'}
                                            hidden={true}
                                            disabled={false}
                                            colorButton='orangeButton'
                                            titleButton='Invoice Print'
                                            title='Invoice Print'
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            InvoiceType={this.state.select.label}
                                            CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(770) 871-5807']}
                                            InvoiceHeader={['Invoice Number:', this.state.Accounts.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Accounts.InvoiceDate), 'Sales Person:', this.state.Accounts.SalespersonNo, 'Customer Number:', this.state.Accounts.Customer.CustomerNo]}

                                            Box1={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                            Box2={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                            HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", "Pay in Advance"]}
                                            DescriptionKeys={this.state.select.label === 'SPOILAGE' ? ["quantityReturned", "ItemCode2", "Description", "Comment", "$", "UnitPrice", "$", "ItemTotalAmt"] : ["ItemCode", "ItemCodeDesc", "ItemComment", "$", "ItemTotalAmt"]}
                                            HeaderDescritpion={this.state.select.label === 'SPOILAGE' ? ["Qty", "ItemCode", "Description", "Comment", "", "UnitPrice", "", "Amount"] : ["Code", "Description", "Comment", "", "Amount"]}
                                            StyleDetail={this.state.select.label === 'SPOILAGE' ? ["ColumnaCantidades", "ColumnaNormalLeft", "ColumnaGrande", "ColumnaGrande", "Simbolo", "ColumnaMonetariaRight", "Simbolo", "ColumnaMonetariaRight"] : ["ColumnaNormalLeft", "ColumnaGrande", "ColumnaGrande", "Simbolo", "ColumnaMonetariaRight"]}
                                            Detail={this.printDetail()}
                                            breakpoint={65}
                                            quantSignDolar={this.realLongDetail()}
                                            keysDecimalFormat={["ItemTotalAmt"]}
                                            bottomInformation={[
                                                this.state.Accounts.TotalQuantityOrdered, "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Accounts.FreightAmt) + Number(this.state.Accounts.TotalCost)))
                                            ]}
                                            bottomStyle={[
                                                "NormalButtom", "ClearBottom", "NormalButtom", "ClearBottom", "NormalButtom", "ClearBottom", "ClearBottom", "LargeButtom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                            ]}
                                            bottomMessage={""}

                                        />
                                        :
                                        <DownloadInvoicePDF2
                                            id={'InvoicePrintAR'}
                                            hidden={true}
                                            disabled={false}
                                            colorButton='orangeButton'
                                            titleButton='Invoice Print'
                                            title='Invoice Print'
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            InvoiceType={this.state.select.label}
                                            CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(770) 871-5807']}
                                            InvoiceHeader={['Invoice Number:', this.state.Accounts.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Accounts.InvoiceDate), 'Sales Person:', this.state.Accounts.SalespersonNo, 'Customer Number:', this.state.Accounts.Customer.CustomerNo]}

                                            Box1={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                            Box2={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                            HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", "Pay in Advance"]}
                                            DescriptionKeys={this.state.select.label === 'SPOILAGE' ? ["quantityReturned", "ItemCode2", "Description", "Comment", "$", "UnitPrice", "$", "ItemTotalAmt"] : ["ItemCode", "ItemCodeDesc", "ItemComment", "$", "ItemTotalAmt"]}
                                            HeaderDescritpion={this.state.select.label === 'SPOILAGE' ? ["Qty", "ItemCode", "Description", "Comment", "", "UnitPrice", "", "Amount"] : ["Code", "Description", "Comment", "", "Amount"]}
                                            StyleDetail={this.state.select.label === 'SPOILAGE' ? ["ColumnaCantidades", "ColumnaNormalLeft", "ColumnaGrande", "ColumnaGrande", "Simbolo", "ColumnaMonetariaRight", "Simbolo", "ColumnaMonetariaRight"] : ["ColumnaNormalLeft", "ColumnaGrande", "ColumnaGrande", "Simbolo", "ColumnaMonetariaRight"]}
                                            Detail={this.printDetail()}
                                            breakpoint={65}
                                            quantSignDolar={this.realLongDetail()}
                                            keysDecimalFormat={["ItemTotalAmt"]}
                                            bottomInformation={[
                                                this.state.Accounts.TotalQuantityOrdered, "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Accounts.FreightAmt) + Number(this.state.Accounts.TotalCost)))
                                            ]}
                                            bottomStyle={[
                                                "NormalButtom", "ClearBottom", "NormalButtom", "ClearBottom", "NormalButtom", "ClearBottom", "ClearBottom", "LargeButtom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                            ]}
                                            bottomMessage={""}

                                        />)
                                    :
                                    (
                                        MobileDisp() ?
                                            <InvoicePDF
                                                hidden={true}
                                                id={'InvoicePrintAR'}
                                                disabled={false}
                                                colorButton='orangeButton'
                                                titleButton='Invoice Print'
                                                title='Invoice Print'
                                                CompanyTitle='Mayaland'
                                                InvoiceType={this.state.select.label}
                                                CompanyContactInfo={['Mayaland LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(678) 213-1226']}
                                                InvoiceHeader={['Invoice Number:', this.state.Accounts.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Accounts.InvoiceDate), 'Sales Person:', this.state.Accounts.SalespersonNo, 'Customer Number:', this.state.Accounts.Customer.CustomerNo]}
                                                Box1={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                                Box2={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Accounts.Customer.TermsCode)]}
                                                DescriptionKeys={["ItemCode", "ItemCodeDesc", "ItemComment", "$", "ItemTotalAmt"]}
                                                HeaderDescritpion={["Code", "Description", "Comment", "", "Amount"]}
                                                StyleDetail={["ColumnaNormal2", "ColumnaNormal2", "ColumnaGrande2", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={55}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["ItemTotalAmt"]}
                                                bottomInformation={[
                                                    "", "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Accounts.FreightAmt) + Number(this.state.Accounts.TotalCost)))
                                                ]}
                                                bottomStyle={[
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3"
                                                ]}
                                                bottomMessage={" "}
                                            />
                                            :
                                            <DownloadInvoicePDF
                                                hidden={true}
                                                id={'InvoicePrintAR'}
                                                disabled={false}
                                                colorButton='orangeButton'
                                                titleButton='Invoice Print'
                                                title='Invoice Print'
                                                CompanyTitle='Mayaland'
                                                InvoiceType={this.state.select.label}
                                                CompanyContactInfo={['Mayaland LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(678) 213-1226']}
                                                InvoiceHeader={['Invoice Number:', this.state.Accounts.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Accounts.InvoiceDate), 'Sales Person:', this.state.Accounts.SalespersonNo, 'Customer Number:', this.state.Accounts.Customer.CustomerNo]}
                                                Box1={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                                Box2={[this.state.Accounts.Customer.CustomerName, this.state.Accounts.Customer.AddressLine1, this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Accounts.Customer.TermsCode)]}
                                                DescriptionKeys={["ItemCode", "ItemCodeDesc", "ItemComment", "$", "ItemTotalAmt"]}
                                                HeaderDescritpion={["Code", "Description", "Comment", "", "Amount"]}
                                                StyleDetail={["ColumnaNormal2", "ColumnaNormal2", "ColumnaGrande2", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={55}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["ItemTotalAmt"]}
                                                bottomInformation={[
                                                    "", "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Accounts.FreightAmt) + Number(this.state.Accounts.TotalCost)))
                                                ]}
                                                bottomStyle={[
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3"
                                                ]}
                                                bottomMessage={" "}
                                            />
                                    )

                                }

                            </div>
                            {getValueCookie('CompanyId') === '6' ?
                                <>
                                    <div className='col-sm-6 d-grid gap-2 pb-2'></div>
                                    <div className='col-sm-6 d-grid gap-2 pb-2'>
                                        <InvoiceZebra
                                            id={'InvoicePrintARZebra'}
                                            hidden={true}
                                            colorButton='redButton'
                                            titleButton='Invoice Print'
                                            title='Invoice Print'
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            InvoiceType={this.state.select.label}
                                            CompanyContactInfo={['Altitude LLC', '1860 Amwiler Lily Valley Dr', 'Lawrenceville, GA 30045', '(770) 871-5807']}
                                            InvoiceHeader={[['Invoice Number:', this.state.Accounts.InvoiceNo], ['Date:', DateFormatMAS90(this.state.Accounts.InvoiceDate)], ['Grocer:', this.state.Accounts.SalespersonNo], ['Client No:', this.state.Accounts.Customer.CustomerNo], ['Client Name:', this.state.Accounts.Customer.CustomerName], ['Address:', this.state.Accounts.Customer.AddressLine1 + ' ' + this.state.Accounts.Customer.City + ", " + this.state.Accounts.Customer.State + " " + this.state.Accounts.Customer.ZipCode]]}
                                            DescriptionKeys={["ItemCode", "ItemComment", "$", "ItemTotalAmt"]}
                                            HeaderDescritpion={["Code", "Comment", "", "Amount"]}
                                            StyleDetail={["CodeColumn", "MiddleColumn", "SignColumn", "PriceColumn"]}
                                            Detail={this.printDetail("Zebra")}
                                            quantSignDolar={this.realLongDetail()}
                                            keysDecimalFormat={["ItemTotalAmt"]}
                                            bottomInformation={[
                                                ["", "TOTAL", "$", NumberFormat(pricesFormat(Number(this.state.Accounts.FreightAmt) + Number(this.state.Accounts.TotalCost)))]]}
                                            bottomMessage={""}

                                        />
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <div className='col-sm-1'></div>
                </div>

            </div >
        )
    }
}