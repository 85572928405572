export class IncludeProducts{
    ItemCode
    ProductLineNo
    ProductLine
    Description
    UnitCost

    constructor(ItemCode,ProductLineNo,ProductLine,Description,UnitCost){
        this.ItemCode=ItemCode
        this.ProductLineNo=ProductLineNo
        this.ProductLine=ProductLine
        this.Description=Description
        this.UnitCost=UnitCost?(UnitCost/0.95):1
    }

}