import React, { Component } from 'react'
import Catalogue from '../components/Catalogue'
import "../css/general-style.css"
import '../css/table-responsive.css'
import "../css/pickingSystem-style.css"
import { AiOutlineCaretDown, AiTwotoneSave } from "react-icons/ai";//BsFileArrowUpFill
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import ModalOrders from '../components/ModalComponent';
import { getValueCookie } from '../services/cookieService';
import { create_Delete_Update_Information, getInformationWithData, getInformationWithDataGlobalSales } from '../services/CABE';
import { DateFormatMAS90, formatInputDateQuery, FormatQueryReturnDateForReport, getActualDateUTC } from '../functions/dateFormat';
import { getIndexElement } from '../functions/searchInObject'
import Swal from 'sweetalert2'
import { OrderPDF } from '../components/OrderPDF'
import { getPrintHeaderCompany } from '../functions/companyInformation'
import { pricesFormat } from '../functions/pricesFormat'
import SearchUPC from '../components/SearchUPC'
import { DecimalPart, decimalToHour, DeleteStrangeCharacter, MobileDisp, NumberFormat, OrderArray, ReplaceCharacter, validateNumbersForQuantitys } from '../functions/generalFunctions'
import { confirmCloseAlert } from '../functions/alerts'
import LoadingWindow from '../components/LoadingWindow'
import { DownloadOrderPDF } from '../components/DownloadOrderPDF'

export default class PickingSystem extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        Inventory: [],
        General: {
            ModeTitle: 'Sales Order',
            Mode: 'S/O',
            Colorclass: 'yellow',
            showModal1: false,
            showModal2: false,
            currentPallet: '01'
        },
        bandera: true,
        OrderHeaderList: [],
        OrderDetailPicked: [],
        OrderDetailPickedToPrint: [],
        OrderDetailtoPick: [],
        ActualHeaderOrder: {
            Status: '',
            OrderNo: '',
            OrderName: '',
            OrderName2: '',
            OrderNameNo: '',
            OrderNameAddress: '',
            OrderNameCity: '',
            Date: '',
            SalesPerson: '',
            ZipCode: '',
            State: '',
            Order: null,
            Packing: ''
        },
        CurrentItem: {
            ItemCode: '',
            Description: '',
            onHand: 0,
            QuantityOrdered: 0,
            QuantityShipRec: 0,
            BIN: '',
            Pallet: '',
            UnitPrice: '',
            ImgURL: '',
            RegisterType: '',
            HaveUPC: false,
            DueDate: null
        },

        Totals: {

            OrderDetailtoPick: {
                QuantityOrdered: 0,
                TotalWeight: 0
            },
            OrderDetailPicked: {
                QuantityOrdered: 0,
                QuantityShipRec: 0,
                TotalWeight: 0
            }
        },
        companyPrintHeader: getPrintHeaderCompany(),
        PercentageScanning: 0,
        PercentageManual: 0,
        TotalManual: 0,
        TotalScanning: 0,
        order: true
    }

    clearAll() {
        const temporal = this.state.General
        temporal.currentPallet = '01'
        this.setState({
            OrderHeaderList: [],
            OrderDetailPicked: [],
            OrderDetailPickedToPrint: [],
            OrderDetailtoPick: [],
            ActualHeaderOrder: {
                Status: '',
                OrderNo: '',
                OrderName: '',
                OrderName2: '',
                OrderNameNo: '',
                OrderNameAddress: '',
                OrderNameCity: '',
                Date: '',
                SalesPerson: '',
                ZipCode: '',
                State: '',
                Order: null,
                Packing: ''
            },
            CurrentItem: {
                ItemCode: '',
                Description: '',
                onHand: 0,
                QuantityOrdered: 0,
                QuantityShipRec: 0,
                BIN: '',
                Pallet: '',
                UnitPrice: '',
                ImgURL: '',
                RegisterType: '',
                HaveUPC: false,
                DueDate: null
            },

            Totals: {

                OrderDetailtoPick: {
                    QuantityOrdered: 0,
                    TotalWeight: 0
                },
                OrderDetailPicked: {
                    QuantityOrdered: 0,
                    QuantityShipRec: 0,
                    TotalWeight: 0
                }
            },
            General: temporal,
            PercentageManual: 0,
            PercentageScanning: 0,
            TotalManual: 0,
            TotalScanning: 0
        })
    }

    changeMode() {
        const temp = this.state.General
        if (temp.Mode === 'S/O') {
            temp.Colorclass = 'orange'
            temp.Mode = 'P/O'
            temp.ModeTitle = 'Purchase Order'
        } else {
            temp.Colorclass = 'yellow'
            temp.Mode = 'S/O'
            temp.ModeTitle = 'Sales Order'
        }
        this.setState({ General: temp })
        this.clearAll()
    }

    componentDidMount() {
        //this.setState({ companyPrintHeader: getPrintHeaderCompany() })
    }

    handleModalOpen = (modal) => {
        const temporal = this.state.General
        temporal[modal] = true
        this.setState({ General: temporal })
    }

    handleModalClose = (modal) => {
        const temporal = this.state.General
        temporal[modal] = false
        this.setState({ General: temporal })
        if (modal === "showModal2") {
            this.clearCurrentItem()
            const buscador = document.getElementById('PickingSystemSearchUPC')
            if (buscador) {
                setTimeout(() => { buscador.focus(); }, 300);
            }
        }
    }

    async updateInv() {
        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
        }
        const inventM = await getInformationWithData('/Items/get', data);
        this.setState({ Inventory: inventM.data })

    }

    async getActualOrders() {
        await this.handleModalOpen("showModal1")
        this.ModalLoading.current.showState(true);
        if (this.state.Inventory.length === 0) {
            await this.updateInv()
        }


        const data = {
            idcompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            SalespersonNo: getValueCookie('SalesPerson'),
            Module:'Picking'
        }
        const data2 = {
            idcompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            SalespersonNo: '0000'
        }
        var proccessInformation = []

        if (this.state.General.Mode === 'S/O') {
            const respuesta = await getInformationWithData('/sales/get/header', data);
            const completeList = await getInformationWithData('/sales/get/header/byDate', data2);


            if (respuesta.status.code === 1) {

                proccessInformation = respuesta.data.map((element) => {
                    const pos = getIndexElement(completeList.data, 'SalesOrderNo', element.SalesOrderNo)

                    return {
                        Status: element.Status ? element.Status : 0,
                        OrderNo: element.Comment ? element.SalesOrderNo + '-' + element.Comment : element.SalesOrderNo,
                        OrderName: element.BillToName,
                        Date: element.DateUpdated,
                        DateTime: decimalToHour(element.TimeCreated),
                        SalesPerson: element.SalespersonNo,
                        OrderNameAddress: element.BillToAddress1,
                        OrderNameCity: element.BillToCity,
                        OrderNameNo: element.CustomerNo,
                        PriceLevel: element.PriceLevel,
                        OrderType: element.OrderType,
                        Packing: element.Packing,
                        PosQue: pos !== -1 ? (completeList.data[pos].posicion ? (completeList.data[pos].posicion) : 999) : 999
                    }


                })
            }

            this.setState({ OrderHeaderList: proccessInformation })

        } else if (this.state.General.Mode === 'P/O') {
            const result = await getInformationWithData('/picklist/get/purchaseOrderHeader', data)

            if (result.status.code === 1) {


                proccessInformation = result.data.map((element) => {
                    return {
                        Status: element.Status,
                        OrderNo: element.Comment !== '' && this.state.General.Mode === 'P/O' ? element.OrderNo + '-' + element.Comment : element.OrderNo,
                        OrderName: element.OrderName,
                        Date: element.Date,
                        SalesPerson: element.SalesPerson,
                        OrderNameAddress: element.Address,
                        OrderNameCity: element.City,
                        OrderNameNo: element.OrderNameNo,
                        completionDate:element.completionDate
                    }
                }

                )
                this.setState({ OrderHeaderList: proccessInformation })
            }
        }
        this.ModalLoading.current.showState(false);

    }

    QueueSalesOrder(allInfo) {

    }


    async SelectOrdertoPickUp(order) {
        this.ModalLoading.current.showState(true);
        let OrderNo = order.OrderNo.split('-')
        const data = {
            SalesOrderNo: OrderNo[0],
            idCompany: Number(getValueCookie('CompanyId')),
            CustomerNo: order.OrderNameNo,
            username: getValueCookie('userName'),
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),

        }




        if (this.state.General.Mode === 'S/O') {
            const customerInfo = await getInformationWithData('/customer/getByCustomerNo', data)
            const temporal = this.state.ActualHeaderOrder
            temporal.Status = order.Status
            temporal.OrderNo = order.OrderNo
            temporal.OrderName = order.OrderName + " - Level " + order.PriceLevel
            temporal.OrderName2 = order.OrderName
            temporal.OrderNameNo = order.OrderNameNo
            temporal.OrderNameCity = order.OrderNameCity
            temporal.Date = order.Date
            temporal.SalesPerson = order.SalesPerson
            temporal.OrderNameAddress = order.OrderNameAddress
            temporal.Order = order
            temporal.Packing = order.Packing
            if (customerInfo.status.code === 1) {
                temporal.ZipCode = customerInfo.data[0].ZipCode
                temporal.State = await this.getCustomerInfo('State', order.OrderNameNo)
            }





            await this.setState({ ActualHeaderOrder: temporal })


            order.OrderNo = OrderNo[0];
            order.idCompany = Number(getValueCookie('CompanyId'));
            order.Mode = false
            if (!(order.Status)) {
                
                order.Status = 0
            }
            let result=null
            if(getValueCookie('CompanyId')!=='6'){
                 result = await getInformationWithData('/sales/get/detail', order)

            }else{
                result = await getInformationWithData('/sales/get/detail/pickingAltitude', order)
            }
           
            if (result.status.code === 1) {

                await result.data.Tabla1.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = (!(this.state.Inventory[index].completeDesc) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }
                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                });

                await result.data.Tabla2.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = ((!(this.state.Inventory[index].completeDesc)) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }

                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                });
                
                this.setState({ OrderDetailtoPick: result.data.Tabla1, OrderDetailPicked: result.data.Tabla2 })
                this.calculateTotals()
            } else {
                this.setState({ OrderDetailtoPick: [], OrderDetailPicked: [] })
            }



        } else if (this.state.General.Mode === 'P/O') {
            const result2 = await getInformationWithData('/picklist/get/purchaseOrderDetail', data)
            //const result3 = await getInformationWithData('/picklist/get/purchaseOrderDetail', data)

            if (result2.status.code === 1) {

                const temporal = this.state.ActualHeaderOrder
                temporal.Status = order.Status
                temporal.OrderNo = order.OrderNo
                temporal.OrderName = order.OrderName
                temporal.OrderName2 = order.OrderName
                temporal.OrderNameNo = order.OrderNameNo
                temporal.OrderNameCity = order.OrderNameCity
                temporal.Date = order.Date
                temporal.SalesPerson = order.SalesPerson
                temporal.OrderNameAddress = order.OrderNameAddress
                temporal.Order = order
                temporal.Packing = ''
                this.setState({ ActualHeaderOrder: temporal })
                await result2.data.Tabla1.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = (!(this.state.Inventory[index].completeDesc) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }
                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                });

                await result2.data.Tabla2.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = ((!(this.state.Inventory[index].completeDesc)) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }

                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                    element.receivedDate = FormatQueryReturnDateForReport(element.receivedDate)
                });

                this.setState({ OrderDetailtoPick: result2.data.Tabla1, OrderDetailPicked: result2.data.Tabla2 })
                this.calculateTotals()




            }
        }

        




        this.calculatePercentageScanning()
        this.handleModalClose("showModal1")
        this.ModalLoading.current.showState(false);
    }


    async UpdateOrderDetail(order,customerNo) {
        this.ModalLoading.current.showState(true);
        let OrderNo = order
        const data = {
            SalesOrderNo: OrderNo,
            idCompany: Number(getValueCookie('CompanyId')),
            CustomerNo: customerNo,
            username: getValueCookie('userName'),
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),

        }




        if (this.state.General.Mode === 'S/O') {
            const customerInfo = await getInformationWithData('/customer/getByCustomerNo', data)
            const temporal = this.state.ActualHeaderOrder
            temporal.Status = order.Status
            temporal.OrderNo = order.OrderNo
            temporal.OrderName = order.OrderName + " - Level " + order.PriceLevel
            temporal.OrderName2 = order.OrderName
            temporal.OrderNameNo = order.OrderNameNo
            temporal.OrderNameCity = order.OrderNameCity
            temporal.Date = order.Date
            temporal.SalesPerson = order.SalesPerson
            temporal.OrderNameAddress = order.OrderNameAddress
            temporal.Order = order
            temporal.Packing = order.Packing
            if (customerInfo.status.code === 1) {
                temporal.ZipCode = customerInfo.data[0].ZipCode
                temporal.State = await this.getCustomerInfo('State', order.OrderNameNo)
            }





            await this.setState({ ActualHeaderOrder: temporal })


            order.OrderNo = OrderNo[0];
            order.idCompany = Number(getValueCookie('CompanyId'));
            order.Mode = false
            if (!(order.Status)) {
                order.Status = 0
            }
            const result = await getInformationWithData('/sales/get/detail', order)

            if (result.status.code === 1) {

                await result.data.Tabla1.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = (!(this.state.Inventory[index].completeDesc) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }
                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                });

                await result.data.Tabla2.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = ((!(this.state.Inventory[index].completeDesc)) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }

                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                });

                this.setState({ OrderDetailtoPick: result.data.Tabla1, OrderDetailPicked: result.data.Tabla2 })
                this.calculateTotals()
            } else {
                this.setState({ OrderDetailtoPick: [], OrderDetailPicked: [] })
            }



        } else if (this.state.General.Mode === 'P/O') {
            const result2 = await getInformationWithData('/picklist/get/purchaseOrderDetail', data)
            //const result3 = await getInformationWithData('/picklist/get/purchaseOrderDetail', data)

            if (result2.status.code === 1) {

                const temporal = this.state.ActualHeaderOrder
                temporal.Status = order.Status
                temporal.OrderNo = order.OrderNo
                temporal.OrderName = order.OrderName
                temporal.OrderName2 = order.OrderName
                temporal.OrderNameNo = order.OrderNameNo
                temporal.OrderNameCity = order.OrderNameCity
                temporal.Date = order.Date
                temporal.SalesPerson = order.SalesPerson
                temporal.OrderNameAddress = order.OrderNameAddress
                temporal.Order = order
                temporal.Packing = ''
                this.setState({ ActualHeaderOrder: temporal })
                await result2.data.Tabla1.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = (!(this.state.Inventory[index].completeDesc) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }
                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                });

                await result2.data.Tabla2.forEach(element => {
                    const index = getIndexElement(this.state.Inventory, 'itemCode', element.ItemCode)
                    let desc = ''
                    if (index !== -1) {
                        desc = ((!(this.state.Inventory[index].completeDesc)) || this.state.Inventory[index].completeDesc === "null") ? this.state.Inventory[index].abbreviatedDesc : this.state.Inventory[index].completeDesc
                    }

                    if (desc !== '') {
                        element.ItemCodeDesc = desc
                    }
                    element.receivedDate = FormatQueryReturnDateForReport(element.receivedDate)
                });

                this.setState({ OrderDetailtoPick: result2.data.Tabla1, OrderDetailPicked: result2.data.Tabla2 })
                this.calculateTotals()




            }
        }

        




        this.calculatePercentageScanning()
        this.handleModalClose("showModal1")
        this.ModalLoading.current.showState(false);
    }

    async PickItem(Item, add) {
        
        if (Number(this.state.ActualHeaderOrder.Status) !== 2||this.state.ActualHeaderOrder.SalesPerson!=='WW') {

            this.handleModalOpen("showModal2")
            const data = {
                ItemCode: Item.ItemCode,
                BIN: Item.BIN,
                company: getValueCookie('Company'),
                idCompany: Number(getValueCookie('CompanyId')),
            }
            const data2 = {
                ItemCode: Item.ItemCode,
                BIN: Item.BIN,
                company: getValueCookie('Company'),
                idCompany: (getValueCookie('CompanyId')),
            }

            const resultquant = await getInformationWithData('/invertory/getQuantity/post', data2)
            const resultImg = await getInformationWithData('/Items/get/Img', data)
            console.log(resultquant,resultImg)
            if (resultquant.status.code === 1 && resultImg.status.code === 1) {
                var URL = ''
                if (resultImg.status.code === 1) {
                    if (resultImg.data.itemURLImage && resultImg.data.itemURLImage !== '') {
                        const date = new Date().getTime()
                        URL = `${resultImg.data.itemURLImage}?${date}`
                    } else {
                        URL = null
                    }
                }
                let onHand = 0
                if (resultquant.data.length > 0) {
                    onHand = resultquant.data[0].Quantity.toFixed(0)
                }

                const ItemSelected = {
                    ItemCode: Item.ItemCode,
                    Description: Item.ItemCodeDesc,
                    onHand: onHand,
                    QuantityOrdered: Item.QuantityOrdered,
                    QuantityShipRec: add ? 1 : 0,
                    BIN: Item.BIN,
                    Pallet: '01',
                    UnitPrice: Item.UnitPrice,
                    ImgURL: URL,
                    RegisterType: Item.RegisterType ? Item.RegisterType : 'm',
                    HaveUPC: ((resultImg.data.upc) || (resultImg.data.upc2) || (resultImg.data.caseupc)) ? true : false,
                    DueDate: null
                }

                let input = document.getElementById('quantityShipRecPickingSystem')
                if (input) {
                    input.value = ItemSelected.QuantityShipRec > 0 ? ItemSelected.QuantityShipRec : 0
                }



                this.setState({ CurrentItem: ItemSelected })

            }
        }else{
            confirmCloseAlert('incorrect','This order are finished, please contact with the Warehouse Admin!')
        }


    }
    clearCurrentItem() {
        const ItemSelected = {
            ItemCode: '',
            Description: '',
            onHand: 0,
            QuantityOrdered: 0,
            QuantityShipRec: 0,
            BIN: '',
            Pallet: '01',
            UnitPrice: '',
            ImgURL: '',
            RegisterType: '',
            DueDate: null
        }
        this.setState({ CurrentItem: ItemSelected })
    }
    calculatePercentageScanning() {
        let total = 0

        let manual = 0
        let scanner = 0
        for (const item of this.state.OrderDetailPicked) {

            if (item.QuantityShipped > 0) {
                total += 1
                if (item.RegisterType === 's') {
                    scanner += 1
                } else {
                    manual += 1
                }
            }

        }
        if (total > 0) {
            let Pmanual = 100 * (manual / total)
            let Pscanning = 100 * (scanner / total)
            this.setState({ TotalManual: manual, TotalScanning: scanner, PercentageManual: Pmanual, PercentageScanning: Pscanning })
        } else {
            this.setState({ TotalManual: 0, TotalScanning: 0, PercentageManual: 0, PercentageScanning: 0 })
        }
    }

    async confirmPickItem() {
        let orderno = this.state.ActualHeaderOrder.OrderNo.split('-')
        const temporal = this.state.CurrentItem
        const index = getIndexElement(this.state.OrderDetailtoPick, "ItemCode", temporal.ItemCode)
        if (index !== -1) {
            const itemToPick = this.state.OrderDetailtoPick[index]

            itemToPick.QuantityShipped = Number(document.getElementById('quantityShipRecPickingSystem').value)
            itemToPick.Pallet = this.state.General.currentPallet
            itemToPick.check = 1
            itemToPick.UserName = getValueCookie('userName')
            itemToPick.receivedDate = FormatQueryReturnDateForReport(getActualDateUTC())
            itemToPick.TotalLineWeight = Number(document.getElementById('quantityShipRecPickingSystem').value) * Number(itemToPick.LineWeight)
            itemToPick.HaveUPC = temporal.HaveUPC
            itemToPick.RegisterType = temporal.RegisterType
            const Table1 = this.state.OrderDetailtoPick
            const Table2 = this.state.OrderDetailPicked


            const data = {
                OrderNo: orderno[0],
                IdCompany:Number(getValueCookie('CompanyId')),
                ItemCode: itemToPick.ItemCode,
                QuantityRecived: Number(document.getElementById('quantityShipRecPickingSystem').value),
                QuantityShipped: Number(document.getElementById('quantityShipRecPickingSystem').value),
                Pallet: document.getElementById('palletPickingSystem').value,
                User: getValueCookie('userName'),
                DispatchDate: getActualDateUTC(),
                check: 1,
                Mode: this.state.General.Mode,
                QuantityOrdered: temporal.QuantityOrdered,
                UnitPrice: temporal.UnitPrice,
                UnitCost: itemToPick.LastTotalUnitCost,
                ItemCodeDesc: DeleteStrangeCharacter(itemToPick.ItemCodeDesc),
                LineWeight: itemToPick.LineWeight,
                RegisterType: temporal.RegisterType,
                HaveUPC: temporal.HaveUPC,
                DueDate: temporal.DueDate?this.formatDate(temporal.DueDate):temporal.DueDate
            }
            if (this.state.General.Mode === 'S/O') {
                if (data.QuantityShipped > 0 && !data.HaveUPC) {
                    data.RegisterType = 's'
                    itemToPick.RegisterType = 's'
                }
            } else {
                if (data.QuantityRecived > 0 && !data.HaveUPC) {
                    data.RegisterType = 's'
                    itemToPick.RegisterType = 's'
                }
            }

           
            if ((this.state.CurrentItem.DueDate||data.QuantityRecived===0) || this.state.General.Mode === 'S/O') {
                const result = await create_Delete_Update_Information('/picklist/set/dispatchItem', data)

                if (result.status.code !== 1) {
                    await this.handleModalClose("showModal2")
                    await confirmCloseAlert('incorrect', 'Whoops we have an error. Please try again!')

                    await this.SelectOrdertoPickUp(this.state.ActualHeaderOrder.Order)

                } else {
                    Table1.splice(index, 1)
                    Table2.push(itemToPick)
                    this.setState({ OrderDetailPicked: Table2, OrderDetailtoPick: Table1 })
                    await this.handleModalClose("showModal2")

                }
            } else {
                confirmCloseAlert('incorrect','You need put the Fecha de Vencimiento!')
            }



        } else {

            const newItem = {
                BIN: this.state.CurrentItem.BIN,
                CASEUPC: 0,
                CommetText: '',
                ItemCode: this.state.CurrentItem.ItemCode,
                ItemCodeDesc: this.state.CurrentItem.Description,
                LineSeqNo: 0,
                LineWeight: '',
                OrderNo: orderno[0],
                Pallet: document.getElementById('palletPickingSystem').value,
                QuantityOrdered: 0,
                QuantityShipped: Number(document.getElementById('quantityShipRecPickingSystem').value),
                UPC: '',
                caseupc: 0,
                check: 1,
                RegisterType: this.state.CurrentItem.RegisterType,
                HaveUPC: this.state.CurrentItem.HaveUPC,
                DueDate: this.state.CurrentItem.DueDate
            }
            if (this.state.General.Mode === 'S/O') {
                if (newItem.QuantityShipped > 0 && !newItem.HaveUPC) {
                    newItem.RegisterType = 's'
                }
            } else {
                if (newItem.QuantityRecived > 0 && !newItem.HaveUPC) {
                    newItem.RegisterType = 's'
                }
            }

            const Table2 = this.state.OrderDetailPicked
            Table2.push(newItem)
            this.setState({ OrderDetailPicked: Table2 })
            this.handleModalClose("showModal2")
        }


        this.calculateTotals()
        this.calculatePercentageScanning()
        const buscador = document.getElementById('PickingSystemSearchUPC')
        if (buscador) {
            setTimeout(() => { buscador.focus(); }, 300);
        }

    }

    async RemoveProductPickedList(temporal) {

        if (Number(this.state.ActualHeaderOrder.Status) !== 2||this.state.ActualHeaderOrder.SalesPerson!=='WW') {
            Swal.fire({
                title: 'Are you sure?',
                text: "You are removing a product to the Picked Up List",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: 'Yes, remove',
                cancelButtonText: 'No, cancel!',
            }).then(async (result) => {
                if (result.isConfirmed) {


                    const index = getIndexElement(this.state.OrderDetailPicked, "ItemCode", temporal.ItemCode)
                    if (index !== -1) {

                        const itemToPick = this.state.OrderDetailPicked[index]

                        itemToPick.QuantityShipped = 0
                        itemToPick.Pallet = '01'
                        itemToPick.check = 0
                        itemToPick.HaveUPC = false
                        itemToPick.RegisterType = ''
                        const Table1 = this.state.OrderDetailtoPick
                        const Table2 = this.state.OrderDetailPicked
                        Table2.splice(index, 1)
                        Table1.push(itemToPick)
                        this.setState({ OrderDetailPicked: Table2, OrderDetailtoPick: Table1 })
                        if (this.state.General.Mode === 'S/O') {
                            const data = {
                                OrderNo: temporal.SalesOrderNo,
                                ItemCode: itemToPick.ItemCode,
                                QuantityRecived: 0,
                                Pallet: '',
                                User: '',
                                DispatchDate: new Date(),
                                check: 0,
                                Mode: this.state.General.Mode,
                            }
                            await create_Delete_Update_Information('/picklist/set/dispatchItem', data)
                        } else {
                            let orderno = this.state.ActualHeaderOrder.OrderNo.split('-')
                            const data = {
                                OrderNo: temporal.OrderNo ? temporal.OrderNo : orderno[0],
                                ItemCode: itemToPick.ItemCode,
                                QuantityRecived: 0,
                                Pallet: '01',
                                User: '',
                                DispatchDate: '000-00-00 00:00:00',
                                check: 0,
                                Mode: this.state.General.Mode,
                            }

                            await create_Delete_Update_Information('/picklist/set/dispatchItem', data)
                        }




                    }
                    this.calculateTotals()
                }
            })
        }else{
            confirmCloseAlert('incorrect','This order are finished, please contact with the Warehouse Admin!')
        }

    }
    print() {

    }

    clearTotals() {
        const temporal = this.state.Totals
        temporal.OrderDetailtoPick.QuantityOrdered = 0
        temporal.OrderDetailtoPick.TotalWeight = 0

        temporal.OrderDetailPicked.QuantityOrdered = 0
        temporal.OrderDetailPicked.QuantityShipRec = 0
        temporal.OrderDetailPicked.TotalWeight = 0

        this.setState({ Totals: temporal })
    }

    calculateTotals() {
        const temporal = this.state.Totals
        this.clearTotals()
        for (const item of this.state.OrderDetailtoPick) {

            temporal.OrderDetailtoPick.QuantityOrdered += Number(item.QuantityOrdered)
            temporal.OrderDetailtoPick.TotalWeight += Number(item.QuantityOrdered) * Number(item.LineWeight)


        }
        for (const item of this.state.OrderDetailPicked) {
            if (item.Pallet !== -1) {
                if (item.LineSeqNo || this.state.General.Mode === 'P/O') {
                    temporal.OrderDetailPicked.QuantityOrdered += Number(item.QuantityOrdered)
                    temporal.OrderDetailPicked.QuantityShipRec += Number(item.QuantityShipped)
                    temporal.OrderDetailPicked.TotalWeight += Number(item.QuantityShipped) * Number(item.LineWeight)
                }
            }
        }
        this.setState({ Totals: temporal })
    }

    confirmCompletePick() {
        Swal.fire({
            title: 'Are you sure to complete this order?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I\'m sure!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let orderno = this.state.ActualHeaderOrder.OrderNo.split('-')
                if(this.state.General.Mode==='P/O'){
                    await this.UpdateOrderDetail(orderno, this.state.ActualHeaderOrder.OrderNameNo)
                }

                this.calculatePercentageScanning()
                
                const data = {
                    OrderNo: orderno[0],
                    Status: '2',
                    idCompany: getValueCookie('CompanyId'),
                    MODE: this.state.General.Mode
                }
                const resultSql = await create_Delete_Update_Information('/picklist/set/PickListState', data)
                await getInformationWithDataGlobalSales('/Sales/setStatus',{SalesOrderNo: data.OrderNo,OrderStatus: 6})
                if (resultSql.code === 1) {
                    const temporal = this.state.ActualHeaderOrder
                    temporal.Status = '2'
                    this.setState({ ActualHeaderOrder: temporal })
                    Swal.fire({
                        title: 'Order Completed!',
                        html: `
                        <div className='tableFixHead'>
                        <table className='table align-middle'>
                        <thead className='thead'>
                            <tr className='text-light text-center'>
                                <th className='bg-dark'>Type</th>
                                <th className='bg-dark'>Quantity</th>
                                <th className='bg-dark'>Percentage</th>
                            </tr>
                        </thead>
                        <tbody className='tbody'>
                            <tr>
                            <td>Scanned Products</td>
                            <td>${this.state.TotalScanning}</td>
                            <td className='text-end'>${pricesFormat(this.state.PercentageScanning)} %</td>
                            </tr>
                            <tr>
                            <td>Manual Products</td>
                            <td>${this.state.TotalManual}</td>
                            <td className='text-end'>${pricesFormat(this.state.PercentageManual)}%</td>
                            </tr>
                        </tbody>
                        <tfoot className='tfoot'>
                        <tr className='bg-secondary text-light'>
                        <td>Total</td>
                        <td>${this.state.TotalManual + this.state.TotalScanning}</td>
                        <td>${100} %</td>
                        </tr>
                        </tfoot>
                    </table>
                            </div>
                            <div className=''>
                        <p>The order was registered.\nWould you like print it?</p>
                        </div>
                            `,
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, please!',
                        cancelButtonText: 'No thanks'
                    }
                    ).then((result) => {
                        if (result.isConfirmed) {
                            var button = document.getElementById('PrintPickOrder')
                            if (button) {
                                button.click()
                            }
                        } else {
                            this.clearAll()
                        }
                    })
                }


            }
        })
    }

    stateInWords(state) {

        if (this.state.General.Mode === 'P/O') {
            if (state === '1') {
                return 'Pending Order'
            } else if (state === '2') {
                return 'Order Completed'
            } else {
                return ''
            }
        } else {
            if (state === 1) {
                return 'In process'
            } else if (state === 2) {
                return 'Finished'
            } else {
                return 'Not started'
            }
        }



    }
    stateClassColor(state) {

        if (this.state.General.Mode === 'P/O') {
            if (state === '1') {
                return 'text-warning'
            } else if (state === '2') {
                return 'text-success'
            } else {
                return ''
            }
        } else {
            if (state === 1) {
                return 'text-warning'
            } else if (state === 2) {
                return 'text-success'
            } else {
                return 'text-danger'
            }
        }
    }
    formatDatePickedUp() {
        let temporal = []
        let copy = JSON.parse(JSON.stringify(this.state.OrderDetailPicked))
        copy.forEach(element => {
            element.receivedDate2 = element.receivedDate
            element.QuantityOrdered = NumberFormat(pricesFormat(element.QuantityOrdered))
            element.QuantityShipped = NumberFormat(pricesFormat(element.QuantityShipped))
            element.LineWeight = NumberFormat(pricesFormat(Number(element.LineWeight)))
            element.TotalLineWeight = Number(element.TotalLineWeight)
            if (element.Pallet !== -1) {
                if (element.LineSeqNo) {
                    temporal.push(element)
                }

            }

        });
        return temporal
    }

    ordenar(arreglo, llave, bandera) {

        const l = arreglo.length;
        if (bandera) {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if (arreglo[j][llave] > arreglo[j + 1][llave]) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        } else {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if (arreglo[j][llave] < arreglo[j + 1][llave]) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        }
        return arreglo;
    }


    arreglarLista(llave) {
        let pick = this.state.OrderDetailtoPick
        pick = this.ordenar(pick, llave, this.state.bandera);
        this.setState({ bandera: !this.state.bandera });
        this.setState({ OrderDetailtoPick: pick });
    }

    arreglarLista2(llave) {
        let pick = this.state.OrderDetailPicked
        pick = this.ordenar(pick, llave, this.state.bandera);
        this.setState({ bandera: !this.state.bandera });
        this.setState({ OrderDetailPicked: pick });
    }

    onTarget = e => {
        const id = e.target.id
        if (id === 'palletPickingSystem') {
            const temporal = this.state.General
            temporal.currentPallet = e.target.value
            this.setState({ General: temporal })
        }
    }

    async getCustomerInfo(key, customerNo) {
        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            CustomerNo: customerNo,
        }
        const result = await getInformationWithData('/customer/getByCustomerNo', data);
        if (result.status.code === 1) {
            if (result.data.length > 0) {
                return result.data[0][key]
            }
        }
        return ''
    }
    GeneralOrder(key, asc, name) {
        const temporal = this.state[name]
        const Order = OrderArray(temporal, key, asc)

        this.setState({ [name]: Order, order: !asc })
    }
    changeInputShipped = (e) => {
        let quant = Number(e.target.value)

        if (!validateNumbersForQuantitys(quant)) {
            let parteDecimal = 1 - DecimalPart(quant)
            quant += parteDecimal
            document.getElementById(e.target.id).value = quant
        }
    }
    changeDueDate = (e) => {
        const temporal = this.state.CurrentItem
        let date = new Date(e.target.value)
        temporal.DueDate = new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()))
    }
    formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Picking System</p>
                <div className='row pb-4'>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-10'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="input-group input-group-lg flex-nowrap">
                                    <input type="text" autoComplete='off' className="form-control inputResize" disabled value={this.state.ActualHeaderOrder.OrderNo} id='OrderNoPickingSystem' />
                                    <span className="input-group-text"><button type="button" className="btn greenButton btn-lg" onClick={() => this.getActualOrders()}><AiOutlineCaretDown /></button></span>
                                    <span className="input-group-text"><button type="button" className={"btn " + this.state.General.Colorclass + "Button btn-lg"} onClick={() => this.changeMode()}>{this.state.General.Mode + " Mode"}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-1'></div>
                </div>

                <div className='row pb-4'>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-10'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-12 InputText'>
                                        <p>Order Name:</p>
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <div className="input-group input-group-lg">
                                            <input disabled type="text" autoComplete='off' value={this.state.ActualHeaderOrder.OrderName} className="form-control" id='orderNamePickingSystem' />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-12 InputText'>
                                        <p>Order by:</p>
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <div className="input-group input-group-lg">
                                            <input disabled type="text" autoComplete='off' value={this.state.ActualHeaderOrder.SalesPerson} className="form-control" id='salesPersonPickingSystem' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-12 InputText'>
                                        <p>Comment:</p>
                                    </div>
                                    <div className='col-12 pb-1'>
                                        <div className="input-group input-group-lg pb-4 textAreaGeneral">
                                            <textarea disabled={this.state.ActualHeaderOrder.OrderNo === '' || this.state.ActualHeaderOrder.Status === '2'} className="form-control" id="exampleFormControlTextarea1" placeholder='Insert comment...' rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12' hidden={this.state.General.Mode === 'P/O'}>
                                <div className='row'>
                                    <div className='col-12 InputText'>
                                        <p>Type of Packing:</p>
                                    </div>
                                    <div className='col-12 pb-1'>
                                        <div className='input-group input-group-lg'>
                                            <input disabled type="text" autoComplete='off' value={ReplaceCharacter(this.state.ActualHeaderOrder.Packing?this.state.ActualHeaderOrder.Packing:'',';',' ')} className="form-control" id='typeOfPackingPickingSystem' />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>
                </div>

                <div className='row pb-2'>
                    <div className='col-sm-12'>
                        <p className={'p-2 fs-3 text-center ' + this.state.General.Colorclass + 'Background'}>{"Picking List " + this.state.General.ModeTitle}</p>
                    </div>
                </div>

                <div className='row pb-3'>
                    <SearchUPC id={"PickingSystemSearchUPC"} ComponentName={'PickingSystem'} Padre={this} />
                    <div className='col-sm-12'>
                        <div hidden={this.state.General.Mode === 'S/O'}>
                            <Catalogue Array={'OrderDetailtoPick'} disabled={this.state.ActualHeaderOrder.Status === '2'} nombrePadre={"PickingSystem"} Padre={this} />
                        </div>
                    </div>
                </div>

                <div className='row pb-1'>
                    <div className='col-sm-12'>
                        <div className='col-sm-12 tableFixHead tb-1'>
                            <table className='table align-middle'>
                                <thead>
                                    <tr className='text-light text-center'>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista('LineSeqNo')} hidden>Line Key <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista('ItemCode')}>Item Code <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary'>Description</th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista('QuantityOrdered')}>Quantity Ordered <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista('LineWeight')}>Weight <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista('TotalWeight')}>Total Weight <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th hidden className='bg-secondary'>BIN</th>
                                        <th className='bg-secondary'>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.OrderDetailtoPick.map((item, i) => (
                                        <tr onClick={() => this.PickItem(item, false)} key={i}>
                                            <td hidden>{item.LineSeqNo}</td>
                                            <td>{item.ItemCode}</td>
                                            <td>{item.ItemCodeDesc}</td>
                                            <td className='text-center'>{item.QuantityOrdered}</td>
                                            <td className='text-center'>{item.LineWeight}</td>
                                            <td className='text-center'>{item.TotalWeight ? item.TotalWeight : Number(item.LineWeight) * Number(item.QuantityOrdered)}</td>
                                            <td hidden className='text-center'>{item.BIN}</td>
                                            <td className='text-start'>{item.CommentText}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot className='tfoot'>

                                    <tr className='bg-dark text-light text-center'>
                                        <td></td>
                                        <td>Totals</td>
                                        <td>{this.state.Totals.OrderDetailtoPick.QuantityOrdered}</td>
                                        <td></td>
                                        <td>{this.state.Totals.OrderDetailtoPick.TotalWeight.toFixed(2)}</td>
                                        <td hidden></td>
                                        <td></td>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <p className='bg-primary text-white p-2 fs-3 text-center'>Picked Up List</p>
                    </div>
                    <div className='col-sm-12'>
                        <div className='col-12 tableFixHead tb-1'>
                            <table className='table'>
                                <thead>
                                    <tr className='text-light text-center'>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista2('LineSeqNo')}>LineSeqNo <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista2('ItemCode')}>Item Code <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary'>Description</th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista2('QuantityOrdered')}>Quantity Ordered <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista2('QuantityShipped')}>{this.state.General.Mode === 'S/O' ? <>Quantity Shipped <BsFileArrowUpFill /><BsFileArrowDownFill /></> : 'Quantity Received'}</th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista2('LineWeight')}>Weight  <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary' onClick={() => this.arreglarLista2('TotalLineWeight')}>Total Weight <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        <th className='bg-secondary'>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.OrderDetailPicked.map((item, i) => (
                                        <tr hidden={this.state.General.Mode === 'S/O' && (item.Pallet === -1 || (!item.LineSeqNo))} onClick={() => this.RemoveProductPickedList(item)} key={i}>
                                            <td className='text-start'  >{item.LineSeqNo}</td>
                                            <td className='text-start'>{item.ItemCode}</td>
                                            <td className='text-start'>{item.ItemCodeDesc}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(item.QuantityOrdered))}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(item.QuantityShipped))}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(item.LineWeight))}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(item.TotalLineWeight))}</td>
                                            <td className='text-start'>{item.CommentText}</td>
                                        </tr>
                                    ))}

                                </tbody>
                                <tfoot className='tfoot'>

                                    <tr className='bg-dark text-light text-center'>
                                        <td></td>
                                        <td></td>
                                        <td className=''>Totals</td>
                                        <td className=''>{NumberFormat(this.state.Totals.OrderDetailPicked.QuantityOrdered)}</td>
                                        <td className=''>{NumberFormat(this.state.Totals.OrderDetailPicked.QuantityShipRec)}</td>
                                        <td></td>
                                        <td className=''>{NumberFormat(pricesFormat(this.state.Totals.OrderDetailPicked.TotalWeight))}</td>

                                        <td></td>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-sm-6 pb-2'>
                                <button type="button" disabled={this.state.ActualHeaderOrder.Status === 2 || this.state.ActualHeaderOrder.Status === '2' || this.state.ActualHeaderOrder.Status === ''} onClick={() => this.confirmCompletePick()} className="btn greenButton btn-lg w-100"><AiTwotoneSave /> Finish Order</button>
                            </div>
                            <div className='col-sm-6 pb-2'>
                                {MobileDisp() ?
                                    <OrderPDF
                                        id={"PrintPickOrder"} disabled={this.state.ActualHeaderOrder.Status === ''} colorButton="orangeButton" title="Print Order"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Picked Up List"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.ActualHeaderOrder.OrderNo, "Order by: ", this.state.ActualHeaderOrder.SalesPerson, "No Vendor: ", this.state.ActualHeaderOrder.OrderNameNo, "Printed by: ", getValueCookie('userName'), "Vendor: ", this.state.ActualHeaderOrder.OrderName2, "Printed Date: ", FormatQueryReturnDateForReport(getActualDateUTC()), "Address: ", (this.state.ActualHeaderOrder.OrderNameAddress + " " + this.state.ActualHeaderOrder.OrderNameCity), "Date: ", (formatInputDateQuery(this.state.ActualHeaderOrder.Date) !== "" ? formatInputDateQuery(this.state.ActualHeaderOrder.Date) : DateFormatMAS90(this.state.ActualHeaderOrder.Date)), this.state.ActualHeaderOrder.ZipCode !== '' ? "Zip Code:" : "", this.state.ActualHeaderOrder.ZipCode, this.state.ActualHeaderOrder.State !== '' ? "State:" : "", this.state.ActualHeaderOrder.State !== '' ? this.state.ActualHeaderOrder.State : ""]}
                                        headerTable={["\n Item Code", "\n Description", "\nPallet", "\n Quantity \n Ordered", this.state.General.Mode === 'S/O' ? "\n Quantity \n Shipped" : "\n Quantity \n Received", "\n Total\n Weight", "\n Time\n Stamp", "User\nName\n"]}
                                        bodyTable={this.formatDatePickedUp()}
                                        headerBodyTable={["ItemCode", "ItemCodeDesc", "Pallet", "QuantityOrdered", "QuantityShipped", "TotalLineWeight", "receivedDate2", "UserName"]}
                                        bottomInfo={["", "TOTALS:", "", NumberFormat(this.state.Totals.OrderDetailPicked.QuantityOrdered), NumberFormat(this.state.Totals.OrderDetailPicked.QuantityShipRec), NumberFormat(pricesFormat(this.state.Totals.OrderDetailPicked.TotalWeight)), "", ""]}
                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo2", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaTextoNormal", "ColumnaCantidades"]}
                                        hidden={false}
                                        comment={''}
                                        breakpoint={30}
                                        keysDecimalFormat={["TotalLineWeight"]}
                                        titleButton='Print Order'
                                    />
                                    :
                                    <DownloadOrderPDF
                                        id={"PrintPickOrder"} disabled={this.state.ActualHeaderOrder.Status === ''} colorButton="orangeButton" title="Print Order"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Picked Up List"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.ActualHeaderOrder.OrderNo, "Order by: ", this.state.ActualHeaderOrder.SalesPerson, "No Vendor: ", this.state.ActualHeaderOrder.OrderNameNo, "Printed by: ", getValueCookie('userName'), "Vendor: ", this.state.ActualHeaderOrder.OrderName2, "Printed Date: ", FormatQueryReturnDateForReport(getActualDateUTC()), "Address: ", (this.state.ActualHeaderOrder.OrderNameAddress + " " + this.state.ActualHeaderOrder.OrderNameCity), "Date: ", (formatInputDateQuery(this.state.ActualHeaderOrder.Date) !== "" ? formatInputDateQuery(this.state.ActualHeaderOrder.Date) : DateFormatMAS90(this.state.ActualHeaderOrder.Date)), this.state.ActualHeaderOrder.ZipCode !== '' ? "Zip Code:" : "", this.state.ActualHeaderOrder.ZipCode, this.state.ActualHeaderOrder.State !== '' ? "State:" : "", this.state.ActualHeaderOrder.State !== '' ? this.state.ActualHeaderOrder.State : ""]}
                                        headerTable={["\n Item Code", "\n Description", "\nPallet", "\n Quantity \n Ordered", this.state.General.Mode === 'S/O' ? "\n Quantity \n Shipped" : "\n Quantity \n Received", "\n Total\n Weight", "\n Time\n Stamp", "User\nName\n"]}
                                        bodyTable={this.formatDatePickedUp()}
                                        headerBodyTable={["ItemCode", "ItemCodeDesc", "Pallet", "QuantityOrdered", "QuantityShipped", "TotalLineWeight", "receivedDate2", "UserName"]}
                                        bottomInfo={["", "TOTALS:", "", NumberFormat(this.state.Totals.OrderDetailPicked.QuantityOrdered), NumberFormat(this.state.Totals.OrderDetailPicked.QuantityShipRec), NumberFormat(pricesFormat(this.state.Totals.OrderDetailPicked.TotalWeight)), "", ""]}
                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo2", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaTextoNormal", "ColumnaCantidades"]}
                                        hidden={false}
                                        comment={''}
                                        breakpoint={30}
                                        keysDecimalFormat={["TotalLineWeight"]}
                                        titleButton='Print Order'
                                        docname={this.state.ActualHeaderOrder.OrderNo}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <ModalOrders title={'Open ' + this.state.General.ModeTitle} show={this.state.General.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>

                        <div className='col-12 tableFixHead tb-1'>
                            <table className='table'>
                                <thead>
                                    <tr className='text-dark text-center'>
                                        <th className='bg-light' onClick={() => this.GeneralOrder('Status', this.state.order, 'OrderHeaderList')} ><div className='row'><div className='col-12'>Status</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-light' onClick={() => this.GeneralOrder('OrderNo', this.state.order, 'OrderHeaderList')}><div className='row'><div className='col-12'>Order</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-light' onClick={() => this.GeneralOrder('OrderName', this.state.order, 'OrderHeaderList')}><div className='row'><div className='col-12'>Customer</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th hidden={this.state.General.Mode === 'P/O'} onClick={() => this.GeneralOrder('PriceLevel', this.state.order, 'OrderHeaderList')} className='bg-light'><div className='row'><div className='col-12'>Price Level</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th hidden={this.state.General.Mode === 'S/O'} onClick={() => this.GeneralOrder('completionDate', this.state.order, 'OrderHeaderList')} className='bg-light'><div className='row'><div className='col-12'>Estimated Complete Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>                            
                                        <th className='bg-light' onClick={() => this.GeneralOrder('Date', this.state.order, 'OrderHeaderList')}><div className='row'><div className='col-12'>Last Update Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th hidden className='bg-light' onClick={() => this.GeneralOrder('CompleteDate', this.state.order, 'OrderHeaderList')}><div className='row'><div className='col-12'>Completed Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-light'><div className='row' onClick={() => this.GeneralOrder('SalesPerson', this.state.order, 'OrderHeaderList')}><div className='col-12'>Sales Person</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th hidden={this.state.General.Mode === 'P/O'} onClick={() => this.GeneralOrder('PosQue', this.state.order, 'OrderHeaderList')} className='bg-light'><div className='row'><div className='col-12'>Queue Position</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.OrderHeaderList.map((order, i) => (
                                            <tr hidden={(order.OrderType !== 'S' && this.state.General.Mode === 'S/O')} className='row_hover' onClick={() => this.SelectOrdertoPickUp(order)} key={i}>
                                                <td className={'text-start ' + this.stateClassColor(order.Status)}>{this.stateInWords(order.Status)}</td>
                                                <td>{order.OrderNo}</td>
                                                <td>{order.OrderName}</td>
                                                <td className='text-center' hidden={this.state.General.Mode === 'P/O'}>{order.PriceLevel}</td>
                                                <td className='text-center' hidden={this.state.General.Mode === 'S/O'}>{formatInputDateQuery(order.completionDate)}</td>
                                                <td className='text-center'>{formatInputDateQuery(order.Date) !== "" ? formatInputDateQuery(order.Date) : DateFormatMAS90(order.Date) + " " + order.DateTime}</td>
                                                <td hidden className='text-center'>{order.CompleteDate}</td>
                                                <td className='text-center'>{order.SalesPerson}</td>
                                                <td hidden={(this.state.General.Mode === 'P/O')} className='text-center'>{order.PosQue === 999 ? 'Completed' : order.PosQue}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot'>
                                </tfoot>

                            </table>
                        </div>

                    </div>
                </ModalOrders>
                <ModalOrders title={this.state.General.Mode === 'S/O' ? 'Enter Quantity Shipped' : 'Enter Quantity Received'} show={this.state.General.showModal2} close={(modal = "showModal2") => this.handleModalClose(modal)}>
                    <div className='row'>

                        <div className='col-sm-6'>
                            <div className='row'>
                                <div className='col-12 fs-4 text-start'>
                                    <p>Item Code:</p>
                                    <p className='fw-bold'>{this.state.CurrentItem.ItemCode}</p>
                                </div>
                                <div className='col-12 fs-4 text-start'>
                                    <p>Description:</p>
                                    <p className='fw-bold'>{this.state.CurrentItem.Description}</p>
                                </div>
                                <div className='col-12 fs-4 text-start'>
                                    <p>BIN:</p>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-6 pb-3 text-center'>
                                    <div className="input-group input-group-lg">
                                        <input value={this.state.CurrentItem.BIN ? this.state.CurrentItem.BIN : '-'} disabled type="text" autoComplete='off' className="form-control text-center" id='' />
                                    </div>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-12 fs-4 text-start'>
                                    <p>Pallet:</p>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-6 pb-4'>
                                    <select defaultValue={this.state.General.currentPallet} className="form-select form-select-lg mb-3" id='palletPickingSystem' onChange={this.onTarget}>
                                        {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16'].map(element => (
                                            <option key={element} className='text-center'>{element}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-3'></div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='row'>
                                <div className='col-12 fs-4 text-start'>
                                    <p>On Hand:</p>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-6 pb-3 text-center'>
                                    <div className="input-group input-group-lg">
                                        <input disabled type="number" autoComplete='off' value={this.state.CurrentItem.onHand} className="form-control text-center" id='' />
                                    </div>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-12 fs-4 text-start'>
                                    <p>Quantity Ordered:</p>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-6 pb-3 text-center'>
                                    <div className="input-group input-group-lg">
                                        <input disabled type="number" autoComplete='off' value={this.state.CurrentItem.QuantityOrdered} className="form-control text-center" id='' />
                                    </div>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-12 fs-4 text-start'>
                                    <p>{this.state.General.Mode === 'S/O' ? 'Quantity Shipped:' : 'Quantity Received:'}</p>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-6 text-center'>
                                    <div className="input-group input-group-lg">
                                        <input type="number" min={0} autoComplete='off' onChange={(e) => this.changeInputShipped(e)} defaultValue={this.state.CurrentItem.QuantityShipRec} className="form-control text-center" id='quantityShipRecPickingSystem' />
                                    </div>
                                </div>
                                <div className='col-3'></div>
                                {this.state.General.Mode === 'P/O' ?
                                    <>
                                        <div className='col-12 fs-4 text-start'>
                                            <p>Fecha de Vencimiento :</p>
                                        </div>
                                        <div className='col-3' />
                                        <div className='col-6 text-center'>
                                            <div className="input-group input-group-lg">
                                                <input type="date" autoComplete='off' value={this.state.CurrentItem.DueDate ? this.formatDate(this.state.CurrentItem.DueDate) : this.state.CurrentItem.DueDate} onChange={(e) => this.changeDueDate(e)} className="form-control text-center" id='dueDatePickingSystem' />
                                            </div>
                                        </div>
                                        <div className='col-3' />
                                    </> : <></>
                                }
                            </div>
                        </div>


                    </div>
                    <div className='row flex-column-reverse flex-sm-row'>
                        <div className='col-sm-6 '>
                            <div className='row'>
                                <div className='col-12 pb-4'>
                                    <button type="button" className="btn greenButton btn-lg w-100" onClick={() => this.confirmPickItem()} >Enter</button>
                                </div>
                                <div className='col-12'>
                                    <button type="button" className="btn btn-secondary btn-lg w-100" onClick={() => this.handleModalClose("showModal2")}>Cancel</button>
                                </div></div>
                        </div>
                        <div className='col-sm-6'>

                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <div className='ItemImage'>
                                        <img key={this.state.CurrentItem.ImgURL} className='' id='ShortsProductImg' src={(this.state.CurrentItem.ImgURL && this.state.CurrentItem.ImgURL !== '') ? this.state.CurrentItem.ImgURL : '/assets/notavailable.png'} alt='ProductImg' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </ModalOrders>
            </div>
        )
    }
}
