import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';



export default class PieGraph extends Component {

    constructor(props) {
        super(props)
        
        ChartJS.register(ArcElement, Tooltip, Legend);

    }
    

    state={
       
     
    }


   


    render(){
        return(
            <React.Fragment>
                <Pie options={this.props.options} data={this.props.data}></Pie>
            </React.Fragment>
        )
    }
}






