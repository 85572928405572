import React, { Component } from 'react'
import CartaProducto from '../CartaProducto'
import { getInformationWithData } from '../../services/CABE.js'
import { getValueCookie } from '../../services/cookieService';
import LoadingWindow from '../LoadingWindow';
import { getIndexElement } from '../../functions/searchInObject';

import { BsJournals } from "react-icons/bs";
import { BsArrowRepeat } from "react-icons/bs";
import { pricesFormat } from '../../functions/pricesFormat';

import ModalOrders from '../ModalComponent';

//BsJournals

export default class CatalogueSpoilage extends Component {

    constructor(props) {
        super(props);
        this.retornarProducto = React.createRef();
        this.ModalLoading = React.createRef();
    }

    state = {
        Catalogo: [],
        ProductosMostrados: [],
        ActualInvoice: '',
        showModal: false,
        enableButton: false,

    }




    async obtenerProductos() {
        await this.handleModalOpen()
        await this.ModalLoading.current.showState(true);
        if(this.state.ActualInvoice!==this.props.Invoice||this.state.Catalogo.length===0){
            this.setState({ ProductosMostrados: [], Catalogo: [],ActualInvoice:this.props.Invoice });
            const data = {
                company: getValueCookie('Company'),
                idcompany: getValueCookie('CompanyId'),
                priceLevel: null,
            }
            const respuesta = await getInformationWithData('/Items/get', data);

            
            if (getValueCookie('CompanyId') === '6') {
                data.company = 'Mayaland Atlanta'
                data.idcompany = '3'
                const respuesta2 = await getInformationWithData('/Items/get', data);
                respuesta.status.code = respuesta2.status.code

                
                let prodMayalandToInclude = []
                for (const item of respuesta2.data) {
                    item.IdCompany = 3
                    const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)
                    if (index !== -1) {

                        await respuesta.data.splice(index, 1)
                        await prodMayalandToInclude.push(item)
                    }
                }
                let acceptProductLines = [{ ProdLine: '2012' }, { ProdLine: '2013' }, { ProdLine: '2014' }]

                for (let m = respuesta.data.length - 1; m >= 0; m--) {

                    const item = respuesta.data[m]

                    item.IdCompany = 6
                    const prodIndex = getIndexElement(acceptProductLines, 'ProdLine', item.ProductLine)
                    if (prodIndex !== -1) {
                        if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                            item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))
                        }
                    } else {
                        respuesta.data.splice(m, 1)
                    }

                }
                respuesta.data = respuesta.data.concat(prodMayalandToInclude)
            }

            let ShowData = []
            
            if (respuesta.status.code === 1) {
                if (this.props.Invoice===''&&getValueCookie('CompanyId')==='6'){
                    for (const item of respuesta.data) {
                            item.quantityShipped=0                          
                            item.quantityReturned=0
                            item.Total=0
                            item.ItemComment=''
                            ShowData.push(item) 
                    }
                    this.setState({ Catalogo: ShowData });
                    this.setState({ ProductosMostrados: ShowData });
                }else{
                    for (const item of this.props.detail) {
                        const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)
                        if (index !== -1) {
                            respuesta.data[index].quantityShipped=item.quantityShipped
                            respuesta.data[index].quantityReturned=0
                            respuesta.data[index].UnitPrice=item.UnitPrice
                            respuesta.data[index].Total=0
                            respuesta.data[index].ItemComment=''
                            ShowData.push(respuesta.data[index])
                           
                        }else{
                           
                            item.quantityReturned=0
                            item.Total=0
                            item.ItemComment=''
                            item.itemURLImage=null
                            ShowData.push(item)
                        }
                    }
                    this.setState({ Catalogo: ShowData });
                    this.setState({ ProductosMostrados: ShowData });
                }
             
            }

     
        }
        
        const buscador = document.getElementById('SearchProdCatalogueSpoilage');
        buscador.value = '';
        setTimeout(() => { buscador.focus(); }, 300);
        await this.ModalLoading.current.showState(false);

    }

    buscarProductos = e => {

        let busqueda = e.target.value;
        let Productos = []

        if (busqueda) {
            Productos = this.state.Catalogo.filter((producto) => {
                if (((
                    this.contiene(producto.itemCode, busqueda)
                    || this.contiene(producto.abbreviatedDesc, busqueda)
                ))
                ) {
                    return producto
                } else {
                    return null
                }
            });
        } else {
            Productos = this.state.Catalogo
        }
        this.setState({ ProductosMostrados: Productos });
    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        } else {
            return false
        }
    }

    async getProducto(producto) {
        let Padre=this.props.Padre
        let Detail=Padre.state.ARDetail
        
        if(this.props.mode === 'SPOILAGE'){
            const index=getIndexElement(Detail,'itemCode',producto.itemCode)
            if(index!==-1){
                Detail[index].quantityReturned+=1
                Detail[index].Total=Detail[index].quantityReturned*Detail[index].UnitPrice
                Detail[index].Visible=true
            }else{
                producto.quantityReturned=1
                producto.Total=producto.UnitPrice
                producto.ItemComment=''
                producto.Visible=true
                Detail.push(producto)
            }
            await Padre.setState({ARDetail:Detail})
            await Padre.refreshInputs()
            await Padre.calculateTotals()
         
        }else{
            Detail.push(producto)
            
            Padre.setState({ARDetail:Detail})
        }

        
        await this.handleModalClose()

    }

    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = async () => {

        await this.setState({ showModal: false })
    }



    render() {
        return (
            <React.Fragment>
                <button type="button" hidden={this.props.hidden} disabled={this.props.disabled} className="btn catalogueOpen btn-lg w-100" onClick={() => this.obtenerProductos()}>Open Catalogue <BsJournals /></button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Detail'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className="modal-body ">
                        <div className='row form-group'>
                            <div className="input-group input-group-lg flex-nowrap">
                                <input className='form-control w-75 display-inline' placeholder='Search...' id={'SearchProdCatalogueSpoilage'} onKeyUp={this.buscarProductos} autoComplete={"off"} />
                                <span className="input-group-text">
                                    <button className='btn greenButton btn-lg' onClick={() => this.obtenerProductos()}><BsArrowRepeat /></button>
                                </span>
                            </div>
                        </div>
                        <div className='row pt-4'>
                            {

                                this.state.ProductosMostrados.map((producto, i) => (
                                    <div key={i} className='col-sm-6' onClick={() => this.getProducto(producto)}>
                                        <CartaProducto mode={"Spoilage"} itemURLImage={producto.itemURLImage} ItemCode={producto.itemCode} Description={(producto.completeDesc && producto.completeDesc !== "null") ? producto.completeDesc : producto.abbreviatedDesc} OnHand={producto.quantityShipped} UPC={producto.upc} LastTotalUnitCost={producto.LastTotalUnitCost} UnitPrice={producto.UnitPrice} needPrice={this.props.needPrice} needCost={this.props.needCost} />
                                    </div>

                                ))
                            }
                        </div>
                    </div>

                </ModalOrders>

            </React.Fragment>
        )
    }
}
