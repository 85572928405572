
import React from 'react';
import { saveAs } from 'file-saver';
import { AiFillFileExcel } from "react-icons/ai"
import "../css/general-style.css"


const CSVDocument = (props) => {

    return (
        <>
            <div>
                <button hidden={props.hidden} disabled={props.disabled} className="btn greenButton btn-lg w-100 h-100" onClick={()=>downLoadFile(props.data,props.heads,props.name,props.Titles)}>{"Download CSV File "}<AiFillFileExcel /></button>
            </div>
        </>
    )
}

export function downLoadFile(Array, Heads, name,Titles) {
    let doc = "";
    for (const Title of Titles) {
        doc+=Title
        doc+=","
    }
    doc=doc.slice(0, -1);
    doc += "\n"
    for (const Item of Array) {
        for (const head of Heads) {
            doc += Item[head]
            doc += ","
        }
        doc=doc.slice(0, -1);
        doc += "\n"
    }
    const archivo = new Blob([doc], { type: 'text/plain;charset=utf-8' });
    saveAs(archivo, name + '.csv');
}

export default CSVDocument