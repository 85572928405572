import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Login from './pages/Login';
import GlobalProvider from './services/auth/authContext';

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <Login />
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

