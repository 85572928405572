export const SalesPerSeptember= [
    {
        "CustomerNo": "0010239",
        "BillToName": "Antigua Distribution",
        "InvoiceNo": "PM51194",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 1857.9,
        "UDF_RUTA": "Distributor"
    },
    {
        "CustomerNo": "0016136",
        "BillToName": "Mich Brands LLC",
        "InvoiceNo": "PM51237",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 1031.6,
        "UDF_RUTA": "Distributor"
    },
    {
        "CustomerNo": "0002720",
        "BillToName": "El Boqueron Imports LLC",
        "InvoiceNo": "PM51208",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 2105.4,
        "UDF_RUTA": "Distributor"
    },
    {
        "CustomerNo": "0002720",
        "BillToName": "El Boqueron Imports LLC",
        "InvoiceNo": "PM51295",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 627,
        "UDF_RUTA": "Distributor"
    },
    {
        "CustomerNo": "0015535",
        "BillToName": "Azevedo Trade Distributor LLC",
        "InvoiceNo": "PM51331",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 371.75,
        "UDF_RUTA": "Distributor"
    },
    {
        "CustomerNo": "0016089",
        "BillToName": "Tienda nueva Geruslem",
        "InvoiceNo": "PM51317",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 3914.95,
        "UDF_RUTA": "Pallet"
    },
    {
        "CustomerNo": "0015575",
        "BillToName": "Silvia Mazariego",
        "InvoiceNo": "PM51381",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 27.5,
        "UDF_RUTA": "Warehouse"
    },
    {
        "CustomerNo": "0015575",
        "BillToName": "Silvia Mazariego",
        "InvoiceNo": "PM51380",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 5.5,
        "UDF_RUTA": "Warehouse"
    },
    {
        "CustomerNo": "0016163",
        "BillToName": "Tienda guatemala betel - manue",
        "InvoiceNo": "PM51384",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 2890.85,
        "UDF_RUTA": "Warehouse"
    },
    {
        "CustomerNo": "0001886",
        "BillToName": "Tienda La Libertad-MS",
        "InvoiceNo": "PM51342",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 915.9,
        "UDF_RUTA": "Pallet"
    },
    {
        "CustomerNo": "0001619",
        "BillToName": "La Bendicion Mini Market Inc",
        "InvoiceNo": "PM51350",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 1686.2,
        "UDF_RUTA": "Warehouse"
    },
    {
        "CustomerNo": "0010139",
        "BillToName": "Tienda La Chiquita-DE",
        "InvoiceNo": "PM51375",
        "InvoiceType": "IN",
        "InvoiceDate": "2023-10-01",
        "NonTaxableSalesAmt": 2857.1,
        "UDF_RUTA": "Pallet"
    }
]

export const SalesPerCalendarSeptember={
    "01": {
        "registros": 12,
        "PromMarkup": 0,
        "detail": [
            {
                "CustomerNo": "0010239",
                "BillToName": "Antigua Distribution",
                "InvoiceNo": "PM51194",
                "InvoiceDate": "2023-09-25",
                "NonTaxableSalesAmt": 1857.9,
                "UDF_RUTA": "Distributor",
                "Month": "09",
                "Year": "2023",
                "day": "25"
            },
            {
                "CustomerNo": "0016136",
                "BillToName": "Mich Brands LLC",
                "InvoiceNo": "PM51237",
                "InvoiceDate": "2023-09-26",
                "NonTaxableSalesAmt": 1031.6,
                "UDF_RUTA": "Distributor",
                "Month": "09",
                "Year": "2023",
                "day": "26"
            },
            {
                "CustomerNo": "0002720",
                "BillToName": "El Boqueron Imports LLC",
                "InvoiceNo": "PM51208",
                "InvoiceDate": "2023-09-26",
                "NonTaxableSalesAmt": 2105.4,
                "UDF_RUTA": "Distributor",
                "Month": "09",
                "Year": "2023",
                "day": "26"
            },
            {
                "CustomerNo": "0002720",
                "BillToName": "El Boqueron Imports LLC",
                "InvoiceNo": "PM51295",
                "InvoiceDate": "2023-09-27",
                "NonTaxableSalesAmt": 627,
                "UDF_RUTA": "Distributor",
                "Month": "09",
                "Year": "2023",
                "day": "27"
            },
            {
                "CustomerNo": "0015535",
                "BillToName": "Azevedo Trade Distributor LLC",
                "InvoiceNo": "PM51331",
                "InvoiceDate": "2023-09-28",
                "NonTaxableSalesAmt": 371.75,
                "UDF_RUTA": "Distributor",
                "Month": "09",
                "Year": "2023",
                "day": "28"
            },
            {
                "CustomerNo": "0016089",
                "BillToName": "Tienda nueva Geruslem",
                "InvoiceNo": "PM51317",
                "InvoiceDate": "2023-09-28",
                "NonTaxableSalesAmt": 3914.95,
                "UDF_RUTA": "Pallet",
                "Month": "09",
                "Year": "2023",
                "day": "28"
            },
            {
                "CustomerNo": "0015575",
                "BillToName": "Silvia Mazariego",
                "InvoiceNo": "PM51381",
                "InvoiceDate": "2023-09-29",
                "NonTaxableSalesAmt": 27.5,
                "UDF_RUTA": "Warehouse",
                "Month": "09",
                "Year": "2023",
                "day": "29"
            },
            {
                "CustomerNo": "0015575",
                "BillToName": "Silvia Mazariego",
                "InvoiceNo": "PM51380",
                "InvoiceDate": "2023-09-29",
                "NonTaxableSalesAmt": 5.5,
                "UDF_RUTA": "Warehouse",
                "Month": "09",
                "Year": "2023",
                "day": "29"
            },
            {
                "CustomerNo": "0016163",
                "BillToName": "Tienda guatemala betel - manue",
                "InvoiceNo": "PM51384",
                "InvoiceDate": "2023-09-29",
                "NonTaxableSalesAmt": 2890.85,
                "UDF_RUTA": "Warehouse",
                "Month": "09",
                "Year": "2023",
                "day": "29"
            },
            {
                "CustomerNo": "0001886",
                "BillToName": "Tienda La Libertad-MS",
                "InvoiceNo": "PM51342",
                "InvoiceDate": "2023-09-29",
                "NonTaxableSalesAmt": 915.9,
                "UDF_RUTA": "Pallet",
                "Month": "09",
                "Year": "2023",
                "day": "29"
            },
            {
                "CustomerNo": "0001619",
                "BillToName": "La Bendicion Mini Market Inc",
                "InvoiceNo": "PM51350",
                "InvoiceDate": "2023-09-29",
                "NonTaxableSalesAmt": 1686.2,
                "UDF_RUTA": "Warehouse",
                "Month": "09",
                "Year": "2023",
                "day": "29"
            },
            {
                "CustomerNo": "0010139",
                "BillToName": "Tienda La Chiquita-DE",
                "InvoiceNo": "PM51375",
                "InvoiceDate": "2023-09-29",
                "NonTaxableSalesAmt": 2857.1,
                "UDF_RUTA": "Pallet",
                "Month": "09",
                "Year": "2023",
                "day": "29"
            }
        ],
        "NonTaxableSalesAmt": {
            "total": 18291.65,
            "promedio": 1524.30
        }
    }
}