import { getInformationWithData } from "../../../services/CABE"
import { getValueCookie } from "../../../services/cookieService"

export class ItemsVendor {

    ItemCode
    ProductLineDesc
    ItemCodeDesc
    ItemURL
    VendorList = []

    constructor(ItemCode, ItemCodeDesc, ProductLineDesc) {
        this.ItemCode = ItemCode
        this.ItemCodeDesc = ItemCodeDesc
        this.ProductLineDesc = ProductLineDesc

    }

    addVendor(vendor) {
        this.VendorList.push(vendor)
    }

    async refreshItemURL() {
        if (this.ItemURL) {
            return this.ItemURL
        } else {
            const data = {
                idCompany: Number(getValueCookie('CompanyId')),
                ItemCode: this.ItemCode
            }
            const result = await getInformationWithData('/Items/get/Img', data)
            if (result.status.code === 1) {
                if (result.data.itemURLImage) {
                    if (result.data.itemURLImage !== '') {
                        const date = new Date().getTime()
                        return `${result.data.itemURLImage}?${date}`
                    }

                }
            }

        }

        return ''
    }


}


export class Vendors {
    VendorNo
    VendorName
    LastReceiptQuantity
    LastUnitCost
    LastReceiptDate
    ItemList = []

    constructor(VendorNo, VendorName, LastReceiptQuantity, LastUnitCost, LastReceiptDate) {
        this.VendorNo = VendorNo
        this.VendorName = VendorName
        this.LastReceiptQuantity = LastReceiptQuantity
        this.LastUnitCost = LastUnitCost
        this.LastReceiptDate = LastReceiptDate
    }
    addItem(item) {
        this.ItemList.push(item)
    }
}

export class ItemPerVendor {
    LastReceiptQuantity
    LastUnitCost
    LastReceiptDate
    ItemCode
    ItemCodeDesc
    ProductLineDesc
    ItemURL

    constructor(LastReceiptQuantity, LastUnitCost, LastReceiptDate, ItemCode, ItemCodeDesc, ProductLineDesc) {
        this.LastReceiptQuantity = LastReceiptQuantity
        this.LastUnitCost = LastUnitCost
        this.LastReceiptDate = LastReceiptDate
        this.ItemCode = ItemCode
        this.ItemCodeDesc = ItemCodeDesc
        this.ProductLineDesc = ProductLineDesc
    }
}

export class VendorDetail {
    VendorNo
    VendorName
    Detail = []

    constructor(VendorNo, VendorName) {
        this.VendorNo = VendorNo
        this.VendorName = VendorName
    }
    addDetailVendor(PurchaseOrderNo, OrderDate, QuantityInvoiced, UnitCost) {
        this.Detail.push(new DetailVendor(PurchaseOrderNo, OrderDate, QuantityInvoiced, UnitCost))
    }
}

export class DetailVendor {
    PurchaseOrderNo
    OrderDate
    QuantityInvoiced
    UnitCost

    constructor(PurchaseOrderNo, OrderDate, QuantityInvoiced, UnitCost) {
        this.PurchaseOrderNo = PurchaseOrderNo
        this.OrderDate = OrderDate
        this.QuantityInvoiced = QuantityInvoiced
        this.UnitCost = UnitCost
    }
}


export class Items {

    ItemCode
    ProductLine
    ItemCodeDesc
    Prices = []
    Dates =[]

    constructor(ItemCode, ItemCodeDesc, ProductLineDesc) {
        this.ItemCode = ItemCode
        this.ItemCodeDesc = ItemCodeDesc
        this.ProductLine = ProductLineDesc

    }

}

export class Cost{
    Date
    Cost
    VendorNo
    VendorName
    PricesLevels=[]
    constructor(Date,Cost,VendorNo,VendorName,PricesLevels){
        this.Date=Date
        this.Cost=Cost
        this.VendorNo=VendorNo
        this.VendorName=VendorName
        if(PricesLevels){
            this.PricesLevels=PricesLevels
        }else{
            this.PricesLevels=[]
        }
        
    }
}
