

import React, { Component } from "react"
import CompanyDashBoard from "../pages/CompanyDashBoard"
import Footer from "../components/Footer"
import CycleInventory from "../pages/CycleInvetory"
import CycleInvetoryGraph from "../pages/CycleInventoryGraph"
import KPI from "../pages/KPI"
import Inventory from "../pages/Inventory"
import PickingSystem from "../pages/PickingSystem"
import PurchaseOrder from "../pages/PurchaseOrder"
import "../css/general-style.css"
import SalesOrder from "../pages/SalesOrder"
import NewCustomer from "../pages/NewCustomer"
import Invoices from "../pages/Invoice"
import AccountsReceivable from "../pages/AccountsReceivable"
import MargenesPrecio from '../pages/Reports/MargenesPrecio'
import Shorts from "../pages/Shorts"
import MOH from "../pages/Reports/MOH"
import Sales from "../pages/Reports/Sales"
import VendorReport from "../pages/Reports/VendorReport/Vendor"
import PurchaseProcessReport from "../pages/Reports/Purchasing ProcessReport/PurchaseProcess"
import Tempo from "../pages/tempo"
import ActualInventoryReport from "../pages/Reports/ActualInventoryReport/ActualInventoryReport"
import HistoryInventoryReport from "../pages/Reports/HistoryInventoryReport/HistoryInventoryReport"
import AutomaticPurchaseOrder from "../pages/AutomaticPurchaseOrder/AutomaticPurchaseOrder"
import NewProductsView from "../pages/Inventory/NewProductsToInclude/NewProductsView"
import PriceMarginProdLine from "../pages/Inventory/PriceMarginProductLine"
import PaymentRecord from "../pages/PaymentManagment/PaymentRecord/PaymentRecord"
import WasteProducts from "../pages/WarehouseManagment/WasteModdle/WasteProducts"
import ScanningReportView from "../pages/Reports/ScanningReport/ScanningReportView"
import TrackingNumberReportView from "../pages/Reports/TrackingNumberReport/TrackingNumberView"
import WeightPerTravelView from "../pages/Reports/WeightPerTravel/WeightPerTravelView"
import HistoryPurchaseOrderView from "../pages/Reports/HistoryPurchaseOrder/HistoryPurchaseOrderView"
import Lobby from "../pages/InitialDashBoard/Lobby"
import ReturnPurchaseOrderView from "../pages/PurchaseReturn/PurchaseReturn"
import SalesOrderReport from "../pages/Reports/SalesOrderReport/SalesOrderReport"
import VendorPriceChangeReport from "../pages/Reports/VendorsPriceChange/VendorPriceChange"
import SpoilageReport from "../pages/Reports/Spoilage"
import PortalSalesOrder from "../pages/PortalSales/PortalSales"
import SalesByRoute from "../pages/Reports/SalesByRoute/SalesByRoute"
import CalculateMarginPrices from "../pages/Reports/CalculateNewMarginPrice/MargenesPrecio"
import GeneralMarginPrices from "../pages/Reports/GeneralMarginPrices/GeneralMarginPrices"
import InventorySystem from "../pages/InventorySystem/InventorySystem"
import InventoryOverstockView from "../pages/Reports/InventoryExcess/InventoryOverstockView"
import LogisticRoutesView from "../pages/LogisticRoutes/LogisticRoutesView"
import UsersManagement from "../pages/UsersManagement/UsersManagement"
import { Menu } from "./Menu/Menu"
import Purchasing from "../pages/Purchasing/Purchasing"
import PromotionsView from "../pages/Promotions/PromotionsView"
import LogsPage from "../pages/Logs/Logs"

export default class App extends Component {

    state = {
        Refresh: false
    }

    componentDidMount() {
        window.addEventListener('storage', e => {
            if (e.key === 'session' && e.newValue !== e.oldValue) {
                window.location.reload()
            }
        })
    }

    render() {
        return (
            <div>
                <div className="page" id="CompanyDashBoard">
                    <CompanyDashBoard />
                </div>
                <div id="GeneralPages" className="page">
                    <Menu Refresh={this.state.Refresh} Father={this} />
                    <div className="container-fluid generalContainer contPrincipal" id="actualPage">
                        <div id="Lobby" className="page">
                            <Lobby />
                        </div>
                        <div id="CycleInventory" className="page">
                            <CycleInventory />
                        </div>
                        <div id="CycleInventoryGraph" className="page">
                            <CycleInvetoryGraph />
                        </div>
                        <div id="KPIGraph" className="page">
                            <KPI />
                        </div>
                        <div id="Inventory" className='page'>
                            <Inventory />
                        </div>
                        <div id="PurchaseOrder" className='page'>
                            <PurchaseOrder />
                        </div>
                        <div id="PikingSystem" className='page'>
                            <PickingSystem />
                        </div>
                        <div id="SalesOrder" className="page">
                            <SalesOrder />
                        </div>
                        <div id="PortalSalesOrder" className="page">
                            <PortalSalesOrder />
                        </div>
                        <div id="NewCustomer" className="page">
                            <NewCustomer />
                        </div>
                        <div id="Invoices" className="page">
                            <Invoices />
                        </div>
                        <div id="AccountsReceivable" className="page">
                            <AccountsReceivable />
                        </div>
                        <div id="MargenesPrecio" className="page">
                            <MargenesPrecio />
                        </div>
                        <div id="ShortsReport" className="page">
                            <Shorts />
                        </div>
                        <div id="MOHReport" className="page">
                            <MOH />
                        </div>
                        <div id="SalesReport" className="page">
                            <Sales />
                        </div>
                        <div id="VendorReport" className="page">
                            <VendorReport />
                        </div>
                        <div id="PurchaseProcessReport" className="page">
                            <PurchaseProcessReport />
                        </div>
                        <div id="DesignPage" className="page">
                            <Tempo />
                        </div>
                        <div id="ActualInventoryReport" className="page">
                            <ActualInventoryReport />
                        </div>
                        <div id="HistoryInventoryReport" className="page">
                            <HistoryInventoryReport />
                        </div>
                        <div id="AutomaticPOPage" className="page">
                            <AutomaticPurchaseOrder />
                        </div>
                        <div id="PriceMarginProdLine" className="page">
                            <PriceMarginProdLine />
                        </div>
                        <div id="NewProductsToInclude" className="page">
                            <NewProductsView />
                        </div>
                        <div id="PaymentRecord" className="page">
                            <PaymentRecord />
                        </div>
                        <div id="WasteSystem" className="page">
                            <WasteProducts />
                        </div>
                        <div id="ScanningReport" className="page">
                            <ScanningReportView />
                        </div>
                        <div id="TrackingReport" className="page">
                            <TrackingNumberReportView />
                        </div>
                        <div id="WeightPerTravelReport" className="page">
                            <WeightPerTravelView />
                        </div>
                        <div id="HistoryPurchaseOrdersReport" className="page">
                            <HistoryPurchaseOrderView />
                        </div>
                        <div id="SalesOrderReport" className="page">
                            <SalesOrderReport />
                        </div>
                        <div id="VendorPriceChangeReport" className="page">
                            <VendorPriceChangeReport />
                        </div>
                        <div id="ReturnPurchaseOrder" className="page">
                            <ReturnPurchaseOrderView />
                        </div>
                        <div id="SpoilageReport" className="page">
                            <SpoilageReport />
                        </div>
                        <div id="RouteSalesReport" className="page">
                            <SalesByRoute />
                        </div>
                        <div id="CalculateMargin" className="page">
                            <CalculateMarginPrices />
                        </div>
                        <div id="GeneralMarginPrices" className="page">
                            <GeneralMarginPrices />
                        </div>
                        <div id="LogsPage" className="page">
                            <LogsPage />
                        </div>
                        <div id="InventorySystem" className="page">
                            <InventorySystem />
                        </div>
                        <div id="InventoryOverstock" className="page">
                            <InventoryOverstockView />
                        </div>
                        <div id="RoutesPerInvoice" className="page">
                            <LogisticRoutesView />
                        </div>
                        <div id="UsersManagement" className="page">
                            <UsersManagement />
                        </div>
                        <div id="Purchasing" className="page">
                            <Purchasing />
                        </div>
                        <div id="PromotionsView" className="page">
                            <PromotionsView />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}



