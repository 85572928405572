export class Item {
    itemCode
    Description
    QuantityReceived
    QuantityReturned
    UnitCost
    LineKey
    UnitWeight
    TotalCost
    TotalWeight
    Comment
    Visble

    constructor(ItemCode, Description,QuantityReceived, QuantityReturned, UnitCost, UnitWeight,Comment) {
        this.itemCode = ItemCode
        this.Description = Description
        this.QuantityReceived = Number(QuantityReceived)
        this.QuantityReturned = Number(QuantityReturned)
        this.UnitCost = Number(UnitCost)
        this.UnitWeight = Number(UnitWeight)
        this.TotalCost = Number(UnitCost) * Number(QuantityReturned)
        this.TotalWeight = Number(UnitWeight) * Number(QuantityReturned)
        this.Comment=Comment
        this.Visible=QuantityReceived!==0?true:false
        this.LineKey=0
    }

    calculateTotals(){
        this.TotalCost = Number(this.UnitCost) * Number(this.QuantityReturned)
        this.TotalWeight = Number(this.UnitWeight) * Number(this.QuantityReturned)
    }
}