import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import Calendar from 'react-calendar';
import "../../css/Calendar.css";
import SmallModal from '../SmallModal';
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { getIndexElement } from '../../functions/searchInObject';
import { DateFormatMAS90 } from '../../functions/dateFormat';
import { getValueCookie } from '../../services/cookieService';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        date: new Date(),
        Detail: [],
        Title: '',
        Total: 0,
        isAsigned: null
    }
    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {

        this.setState({ [modal]: false })
        this.clearStates()

    }

    selectDay = (value, e) => {

        this.handleModalOpen("showModal1")
        let date = new Date(value)
        const index = getIndexElement(this.props.data, 'day', String(date.getDate()))
        let Detail = []
        let Total = 0
        if (index !== -1) {
            Detail = this.props.data[index].detail
            Total = this.props.data[index].total
        }
        let Title = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
        this.setState({ Title: Title, Detail: Detail, Total: Total })
    }

    selectWeek = (value, e) => {
        this.handleModalOpen("showModal1")

        const index = getIndexElement(this.props.data, 'Week', String(value))
        let Detail = []
        let Total = 0
        if (index !== -1) {

            Detail = this.props.data[index].detail
            Total = this.props.data[index].total
        }
        let Title = " Week " + value
        this.setState({ Title: Title, Detail: Detail, Total: Total })
    }

    clearStates() {
        this.setState({
            Detail: [],
            Title: ''
        })
    }
    componentDidMount() {
        this.updateTotals()
    }


    updateTotals() {

        for (const week of this.props.data) {

            if (week.Week) {

                let m = document.getElementById('quant_' + week.Week + "_" + this.props.SalesPerson)
                let n = document.getElementById('sales_' + week.Week + "_" + this.props.SalesPerson)
                if (m) {
                    m.innerHTML = NumberFormat(Number(Number(week.totalsales).toFixed(0)))
                }
                if (n) {
                    n.innerHTML = "$" + NumberFormat(Number(Number(week.total).toFixed(0)))

                }
            }

        }
    }

    asignTotals = ({ date, view }) => {



        if (view === 'month') {

            const index = getIndexElement(this.props.data, 'day', String(new Date(date).getDate()))

            if (index !== -1) {
                return <><br /><p className='salesCalendar'>${NumberFormat(Number(Number(this.props.data[index].total).toFixed(0)))}</p><p className='quantSalesCalendar'>{this.props.data[index].totalsales}</p></>
            } else {
                return <><br /><p className='salesCalendar'>$0</p><p className='quantSalesCalendar'>0</p></>
            }
        }

    }

    changeDate = async (action, activeStartDate, value, view) => {
        if (getValueCookie('TypeUser') === '1') {
            await this.props.Father.changeAllMonthData(this.props.SalesPerson, activeStartDate).then(async () => {
                await this.updateTotals()
            })
        } else {
            await this.updateTotals()
        }


    }



    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h5>{this.props.Title}</h5>
                    </div>
                    <div className='col-sm-12 pb-2 backgroundWhite'>
                        <div className='row pt-3 pb-3'>
                            <div className='col-12'>
                                <div className='pb-1'>
                                    <div className="color-box backLeyendGreen" ></div>
                                    <span className='text-blockLeyend'>Daily Sales</span>
                                </div>
                                <div className='pb-1'>
                                    <div className="color-box backLeyendBlue" ></div>
                                    <span className='text-blockLeyend'># Of Daily Invoices</span>
                                </div>
                            </div>
                            <div className='col-1' />
                            {
                                getValueCookie('TypeUser') !== '1' ?
                                    <Calendar minDetail={"month"} maxDetail={"month"} minDate={new Date(this.state.date.getFullYear(), this.state.date.getMonth(), 1)} maxDate={new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, 0)} onActiveStartDateChange={({ action, activeStartDate, value, view }) => this.changeDate(action, activeStartDate, value, view)} onClickWeekNumber={(value, e) => this.selectWeek(value, e)} tileClassName={this.props.SalesPerson} showWeekNumbers={true} locale='en' showNeighboringMonth={false} className={['col-10', 'text-center']} tileContent={this.asignTotals} onClickDay={(value, e) => this.selectDay(value, e)} value={this.state.date} />

                                    :
                                    <Calendar activeStartDate={new Date(this.props.Date)} onActiveStartDateChange={({ action, activeStartDate, value, view }) => this.changeDate(action, activeStartDate, value, view)} onClickWeekNumber={(value, e) => this.selectWeek(value, e)} tileClassName={this.props.SalesPerson} showWeekNumbers={true} locale='en' showNeighboringMonth={false} className={['col-10', 'text-center']} tileContent={this.asignTotals} onClickDay={(value, e) => this.selectDay(value, e)} value={this.state.date} />

                            }

                            <div className='col-1' />
                            <div className='col-1' />
                            <div className='col-10 text-end'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <span className='text-blockLeyend salesCalendar'>Total: $ {NumberFormat(pricesFormat(this.props.Totals.Total))}</span>
                                    </div>
                                    <div className='col-12'>
                                        <span className='text-blockLeyend quantSalesCalendar'># Of Invoices: {this.props.Totals.NoInvoices}</span>
                                    </div>

                                </div>
                            </div>
                            <div className='col-1' />

                        </div>


                    </div>

                </div>
                <SmallModal centered={true} size={'xl'} title={'Sales: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1' />
                        <div className='col-12'>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle'>
                                    <thead className='bg-primary text-white'>
                                        <tr>
                                            <th className='text-center bg-primary '>Invoice No</th>
                                            <th className='text-center bg-primary '>Invoice Type</th>
                                            <th className='text-center bg-primary '>Customer No</th>
                                            <th className='text-center bg-primary '>Customer Name</th>
                                            <th className='text-center bg-primary '>Date</th>
                                            <th className='text-center bg-primary '>Route</th>
                                            <th className='text-center bg-primary '></th>
                                            <th className='text-center bg-primary '>Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Detail.map((order, o) => (
                                            <tr key={o}>
                                                <td className='text-center'>{order.InvoiceNo}</td>
                                                <td className='text-center'>{order.InvoiceType}</td>
                                                <td className='text-center'>{order.CustomerNo}</td>
                                                <td className='text-left'>{order.BillToName}</td>
                                                <td className='text-center'>{DateFormatMAS90(order.InvoiceDate)}</td>
                                                <td className='text-left'>{order.UDF_RUTA}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.NonTaxableSalesAmt))}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className='bg-primary text-white'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-center textTableSize'>Totals</td>
                                            <td className='text-center textTableSize'></td>
                                            <td className='textTableSize text-end'>$</td>
                                            <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>

                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>

            </>
        )
    }
}
