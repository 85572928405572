import React, { Component } from 'react';
import "../css/footer-style.css"

export default class Footer extends Component {

    ActualYear() {
        const time = new Date()
        var year = time.getFullYear()
        return year.toString()
    }

    render() {
        return (
            <React.Fragment>
                    <div className='container-fluid justify-content-center text-center bg-dark text-white pt-1'>
                        <div className='logoFooter pt-2 pb-2'>
                            <p>v1.0.3 Copyright &#169; {this.ActualYear()} Portal Manager.<br /> All Rights Reserved.</p>
                        </div>
                    </div>
            </React.Fragment>
        )
    }
}