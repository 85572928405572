export class ItemXVendor {

    ItemCode
    ProductLineDesc
    ItemCodeDesc
    ItemURL
    Carrier
    Comment
    OrderNo
    OrderDate
    EstCompletionDate
    QuantityOrdered
    UnitCost
    Description
    OrderBy
    onHand
    VendorList = []

    constructor(ItemCode, ItemCodeDesc,ProductLineDesc,Carrier,Comment,OrderNo,OrderDate,EstCompletionDate,QuantityOrdered,UnitCost,Description,OrderBy,onHand) {
        this.ItemCode = ItemCode
        this.ItemCodeDesc = ItemCodeDesc
        this.ProductLineDesc=ProductLineDesc
        this.Carrier=Carrier
        this.Comment=Comment
        this.OrderNo=OrderNo
        this.OrderDate=OrderDate 
        this.EstCompletionDate=EstCompletionDate
        this.QuantityOrdered=QuantityOrdered
        this.UnitCost=UnitCost 
        this.Description=Description
        this.OrderBy=OrderBy
        this.onHand=onHand

    }

    addVendor(vendor) {
        this.VendorList.push(vendor)
    }

}

export class VendorXItem{
    VendorNo
    VendorName
    Carrier
    Comment
    OrderNo
    OrderDate
    EstCompletionDate
    QuantityOrdered
    UnitCost
    Description
    OrderBy
    ItemList=[]

    constructor(VendorNo,VendorName,Carrier,Comment,OrderNo,OrderDate,EstCompletionDate,QuantityOrdered,UnitCost,Description,OrderBy){
        this.VendorNo=VendorNo
        this.VendorName=VendorName
        this.Carrier=Carrier
        this.Comment=Comment
        this.OrderNo=OrderNo
        this.OrderDate=OrderDate 
        this.EstCompletionDate=EstCompletionDate
        this.QuantityOrdered=QuantityOrdered
        this.UnitCost=UnitCost 
        this.Description=Description
        this.OrderBy=OrderBy
    }

    addItem(item){
        this.ItemList.push(item)
    }
}






