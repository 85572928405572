import { random_rgba } from "../../functions/generalFunctions"
import { pricesFormat } from "../../functions/pricesFormat"
import { getIndexElement } from "../../functions/searchInObject"
//import { getValueCookie } from "../../services/cookieService"

export class GeneralFormatToSend {
    idCompany
    SalespersonNo

    constructor(idCompany, SalespersonNo,Start,End,MonthNo) {
        
        this.idCompany = Number(idCompany)
        this.SalespersonNo = SalespersonNo
        this.Start=Start
        this.End=End
        this.MonthNo=MonthNo

       
    }
}



export class PurchaseByVendors{
    constructor(){
        this.Detail=[]
        this.Total=0
        this.Percentage=0
        this.DataSet = {
            labels: [],
            datasets: [
                {
                    label: "",
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    labels: [],
                },
            ]

        }

        this.Options = {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: "",
                    font: {
                        size: 20, // Ajusta el tamaño del título aquí
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || '';
                            if(context.label){
                                label+=context.label
                            }
                            if (label) {
                                label += ': ';
                            }

                            
                            label +=context.parsed+'%'
                            return label;
                        },
                    },
                },
            },
            is3D : true

        };
    }

    addData(VendorNo,VendorName,TotalAmt,Detail){
        const index=getIndexElement(this.Detail,'VendorNo',VendorNo)
        if(index===-1){
            this.Total+=TotalAmt
            let temporal = new PruchaseDetail(VendorNo,VendorName,TotalAmt,this.Total,Detail)
            this.Detail.push(temporal)
            this.updateAllPercentages()
            
        }else{
            this.Total+=TotalAmt
            this.Detail[index].updateInformation(TotalAmt,this.Total,Detail)
            this.updateAllPercentages()
        }
    }
    updateAllPercentages(){
        this.Detail.map((element)=>(
            element.Percentage=(element.Total/this.Total)*100
        ))
    }
    generateGraph(){
        for (const Vendor of this.Detail) {
            let Color=random_rgba()
            this.DataSet.datasets[0].data.push((pricesFormat(Vendor.Percentage)))
            this.DataSet.labels.push(Vendor.VendorName)
            this.DataSet.datasets[0].backgroundColor.push(Color)
            this.DataSet.datasets[0].borderColor.push(Color)
            this.DataSet.datasets[0].labels.push(Vendor.Percentage)
        }
    }
}

export class PruchaseDetail{
    
    constructor(VendorNo,VendorName,TotalAmt,ActualAmt,Detail){
        this.VendorNo=VendorNo
        this.VendorName=VendorName
        this.Total=TotalAmt
        this.Percentage=(TotalAmt/ActualAmt)*100
        this.Detail=[Detail]
    }
    updateInformation(TotalAmt,ActualAmt,Detail){
        this.Total+=TotalAmt
        this.Percentage=(TotalAmt/ActualAmt)*100
        this.Detail.push(Detail)
    }

}




