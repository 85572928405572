import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineSearch } from 'react-icons/ai'
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { getValueCookie } from '../../services/cookieService';


export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title: '',
        showModal1: false,
        Detail: [],
        Total: 0,
        Data: [],
        filterText1: '',
        useProps: true,
        Order:true

    }
    clearStates() {
        this.setState({
            Detail: [],
            Title: '',
            Total: 0,

        })
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearStates()
    }

    getDetail(detail, title, total) {
        this.setState({ Detail: detail.sort((a, b) => b.NonTaxableSalesAmt - a.NonTaxableSalesAmt), Title: title, Total: total })
        this.handleModalOpen("showModal1")
    }

    addMonth() {
        if (getValueCookie('TypeUser') === '1') {
            const date = this.props.Date
            const firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().substring(0, 10);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0).toISOString().substring(0, 10);

            this.props.Father.changeMonth({ IdCompany: 3, Start: firstDay, End: lastDay }, (this.props.Date.setMonth(this.props.Date.getMonth() + 1)))
        }

    }
    restMonth() {
        if (getValueCookie('TypeUser') === '1') {
            const date = this.props.Date
            const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().substring(0, 10);
            const lastDay = new Date(date.getFullYear(), date.getMonth(), 0).toISOString().substring(0, 10);

            this.props.Father.changeMonth({ IdCompany: 3, Start: firstDay, End: lastDay }, (this.props.Date.setMonth(this.props.Date.getMonth() - 1)))
        }
    }

    handleFilterChange = (event) => {
        this.setState({
            filterText1: event.target.value.toLowerCase(), // Convertir el texto del filtro a minúsculas
        });
    };

    orderInfo(columName) {
        //console.log("colum:",columName)
        let order = this.state.Order
        //Revisar si el useProps es falso para utlizar el mismo estado
        const filtered = this.state.useProps ? this.props.data : this.state.Data;
        const temporal = filtered.sort((a, b) => {
            if (order) {
                return a[columName] > b[columName] ? 1 : a[columName] < b[columName] ? -1 : 0;
            } else {
                return a[columName] < b[columName] ? 1 : a[columName] > b[columName] ? -1 : 0;
            }
        });
        //console.log("temp",temporal)
        this.setState({ Data: temporal, Order: !order, useProps: false })
    }

    render() {
        const { filterText1, useProps, Data } = this.state;
        const filteredData = useProps ? this.props.data : Data;
        const lowerCaseFilterText = filterText1.toLowerCase(); // Convertir el texto del filtro a minúsculas
        return (
            <>
                <div className='row'>
                    {/* <div className='col-sm-12'>
                        <h5>{this.props.Title}</h5>
                    </div> */}


                    <div className='col-sm-12 pb-3 pt-1'>
                        <div className="input-group input-group-lg">
                            <span className="input-group-text"><AiOutlineSearch /></span>
                            <input type="text" autoComplete='off' className="form-control" placeholder='Search by Produc Line' onChange={this.handleFilterChange} />
                        </div>
                    </div>

                    <div className='col-sm-12'>
                        <div className='tableFixHeadSmall'>
                            <table className='table align-middle'>
                                <thead className='thead'>
                                    <tr className='text-light text-center'>

                                        <th className='bg-dark text-center' colSpan={8}>
                                            <div className='row'>
                                                <div className='col-2' onClick={() => this.restMonth()}><AiFillCaretLeft /></div>
                                                <div className='col-8'>{this.props.Month}</div>
                                                <div className='col-2' onClick={() => this.addMonth()}><AiFillCaretRight /></div>
                                            </div>
                                        </th>

                                    </tr>
                                    <tr className='text-light text-center'>
                                        <th onClick={() => this.orderInfo("ProductLine")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th onClick={() => this.orderInfo("TotalOnHand")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>On Hand</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th onClick={() => this.orderInfo("TotalQuantityShipped")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Total Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark '></th>
                                        <th onClick={() => this.orderInfo("TotalInv")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Total Inventory</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark '></th>
                                        <th onClick={() => this.orderInfo("TotalSales")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Total Sales</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th onClick={() => this.orderInfo("Rotation")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Rotation</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    </tr>
                                </thead>
                                <tbody className='tbody bg-light'>
                                    {
                                        filteredData.filter(
                                            (element) =>
                                                element.ProductLine.toLowerCase().includes(lowerCaseFilterText)
                                        ).map((element, e) => (
                                            <tr /* onClick={() => this.getDetail(element.Detail, element.VendorName, element.Total)} */ key={e}>
                                                <td className=' text-start' >{element.ProductLine}</td>
                                                <td className=' text-center' >{element.TotalOnHand}</td>
                                                <td className=' text-center' >{element.TotalQuantityShipped}</td>
                                                <td className=' text-end'>$</td>
                                                <td className=' text-endr' >{NumberFormat(pricesFormat(element.TotalInv))}</td>
                                                <td className=' text-end'>$</td>
                                                <td className=' text-end'>{NumberFormat(pricesFormat(element.TotalSales))}</td>
                                                <td className=' text-center'>{pricesFormat(element.Rotation)}</td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                                {/* <tfoot className='tfoot bg-light'>
                                    <tr className=''>
                                        <td className='text-center '>TOTAL</td>
                                        <td className=' text-end'></td>
                                        <td className=' text-end'>$</td>
                                        <td className=' text-end'>{NumberFormat(pricesFormat(this.props.data.Total))}</td>
                                        <td className=' text-center'>{"100%"}</td>
                                    </tr>
                                </tfoot> */}
                            </table>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
