export class Inventory {
    NoInventory
    QuantityDays
    CloseDate
    QuantityItemsCount
    ValuedAccuracy
    TotalValuedInventory
    BinaryAccuracy
    Adjustments
    KPI
    Detail
    TotalQuantityItemsError
    TotalQuantityItemsOk
    DaysPerAdjustmenst
    AllErrorItems
    AllWrongDifferences

    constructor(NoInventory, QuantityDays, CloseDate) {
        this.NoInventory=NoInventory
        this.QuantityDays=QuantityDays
        this.CloseDate=CloseDate
        this.QuantityItemsCount=0
        this.ValuedAccuracy=0
        this.BinaryAccuracy=0
        this.Adjustments=0
        this.KPI=0
        this.TotalQuantityItemsError=0
        this.TotalValuedInventory=0
        this.TotalQuantityItemsOk=0
        this.Detail=[]
        this.DaysPerAdjustmenst=[]
        this.AllErrorItems=[]
        this.AllWrongDifferences=[]
    }

    addItemCount(){
        this.QuantityItemsCount+=1
    }
    addItemWrong(){
        this.TotalQuantityItemsError+=1
    }
    addItemOk(){
        this.TotalQuantityItemsOk+=1
    }
    calculateBinaryAccuracy(){
        if(this.QuantityItemsCount!==0){
            this.BinaryAccuracy=(this.TotalQuantityItemsOk/this.QuantityItemsCount)*100
        }
        
    }
    addAdjustment(value){
        this.Adjustments+=value
    }
    calculateTotalValuedInventory(){
       for (const item of this.Detail) {
            this.TotalValuedInventory+=item.Accumulated
            item.calculateValuedAccuracy()

       }
    }
    calculateValuedAccuracy(){
        if(this.TotalValuedInventory!==0){
            this.ValuedAccuracy=100+((this.Adjustments/this.TotalValuedInventory)*100)
        }
        
    }
    calculateKPI(){
        this.KPI=(0.7*this.ValuedAccuracy)+(0.3*this.BinaryAccuracy)
    }
}


export class InventoryProductLine {
    Name
    LastDate
    TotalItem
    TotalCountItem
    TotalOKItem
    TotalWrongItem
    BinaryAccuracy
    ValuedAccuracy
    Advance
    Accumulated
    ListProducts
    TotalCost


    constructor(Name) {
        this.Name = Name
        this.LastDate = 'Not Counting Yet'
        this.TotalItem = 0
        this.TotalCountItem = 0
        this.TotalOKItem = 0
        this.TotalWrongItem = 0
        this.BinaryAccuracy = 0.00
        this.Advance = 0.00
        this.Accumulated = 0.00
        this.ValuedAccuracy=0.00
        this.ListProducts = []
        this.TotalCost = 0
    }

    addItem() {
        this.TotalItem += 1
    }
    addCountItem() {
        this.TotalCountItem += 1
    }
    addOkItem() {
        this.TotalOKItem += 1
    }
    addWrongItem() {
        this.TotalWrongItem += 1
    }
    calculateBinaryAccuracy() {
        let real = this.TotalCountItem === 0 ? 1 : (this.TotalWrongItem / this.TotalCountItem)
        this.BinaryAccuracy = 100 - (real * 100)
    }
    calculateAdvance(totalItems) {
        if(totalItems!==0){
            this.Advance = (this.TotalCountItem / totalItems) * 100
        }
       
    }
    calculateTotalCost(value) {
        this.TotalCost += value
    }
    addAcumulated(value){
        this.Accumulated+=value
    }
    calculateValuedAccuracy(){
        if(this.Accumulated!==0){
            this.ValuedAccuracy=100+((this.TotalCost/this.Accumulated)*100)
        }
        
    }
}

export class DayPerAdjustment{
    Num
    Day
    Value

    constructor(Day,Num){
        this.Num=Num
        this.Day=Day
        this.Value=0
    }
    addValue(value){
        this.Value+=value
    }
}
