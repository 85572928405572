import React, { Component } from "react";
import LoadingWindow from '../../components/LoadingWindow';
import { create_Delete_Update_Information, getInformationWithData } from "../../services/CABE";
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { getIndexElement } from "../../functions/searchInObject";
import { pricesFormat } from "../../functions/pricesFormat";
import { confirmCloseAlert } from "../../functions/alerts";
import ExcelDocument from "../../components/ExcelDocument";
import { getDataSet } from "../../functions/generateDataSetExcel";
import { getValueCookie } from "../../services/cookieService";
import { getActualDateUTC, getDateFromReports } from "../../functions/dateFormat";
import { OrderArray } from "../../functions/generalFunctions";
import CustomerList from "../../components/CustomerList";
export default class PromotionsView extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        FilterProductLines: [],
        ProductLines: [],
        order: true,
        Customer: {
            AddressLine1: '',
            City: '',
            CustomerName: '',
            CustomerNo: '',
            EmailAddress: '',
            PriceLevel: undefined,
            SalespersonNo: '',
            State: '',
            TelephoneNo: '',
            ZipCode: '',
            uniqueTrack: '',
        }
    }

    async getCustomerPromotions() {
        
        const data = {
            CustomerNo: this.state.Customer.CustomerNo,
            IdCompany: Number(getValueCookie('CompanyId'))
        }
        const result = await getInformationWithData('/Items/Promotions/Customer', data)
        console.log(result)
        if (result.status.code === 1) {
            await this.setState({ ProductLines: result.data, FilterProductLines: result.data })
            await this.refreshInputs()
        }
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchProductLines = async (e) => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.ProductLines
        } else {
            Filter = this.state.ProductLines.filter((detail) => {
                if (this.Contains(detail.ProductLineDesc, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }

        await this.setState({ FilterProductLines: Filter })
        await this.refreshInputs()
    }

    refreshInputs() {

        for (const item of this.state.FilterProductLines) {
            if (document.getElementById('priceMarginProdLine2_' + item.ProductLine)) {
                document.getElementById('priceMarginProdLine2_' + item.ProductLine).value = item.Discount
            }

        }
    }

    EnableMarginPrice(id) {
        if (document.getElementById(id)) {
            document.getElementById(id).removeAttribute('disabled')
        }
    }
    changePriceMargin = async (e) => {
        if (e.key === 'Enter') {
            const id = e.target.id.split('_')
            if (id.length === 2) {
                const temporal = this.state.ProductLines
                const temporal2 = this.state.FilterProductLines
                const index = getIndexElement(temporal, 'ProductLine', id[1])
                const index2 = getIndexElement(temporal2, 'ProductLine', id[1])

                if (index !== -1) {
                    const data = {
                        CustomerNo: temporal[index].CustomerNo,
                        Discount: Number(e.target.value),
                        ProductLine: temporal[index].ProductLine,
                        IdCompany:Number(temporal[index].IdCompany),
                        Username:getValueCookie('userName'),
                        Date: getActualDateUTC()

                    }
                    const result = await create_Delete_Update_Information('/Items/Promotions/Customer/Set', data)
                    if (result.status.code === 1) {
                        temporal[index].Discount = pricesFormat(e.target.value)
                        temporal2[index2].Discount = pricesFormat(e.target.value)
                        this.setState({ FilterProductLines: temporal2, ProductLines: temporal })
                        document.getElementById(e.target.id).setAttribute('disabled', 'disabled')
                        confirmCloseAlert('correct', 'The Discount of : ' + temporal[index].ProductLineDesc + ' for the Customer: '+this.state.Customer.CustomerName+' has changed successfully!')
                    } else {
                        confirmCloseAlert('incorrect', 'Whoops, something is wrong. Try again!')
                    }
                }

            }
        }

    }
    async GeneralOrder(key, asc, name) {
        const temporal = this.state[name]
        const Order = OrderArray(temporal, key, asc)
        await this.setState({ [name]: Order, order: !asc })
        await this.refreshInputs()
    }



    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Discounts Per Customer</p>
                <div className="row pb-3">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row pb-2">
                            <div className="col-12 pb-4">
                                <div className="d-grid gap-2">
                                    <CustomerList headerName='Customer' nombrePadre="Promotions" Padre={this} />

                              </div>
                            </div>
                            <div className='row pt-5 pb-2 grayBackground'>
                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>Customer No: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.CustomerNo}</div>
                                            </div>

                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>Name: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.CustomerName}</div>
                                            </div>

                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>Address Line: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.AddressLine1}</div>
                                            </div>

                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>City: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.City}</div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>Zip Code: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.ZipCode}</div>
                                            </div>

                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>State: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.State}</div>
                                            </div>

                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>Telphone No: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.TelephoneNo}</div>
                                            </div>

                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-6 InputTextBolder'><p>Email Address: </p></div>
                                                <div className='col-6 textTableSize'>{this.state.Customer.EmailAddress}</div>
                                            </div>

                                        </div>

                                    </div>
                                </div>



                            </div>
                            <div hidden={this.state.ProductLines.length === 0} className="col-12 pt-3 pb-4">
                                <input onChange={this.searchProductLines} className="form-control form-control-lg" placeholder='Search by Product Line...' id='SearchMarginPriceProdLine' type="text" />
                            </div>
                            <div hidden={this.state.ProductLines.length === 0} className="col-12 pb-4">
                                <div className="d-grid gap-2">
                                    <ExcelDocument id={"ExcelDiscountsByCustomers"} data={getDataSet(this.state.ProductLines, ['Product Line', 'Discount %'], ['ProductLineDesc', 'Discount'])} sheetname={"Discounts By Product Lines"} archname={"Discounts Product Lines Customer "+this.state.Customer.CustomerName+" "+ getValueCookie('Company') + " DATE " + getDateFromReports()} />
                                </div>
                            </div>
                            <div hidden={this.state.ProductLines.length === 0} className="col-12 tableFixHead">
                                <table className='table align-middle'>
                                    <thead className='thead'>
                                        <tr className='bg-primary text-white'>

                                            <th onClick={() => this.GeneralOrder('ProductLineDesc', this.state.order, 'FilterProductLines')} className='text-center bg-primary '><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.GeneralOrder('Discount', this.state.order, 'FilterProductLines')} className='text-center bg-primary '><div className='row'><div className='col-12'>Discount %</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.FilterProductLines.map((item, i) => (
                                            <tr key={i} hidden={item.Visible === 0}>

                                                <td className='text-start textTableSize'>{item.ProductLineDesc}</td>
                                                <td className='text-center'>
                                                    <div onDoubleClick={() => this.EnableMarginPrice('priceMarginProdLine2_' + item.ProductLine)} className="input-group input-group-lg flex-nowrap">

                                                        <input disabled type="number" id={'priceMarginProdLine2_' + item.ProductLine} min={0} step={0.01} defaultValue={item.Discount} onKeyDown={this.changePriceMargin} className="form-control text-center inputResize" />
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-1"></div>

                </div>
            </div>
        )
    }
}