import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import "../../css/inventory-style.css"
import { getValueCookie } from '../../services/cookieService';
import { getInformationWithData } from '../../services/CABE';
import { DeleteCharactersNotAccepted, ItemDesc, MonthInLetters, NumberFormat, OrderArray } from '../../functions/generalFunctions';
import { getIndexElement } from '../../functions/searchInObject'
import { pricesFormat } from '../../functions/pricesFormat';
import { v4 as uuidv4 } from 'uuid';
import LoadingWindow from '../../components/LoadingWindow';
import ExcelDocument from '../../components/ExcelDocument';
import { getDataSet, getDataSetSales, getDataSetSales2 } from '../../functions/generateDataSetExcel';
import ModalOrders from '../../components/ModalComponent';
import { DateFormatMAS90, getDateFromReports, OrderArrayByDateGenericLines } from '../../functions/dateFormat';
import { confirmCloseAlert } from '../../functions/alerts';
export default class Sales extends Component {
    constructor(props) {
        super(props)
        this.BarGraph1 = React.createRef()
        this.ModalLoading = React.createRef();

    }

    state = {
        HeaderTable: {
            Row1: [],
            Row2: [],
            Row3: []
        },
        HeaderTable2: {
            Row1: [],
            Row2: [],
            Row3: []
        },
        BodyCustomer: [],
        BodyFilterCustomer: [],
        Body: [],
        BodyFilter: [],
        AllInformation: [],
        Totals: [],
        showModal1: false,
        currentItem: {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: '',
            totalShorts: 0,
            totalQuantityShipped: 0,
            totalQuantityOrdered: 0,
            totalShort: 0
        },
        currentCustomer: {
            CustomerNo: '',
            CustomerName: '',
            totalShorts: 0,
            totalQuantityShipped: 0,
            totalQuantityOrdered: 0,
            totalShort: 0
        },
        AllNoProccessInformation: [],
        ShortDetail: [],
        CustomerDetail: [],
        ShortDetailWithTotal: [],
        flag: true,
        Mode: 1

    }


    TableHeader(splitStartDate, splitEndDate) {
        let years = [{ year: '', span: 4 }]
        let years2 = [{ year: '', span: 3 }]
        let months = []
        let General = [{ year: '', month: '', title: ['   Key   ', 'Item Code', 'Product Line', 'Description'] }]
        let General2 = [{ year: '', month: '', title: ['   Key   ', 'Customer No', 'Customer Name'] }]

        for (let a = Number(splitStartDate[0]); a <= Number(splitEndDate[0]); a++) {
            if (a === Number(splitStartDate[0]) && a === Number(splitEndDate[0])) {
                var cont1 = 0
                for (let b = Number(splitStartDate[1]); b <= Number(splitEndDate[1]); b++) {
                    cont1++
                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])
                    General2 = General2.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])

                }
                const temp = {
                    year: String(a),
                    span: cont1 * 3
                }
                years.push(temp)
                years2.push(temp)
                break
            } else if (a === Number(splitStartDate[0])) {
                var cont2 = 0
                for (let b = Number(splitStartDate[1]); b <= 12; b++) {
                    cont2++
                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])
                    General2 = General2.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])

                }

                const temp = {
                    year: String(a),
                    span: cont2 * 3
                }
                years.push(temp)
                years2.push(temp)

            } else if (a === Number(splitEndDate[0])) {
                var cont3 = 0
                for (let b = 1; b <= Number(splitEndDate[1]); b++) {
                    cont3++
                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])
                    General2 = General2.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])

                }

                const temp = {
                    year: String(a),
                    span: cont3 * 3
                }
                years.push(temp)
                years2.push(temp)

            } else {

                for (let b = 1; b <= 12; b++) {

                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])
                    General2 = General2.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Sales', '', 'Sales ($)'] }])

                }

                const temp = {
                    year: String(a),
                    span: 12 * 3
                }
                years.push(temp)
                years2.push(temp)
            }

        }

        this.setState({
            HeaderTable: {
                Row1: years,
                Row2: months,
                Row3: General
            },
            HeaderTable2: {
                Row1: years2,
                Row2: months,
                Row3: General2
            }
        })

        return
    }
    clearAll() {
        this.setState({
            HeaderTable: {
                Row1: [],
                Row2: [],
                Row3: []
            },
            Body: [],
            BodyFilter: [],
            AllInformation: [],
            Totals: [],
            currentItem: {
                ItemCode: '',
                ItemCodeDesc: '',
                ProductLine: '',
                itemURL: ''
            },
            AllNoProccessInformation: [],
            ShortDetail: [],
        })
    }

    async getShorts() {
        this.ModalLoading.current.showState(true);
        this.clearAll()
        var date1 = document.getElementById('startSalesFilterDate').value
        var date2 = document.getElementById('endSalesFilterDate').value
        const splitStartDate = date1.split('-')
        const splitEndDate = date2.split('-')
        if (splitStartDate.length === 3 && splitEndDate.length === 3) {
            this.TableHeader(splitStartDate, splitEndDate)
            const data = {
                Start: date1,
                End: date2,
                idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId'))
            }
            const result = await getInformationWithData('/invoice/report/Sales', data)

            let temporal = []
            let customer = []
            if (result.status.code === 1) {

                this.setState({ AllInformation: result.data })
                for (const item of result.data) {
                    const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
                    const index2 = getIndexElement(customer, 'CustomerNo', item.CustomerNo)
                    const splitDate = item.InvoiceDate ? item.InvoiceDate.split('-') : []
                    if (index === -1 && splitDate.length === 3) {

                        const json = {
                            ItemCode: item.ItemCode,
                            ItemCodeDesc: item.ItemCodeDesc,
                            ProductLine: item.ProductLineDesc,
                            Detail: []
                        }

                        const year = {
                            year: String(splitDate[0]),
                            Months: []
                        }

                        const month = {
                            month: String(splitDate[1]),
                            dolar: '$',
                            shorts: item.shorts,
                            QuantityShipped: Number(item.QuantityShipped),
                            PurchaseCost: item.UnitCost,
                        }
                        year.Months.push(month)
                        json.Detail.push(year)
                        temporal.push(json)
                    } else if (splitDate.length === 3) {
                        const element = temporal[index]
                        const indexYear = getIndexElement(element.Detail, 'year', String(splitDate[0]))
                        if (indexYear === -1) {
                            const year2 = {
                                year: String(splitDate[0]),
                                Months: []
                            }

                            const month2 = {
                                month: String(splitDate[1]),
                                dolar: '$',
                                shorts: item.shorts,
                                QuantityShipped: Number(item.QuantityShipped),
                                PurchaseCost: item.UnitCost,
                            }
                            year2.Months.push(month2)
                            element.Detail.push(year2)
                        } else {
                            const element2 = element.Detail[indexYear]
                            const indexMonth = getIndexElement(element2.Months, 'month', String(splitDate[1]))
                            if (indexMonth === -1) {
                                const month3 = {
                                    month: String(splitDate[1]),
                                    dolar: '$',
                                    shorts: item.shorts,
                                    QuantityShipped: Number(item.QuantityShipped),
                                    PurchaseCost: item.UnitCost,
                                }
                                element2.Months.push(month3)
                                element.Detail[indexYear] = element2
                                temporal[index] = element

                            } else {
                                //AÑADIR PROMEDIOS Y SUMAS 
                                const element3 = element2.Months[indexMonth]
                                element3.shorts += item.shorts
                                element3.QuantityShipped += item.QuantityShipped
                                element3.PurchaseCost += item.UnitCost
                                element2.Months[indexMonth] = element3
                                element.Detail[indexYear] = element2
                                temporal[index] = element
                            }
                        }

                    }

                    if (index2 === -1 && splitDate.length === 3) {

                        const json = {
                            CustomerNo: item.CustomerNo,
                            CustomerName: item.BillToName,
                            Detail: []
                        }

                        const year = {
                            year: String(splitDate[0]),
                            Months: []
                        }

                        const month = {
                            month: String(splitDate[1]),
                            dolar: '$',
                            shorts: item.shorts,
                            QuantityShipped: Number(item.QuantityShipped),
                            PurchaseCost: item.UnitCost,
                        }
                        year.Months.push(month)
                        json.Detail.push(year)
                        customer.push(json)
                    } else if (splitDate.length === 3) {
                        const element = customer[index2]
                        const indexYear = getIndexElement(element.Detail, 'year', String(splitDate[0]))
                        if (indexYear === -1) {
                            const year2 = {
                                year: String(splitDate[0]),
                                Months: []
                            }

                            const month2 = {
                                month: String(splitDate[1]),
                                dolar: '$',
                                shorts: item.shorts,
                                QuantityShipped: Number(item.QuantityShipped),
                                PurchaseCost: item.UnitCost,
                            }
                            year2.Months.push(month2)
                            element.Detail.push(year2)
                        } else {
                            const element2 = element.Detail[indexYear]
                            const indexMonth = getIndexElement(element2.Months, 'month', String(splitDate[1]))
                            if (indexMonth === -1) {
                                const month3 = {
                                    month: String(splitDate[1]),
                                    dolar: '$',
                                    shorts: item.shorts,
                                    QuantityShipped: Number(item.QuantityShipped),
                                    PurchaseCost: item.UnitCost,
                                }
                                element2.Months.push(month3)
                                element.Detail[indexYear] = element2
                                customer[index2] = element

                            } else {
                                //AÑADIR PROMEDIOS Y SUMAS 
                                const element3 = element2.Months[indexMonth]
                                element3.shorts += item.shorts
                                element3.QuantityShipped += item.QuantityShipped
                                element3.PurchaseCost += item.UnitCost
                                element2.Months[indexMonth] = element3
                                element.Detail[indexYear] = element2
                                customer[index2] = element
                            }
                        }

                    }
                }

                for (const month of this.state.HeaderTable.Row2) {
                    for (const item of temporal) {
                        const indexyear = getIndexElement(item.Detail, 'year', month.year)
                        if (indexyear === -1) {
                            const add = {
                                year: month.year,
                                Months: [{
                                    PurchaseCost: '-',
                                    QuantityShipped: 0.00,
                                    dolar: "$",
                                    month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                                    shorts: 0.00,
                                }]
                            }
                            item.Detail.push(add)
                        } else {
                            const temporal = item.Detail[indexyear]
                            const indexmonth = getIndexElement(temporal.Months, 'month', String(month.num).length === 1 ? "0" + String(month.num) : String(month.num))
                            if (indexmonth === -1) {
                                const add2 = {
                                    PurchaseCost: '-',
                                    QuantityShipped: 0.00,
                                    dolar: "$",
                                    month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                                    shorts: 0.00,
                                }
                                temporal.Months.push(add2)
                                item.Detail[indexyear] = temporal
                            }
                        }
                    }

                    for (const item of customer) {
                        const indexyear = getIndexElement(item.Detail, 'year', month.year)
                        if (indexyear === -1) {
                            const add = {
                                year: month.year,
                                Months: [{
                                    PurchaseCost: '-',
                                    QuantityShipped: 0.00,
                                    dolar: "$",
                                    month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                                    shorts: 0.00,
                                }]
                            }
                            item.Detail.push(add)
                        } else {
                            const temporal = item.Detail[indexyear]
                            const indexmonth = getIndexElement(temporal.Months, 'month', String(month.num).length === 1 ? "0" + String(month.num) : String(month.num))
                            if (indexmonth === -1) {
                                const add2 = {
                                    PurchaseCost: '-',
                                    QuantityShipped: 0.00,
                                    dolar: "$",
                                    month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                                    shorts: 0.00,
                                }
                                temporal.Months.push(add2)
                                item.Detail[indexyear] = temporal
                            }
                        }
                    }

                }





                temporal = this.Order(temporal, 'ItemCode', true)
                customer = this.Order(customer, 'CustomerNo', true)

                temporal = await ItemDesc(temporal, 'ItemCodeDesc', 'ItemCode')
                for (const item of temporal) {
                    for (const detail of item.Detail) {
                        detail.Months = this.Order(detail.Months, 'month', true)
                    }
                    item.Detail = this.Order(item.Detail, 'year', true)
                }
                for (const item of customer) {
                    for (const detail of item.Detail) {
                        detail.Months = this.Order(detail.Months, 'month', true)
                    }
                    item.Detail = this.Order(item.Detail, 'year', true)
                }


                await this.setState({ BodyCustomer: customer, BodyFilterCustomer: customer, Body: temporal, BodyFilter: temporal, Totals: this.getTotals(temporal), AllNoProccessInformation: result.data })
            }
        } else {
            confirmCloseAlert('incorrect', 'Both dates must be established\n to make the report!')
        }
        this.ModalLoading.current.showState(false);
    }

    contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }

    searchShorts = e => {

        let search = e.target.value;

        let orders = this.state.Body.filter((item) => {
            if (((
                this.contains(item.ItemCode, search) ||
                this.contains(item.ProductLine, search) ||
                this.contains(item.ItemCodeDesc, search)

            ))
            ) {
                return item
            } else {
                return null
            }
        });
        this.setState({ BodyFilter: orders, Totals: this.getTotals(orders) });

    }
    searchShorts2 = e => {

        let search = e.target.value;

        let orders = this.state.BodyCustomer.filter((item) => {
            if (((
                this.contains(item.CustomerNo, search) ||
                this.contains(item.CustomerName, search)

            ))
            ) {
                return item
            } else {
                return null
            }
        });
        this.setState({ BodyFilterCustomer: orders, Totals: this.getTotals(orders) });

    }

    getTotals(temporal) {
        let totals = []
        let flag = true
        let count = 0
        for (const item of temporal) {
            for (const detail of item.Detail) {
                for (let a = 0; a < detail.Months.length; a++) {
                    if (flag) {
                        totals.push(detail.Months[a].QuantityShipped === '-' ? 0 : Number(detail.Months[a].QuantityShipped))
                        totals.push('$')
                        totals.push(detail.Months[a].shorts === '-' ? 0 : Number(detail.Months[a].shorts))
                    } else {
                        totals[count] += detail.Months[a].QuantityShipped === '-' ? 0 : Number(detail.Months[a].QuantityShipped)
                        count += 2
                        totals[count] += detail.Months[a].shorts === '-' ? 0 : Number(detail.Months[a].shorts)
                        count += 1
                    }

                }
            }
            flag = false
            count = 0
        }
        for (let num = 0; num < totals.length; num++) {
            if (totals[num] !== '$') {
                totals[num] = NumberFormat(pricesFormat(totals[num]))
            }
        }

        return totals
    }

    Order(arreglo, llave, bandera) {

        const l = arreglo.length;
        if (bandera) {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if (arreglo[j][llave] > arreglo[j + 1][llave]) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        } else {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if (arreglo[j][llave] < arreglo[j + 1][llave]) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        }
        return arreglo;
    }

    handleModalOpen = (modal) => {
        var temporal = this.state[modal]
        temporal = true
        this.setState({ [modal]: temporal })
    }

    handleModalClose = (modal) => {
        var temporal = this.state[modal]
        temporal = false
        this.setState({ [modal]: temporal })
    }
    clearOnlyShortDetail() {
        this.setState({
            currentItem: {
                ItemCode: '',
                ItemCodeDesc: '',
                ProductLine: '',
                itemURL: '',
                totalShorts: 0,
                totalQuantityShipped: 0,
                totalQuantityOrdered: 0,
                totalShort: 0
            },
            ShortDetail: [],
            currentCustomer: {
                CustomerNo: '',
                CustomerName: '',
                totalShorts: 0,
                totalQuantityShipped: 0,
                totalQuantityOrdered: 0,
                totalShort: 0
            },
            CustomerDetail: [],
        })
    }

    async DetailofShort(itemCode) {
        this.clearOnlyShortDetail()
        const temporal = this.state.currentItem
        temporal.ItemCode = itemCode.ItemCode
        temporal.ItemCodeDesc = itemCode.ItemCodeDesc
        temporal.ProductLine = itemCode.ProductLine

        let SpecificDetail = []
        var total = 0
        var totalShipped = 0
        var totalOrdered = 0
        
        for (const item of this.state.AllNoProccessInformation) {
            if (item.ItemCode === itemCode.ItemCode) {
                total += item.shorts
                totalShipped += item.QuantityShipped
                totalOrdered += item.QuantityOrdered
                item.TotalShort = item.shorts
                item.UnitCostD = '$' + pricesFormat(item.UnitCost)
                item.UnitPriceD = '$' + pricesFormat(item.UnitPrice)
                item.TotalShortD = '$' + pricesFormat(item.shorts)
                SpecificDetail.push(item)
            }

        }
        temporal.totalShorts = total
        temporal.totalQuantityShipped = totalShipped
        temporal.totalQuantityOrdered = totalOrdered
        SpecificDetail.push()

        SpecificDetail = OrderArrayByDateGenericLines(SpecificDetail, 'InvoiceDate')
        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            ItemCode: itemCode.ItemCode
        }
        const resultImg = await getInformationWithData('/Items/get/Img', data)

        if (resultImg.status.code === 1) {
            if (resultImg.data.itemURLImage !== undefined) {
                if (resultImg.data.itemURLImage !== '') {
                    const date = new Date().getTime()
                    temporal.itemURL = `${resultImg.data.itemURLImage}?${date}`
                } else {
                    temporal.itemURL = ''
                }
            } else {
                temporal.itemURL = ''
            }
        }

        await this.setState({ ShortDetail: SpecificDetail, currentItem: temporal })
        var totalRow = {
            InvoiceDate: 'TOTAL',
            QuantityShipped: totalShipped,
            QuantityOrdered: totalOrdered,
            TotalShortD: '$' + pricesFormat(total)
        }
        var copySpecificDetail = JSON.parse(JSON.stringify(SpecificDetail))
        copySpecificDetail.push(totalRow)
        await this.setState({ ShortDetailWithTotal: copySpecificDetail })
        await this.handleModalOpen('showModal1')
    }
    async DetailbyCustomer(info) {
        this.clearOnlyShortDetail()
        const temporal = this.state.currentCustomer
        temporal.CustomerNo = info.CustomerNo
        temporal.CustomerName = info.CustomerName

        let SpecificDetail = []
        var total = 0
        var totalShipped = 0
        var totalOrdered = 0

        for (const item of this.state.AllNoProccessInformation) {
            if (item.CustomerNo === info.CustomerNo) {
                total += item.shorts
                totalShipped += item.QuantityShipped
                totalOrdered += item.QuantityOrdered
                item.TotalShort = item.shorts
                item.UnitCostD = '$' + pricesFormat(item.UnitCost)
                item.UnitPriceD = '$' + pricesFormat(item.UnitPrice)
                item.TotalShortD = '$' + pricesFormat(item.shorts)
                SpecificDetail.push(item)
            }

        }
        temporal.totalShorts = total
        temporal.totalQuantityShipped = totalShipped
        temporal.totalQuantityOrdered = totalOrdered
        SpecificDetail.push()

        SpecificDetail = OrderArrayByDateGenericLines(SpecificDetail, 'InvoiceDate')

  

        await this.setState({ CustomerDetail: SpecificDetail, currentCustomer: temporal })
        var totalRow = {
            InvoiceDate: 'TOTAL',
            QuantityShipped: totalShipped,
            QuantityOrdered: totalOrdered,
            TotalShortD: '$' + pricesFormat(total)
        }
        var copySpecificDetail = JSON.parse(JSON.stringify(SpecificDetail))
        copySpecificDetail.push(totalRow)
        await this.setState({ ShortDetailWithTotal: copySpecificDetail })
        await this.handleModalOpen('showModal1')
    }

    async OrderPriceMargins(option, pos) {
        var temporal = this.state.BodyFilter
        switch (option) {
            case "Item Code":
                temporal = OrderArray(temporal, "ItemCode", this.state.flag)
                this.setState({ BodyFilter: temporal, flag: !this.state.flag })
                break;
            case "Product Line":
                temporal = OrderArray(temporal, "ProductLine", this.state.flag)
                this.setState({ BodyFilter: temporal, flag: !this.state.flag })
                break;
            case "Sales":
                let t1 = await this.orderShorts(temporal, pos, "QuantityShipped")
                await this.setState({ BodyFilter: t1, flag: !this.state.flag })
                break;
            case "Sales ($)":
                let t2 = await this.orderShorts(temporal, pos, "shorts")
                await this.setState({ BodyFilter: t2, flag: !this.state.flag })
                break;
            default:
                break;
        }
    }
    async OrderPriceMargins2(option, pos) {
        var temporal = this.state.BodyFilterCustomer
        switch (option) {
            case "Customer No":
                temporal = OrderArray(temporal, "CustomerNo", this.state.flag)
                this.setState({ BodyFilterCustomer: temporal, flag: !this.state.flag })
                break;
            case "Customer Name":
                temporal = OrderArray(temporal, "CustomerName", this.state.flag)
                this.setState({ BodyFilterCustomer: temporal, flag: !this.state.flag })
                break;
            case "Sales":
                let t1 = await this.orderShorts(temporal, pos, "QuantityShipped")
                await this.setState({ BodyFilterCustomer: t1, flag: !this.state.flag })
                break;
            case "Sales ($)":
                let t2 = await this.orderShorts(temporal, pos, "shorts")
                await this.setState({ BodyFilterCustomer: t2, flag: !this.state.flag })
                break;
            default:
                break;
        }
    }

    async orderShorts(array, conditions, key) {

        const l = array.length

        for (let i = 0; i < l; i++) {
            for (let j = 0; j < l - 1 - i; j++) {

                const pos1 = await getIndexElement(array[j].Detail, 'year', conditions.year)
                const pos2 = await getIndexElement(array[j].Detail[pos1].Months, 'month', conditions.month)
                const pos3 = await getIndexElement(array[j + 1].Detail, 'year', conditions.year)
                const pos4 = await getIndexElement(array[j + 1].Detail[pos3].Months, 'month', conditions.month)
                const m = array[j].Detail[pos1].Months[pos2][key] === '-' ? 0 : Number(array[j].Detail[pos1].Months[pos2][key])
                const n = array[j + 1].Detail[pos3].Months[pos4][key] === '-' ? 0 : Number(array[j + 1].Detail[pos3].Months[pos4][key])
                if (this.state.flag) {
                    if (m > n) {
                        [array[j], array[j + 1]] = await [array[j + 1], array[j]];
                    }
                } else {
                    if (m < n) {
                        [array[j], array[j + 1]] = await [array[j + 1], array[j]];
                    }
                }

            }
        }


        return array
    }

    valueRadioButton = async e => {
        let stat = Number(e.target.value)
        this.setState({ Mode: stat })
        if (stat === 1) {
            
            this.setState({Totals: this.getTotals(this.state.BodyFilter)})
        } else {
            
            this.setState({Totals: this.getTotals(this.state.BodyFilterCustomer)})
        }
    }

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Report of Sales</p>
                <div className='row pb-2'>
                    <div className='col-1'></div>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <p>Start:</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='startSalesFilterDate' type="date" />
                                        </div>
                                    </div>
                                    {this.state.Mode === 1 ?
                                        <div className="d-grid gap-2">
                                            <ExcelDocument
                                                hidden={this.state.Body.length === 0}
                                                archname={'Sales ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname2={['Sales Detail ' + getValueCookie('Company')]}
                                                sheetname={'Sales ' + getValueCookie('Company')}
                                                data={getDataSetSales(this.state.HeaderTable.Row1, this.state.HeaderTable.Row2, this.state.HeaderTable.Row3, this.state.Body, this.state.Totals)}
                                                data2={[getDataSet(this.state.AllInformation, ['Item Code', 'Product Line', 'Description', 'Invoice No', 'Customer No', 'Customer Name', 'Price Level', 'Sales Person', 'Route', 'Date', 'Quantity Ordered', 'Quantity Shipped', 'Unit Price ($)', 'Total Sales ($)'], ['ItemCode', 'ProductLineDesc', 'ItemCodeDesc', 'InvoiceNo', 'CustomerNo', 'BillToName', 'PriceLevel', 'SalespersonNo', 'UDF_RUTA', 'InvoiceDate', 'QuantityOrdered', 'QuantityShipped', 'UnitPrice', 'shorts'])]}
                                            />
                                        </div>

                                        :

                                        <div className="d-grid gap-2">
                                            <ExcelDocument
                                                hidden={this.state.Body.length === 0}
                                                archname={'Sales ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname2={['Sales Detail ' + getValueCookie('Company')]}
                                                sheetname={'Sales ' + getValueCookie('Company')}
                                                data={getDataSetSales2(this.state.HeaderTable2.Row1, this.state.HeaderTable2.Row2, this.state.HeaderTable2.Row3, this.state.BodyCustomer, this.state.Totals)}
                                                data2={[getDataSet(this.state.AllInformation, ['Item Code', 'Product Line', 'Description', 'Invoice No', 'Customer No', 'Customer Name', 'Price Level', 'Sales Person', 'Route', 'Date', 'Quantity Ordered', 'Quantity Shipped', 'Unit Price ($)', 'Total Sales ($)'], ['ItemCode', 'ProductLineDesc', 'ItemCodeDesc', 'InvoiceNo', 'CustomerNo', 'BillToName', 'PriceLevel', 'SalespersonNo', 'UDF_RUTA', 'InvoiceDate', 'QuantityOrdered', 'QuantityShipped', 'UnitPrice', 'shorts'])]}
                                            />
                                        </div>
                                    }


                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row TitleText'>

                                    <div className='col-12'>
                                        <p>End:</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='endSalesFilterDate' type="date" />
                                        </div>
                                    </div>
                                    <div className='col-12 pb-4 InputTextBolder text-center'>
                                        <div className="d-grid gap-2">

                                            <button onClick={() => this.getShorts()} type="button" className="btn blueButton btn-lg">Reload <BsArrowRepeat /></button>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div hidden={this.state.Body.length === 0} className='col-12 pb-1'>
                                <div className="form-check">
                                    <input className="form-check-input textRadioButtonSize" value={1} onChange={this.valueRadioButton} checked={this.state.Mode === 1} type="radio" id='typeItemSalesReport' name="typeSalesReport" />
                                    <label className="form-check-label textRadioButtonSize" htmlFor='typeItemSalesReport' >
                                        Item List
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input textRadioButtonSize" value={2} onChange={this.valueRadioButton} checked={this.state.Mode === 2} type="radio" id='typeCustomerSalesReport' name="typeSalesReport" />
                                    <label className="form-check-label textRadioButtonSize" htmlFor='typeCustomerSalesReport' >
                                        Customer List
                                    </label>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='col-1'></div>
                </div>

                {this.state.Mode === 1 ?
                    <div className='row'>

                        <div hidden={this.state.AllNoProccessInformation.length === 0} className='col-12 pb-2'>
                            <div className="input-group input-group-lg pb-4" >
                                <input type="text" autoComplete='off' className="form-control" id='searchSalesItem' placeholder='Search Item by ItemCode, Line Product, Description...' onChange={this.searchShorts} />
                            </div>
                        </div>

                        <div hidden={this.state.Body.length === 0} className='col-12 tableFixHead'>
                            <table className="table  align-middle">
                                <thead className='text-center'>
                                    {<tr className='bg-primary text-white'>
                                        {this.state.HeaderTable.Row1.map((col, i) => (
                                            <th className={'text-center ' + ((i + 1) % 2 === 1 ? 'bg-primary' : 'bg-success')} key={i} scope="col" colSpan={col.span}>{col.year}</th>
                                        ))}
                                    </tr>}
                                    {<tr className='bg-primary text-white'>
                                        <th className='text-center bg-primary ' colSpan={4}></th>
                                        {
                                            this.state.HeaderTable.Row2.map((col, i) => (
                                                <th className={'text-center ' + ((i + 1) % 2 === 1 ? 'bg-dark' : 'bg-secondary')} key={i} colSpan={3}>{col.month}</th>
                                            ))
                                        }
                                    </tr>}
                                    {<tr className='bg-primary text-white'>
                                        {
                                            this.state.HeaderTable.Row3.map((col, c) => (
                                                col.title.map((tit, t) => (
                                                    <th onClick={() => this.OrderPriceMargins(tit, col)} className={'text-center ' + (col.month === '' ? 'bg-primary' : Number(c) % 2 === 1 ? 'bg-dark' : 'bg-secondary')} key={t}><div className='row text-center'><div className='col-12'>{tit}<div className='col-12'>{tit !== '' ? <> <BsFileArrowUpFill /><BsFileArrowDownFill /></> : <></>}</div></div></div></th>
                                                ))
                                            ))
                                        }
                                    </tr>}

                                </thead>
                                <tbody>
                                    {this.state.BodyFilter.map((element, e) => (
                                        <tr onClick={() => this.DetailofShort(element)} key={uuidv4()} className='text-center'>
                                            <td className='text-center'>{e + 1}</td>
                                            <td className='text-start'>{element.ItemCode}</td>
                                            <td className='text-start'>{element.ProductLine}</td>
                                            <td className='text-start'>{element.ItemCodeDesc}</td>
                                            {element.Detail.map((year) => (
                                                year.Months.map((month) => (
                                                    month.shorts === '-' ?
                                                        <React.Fragment key={uuidv4()}>
                                                            <td className='text-center'>-</td>
                                                            <td className='text-center'></td>
                                                            <td className='text-center'>-</td>
                                                            {/*<td className='text-center'></td>
                                                <td className='text-center'>-</td>*/}
                                                        </React.Fragment>

                                                        :
                                                        <React.Fragment key={uuidv4()}>
                                                            <td className='text-center'>{month.QuantityShipped}</td>
                                                            <td className='text-end'>{month.dolar}</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(month.shorts))}</td>
                                                            {/*<td className='text-end'>{month.dolar}</td>*/}
                                                            {/*<td className='text-end'>{NumberFormat(pricesFormat(month.PurchaseCost))}</td>*/}
                                                        </React.Fragment>
                                                ))
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>TOTALS</td>
                                        {this.state.Totals.map((total, t) => (
                                            <td className={t === 0 ? 'text-center' : 'text-end'} key={t}>{total}</td>
                                        ))}
                                    </tr>

                                </tfoot>
                            </table>
                        </div>
                    </div>
                    :
                    <div className='row'>
                        <div hidden={this.state.AllNoProccessInformation.length === 0} className='col-12 pb-2'>
                            <div className="input-group input-group-lg pb-4" >
                                <input type="text" autoComplete='off' className="form-control" id='searchSalesItem' placeholder='Search Customer by Customer No, Customer Name...' onChange={this.searchShorts2} />
                            </div>
                        </div>
                        <div hidden={this.state.Body.length === 0} className='col-12 tableFixHead'>
                            <table className="table  align-middle">
                                <thead className='text-center'>
                                    {<tr className='bg-primary text-white'>
                                        {this.state.HeaderTable2.Row1.map((col, i) => (
                                            <th className={'text-center ' + ((i + 1) % 2 === 1 ? 'bg-primary' : 'bg-success')} key={i} scope="col" colSpan={col.span}>{col.year}</th>
                                        ))}
                                    </tr>}
                                    {<tr className='bg-primary text-white'>
                                        <th className='text-center bg-primary ' colSpan={3}></th>
                                        {
                                            this.state.HeaderTable2.Row2.map((col, i) => (
                                                <th className={'text-center ' + ((i + 1) % 2 === 1 ? 'bg-dark' : 'bg-secondary')} key={i} colSpan={3}>{col.month}</th>
                                            ))
                                        }
                                    </tr>}
                                    {<tr className='bg-primary text-white'>
                                        {
                                            this.state.HeaderTable2.Row3.map((col, c) => (
                                                col.title.map((tit, t) => (
                                                    <th onClick={() => this.OrderPriceMargins2(tit, col)} className={'text-center ' + (col.month === '' ? 'bg-primary' : Number(c) % 2 === 1 ? 'bg-dark' : 'bg-secondary')} key={t}><div className='row text-center'><div className='col-12'>{tit}<div className='col-12'>{tit !== '' ? <> <BsFileArrowUpFill /><BsFileArrowDownFill /></> : <></>}</div></div></div></th>
                                                ))
                                            ))
                                        }
                                    </tr>}

                                </thead>
                                <tbody>
                                    {this.state.BodyFilterCustomer.map((element, e) => (
                                        <tr onClick={() => this.DetailbyCustomer(element)} key={uuidv4()} className='text-center'>
                                            <td className='text-center'>{e + 1}</td>
                                            <td className='text-start'>{element.CustomerNo}</td>
                                            <td className='text-start'>{element.CustomerName}</td>
                                            {element.Detail.map((year) => (
                                                year.Months.map((month) => (
                                                    month.shorts === '-' ?
                                                        <React.Fragment key={uuidv4()}>
                                                            <td className='text-center'>-</td>
                                                            <td className='text-center'></td>
                                                            <td className='text-center'>-</td>
                                                            {/*<td className='text-center'></td>
                                                        <td className='text-center'>-</td>*/}
                                                        </React.Fragment>

                                                        :
                                                        <React.Fragment key={uuidv4()}>
                                                            <td className='text-center'>{month.QuantityShipped}</td>
                                                            <td className='text-end'>{month.dolar}</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(month.shorts))}</td>
                                                            {/*<td className='text-end'>{month.dolar}</td>*/}
                                                            {/*<td className='text-end'>{NumberFormat(pricesFormat(month.PurchaseCost))}</td>*/}
                                                        </React.Fragment>
                                                ))
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>TOTALS</td>
                                        {this.state.Totals.map((total, t) => (
                                            <td className={t === 0 ? 'text-center' : 'text-end'} key={t}>{total}</td>
                                        ))}
                                    </tr>

                                </tfoot>
                            </table>
                        </div>
                    </div>

                }



                <ModalOrders title={this.state.Mode === 1 ? 'Sales of Item: ' + this.state.currentItem.ItemCode : 'Sales of Customer: ' + this.state.currentCustomer.CustomerName} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>

                    {this.state.Mode === 1 ?
                        <div>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Item Code:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.ItemCode}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Description: </p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.ItemCodeDesc}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Product Line: </p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.ProductLine}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col-12 pt-2 text-center'>
                                            <div className='ItemImg'>
                                                <img key={this.state.currentItem.itemURL} className='pb-5 pt-5' id='ShortsProductImg' src={this.state.currentItem.itemURL !== '' ? this.state.currentItem.itemURL : '/assets/notavailable.png'} alt='ProductImg' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2'></div>
                                <div className='col-3'></div>
                                <div className='col-6'>
                                    <div className="d-grid gap-2">
                                        <ExcelDocument archname={'Sales Item ' + DeleteCharactersNotAccepted(this.state.currentItem.ItemCode) + ' ' + getDateFromReports() + ' ' + getValueCookie('Company')} sheetname={'Shorts Item ' + DeleteCharactersNotAccepted(this.state.currentItem.ItemCode) + ' ' + getDateFromReports() + ' ' + getValueCookie('Company')} data={getDataSet(this.state.ShortDetailWithTotal, ['Invoice No', 'Customer No', 'Customer Name', 'Price Level', 'Sales Person', 'Route', 'Date', 'Quantity Ordered', 'Quantity Shipped', 'Unit Cost', 'Total Sales($)'], ['InvoiceNo', 'CustomerNo', 'BillToName', 'PriceLevel', 'SalespersonNo', 'UDF_RUTA', 'InvoiceDate', 'QuantityOrdered', 'QuantityShipped', 'UnitPriceD', 'TotalShortD'])} />
                                    </div>
                                </div>
                                <div className='col-3'></div>
                            </div>
                            <div className='row pt-2'>
                                <div className='col-12 tableFixHead'>
                                    <table className='table'>
                                        <thead>
                                            <tr className='bg-dark text-light text-center'>
                                                <th className='bg-dark'>Invoice No</th>
                                                <th className='bg-dark'>Type</th>
                                                <th className='bg-dark'>Customer No</th>
                                                <th className='bg-dark'>Customer Name</th>
                                                <th className='bg-dark'>Price Level</th>
                                                <th className='bg-dark'>Sales Person</th>
                                                <th className='bg-dark'>Route</th>
                                                <th className='bg-dark'>Date</th>
                                                <th className='bg-dark'>Quantity Ordered</th>
                                                <th className='bg-dark'>Quantity Shipped</th>
                                                <th className='bg-dark'></th>
                                                <th className='bg-dark'>Unit Price</th>
                                                <th className='bg-dark'></th>
                                                <th className='bg-dark'>Total Sales($)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.ShortDetail.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className='text-center'>{item.InvoiceNo}</td>
                                                        <td className='text-center'>{item.InvoiceType}</td>
                                                        <td className='text-center'>{item.CustomerNo}</td>
                                                        <td className='text-start'>{item.BillToName}</td>
                                                        <td className='text-center'>{item.PriceLevel}</td>
                                                        <td className='text-center'>{item.SalespersonNo}</td>
                                                        <td className='text-start'>{item.UDF_RUTA}</td>
                                                        <td className='text-center'>{DateFormatMAS90(item.InvoiceDate)}</td>
                                                        <td className='text-center'>{NumberFormat(item.QuantityOrdered)}</td>
                                                        <td className='text-center'>{NumberFormat(item.QuantityShipped)}</td>
                                                        <td className='text-end'>{'$'}</td>
                                                        <td className='text-end'>{NumberFormat(pricesFormat(item.UnitPrice))}</td>
                                                        <td className='text-end'>{'$'}</td>
                                                        <td className='text-end'>{NumberFormat(pricesFormat(item.shorts))}</td>
                                                    </tr>
                                                ))
                                            }


                                        </tbody>
                                        <tfoot className='tfoot'>
                                            <tr className='bg-secondary text-light'>
                                                <td className='text-center'></td>
                                                <td className='text-center'></td>
                                                <td className='text-center'></td>
                                                <td className='text-start'></td>
                                                <td className='text-center'></td>
                                                <td className='text-center'></td>
                                                <td className='text-start'></td>
                                                <td className='text-center'>{'TOTAL'}</td>
                                                <td className='text-center'>{NumberFormat(pricesFormat(this.state.currentItem.totalQuantityOrdered))}</td>
                                                <td className='text-center'>{NumberFormat(pricesFormat(this.state.currentItem.totalQuantityShipped))}</td>
                                                <td className='text-center'></td>
                                                <td className='text-end'></td>
                                                <td className='text-end'>{'$'}</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(this.state.currentItem.totalShorts))}</td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Customer No:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentCustomer.CustomerNo}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Customer Name: </p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentCustomer.CustomerName}</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-4' />


                                <div className='col-2'></div>
                                <div className='col-3'></div>
                                <div className='col-6'>
                                    <div className="d-grid gap-2">
                                        <ExcelDocument archname={'Sales Customer ' + DeleteCharactersNotAccepted(this.state.currentCustomer.CustomerName) + ' ' + getDateFromReports() + ' ' + getValueCookie('Company')} sheetname={'Sales ' + DeleteCharactersNotAccepted(this.state.currentCustomer.CustomerName)} data={getDataSet(this.state.ShortDetailWithTotal, ['Invoice No', 'Item Code', 'Description', 'Price Level', 'Sales Person', 'Route', 'Date', 'Quantity Ordered', 'Quantity Shipped', 'Unit Cost', 'Total Sales($)'], ['InvoiceNo', 'ItemCode', 'ItemCodeDesc', 'PriceLevel', 'SalespersonNo', 'UDF_RUTA', 'InvoiceDate', 'QuantityOrdered', 'QuantityShipped', 'UnitPriceD', 'TotalShortD'])} />
                                    </div>
                                </div>
                                <div className='col-3'></div>
                            </div>
                            <div className='row pt-2'>
                                <div className='col-12 tableFixHead'>
                                    <table className='table'>
                                        <thead>
                                            <tr className='bg-dark text-light text-center'>
                                                <th className='bg-dark'>Invoice No</th>
                                                <th className='bg-dark'>Type</th>
                                                <th className='bg-dark'>Item Code</th>
                                                <th className='bg-dark'>Description</th>
                                                <th className='bg-dark'>Price Level</th>
                                                <th className='bg-dark'>Sales Person</th>
                                                <th className='bg-dark'>Route</th>
                                                <th className='bg-dark'>Date</th>
                                                <th className='bg-dark'>Quantity Ordered</th>
                                                <th className='bg-dark'>Quantity Shipped</th>
                                                <th className='bg-dark'></th>
                                                <th className='bg-dark'>Unit Price</th>
                                                <th className='bg-dark'></th>
                                                <th className='bg-dark'>Total Sales($)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.CustomerDetail.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className='text-center'>{item.InvoiceNo}</td>
                                                        <td className='text-center'>{item.InvoiceType}</td>
                                                        <td className='text-center'>{item.ItemCode}</td>
                                                        <td className='text-start'>{item.ItemCodeDesc}</td>
                                                        <td className='text-center'>{item.PriceLevel}</td>
                                                        <td className='text-center'>{item.SalespersonNo}</td>
                                                        <td className='text-start'>{item.UDF_RUTA}</td>
                                                        <td className='text-center'>{DateFormatMAS90(item.InvoiceDate)}</td>
                                                        <td className='text-center'>{NumberFormat(item.QuantityOrdered)}</td>
                                                        <td className='text-center'>{NumberFormat(item.QuantityShipped)}</td>
                                                        <td className='text-end'>{'$'}</td>
                                                        <td className='text-end'>{NumberFormat(pricesFormat(item.UnitPrice))}</td>
                                                        <td className='text-end'>{'$'}</td>
                                                        <td className='text-end'>{NumberFormat(pricesFormat(item.shorts))}</td>
                                                    </tr>
                                                ))
                                            }


                                        </tbody>
                                        <tfoot className='tfoot'>
                                            <tr className='bg-secondary text-light'>
                                                <td className='text-center'></td>
                                                <td className='text-center'></td>
                                                <td className='text-center'></td>
                                                <td className='text-start'></td>
                                                <td className='text-center'></td>
                                                <td className='text-center'></td>
                                                <td className='text-start'></td>
                                                <td className='text-center'>{'TOTAL'}</td>
                                                <td className='text-center'>{NumberFormat(pricesFormat(this.state.currentCustomer.totalQuantityOrdered))}</td>
                                                <td className='text-center'>{NumberFormat(pricesFormat(this.state.currentCustomer.totalQuantityShipped))}</td>
                                                <td className='text-center'></td>
                                                <td className='text-end'></td>
                                                <td className='text-end'>{'$'}</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(this.state.currentCustomer.totalShorts))}</td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </div>
                        </div>
                    }

                </ModalOrders>


            </div>
        )
    }

}