import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';




export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {

    }

    print() {
        console.log(this.props.data.Months)
    }


    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h5>{this.props.Title}</h5>
                    </div>

                    <div className='backgroundWhite col-sm-12 '>
                        <div className='col-12'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-center text-danger' scope="col"><h5>{"Not Started Total: "}</h5></th>
                                        <th className='text-center text-warning' scope="col"><h5>{"In Process Total: "}</h5></th>
                                        <th className='text-center text-success' scope="col"> <h5>{"Finished Total: "}</h5></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center text-danger'><h5>{"$ " + NumberFormat(pricesFormat(this.props.Totals.NotStarted))}</h5></td>
                                        <td className='text-center text-warning'><h5>{"$ " + NumberFormat(pricesFormat(this.props.Totals.InProcess))}</h5></td>
                                        <td className='text-center text-success'><h5>{"$ " + NumberFormat(pricesFormat(this.props.Totals.Finished))}</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='pb-3' />
                        <div className="tableFixHeadSmall">
                            <table className='table align-middle'>
                                <thead className='bg-primary text-white'>
                                    <tr>
                                        <th className='text-center bg-primary '>Order No</th>
                                        <th className='text-center bg-primary '>Type</th>
                                        <th className='text-center bg-primary '>Customer No</th>
                                        <th className='text-center bg-primary '>Customer Name</th>
                                        <th className='text-center bg-primary '>Date</th>
                                        <th className='text-center bg-primary '>Days in System</th>
                                        <th className='text-center bg-primary '>Picking Status</th>
                                        <th className="text-center bg-primary ">Sales Person</th>
                                        <th className='text-center bg-primary '>Have Invoice?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.data.map((order, o) => (
                                        <tr className={order.ColorRow} key={o}>
                                            <td className='text-start'>{order.SalesOrderNo}</td>
                                            <td className='text-start'>{order.TypeinLetters}</td>
                                            <td className='text-center'>{order.CustomerNo}</td>
                                            <td className='text-start'>{order.BillToName}</td>
                                            <td className='text-center'>{order.OrderDate}</td>
                                            <td className='text-center'>{order.DaysSystem}</td>
                                            <td className={order.StatusColor + ' text-center'}>{order.StatusText}</td>
                                            <td className='text-center'>{order.SalespersonNo}</td>
                                            <td className='text-center'>{order.ExistInv}</td>

                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>




            </>
        )
    }
}
